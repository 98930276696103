import React from "react";
import { useHistory } from "react-router-dom";

//Componets
import { LayoutWrapper } from "components/Layout/Index";
import SettingsDetails from "components/Details/SettingsDetails";

//Services
import SessionService from "services/Session";

//Styles
import * as indexStyled from "../styles/index-styles";

import { useSettings } from "../store/settings";

function Settings() {
  let history = useHistory();

  const session = JSON.parse(localStorage.getItem("session") || "{}");

  const { environment } = useSettings((state) => state.settings); 
  const validBusinessIds = [3739, 110]

  const viewOnlyAndAgent = 
  ( session.role === 'Store.Agent' || 
    session.role === 'Store.ViewOnly' || 
    session.role === 'Business.ViewOnly' || 
    session.role === 'Business.Agent') && validBusinessIds.includes(Number(session.businessId)); 

  if (viewOnlyAndAgent) {
    history.push("/transactions")
  }

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  return (
    <>
      <LayoutWrapper>
        <indexStyled.RowList className=" transactions_details">
          <SettingsDetails />
        </indexStyled.RowList>
      </LayoutWrapper>
    </>
  );
}

export default Settings;
