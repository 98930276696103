import create from "zustand";
import { persist } from "zustand/middleware";

type Nuvemshop = {
  code: string;
  storeId: number;
};

interface NuvemshopState {
  nuvemshop: Nuvemshop;
  setNuvemshopCode: (code: string) => void;
  setNuvemshopStoreId: (storeId: number) => void;
  resetStore: () => void;
}

const initialValue: Nuvemshop = {
  code: "",
  storeId: 0,
};

export const useNuvemshop = create(
  persist<NuvemshopState>(
    (set) => ({
      nuvemshop: { ...initialValue },
      setNuvemshopCode: (code: string) => {
        set(({ nuvemshop }) => ({
          nuvemshop: {
            ...nuvemshop,
            code: code,
          },
        }));
      },
      setNuvemshopStoreId: (storeId: number) => {
        set(({ nuvemshop }) => ({
          nuvemshop: {
            ...nuvemshop,
            storeId: storeId,
          },
        }));
      },
      resetStore: () => {
        set(() => ({
          nuvemshop: { ...initialValue },
        }));
      },
    }),
    {
      name: "@Primefy:nuvemshop",
    }
  )
);
