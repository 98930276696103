import styled, { css } from 'styled-components';

interface StepsProps {
  step: number;
}

export const OnboardingContainer = styled.section`
  min-height: 100vh;
  min-width: 100%;
  padding: 20px;
  background: ${(props) => props.theme.primaryColors.primary};
  text-align: center;
  overflow-x: hidden;
`;

export const StepsContainer = styled.div<StepsProps>`
  width: 32px;
  margin: 0 auto 21px auto;
  display: flex;
  justify-content: space-between;

  .step__one,
  .step__two,
  .step__three {
    min-width: 8px;
    min-height: 8px;
    border-radius: 50%;
    border: 1px solid #fff;
  }

  .step__one {
    ${(props) =>
      props.step === 1 &&
      css`
        background: #fff;
        transition: all 0.1s ease;
      `}
  }

  .step__two {
    ${(props) =>
      props.step === 2 &&
      css`
        background: #fff;
        transition: all 0.1s ease;
      `}
  }

  .step__three {
    ${(props) =>
      props.step === 3 &&
      css`
        background: #fff;
        transition: all 0.1s ease;
      `}
  }
`;

export const Nav = styled.div`
  width: 100%;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

export const StepOne = styled.div`
  opacity: 0;
  animation: fadeStep1 0.3s linear forwards;
  transition: all 0.3s ease-in-out;

  @keyframes fadeStep1 {
    to {
      opacity: 1;
    }
  }

  .limitted-width {
    max-width: 300px;
    margin: 0 auto;
  }

  .limitted-height {
    min-height: 72px;
  }

  strong {
    margin: 32px 0;
    min-height: 72px;
  }

  .c-illustration {
    /* position: relative; */
    min-height: 347px;
    /* margin: 0px 0 34px 0; */

    .cloud__1 {
      position: absolute;
      max-width: 50px;
      transform: translate3D(70px, 50px, 0);
      animation: balanceTopDownCloud1 3s infinite alternate;
      transition: all 0.3s ease-in-out;

      @keyframes balanceTopDownCloud1 {
        from {
          transform: translate3D(70px, 50px, 0);
        }
        to {
          transform: translate3D(60px, 50px, 0);
        }
      }
    }

    .rocket {
      max-width: 300px;
      transform: translate3D(0px, 40px, 0);
      animation: balanceTopDown 3s infinite alternate;
      transition: all 0.3s ease-in-out;

      @keyframes balanceTopDown {
        from {
          transform: translate3D(0px, 40px, 0);
        }
        to {
          transform: translate3D(0px, 50px, 0);
        }
      }
    }

    .cloud__2 {
      max-width: 50px;
      position: absolute;
      transform: translate3D(-50px, 300px, 0);
      animation: balanceTopDownCloud2 3s infinite alternate;
      transition: all 0.3s ease-in-out;

      @keyframes balanceTopDownCloud2 {
        from {
          transform: translate3D(-50px, 300px, 0);
        }
        to {
          transform: translate3D(-60px, 300px, 0);
        }
      }
    }
  }

  .c-text {
    p {
      min-height: 66px;
      margin: 26px 0 20px 0;
      font-family: 'Brandon Text Light' !important;
      font-size: 16px;
      line-height: 22px;
      color: #fff;
    }
  }
`;

export const StepTwo = styled(StepOne)`
  opacity: 0;
  animation: fadeStep2 0.3s linear forwards;
  transition: all 0.3s ease-in-out;

  @keyframes fadeStep2 {
    to {
      opacity: 1;
    }
  }

  .c-illustration-2 {
    max-height: 347px;
    min-height: 347px;

    .dashboard {
      position: relative;
      /* transform: translateX(80px); */

      .checkout-examples {
        position: absolute;
        /* width: 216px;*/
        z-index: 100;
        /* transform: translate3d(-150px, -20px, 0); */

        .device__1 {
          max-width: 126.38px;
          max-height: 172.7px;
          z-index: 10;
          opacity: 0;
          position: relative;
          transform: translate3d(25px, 110px, 0) rotate(-6deg);
          animation: rotateAnimation cubic-bezier(.29,.04,.23,1) 1s forwards;
        }

        .device__2 {
          max-width: 151.26px;
          max-height: 153.44px;
          z-index: 1;
          opacity: 0;
          position: relative;
          transform: translate3d(-75px, 160px, 0) rotate(-6deg);
          animation: rotateAnimation2 cubic-bezier(.29,.04,.23,1) 1s forwards;
          animation-delay: 0.2s;
        }

        .device__3 {
          max-width: 168.96px;
          max-height: 143.15px;
          z-index: -10;
          opacity: 0;
          position: relative;
          transform: translate3d(20px, 40px, 0) rotate(-6deg);
          animation: rotateAnimation3 cubic-bezier(.29,.04,.23,1) 1s forwards;
          animation-delay: 0.4s;
        }

        @keyframes rotateAnimation {
          to {
            opacity: 1;
            transform: translate3d(25px, 115px, 0) rotate(0deg);
            transition: all 1s ease-in-out;
          }
        }

        @keyframes rotateAnimation2{
          to{
            opacity: 1;
            transform: translate3d(-75px, 165px, 0) rotate(0deg);
            transition: all 1s ease-in-out;
          }
        }

        @keyframes rotateAnimation3{
          to{
            opacity: 1;
            transform: translate3d(20px, 45px, 0) rotate(0deg);
            transition: all 1s ease-in-out;
          }
        }
      }

      .dashboard-bg {
        max-width: 294px;
        opacity: 0;
        overflow: hidden;
        z-index: 30 !important;
        transform: translateX(-10px);
        animation: fadeXDashboard 2s forwards;

        @keyframes fadeXDashboard {
          to{
            opacity: 1;
            transition: all 1s ease-in;
            transform: translateX(0px);
          }
        }
      }
    }
  }
`;

export const StepThree = styled(StepOne)`
  opacity: 0;
  animation: fadeStep3 0.3s linear forwards;
  transition: all 0.3s ease-in-out;

  @keyframes fadeStep3 {
    to {
      opacity: 1;
    }
  }

  .c-illustration-3 {
    max-height: 347px;
    min-height: 347px;
    position: relative;

    .maquininha,
    .maquininha-low {
      position: absolute;
      /* max-width: 215px; */
      transform: translate3d(-342px, -350px, 0) scale(0.35);
    }

    .maquininha-low {
      transform: translate3d(-297px, -325px, 0) scale(0.356);
    }

    .card__1 {
      /* max-width: 120px; */
      transform: translate3d(-320px, 30px, 0) scale(0.3);
      position: absolute;
      animation: balanceTopDownCard1 2s infinite alternate;
      transition: all 0.3s ease-in-out;

      @keyframes balanceTopDownCard1 {
        from {
          transform: translate3D(-320px, 30px, 0) scale(0.3);
        }
        to {
          transform: translate3D(-330px, 30px, 0) scale(0.3);
        }
      }
    }

    .card__2 {
      position: absolute;
      max-width: 125px;
      transform: translate3d(-106px, 195px, 0) rotate(0deg);
      animation: balanceTopDownCard2 2s infinite alternate;
      transition: all 0.3s ease-in-out;
      animation-delay: 0.3s;

      @keyframes balanceTopDownCard2 {
        from {
          transform: translate3d(-106px, 195px, 0) rotate(0deg);
        }
        to {
          transform: translate3d(-107px, 200px, 0) rotate(0deg);
        }
      }
    }

    .card__3 {
      max-height: 135px;
      transform: translate3d(110px, 40px, 0);

      animation: balanceTopDownCard3 2s infinite alternate;
      transition: all 0.3s ease-in-out;
      animation-delay: 0.8s;

      @keyframes balanceTopDownCard3 {
        from {
          transform: translate3d(110px, 40px, 0);
        }
        to {
          transform: translate3d(108px, 50px, 0);
        }
      }
    }
  }
`;

export const Footer = styled.div`
  min-height: 100px;

  .already-have-account {
    margin-top: 16px !important;

    a {
      font-family: 'Brandon Text Bold' !important;
      color: #fff !important;
    }
  }
`;
