import moment from "moment";

type ICustomer = {
  firstName: string;
  lastName: string;
  businessName: string;
  surname: string;
  identificationNumber: string;
  email: string;
  phone: string;
  birthdate: string;
  address: {
    street: string;
    number: string;
    zipcode: string;
    reference: string;
    district: string;
    city: string;
    state: number;
    country: number;
  };
};

type TManualOrder = {
  planId?: number;
  name?: string;
  frequency: number;
  paymentTypes: number[];
  grace: number;
  productCategory: string;
  orderNumber: string;
  amount: string;
  description: string;
  expiration: string;
  includeFeeTransfer: boolean;
  installments: number;
  duedate: string;
  whenSend: string;
  sendToCustomer: string;
  warrantyDays: string;
  generateAllBoletos: boolean;
  maxCharges?: number;
};

type ManualCardProps = {
  customer: ICustomer;
  progressStep: number;
  handleErrors: any;
  manualOrder: TManualOrder;
};

export function manualCardValidations({
  manualOrder,
  customer,
  progressStep,
  handleErrors,
}: ManualCardProps) {
  let errors = [];
  let isCnpj = false;

  if (progressStep > 1) {
    if (isNaN(parseFloat(manualOrder.amount)) && progressStep === 2)
      errors.push("orderAmount");

    if (customer.identificationNumber.length > 14) {
      isCnpj = true;
    } else if (customer.identificationNumber.length === 0) {
      isCnpj = false;
    }

    if (isCnpj) {
      const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;
      if (!cnpjRegex.test(customer.identificationNumber)) errors.push("cnpj");

      if (
        customer.businessName.length > 80 ||
        customer.businessName.length === 0
      )
        errors.push("businessName");
    } else {
      if (customer.firstName.length > 45 || customer.firstName.length === 0)
        errors.push("customerName");

      if (customer.lastName.length > 45 || customer.lastName.length === 0)
        errors.push("customerLastname");

      if (customer.identificationNumber.indexOf("/") > 0) {
        let cpfClean = customer.identificationNumber
          .replace(".", "")
          .replace(".", "")
          .replace("/", "");

        let newCpf = cpfClean.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3-$4"
        );

        const cpfRegex = /\d{3}.\d{3}.\d{3}-\d{2}/;
        if (!cpfRegex.test(newCpf)) errors.push("cpf");
      } else {
        const cpfRegex = /\d{3}.\d{3}.\d{3}-\d{2}/;

        if (customer.identificationNumber.length === 11) {
          let formattedIdentificationNumber =
            customer.identificationNumber.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/,
              "$1.$2.$3-$4"
            );

          if (!cpfRegex.test(formattedIdentificationNumber)) errors.push("cpf");
        } else {
          if (!cpfRegex.test(customer.identificationNumber)) errors.push("cpf");
        }
      }
    }

    if (customer.phone.length < 14) errors.push("customerPhone");

    const emailExpression = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/;
    if (!emailExpression.test(customer.email)) errors.push("customerEmail");
  }

  handleErrors(errors);

  return errors.length === 0;
}
