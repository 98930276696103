// @ts-nocheck

import React, { useState } from "react";
import InputMask from "react-input-mask";
import { useIntl } from "react-intl";

//Styles
import * as styled from "./credit-card-input-styles";

//Assets
import cardIcon from "assets/images/icon-card.svg";
import visaIcon from "assets/images/icon-card-visa.svg";
import masterIcon from "assets/images/icon-card-mastercard.svg";
import maestroIcon from "assets/images/icon-card-maestro.svg";
import auraIcon from "assets/images/icon-card-aura.svg";
import hiperIcon from "assets/images/icon-card-hipercard.svg";
import jcbIcon from "assets/images/icon-card-jcb.svg";
import americanExpressIcon from "assets/images/icon-card-americanexpress.svg";
import dinersIcon from "assets/images/icon-card-dinersclub.svg";
import eloIcon from "assets/images/icon-card-elo.svg";
import discoverIcon from "assets/images/icon-card-discover.svg";

interface InputProps {
  hasCardError: boolean;
  removeError(e: boolean): void;
  hasExpirationError: boolean;
  hasCvvError: boolean;
  cardNumber: string;
  setCardNumber(e: string): any;
  expiration: string;
  setExpiration(e: string): any;
  cvv: string;
  setCvv(e: string): any;
  color: string;
}

const MinimalistInputElement = ({
  hasCardError,
  removeError,
  hasExpirationError,
  hasCvvError,
  cardNumber,
  setCardNumber,
  expiration,
  setExpiration,
  cvv,
  setCvv,
  color,
}: InputProps) => {
  const [showCardBand, setShowCardBand] = useState(false);
  const [iconCardBand, setIconCardBand] = useState("");

  const intl = useIntl();

  function handleCaractere(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target.value;

    setCardNumber(target);

    let targetSpaceless = target.replace(/\s/g, "");

    if (
      targetSpaceless.match("^4") &&
      !targetSpaceless.match("^(438935)|(451416)|(4576)|(4011)")
    ) {
      setShowCardBand(true);
      setIconCardBand(visaIcon);
    } else if (targetSpaceless.match("^5[1-5]")) {
      setShowCardBand(true);
      setIconCardBand(masterIcon);
    } else if (targetSpaceless.match("^6(?:011|5[0-9]{2})")) {
      setShowCardBand(true);
      setIconCardBand(discoverIcon);
    } else if (
      targetSpaceless.match(
        "^(636368)|(438935)|(504175)|(451416)|(636297)|(5067)|(4576)|(4011)"
      )
    ) {
      setShowCardBand(true);
      setIconCardBand(eloIcon);
    } else if (targetSpaceless.match("^3(0[0-5]|[68])")) {
      setShowCardBand(true);
      setIconCardBand(dinersIcon);
    } else if (targetSpaceless.match("^3[47]")) {
      setShowCardBand(true);
      setIconCardBand(americanExpressIcon);
    } else if (targetSpaceless.match("^(?:2131|1800|35)")) {
      setShowCardBand(true);
      setIconCardBand(jcbIcon);
    } else if (
      targetSpaceless.match("^(606282)|(3841)") &&
      !targetSpaceless.match("^(6011)")
    ) {
      setShowCardBand(true);
      setIconCardBand(hiperIcon);
    } else if (targetSpaceless.match("^(?:5[0678]|6304|6390|67)")) {
      setShowCardBand(true);
      setIconCardBand(maestroIcon);
    } else if (targetSpaceless.match("^(50)")) {
      setShowCardBand(true);
      setIconCardBand(auraIcon);
    } else {
      setShowCardBand(false);
      removeError(false);
    }
  }

  return (
    <styled.InputElement>
      {showCardBand && (
        <div className="card-band">
          <img src={iconCardBand} alt="" />
        </div>
      )}
      {!showCardBand && (
        <styled.CardIcon hasCardError={hasCardError}></styled.CardIcon>
      )}

      <div className="container">
        <InputMask
          mask="9999 9999 9999 9999"
          maskChar={null}
          value={cardNumber}
          onChange={(event) => handleCaractere(event)}
        >
          <styled.InputNumberCard
            autoComplete={"" + Math.random()}
            name="card-number"
            placeholder={intl.formatMessage({ id: "credit.card.number" })}
            hasCardError={hasCardError}
            color={color}
          ></styled.InputNumberCard>
        </InputMask>
      </div>

      <div className="container-expiration">
        <InputMask
          mask="99/99"
          maskChar={null}
          value={expiration}
          onChange={(event) => setExpiration(event.target.value)}
        >
          <styled.InputValidate
            autoComplete={"" + Math.random()}
            name="validate"
            placeholder={intl.formatMessage({ id: "credit.card.expiration" })}
            hasExpirationError={hasExpirationError}
            color={color}
          />
        </InputMask>
      </div>

      <div className="container-cvv">
        <InputMask
          mask="9999"
          maskChar={null}
          value={cvv}
          onChange={(event) => setCvv(event.target.value)}
        >
          <styled.InputCVV
            autoComplete={"" + Math.random()}
            name="cvv"
            placeholder={intl.formatMessage({ id: "credit.card.cvv" })}
            hasCvvError={hasCvvError}
            color={color}
          />
        </InputMask>
      </div>
    </styled.InputElement>
  );
};

export default MinimalistInputElement;
