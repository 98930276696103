import axios from 'axios';

interface UserProps {
  id: number;
}

interface PersonalData {
  id: string;
  name: string;
  username: string;
  confirmPassword: string | null;
  password: string | null;
  permission?: string | null;
}

interface StoreDataProps {
  socialName: string,
  encodedLogoImage: string | null,
}

interface CreateUser {
  name: string,
	username:string,
	password:string,
	permission:{
		entityId: number,
		entityType: string,
		role: number
	}
}

class UserProfileService {
    
  // async getInfos() {
  //   const session = JSON.parse(localStorage.getItem("session") || "{}");

  //   return axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/me`, {
  //     headers: {
  //       'Authorization': `Bearer ${session.token}`
  //     }
  //   });
  // }

  async getCompanyInfos(id: UserProps) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if(session.entityType === 'Administrator') {
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/stores`,
      {
        headers: {
          'Authorization': `Bearer ${session.token}`
        }
      });
    }else {
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${id}`, {
        headers: {
          'Authorization': `Bearer ${session.token}`,
          'Content-Type': "application/json"
        }
      });
    }
  }

  async getCompanyInfosNumber(id: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${id}`, {
      headers: {
        'Authorization': `Bearer ${session.token}`,
        'Content-Type': "application/json"
      }
    });
  }

  async putUserInfos(id: number, personalData: PersonalData) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    
    const data = {
      id: id,
      name: personalData.name,
      username: personalData.username,
      confirmPassword: personalData.confirmPassword,
      password: personalData.password,
      permission: null
    }

    axios.put(`${process.env.REACT_APP_API_BASE_URL}/users/me`, data, {
      headers: {
        'Authorization': `Bearer ${session.token}`
      }
    });

    axios.put(`${process.env.REACT_APP_API_BASE_URL_STAGING}/users/me`, data, {
      headers: {
        'Authorization': `Bearer ${session.token}`
      }
    });
  }


  async putStore(id: number, storeData: StoreDataProps) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

      return axios.put(`${process.env.REACT_APP_API_BASE_URL}/stores/${id}`, {
        socialName: storeData.socialName,
        encodedLogoImage: storeData.encodedLogoImage
      }, {
        headers: {
          'Authorization': `Bearer ${session.token}`
        }
      });
  }

  async getUsers() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    if(session.entityType === 'Administrator') {
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/users`, {
        headers: {
            'Authorization': `Bearer ${session.token}`
        }
      });
    }else {
      if(session.entityType === 'Business'){
        return axios.get(`${process.env.REACT_APP_API_BASE_URL}/businesses/${session.businessId}/users`, {
          headers: {
              'Authorization': `Bearer ${session.token}`
          }
        }); 
      }
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/users`, {
        headers: {
          'Authorization': `Bearer ${session.token}`
        }
      });
    }
  }

  async updateUser(userId: number, user: CreateUser) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    const data = {
      name: user.name,
      username: user.username,
      password: user.password,
      isAdmin: user.permission.entityType === 'Administrator' ? true : false,
      permission: {
        entityId: user.permission.entityId,
        entityType: user.permission.entityType,
        role: user.permission.role
      }
    }
    
    if(session.entityType === 'Administrator') {
      axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/users/${userId}`, data, {
        headers: {
          'Authorization': `Bearer ${session.token}`
        }
      });
      
    } else if(session.entityType === 'Business'){
        
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/users/${userId}`, data, {
          headers: {
              'Authorization': `Bearer ${session.token}`
          }
        }); 
      }
    
    return axios.put(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/users/${userId}`, data, {
      headers: {
        'Authorization': `Bearer ${session.token}`
      }
    });
  }

  async postUser(user: CreateUser) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    const data = {
      name: user.name,
      username: user.username,
      password: user.password,
      isAdmin: user.permission.entityType === 'Administrator' ? true : false,
      permission: {
        entityId: user.permission.entityId,
        entityType: user.permission.entityType,
        role: user.permission.role
      }
    }

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/users`, data, {
        headers: {
            'Authorization': `Bearer ${session.token}`
        }
    });  
  }

  async deleteUser(id: number, entityType: string, entityId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if(session.entityType === 'Administrator') {
      axios.delete(`${process.env.REACT_APP_API_BASE_URL}/admin/users/${id}`,
      {
        headers: {
            'Authorization': `Bearer ${session.token}`
        }
      }); 
    }
    
    if(session.entityType === 'Business') {
      axios.delete(`${process.env.REACT_APP_API_BASE_URL}/store/${session.storeId}/users/${id}`,
      {
        headers: {
            'Authorization': `Bearer ${session.token}`
        }
      }); 
    }

    else {
      axios.delete(`${process.env.REACT_APP_API_BASE_URL}/store/${session.storeId}/users/${id}`,
      {
        headers: {
            'Authorization': `Bearer ${session.token}`
        }
      }); 
    }
  }

  async userFees() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(`${process.env.REACT_APP_API_BASE_URL_V2}/stores/${session.storeId}/fees`,
    {
      headers: {
          'Authorization': `Bearer ${session.token}`
      }
    }); 
  }

  async enotasConnection(identificationNumber: string, invoiceIssuerApiKey: undefined | string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.put(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/connect-enotas`, {
      identificationNumber: identificationNumber,
      invoiceIssuerApiKey: invoiceIssuerApiKey
    },
    {
      headers: {
          'Authorization': `Bearer ${session.token}`
      }
    }); 
  }

  async getUserToken() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/token`, {
    },
    {
       headers: {
         'Authorization': `Bearer ${session.token}`
       }
    });
   }

}

export default new UserProfileService()
