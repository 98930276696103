import axios from 'axios';

class ExportReportsService {

  grossSalesExport(year: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
      if(session.entityType === 'Business'){
          return axios.get(`${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/gross_sales/year/${year}/export`, {
              headers: {
                  'Authorization': `Bearer ${session.token}`
              }
          }); 
      }
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/gross_sales/year/${year}/export`, {
          headers: {
              'Authorization': `Bearer ${session.token}`
          }
      });  
  }


  async newCustomersExport(year: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
      if(session.entityType === 'Business'){
          return axios.get(`${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/new_customers_quantity/year/${year}/export`, {
              headers: {
                  'Authorization': `Bearer ${session.token}`
              }
          }); 
      }
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/new_customers_quantity/year/${year}/export`, {
          headers: {
              'Authorization': `Bearer ${session.token}`
          }
      });  
  }

  async perMonthExport(year: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
      if(session.entityType === 'Business'){
          return axios.get(`${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/per_month/year/${year}/export`, {
              headers: {
                  'Authorization': `Bearer ${session.token}`
              }
          }); 
      }
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/per_month/year/${year}/export`, {
          headers: {
              'Authorization': `Bearer ${session.token}`
          }
      });  
  }

  async getPaymentMethods() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
      if(session.entityType === 'Business'){
          return axios.get(`${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/report-by-payment-method`, {
              headers: {
                  'Authorization': `Bearer ${session.token}`
              }
          }); 
      }
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/report-by-payment-method`, {
          headers: {
              'Authorization': `Bearer ${session.token}`
          }
      });  
  }

  async getRefunds() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
      if(session.entityType === 'Business'){
          return axios.get(`${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/report-by-refund`, {
              headers: {
                  'Authorization': `Bearer ${session.token}`
              }
          }); 
      }
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/report-by-refund`, {
          headers: {
              'Authorization': `Bearer ${session.token}`
          }
      });  
  }

}

export default new ExportReportsService();