import LangResource from "./LangResource";
import pt from "./pt";
export default class Resource {
  messages: LangResource[] = [];

  getMessages(lang: string) {
    let result = this.messages.find(x => x.langKeys.some(_ => _ === lang));

    if (!!result){
      return result.messages;
    }

    return pt.messages;
  }
}
