import React, { useState, useEffect } from "react";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

//Services
import CustomerServices from "../../services/Customers";

//Components
import DetailItem from "./DetailItem";
import Input from "components/Forms/InputFloatLabel";
import Select from "components/Forms/Select";
import Buttons from "components/Forms/Buttons";

//Helpers
import brStates from "helpers/states-format";
import countries from "helpers/countries-format";
import formatIdentificationNumbert from "helpers/formatIdentificationNumber";

//Styles
import * as styled from "./details-styles";

const PersonalCustomerDetails = ({ customer, setCustomer }: any) => {
  let location = useLocation();

  const [isProcessingEditCustomer, setIsProcessingEditCustomer] =
    useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCnpj, setIsCnpj] = useState(false);
  const [customerIdentificationNumber, setCustomerIdentificationNumber] =
    useState("");

  let intl = useIntl();

  const [edittingCustomer, setEdittingCustomer] = useState(false);

  const customerAddressData = customer.customer.address
    ? [
        {
          label: "zip.code",
          value: customer.customer.address.zipCode,
          name: "zipCode",
        },
        {
          label: "street",
          value: customer.customer.address.street,
          name: "street",
        },
        {
          label: "house.number",
          value: customer.customer.address.number,
          name: "number",
        },
        {
          label: "district",
          value: customer.customer.address.district,
          name: "district",
        },
        { label: "city", value: customer.customer.address.city, name: "city" },
      ]
    : [];

  useEffect(() => {
    if (/admin/.test(location.pathname)) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    if (customer.customer.identificationNumber) {
      if (customer.customer.identificationNumber.length > 11) {
        setIsCnpj(true);
      } else {
        setIsCnpj(false);
      }

      setCustomerIdentificationNumber(
        formatIdentificationNumbert(customer.customer.identificationNumber)
      );
    }
  }, [customer]);

  function editCustomer() {
    setIsProcessingEditCustomer(true);
    const relatedStateId = customer.customer.address
      ? brStates.find(
          (state) => state.value === customer.customer.address.state
        )
      : undefined;

    const relatedCountryId = customer.customer.address
      ? countries.find(
          (country) => country.value === customer.customer.address.country
        )
      : undefined;

    const finalCustomer = {
      ...customer.customer,
      identificationNumber: customer.customer.identificationNumber,
      address: {
        ...customer.customer.address,
        state: relatedStateId ? relatedStateId.id : 26,
        country: relatedCountryId ? relatedCountryId.id : 0,
      },
    };

    CustomerServices.editCustomer(customer.customer.id, finalCustomer)
      .then((response) => {
        setEdittingCustomer(false);
      })
      .finally(() => {
        setIsProcessingEditCustomer(false);
      });
  }

  function handleCustomer(name: string, value: string) {
    setCustomer((prevState: any) => {
      return {
        ...prevState,
        customer: { ...prevState.customer, [name]: value },
      };
    });
  }

  function handleCustomerAddress(name: string, value: string) {
    setCustomer((prevState: any) => {
      return {
        ...prevState,
        customer: {
          ...prevState.customer,
          address: {
            ...prevState.customer.address,
            [name]: value,
          },
        },
      };
    });
  }

  return (
    <styled.BoxDetails className="border-bottom">
      <styled.CustomerFlexHeader>
        <FormattedMessage id="transactions.customer.details" />

        {!edittingCustomer ? (
          <Buttons
            mode="unfilled"
            label={intl.formatMessage({
              id: "settings.team.list.edit",
            })}
            handler={() => setEdittingCustomer(true)}
          />
        ) : (
          <div className="flex-buttons-container">
            {!isProcessingEditCustomer ? (
              <Buttons
                mode="filled"
                label={intl.formatMessage({
                  id: "user.profile.ui.save",
                })}
                handler={editCustomer}
              />
            ) : (
              <Buttons
                mode="filled-processing"
                disabled={isProcessingEditCustomer}
                label=""
                handler={() => {}}
              />
            )}

            <Buttons
              mode="unfilled"
              label={intl.formatMessage({
                id: "home.withdrawall.cancel",
              })}
              disabled={isProcessingEditCustomer}
              handler={() => setEdittingCustomer(false)}
            />
          </div>
        )}
      </styled.CustomerFlexHeader>

      <styled.DetailsItems>
        <styled.DetailsItems>
          {!isCnpj &&
          customer.customer.firstName !== "" &&
          !edittingCustomer ? (
            <DetailItem
              label={intl.formatMessage({ id: "detail.order.name.first" })}
              value={customer !== undefined ? customer.customer.firstName : ""}
              loading={customer === undefined ? { width: 100 } : undefined}
            />
          ) : null}

          {isCnpj && customer.customer.firstName !== "" && !edittingCustomer ? (
            <DetailItem
              label={intl.formatMessage({
                id: "detail.order.business.name",
              })}
              value={customer !== undefined ? customer.customer.firstName : ""}
              loading={customer === undefined ? { width: 100 } : undefined}
            />
          ) : null}

          {customer.customer.firstName !== "" && edittingCustomer ? (
            <div className="info-detail-item">
              <h5>
                {intl.formatMessage({
                  id: !isCnpj
                    ? "detail.order.name.first"
                    : "detail.order.business.name",
                })}
              </h5>
              <Input
                required={false}
                mode="named"
                name="firstName"
                value={customer.customer.firstName}
                placeholder=""
                handler={handleCustomer}
              />
            </div>
          ) : null}

          {!isCnpj && customer.customer.surname !== "" && !edittingCustomer ? (
            <DetailItem
              label={intl.formatMessage({
                id: "detail.order.name.surname",
              })}
              value={customer !== undefined ? customer.customer.surname : ""}
              loading={customer === undefined ? { width: 100 } : undefined}
            />
          ) : (
            <div className="info-detail-item">
              <h5>{intl.formatMessage({ id: "detail.order.name.surname" })}</h5>
              <Input
                required={false}
                mode="named"
                name="surname"
                value={customer.customer.surname}
                placeholder=""
                handler={handleCustomer}
              />
            </div>
          )}
          {customer.customer.identificationNumber &&
          customer.customer.identificationNumber !== "" &&
          !edittingCustomer ? (
            <DetailItem
              label={intl.formatMessage({
                id: `detail.order.${isCnpj ? "cnpj" : "cpf"}`,
              })}
              value={customer !== undefined ? customerIdentificationNumber : ""}
              loading={customer === undefined ? { width: 75 } : undefined}
            />
          ) : (
            <div className="info-detail-item">
              <h5>
                {intl.formatMessage({
                  id: `detail.order.${isCnpj ? "cnpj" : "cpf"}`,
                })}
              </h5>
              <Input
                required={false}
                mode="named"
                name="cpf"
                value={customerIdentificationNumber}
                placeholder=""
                handler={handleCustomer}
              />
            </div>
          )}
          {!isCnpj && !edittingCustomer ? (
            <DetailItem
              label={intl.formatMessage({ id: "detail.order.dob" })}
              value={
                customer
                  ? customer.customer.birthdate
                    ? moment(customer.customer.birthdate).format("DD/MM/YYYY")
                    : ""
                  : ""
              }
              loading={customer === undefined ? { width: 75 } : undefined}
            />
          ) : (
            <div className="info-detail-item">
              <h5>
                {intl.formatMessage({
                  id: "detail.order.dob",
                })}
              </h5>
              <Input
                required={false}
                mode="named"
                name="birthdate"
                value={customer.customer.birthdate}
                placeholder=""
                handler={handleCustomer}
              />
            </div>
          )}
          {!edittingCustomer ? (
            <>
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.email" })}
                value={customer !== undefined ? customer.customer.email : ""}
                loading={customer === undefined ? { width: 125 } : undefined}
              />
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.phone" })}
                value={customer !== undefined ? customer.customer.phone : ""}
                loading={customer === undefined ? { width: 75 } : undefined}
              />
            </>
          ) : (
            <>
              <div className="info-detail-item">
                <h5>
                  {intl.formatMessage({
                    id: "detail.order.email",
                  })}
                </h5>
                <Input
                  required={false}
                  mode="named"
                  name="email"
                  value={customer.customer.email}
                  placeholder=""
                  handler={handleCustomer}
                />
              </div>

              <div className="info-detail-item">
                <h5>
                  {intl.formatMessage({
                    id: "detail.order.phone",
                  })}
                </h5>
                <Input
                  required={false}
                  mode="named"
                  name="phone"
                  value={customer.customer.phone}
                  placeholder=""
                  handler={handleCustomer}
                />
              </div>
            </>
          )}
          {customer.customer.address ? (
            <>
              {customerAddressData.map((item, index) =>
                !edittingCustomer ? (
                  <DetailItem
                    label={intl.formatMessage({
                      id: `detail.order.${item.label}`,
                    })}
                    value={item.value}
                    loading={
                      customer === undefined ? { width: 125 } : undefined
                    }
                    key={Math.random() + index}
                  />
                ) : (
                  <div className="info-detail-item" key={item.label}>
                    <h5>
                      {intl.formatMessage({ id: `detail.order.${item.label}` })}
                    </h5>
                    <Input
                      required={false}
                      mode="named"
                      name={item.name}
                      value={item.value}
                      placeholder=""
                      handler={handleCustomerAddress}
                    />
                  </div>
                )
              )}

              {!edittingCustomer ? (
                <>
                  <DetailItem
                    label={intl.formatMessage({
                      id: `detail.order.state`,
                    })}
                    value={customer.customer.address.state}
                    loading={
                      customer === undefined ? { width: 125 } : undefined
                    }
                  />

                  <DetailItem
                    label={intl.formatMessage({
                      id: `detail.order.country`,
                    })}
                    value={customer.customer.address.country}
                    loading={
                      customer === undefined ? { width: 125 } : undefined
                    }
                  />
                </>
              ) : (
                <>
                  <div className="info-detail-item">
                    <h5>{intl.formatMessage({ id: "detail.order.state" })}</h5>
                    <Select
                      data={brStates}
                      mode="named"
                      name="state"
                      value={customer.customer.address.state}
                      placeholder={""}
                      handler={handleCustomerAddress}
                    />
                  </div>

                  <div className="info-detail-item">
                    <h5>
                      {intl.formatMessage({ id: "detail.order.country" })}
                    </h5>
                    <Select
                      data={countries}
                      mode="named"
                      name="country"
                      value={customer.customer.address.country}
                      placeholder={""}
                      handler={handleCustomerAddress}
                    />
                  </div>
                </>
              )}
            </>
          ) : null}
        </styled.DetailsItems>
      </styled.DetailsItems>
    </styled.BoxDetails>
  );
};

export default PersonalCustomerDetails;
