const axios = require("axios");

type TNewProduct = {
  title: string;
  amount: string;
  description: string;
  currency: string;
  redirectUrl?: string;
  nameUnitLabel?: string;
  images: any;
  shippableProduct?: {
    [key: string]: string;
  };
};

class Product {
  async createProduct(product: TNewProduct) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/products`,
      {
        name: product.title,
        description: product.description,
        unitPrice: product.amount,
        currency: "BRL",
        shippable: true,
        unitLabel: product.nameUnitLabel,
        status: "active",
        url: product.redirectUrl,
        metadata: {},
        packageDimension: product.shippableProduct,
        images: product.images,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async updateProductImage(images: any, productId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/products/${productId}/images`,
      { images: images },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async getProductDetails(productId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/products/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  }

  async getAllProducts() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session.entityType === "Business") {
      return await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/products`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    } else {
      return await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/products`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
  }

  async getAllProductOrders(productId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/products/${productId}/orders`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async updateProduct(productId: number, newData: any) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/products/${productId}`,
      newData,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async deleteProduct(productId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/products/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async deleteImageProduct(productId: number, imageId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/products/${productId}/images/${imageId}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }
}

export default new Product();
