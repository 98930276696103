import React from "react";
import { useIntl } from "react-intl";

import leftArrow from "../../../../../assets/images/global/leaft-arrow.svg";

import * as styled from "../../payment-request-styles";

export default function Progress5StepBar({
  progressStep,
  handlePreviousStep,
  creatingPlan,
  title,
  hidePrevious,
}: any) {
  const intl = useIntl();

  return (
    <>
      <styled.Header>
        <div className="column-header">
          {progressStep > 1 && !hidePrevious ? (
            <div className="return-container" onClick={handlePreviousStep}>
              <img src={leftArrow} alt="left arrow" />
              <p>
                {intl.formatMessage({ id: "request.payment.header.return" })}
              </p>
            </div>
          ) : null}

          {title}
        </div>
      </styled.Header>

      <styled.ProgressBar5StepsContainer step={progressStep}>
        <div className="background">
          <div className="progress-1"></div>
        </div>
        <div className="background">
          <div className="progress-2"></div>
        </div>
        <div className="background">
          <div className="progress-3"></div>
        </div>
        <div className="background">
          <div className="progress-4"></div>
        </div>
        <div className="background">
          <div className="progress-5"></div>
        </div>
      </styled.ProgressBar5StepsContainer>
    </>
  );
}
