import React from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

//Services
import SessionService from "services/Session";

//Components
import { LayoutWrapper } from "../../components/Layout/Index";
import Progress5StepBar from "components/Layout/RequestPayment/components/Progress5StepBar";

import { PersonalInformation } from "./components/PersonalInformation";
import { BusinessInformation } from "./components/BusinessInformation";
import { AddressInformation } from "./components/AddressInformation";
import { BankAccountInformation } from "./components/BankAccountInformation";
import { DocumentsInformation } from "./components/DocumentsInformation";

//Styles
import * as styled from "./styles";

// Hooks
import { useStoreRegister } from "./hooks";

function CreateStore() {
  const {
    stepProgressTitle,
    lastStepOfStoreRegister,
    handleStepOfStoreRegister,
    isStoreWithCNPJ,
    store,
    progressStep,
    setProgressStep,
  } = useStoreRegister();

  const history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();
    history.push("/login");
  }

  const intl = useIntl();

  return (
    <>
      <LayoutWrapper>
        <styled.CreateStoreContainer>
          <div className="centered-container">
            <styled.FormContainer>
              <Progress5StepBar
                hidePrevious={true}
                progressStep={progressStep}
                handlePreviousStep={() => {}}
                creatingPlan={false}
                title={intl.formatMessage({
                  id: stepProgressTitle[progressStep - 1],
                })}
              />

              {!isStoreWithCNPJ ? (
                <>
                  {progressStep === 1 ? (
                    <PersonalInformation
                      setProgressStep={setProgressStep}
                      isStoreWithCNPJ={isStoreWithCNPJ}
                      store={store}
                    />
                  ) : null}
                  {progressStep === 2 ? (
                    <BusinessInformation
                      setProgressStep={setProgressStep}
                      isStoreWithCNPJ={isStoreWithCNPJ}
                      store={store}
                    />
                  ) : null}
                  {progressStep === 3 ? (
                    <AddressInformation
                      setProgressStep={setProgressStep}
                      isStoreWithCNPJ={isStoreWithCNPJ}
                      store={store}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  {progressStep === 1 ? (
                    <BusinessInformation
                      setProgressStep={setProgressStep}
                      isStoreWithCNPJ={isStoreWithCNPJ}
                      store={store}
                    />
                  ) : null}
                  {progressStep === 2 ? (
                    <AddressInformation
                      setProgressStep={setProgressStep}
                      isStoreWithCNPJ={isStoreWithCNPJ}
                      store={store}
                    />
                  ) : null}
                  {progressStep === 3 ? (
                    <PersonalInformation
                      setProgressStep={setProgressStep}
                      isStoreWithCNPJ={isStoreWithCNPJ}
                      store={store}
                    />
                  ) : null}
                </>
              )}

              {progressStep === 4 ? (
                <BankAccountInformation
                  setProgressStep={setProgressStep}
                  isStoreWithCNPJ={isStoreWithCNPJ}
                  store={store}
                />
              ) : null}
              {progressStep === 5 ? (
                <DocumentsInformation
                  setProgressStep={setProgressStep}
                  isStoreWithCNPJ={isStoreWithCNPJ}
                  store={store}
                />
              ) : null}
            </styled.FormContainer>
          </div>
        </styled.CreateStoreContainer>
      </LayoutWrapper>
    </>
  );
}

export default CreateStore;
