import React from "react";
import Skeleton from "react-loading-skeleton";

//Styles
import * as styled from "../Details/details-styles";

export const DynamicDetailItem = ({ label, children, loading }: any) => {
  return (
    <>
      <styled.DetailItem>
        <div className={`label`}> {label} </div>
        {loading ? (
          <Skeleton width={loading.width} className="skeleton" />
        ) : (
          <div className={`value`}>{children}</div>
        )}
      </styled.DetailItem>
    </>
  );
};
