import styled from "styled-components";

interface ICreditCardItemProps {
  isTheLastItem: boolean;
}

export const CreditCardItemContainer = styled.div<ICreditCardItemProps>`
  border-bottom: 1px solid
    ${(props) =>
      !props.isTheLastItem
        ? props.theme.backgroundColors.fifth
        : "transparent"};
  .g-detail-item {
    border: 0;
    padding: 16px 0;
  }
`;

export const CreditCardDetailItem = styled.div`
  border: 0;
  padding: 16px 0;
  .g-detail-item__value,
  .value--full-width {
    img {
      width: 32px;
    }
    .button-container {
      margin-left: 30%;
    }
  }
  .value--full-width {
    width: 70%;
    .default {
      background: ${(props) => props.theme.primaryColors.fifth};
      width: 100px;
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
`;
