import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

//Services
import WebhooksServices from "../../services/Webhooks";

//Components
import Buttons from "../Forms/Buttons";
import Input from "../Forms/InputFloatLabel";
import SelectStoresButtonModal from "../Layout/Modals/SelectStoresButtonModal";
import WHEventsButtonModal from "../Layout/Modals/WHEventsButtonModal";
import ErrorMessage from "./ErrorMessage";

//Store
import { useAuth } from "../../store/auth";

interface WebhookConnectionForm {
  destination: number;
  isEditting?: boolean;
  webhookToEdit?: any;
  setVisibleEditWebhookForm?: any;
  setFinishedTheEdition?: any;
}

const WebhookConnectionForm = ({
  destination,
  isEditting,
  webhookToEdit,
  setVisibleEditWebhookForm,
  setFinishedTheEdition,
}: WebhookConnectionForm) => {
  const intl = useIntl();
  const { session } = useAuth();

  const [enotasBusinessId, setEnotasBusinessId] = useState("");
  const [iDForStoreModal, setIDForStoreModal] = useState("");
  const [webhookEvent, setWebhookEvent] = useState<string[]>([]);

  const isViewingAsBusiness = session.entityType === "Business";

  const [isLoading, setIsLoading] = useState(false);

  const [webhook, setWebhook] = useState(() => {
    if (!isEditting) {
      return {
        endpoint: "",
        description: "",
        destination: destination,
        events: [],
      };
    }

    return webhookToEdit;
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const webhookEventsToSend: any[] = [];

    const webhookEvents = webhookEvent.map((webhookEvent) => {
      webhookEventsToSend.push({ type: parseInt(webhookEvent) });
    });

    handleWebhook("events", webhookEventsToSend);
  }, [webhookEvent]);

  function handleWebhook(name: string, value: any) {
    setWebhook((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const [webhookFormErrors, setWebhookFormErrors] = useState<string[]>([]);

  function validateWebHookForm() {
    let errors: string[] = [];

    if (webhook.endpoint === "") {
      errors.push("endpoint");
    }

    if (webhook.description === "") {
      errors.push("description");
    }

    if (webhook.events.length === 0) {
      errors.push("events");
    }

    setWebhookFormErrors(errors);
    return errors.length === 0;
  }

  function connectNewWebhook() {
    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    const session = JSON.parse(localStorage.getItem("session") || "{}");
    let currentStoreId = isViewingAsBusiness
      ? iDForStoreModal
      : session.storeId;

    if (validateWebHookForm()) {
      WebhooksServices.connectAnWebhook(webhook, currentStoreId)
        .then((response) => {
          setIsLoading(false);
          setSuccessMessage(
            `${intl.formatMessage({ id: "webhook.success.message" })}`
          );

          setWebhook({
            endpoint: "",
            description: "",
            destination: destination,
            events: [],
          });

          setFinishedTheEdition(true);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setErrorMessage(
            `${intl.formatMessage({ id: "webhook.error.message" })}`
          );
        });
    }
  }

  function submitEdittedWebhook() {
    setIsLoading(true);

    WebhooksServices.editAWebhook(webhook)
      .then((response) => {
        setIsLoading(false);
        setVisibleEditWebhookForm(false);
        setFinishedTheEdition(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  return (
    <>
      <div className="input-item margin-top-24">
        <h5>
          {intl.formatMessage({ id: "webhook.connection.endpoint.label" })}
        </h5>

        <Input
          required={true}
          mode="named"
          value={webhook.endpoint}
          name="endpoint"
          handler={handleWebhook}
        />
        <ErrorMessage
          errors={webhookFormErrors}
          wantedError="endpoint"
          errorMessage={intl.formatMessage({
            id: "webhook.error.message.default",
          })}
        />
      </div>

      <div className="input-item margin-top-24">
        <h5>
          {intl.formatMessage({ id: "webhook.connection.description.label" })}
        </h5>

        <Input
          required={true}
          mode="named"
          value={webhook.description}
          name="description"
          handler={handleWebhook}
        />
        <ErrorMessage
          errors={webhookFormErrors}
          wantedError="description"
          errorMessage={intl.formatMessage({
            id: "webhook.error.message.default",
          })}
        />
      </div>

      {isViewingAsBusiness && !isEditting ? (
        <div className="input-item margin-top-24">
          <h5>
            {intl.formatMessage({ id: "webhook.connection.store.label" })}
          </h5>

          <SelectStoresButtonModal
            handler={setEnotasBusinessId}
            setID={setIDForStoreModal}
            labelWithStoreNameAndId={false}
            storeId={isEditting ? webhookToEdit.storeId : 0}
          />
        </div>
      ) : null}

      <div className="input-item margin-top-24">
        <h5>{intl.formatMessage({ id: "webhook.connection.events.label" })}</h5>

        <WHEventsButtonModal
          handler={setWebhookEvent}
          clear={() => {}}
          eventsToEdit={isEditting ? webhook.events : []}
        />
        <ErrorMessage
          errors={webhookFormErrors}
          wantedError="events"
          errorMessage={intl.formatMessage({
            id: "webhook.error.message.default",
          })}
        />
      </div>

      <div className="margin-top-24 margin-bottom-24">
        {!isLoading && !isEditting ? (
          <Buttons
            mode="filled"
            label={intl.formatMessage({ id: "integrations.add" })}
            handler={connectNewWebhook}
          />
        ) : !isLoading && isEditting ? (
          <Buttons
            mode="filled"
            label={intl.formatMessage({
              id: "user.profile.team.save.changes.button.label",
            })}
            handler={submitEdittedWebhook}
          />
        ) : (
          <Buttons mode="filled-processing" label="" handler={() => {}} />
        )}

        {successMessage !== "" && (
          <span className="success">{successMessage}</span>
        )}
        {errorMessage !== "" && <span className="error">{errorMessage}</span>}
      </div>
    </>
  );
};

export default WebhookConnectionForm;
