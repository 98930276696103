import styled, { css } from 'styled-components';

type TTipModal = {
  topElement: number;
  leftElement: number;
  step: number;
  side?: string;
  highLightWidth?: number;
  highLightHeight?: number;
}

type TFirstTipModal = {
  documentHeight: number;
}

type TWindow = {
  documentHeight: number;
  step: number;
}

export const RelativeContainer = styled.div`
  position: relative;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const InsideAbsoluteContainer = styled.div<TFirstTipModal>`
  position: absolute;
  min-width: 100vw;
  min-height: ${props => props.documentHeight + 'px'};
`;

export const ModalContainer = styled.div<TTipModal>`
  background: ${props => props.theme.backgroundColors.primary};
  border: 2px solid ${props => props.theme.backgroundColors.third};
  box-sizing: border-box;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;

  ${( props ) => props.side === 'bottom' && 
      css`
        top: calc((${(props.topElement + (props.highLightHeight ? props.highLightHeight : 0)) + 'px'}) + 20px);
        left: calc((${(props.leftElement + (props.highLightWidth ? (props.highLightWidth / 2) : 0)) + 'px'}));

        &:after {
          content: '';
          position: absolute;
          min-width: 16px;
          min-height: 16px;
          top: -0.9em;
          right: 50%;
          transform: rotate(45deg);
          background: ${props => props.theme.backgroundColors.primary};
          border-left: 2px solid ${props => props.theme.backgroundColors.third};
          border-top: 2px solid ${props => props.theme.backgroundColors.third};
        }
      `
  }

  ${( props ) => props.side === 'bottom-left' && 
      css`
        top: calc((${(props.topElement + (props.highLightHeight ? props.highLightHeight : 0)) + 'px'}) + 20px + (${[8, 9].includes(props.step) ? 48 + 'px' : 0 + 'px'}));
        left: calc((${props.leftElement + 'px'}));

        &:after {
          content: '';
          position: absolute;
          min-width: 16px;
          min-height: 16px;
          top: -0.9em;
          right: 80%;
          transform: rotate(45deg);
          background: ${props => props.theme.backgroundColors.primary};
          border-left: 2px solid ${props => props.theme.backgroundColors.third};
          border-top: 2px solid ${props => props.theme.backgroundColors.third};
        }
      `
  }

  ${( props ) => props.side === 'bottom-center' && 
      css`
        top: calc((${(props.topElement + (props.highLightHeight ? props.highLightHeight : 0)) + 'px'}) + 20px);
        left: calc((${(props.leftElement - ((props.highLightWidth ? (props.highLightWidth / 2) : 0))) + 'px'}));

        &:after {
          content: '';
          position: absolute;
          min-width: 16px;
          min-height: 16px;
          top: -0.9em;
          right: 50%;
          transform: rotate(45deg);
          background: ${props => props.theme.backgroundColors.primary};
          border-left: 2px solid ${props => props.theme.backgroundColors.third};
          border-top: 2px solid ${props => props.theme.backgroundColors.third};
        }
      `
  }

  ${( props ) => props.side === 'right' && 
      css`
        top: calc((${(props.topElement + (props.highLightHeight ? (props.highLightHeight / 4) : 0)) + 'px'}));
        left: calc((${(props.leftElement + (props.highLightWidth ? props.highLightWidth : 0)) + 'px'}) + 1%);

        @media screen and (max-width: 1440px) {
          left: calc((${(props.leftElement + (props.highLightWidth ? props.highLightWidth : 0)) + 'px'}) + 2%);
        }

        &:after {
          content: '';
          position: absolute;
          min-width: 16px;
          min-height: 16px;
          top: 45%;
          left: -0.9em;
          transform: rotate(-45deg);
          background: ${props => props.theme.backgroundColors.primary};
          border-left: 2px solid ${props => props.theme.backgroundColors.third};
          border-top: 2px solid ${props => props.theme.backgroundColors.third};
        }
      `
  }

  ${( props ) => props.side === 'left' && 
      css`
        top: calc((${(props.topElement + (props.highLightHeight ? (props.highLightHeight / 4) : 0)) + 'px'}));
        left: calc((${(props.leftElement - (props.highLightWidth ? (props.highLightWidth / 2) : 0)) + 'px'}) - 3.5%);

        @media screen and (max-width: 1440px) {
          left: calc((${(props.leftElement - (props.highLightWidth ? (props.highLightWidth / 2) : 0)) + 'px'}) - 11%);
        }

        &:after {
          content: '';
          position: absolute;
          min-width: 16px;
          min-height: 16px;
          top: 45%;
          right: -0.9em;
          transform: rotate(135deg);
          background: ${props => props.theme.backgroundColors.primary};
          border-left: 2px solid ${props => props.theme.backgroundColors.third};
          border-top: 2px solid ${props => props.theme.backgroundColors.third};
        }
      `
  }

  ${( props ) => props.side === 'top-left' && 
      css`
        top: calc((${(props.topElement - (props.highLightHeight ? (props.highLightHeight * 4) : 0)) + 'px'}) - 15px);
        left: calc((${(props.leftElement) + 'px'}));

        &:after {
          content: '';
          position: absolute;
          min-width: 16px;
          min-height: 16px;
          bottom: -7%;
          left: 20%;
          transform: rotate(225deg);
          background: ${props => props.theme.backgroundColors.primary};
          border-left: 2px solid ${props => props.theme.backgroundColors.third};
          border-top: 2px solid ${props => props.theme.backgroundColors.third};
        }
      `
  }
  
  max-width: 300px;
  transform: scale(0.9);
  transition: all ease-in-out .1s;
  z-index: 9999999999;
  display: flex;
  flex-direction: column;
  padding: 16px;

  .text {
    font-size: 14px;
    color: ${props => props.theme.contentColors.secondary};
  }

  .buttons-container {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
    }

    a {
      font-size: 14px;
      color: ${props => props.theme.primaryColors.primary};
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.primaryColors.primary};
      }
    }
  }

  .intern-container {

    .text-container {
      display: flex;
      justify-content: space-between;

      h5 {
        font-size: 18px;
      }

      p {
        max-width: 290px;
        margin-top: 8px;
        margin-bottom: 30px;
      }

      .img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
      }
    }
  }

  @keyframes scaleModal {
    to {
      transform: scale(1);
    }
  }

  @keyframes reveserScameModal {
    95% {
      transform: scale(0.7);
    }

    100% {
      opacity: 0;
    }
  }
`;

export const FirstModalContainer = styled(ModalContainer)<TTipModal>`
  top: 25%;
  left: 35%;
  transform: translateY(50%);
  min-width: 590px;
  max-width: 590px;

  @media screen and (max-width: 1440px) {
    left: 28%;
    top: 15%;
  }
`;

export const MaskContainer = styled.div<TWindow>`
  position: absolute;
  z-index: 9999;
  min-width: 100vw;
  min-height: ${props => props.documentHeight + 'px'};
  top: 0;
  left: 0;

  svg {
    min-width: 100vw;
    min-height: ${props => props.documentHeight + 'px'};

    defs {
      mask {
        rect {
          &:last-child {
            border-radius: 3px;
            rx: 3; 
            ry: 3;
          }
        }
      }
    }
   
  }

  svg text {
    text-anchor: middle;
  }

  .alpha {
    fill: #999;
  }

  .base {
    mask: url(#mask);
  }

`;