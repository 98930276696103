import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { produce } from "immer";

//Services
import StoreServices from "../../../services/Store";
import WebhooksServices from "../../../services/Webhooks";

//Store
import { useAuth } from "../../../store/auth";

//Types
import { NuvemShopConnectedStores, NuvemshopPaymentMethods } from "../types";

export const useIntegrations = ({
  iDForStoreModal,
}: {
  iDForStoreModal: string | number;
}) => {
  const { session } = useAuth();
  const { control, watch, setValue } = useForm();

  const isViewingAsBusiness = session.entityType === "Business";
  const watchForm = watch();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isNuvemshopConnected, setIsNuvemshopConnected] = useState(false);
  const [emptyNuvemshopPaymentMethods, setEmptyNuvemshopPaymentMethods] = useState(false);
  const [loadingSaveOnHeader, setLoadingSaveOnHeader] = useState(false);
  const [nuvemShopConnectedStores, setNuvemShopConnectedStores] = useState<
    NuvemShopConnectedStores[]
  >([]);
  
  const [nuvemshopPaymentMethods, setNuvemshopPaymentMethods] = useState<NuvemshopPaymentMethods>({
    ccard: false,
    boleto: false,
    pix: false
  });
  
  useEffect(() => {
    if(!nuvemshopPaymentMethods.ccard && !nuvemshopPaymentMethods.boleto && !nuvemshopPaymentMethods.pix && iDForStoreModal !== "") {
      setEmptyNuvemshopPaymentMethods(true);
      setErrorMessage("webhook.connection.nuvemshop.payment.methods.error");
    }else {
      setEmptyNuvemshopPaymentMethods(false);
      setErrorMessage("");
    }
    
  }, [nuvemshopPaymentMethods])

  useEffect(() => {
    if (!isViewingAsBusiness) {
      
      StoreServices.getStoreByID()
        .then((response) => {
          let nuvemshopConnection = response.data.store.connectors.find(
            (connector: any) => {
              return connector.tag === "nuvemshop";
            }
            );

          if (nuvemshopConnection && nuvemshopConnection.isActive) {
            setValue(
              "nuvemshop.maxCharges",
              nuvemshopConnection.maxInstallments
            );
            setIsNuvemshopConnected(true);
            
            setNuvemshopPaymentMethodsValues(nuvemshopConnection.storeConnectorPaymentOptions);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      
      StoreServices.getStores()
        .then((response) => {
          let nuvemshopConnectedStores = response.data.reduce(
            (acc: any, curr: any) => {
              const isStoreConnected = curr.connectors.some(
                (connector: any) => connector.tag === "nuvemshop"
              );

              if (isStoreConnected) {
                setIsNuvemshopConnected(true);
                const nuvemShopConnector = curr.connectors.find((connector: any) => connector.tag === "nuvemshop");
                
                const newStore = {
                  id: curr.id,
                  name: curr.name,
                  identificationNumber: curr.identificationNumber,
                  connector: nuvemShopConnector
                };
                return [...acc, newStore];
              }

              return [...acc];
            },
            []
          );

          setNuvemShopConnectedStores(nuvemshopConnectedStores);
         
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isViewingAsBusiness]);
  
  useEffect(() => {
    if(isViewingAsBusiness) {
      const selectedStore = nuvemShopConnectedStores.find((store) => store.id === iDForStoreModal);
      
      setNuvemshopPaymentMethodsValues(selectedStore?.connector.storeConnectorPaymentOptions)
    }
  }, [iDForStoreModal])
  
  const setNuvemshopPaymentMethodsValues = (storeConnectorPaymentMethods: any) => {
    const updatedNuvemshopPaymentMethods = {
      ccard: false,
      boleto: false,
      pix: false,
    }
    
    if(storeConnectorPaymentMethods && storeConnectorPaymentMethods.length > 0) {
      
      storeConnectorPaymentMethods.forEach((connectorPaymentType: any) => {
        if(connectorPaymentType.supportedPaymentType === "credit" && connectorPaymentType.isActive) {
          updatedNuvemshopPaymentMethods.ccard = true;
          
          setValue(
            "nuvemshop.maxCharges",
            connectorPaymentType.maxInstallments
          );
        }
        
        if(connectorPaymentType.supportedPaymentType === "pix" && connectorPaymentType.isActive) {
          updatedNuvemshopPaymentMethods.pix = true
        }
        
        if(connectorPaymentType.supportedPaymentType === "boleto" && connectorPaymentType.isActive) {
          updatedNuvemshopPaymentMethods.boleto = true
        }
      });
    }
    
    
    setNuvemshopPaymentMethods(updatedNuvemshopPaymentMethods);
  }

  const handleNuvemshopMaxInstallments = () => {
    setLoadingSaveOnHeader(true);
    
    WebhooksServices.updateNuvemshopMaxInstallments(
      watchForm.nuvemshop.maxCharges,
      isViewingAsBusiness ? iDForStoreModal : session.storeId,
      nuvemshopPaymentMethods
    )
      .then((response) => {
        setLoadingSaveOnHeader(false);
        setSuccessMessage("nuvemshop.success.message");
      })
      .catch((err) => {
        console.log(err);
        setLoadingSaveOnHeader(false);
        setErrorMessage("nuvemshop.error.message");
      });
  };

  const handleOpenNewPageOnConnect = (url: string) => {
    window.open(url, "_blank");
  };
  
  const handleNuvemshopPaymentMethods = (paymentMethod: string) => {
    const currentPaymentMethods = produce(nuvemshopPaymentMethods, (draft) => {
      draft[paymentMethod] = !draft[paymentMethod];
    });
    
    setNuvemshopPaymentMethods(currentPaymentMethods);
  }

  return {
    Controller,
    control,
    isViewingAsBusiness,
    isNuvemshopConnected,
    handleOpenNewPageOnConnect,
    handleNuvemshopMaxInstallments,
    loadingSaveOnHeader,
    successMessage,
    errorMessage,
    nuvemShopConnectedStores,
    nuvemshopPaymentMethods,
    setNuvemshopPaymentMethods,
    handleNuvemshopPaymentMethods,
    emptyNuvemshopPaymentMethods
  };
};
