// @ts-nocheck

import React, { InputHTMLAttributes } from "react";

//Styles
import * as styled from "../styles";
import * as formStyled from "../../styles";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  label: string;
  icon?: string;
  iconHandler?: any;
  autoComplete?: any;
  mask?: string;
  bgColors?: string;
  currencySymbol?: string;
  isInputWithoutBorder?: boolean;
  fixedSymbol?: string;
  isCurrency?: boolean;
  disableCurrencyInput?: boolean;
  borderSide?: "" | "left" | "right" | "bottom";
  bottomSeparator?: boolean;
  ref?: any;
}

export const Input = ({
  placeholder = "",
  label,
  icon,
  iconHandler,
  mask,
  bgColors,
  currencySymbol = "R$",
  isInputWithoutBorder,
  fixedSymbol,
  isCurrency,
  disableCurrencyInput,
  borderSide,
  bottomSeparator,
  ref,
  ...rest
}: IInputProps) => {
  return (
    <formStyled.FormItemsContainer>
      <div className="form-item">
        <h5>{label}</h5>

        <styled.Input
          bgColors={bgColors}
          borderSide={borderSide}
          bottomSeparator={bottomSeparator}
          isInputWithoutBorder={isInputWithoutBorder}
        >
          <input
            placeholder={placeholder}
            autoComplete={"" + Math.random()}
            {...rest}
          />
          {icon && (
            <styled.Icon onClick={iconHandler} icon={icon}></styled.Icon>
          )}
        </styled.Input>
      </div>
    </formStyled.FormItemsContainer>
  );
};
