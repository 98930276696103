import styled, { css } from "styled-components";

interface IconProps {
  icon: string;
}

interface ISelectProps {
  isValid: boolean;
  bgColors?: string;
  bottomSeparator?: boolean;
}

export const Select = styled.div<ISelectProps>`
  background: ${(props) => props.theme.backgroundColors.primary};
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
  width: 100%;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: ${(props) => props.theme.textColors.primary};
  padding: 0 8px;

  img {
    cursor: pointer;
    transition: all 0.3s;
  }

  select {
    font-size: 14px;
    width: 100%;
    color: ${(props) => props.theme.textColors.primary};
    background: transparent !important;
    border: 1px solid transparent;
    font-family: "Brandon Text Medium" !important;
    color: ${(props) => props.theme.contentColors.secondary};

    option {
      background: ${(props) => props.theme.backgroundColors.fourth};
      color: ${(props) => props.theme.textColors.primary};
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  ${(props) =>
    props.bottomSeparator &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.backgroundColors.eighth} !important;
      border-radius: 0px;
    `}

  .bold-option {
    font-size: 14px;
    font-family: "Brandon Text Medium" !important;
    margin-right: 8px;
    color: ${(props) => props.theme.contentColors.secondary};
  }
`;

export const CleanSelect = styled(Select)`
  border: 1px solid transparent !important;
`;

export const Icon = styled.div<IconProps>`
  background-color: ${(props) => props.theme.textColors.primary};
  -webkit-mask: url(${(props) => props.icon}) no-repeat center;
  mask: url(${(props) => props.icon}) no-repeat center;
  mask-size: contain;
  width: 18px;
  height: 18px;
`;
