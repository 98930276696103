import React from 'react';
import { useHistory } from 'react-router-dom';

//Components
import { LayoutWrapper } from 'components/Layout/Index';
import TopBar from 'components/Layout/TopBar/Index';
import Sidebar from 'components/Sidebar/Index';
import SaleCommissionBoxDetails from 'components/Details/SaleCommissionBoxDetails';

//Services
import SessionService from 'services/Session';

//Styles
import * as styled from './styles/saleComissionDetails-styles';

import { useSettings } from "../store/settings";

function SaleCommissions() {
    let history = useHistory();

    const session = JSON.parse(localStorage.getItem("session") || "{}");

    const { environment } = useSettings((state) => state.settings);

    const viewOnlyAndAgent = (session.role === "Store.Agent" || session.role === "Store.ViewOnly") && session.businessId === (environment === 'test' ? 110 : 3739); 
  
    if (viewOnlyAndAgent) {
      history.push("/transactions")
    }

    if (!SessionService.isValid()) {
      SessionService.clean();
  
      history.push("/login");
    }

    return (
        <>
            <Sidebar/>
            <LayoutWrapper>
                <TopBar />
                <styled.RowLists>
                    <SaleCommissionBoxDetails />
                </styled.RowLists>
            </LayoutWrapper>
        </>
    )
}

export default SaleCommissions
