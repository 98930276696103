export default {
  title: "light",

  primaryColors: {
    primary: "#FB505A",
    secondary: "#FB616B",
    third: "#EC4B54",
    fourth: "#FDB9BD",
    fifth: "#FBF4F5",
    sixth: "#0E82F8",
  },

  backgroundColors: {
    primary: "#fff",
    secondary: "#E5E5E5",
    third: "#f0f0f0",
    fourth: "#FBFBFB",
    fifth: "#E2E2E2",
    sixth: "#C6C6C6",
    seventh: "#F6F6F6",
    eighth: "#E1E3E6",
  },

  contentColors: {
    primary: "#5E5E5E",
    secondary: "#1B1B1B",
    third: "#fff",
    fourth: "#FB505A",
    fifth: "#BF4815",
    sixth: "#2A3F4D",
    seventh: "#FBFBFB",
    eighth: "#000",
  },

  statusBackgroundColors: {
    primary: "#D7ECC4",
    secondary: "#FAF4D4",
    third: "#FEDEDB",
    fourth: "#E2E8EB",
    fifth: "#BF4815",
    sixth: "#2A3F4D",
    seventh: "#FBFBFB",
    eighth: "#9FB1BD",
  },

  statusContentColors: {
    primary: "#2E5C0E",
    secondary: "#BF4815",
    third: "#2A3F4D",

    fourth: "#2A3F4D",
    fifth: "#2A3F4D",
    sixth: "#2A3F4D",
    seventh: "#FBFBFB",
  },

  secondaryBackgroundColors: {
    primary: "#fff",
    secondary: "#f0f0f0",
    third: "#fff",
    fourth: "#3E3D46",
    fifth: "#3E3D46",
    sixth: "#fff",
    seventh: "#FBFBFB",
  },

  textColors: {
    primary: "#5E5E5E",
    secondary: "#fff",
    third: "#16161B",
    fourth: "#16161B",
    fifth: "#FB505A",
    sixth: "#282731",
    seventh: "#16161B",
  },

  secondaryTextColors: {
    primary: "#fff",
    secondary: "#16161B",
    third: "#748591",
    fourth: "#16161B",
    fifth: "#FB505A",
    sixth: "#282731",
    seventh: "#69696c",
    eighth: "#69696c",
  },
};
