import { useState, useEffect } from "react";

export const useBoxDetailsHook = ({
  loadBoletoInstallments,
  setCanceledOrder,
}: any) => {
  const [openBoletos, setOpenBoletos] = useState<number[]>([]);

  useEffect(() => {}, []);

  const handleOpenBoletoToCancel = (boletoId: number) => {
    let newCurrentOpenedBoletos: number[] = [...openBoletos];

    if (openBoletos.includes(boletoId)) {
      const openedBoletosFiltered = newCurrentOpenedBoletos.filter(
        (openedBoleto) => openedBoleto !== boletoId
      );

      newCurrentOpenedBoletos = openedBoletosFiltered;
    } else {
      newCurrentOpenedBoletos.push(boletoId);
    }

    setOpenBoletos(newCurrentOpenedBoletos);
  };

  const runAfterCancelBoleto = () => {
    setOpenBoletos([]);
    // loadBoletoInstallments();
    setCanceledOrder(true);
  };

  return { handleOpenBoletoToCancel, openBoletos, runAfterCancelBoleto };
};
