import styled from "styled-components";

type UserProfileProps = {
  isAtTopbar: boolean;
};

export const Topbar = styled.div`
  display: flex;
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: ${(props) => props.theme.backgroundColors.primary};
  border-bottom: 1px solid ${(props) => props.theme.backgroundColors.secondary};
  flex-direction: column;

  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 12px;
    height: 48px;
  }

  @media screen and (max-width: 1100px) {
    /* padding: 0px 12px; */
  }

  @media screen and (max-width: 916px) {
    /* display: none; */
  }
  @media screen and (max-width: 425px) {
    button {
      font-size: 12px !important;
    }
  }
`;

export const UserProfileContainer = styled.div<UserProfileProps>`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 24px;

  .avatar-photo {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-flex;
    background-color: ${(props) => props.theme.backgroundColors.third};
    align-items: center;

    div {
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }

  .name {
    margin-right: 8px;

    p {
      font-family: "Brandon Text Medium" !important;
      font-size: 16px;
      color: ${(props) => props.theme.contentColors.secondary};
      text-align: center;
    }

    strong {
      font-weight: 700;
    }
  }

  @media screen and (max-width: 600px) {
    display: ${(props) => (props.isAtTopbar ? "none" : "flex")};
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    max-width: 24px;
  }

  @media screen and (max-width: 1100px) {
    justify-content: space-between;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 1100px) {
    justify-content: space-between;
  }
`;
