import brasilFlag from '../assets/images/flags/brazil.svg';
import ukFlag from '../assets/images/flags/united-kingdom.svg';
import usaFlag from '../assets/images/flags/united-states-of-america.svg';

const currencyAndSymbolUnique = [
  {id: 0, country: "BR", currency: 'BRL', symbol: 'R$', nation: 'Brazil', flag: brasilFlag},
  {id: 1, country: "US", currency: 'USD', symbol: 'US$', nation: 'United States', flag: usaFlag},
  {id: 3, country: "HT", currency: 'HTG', symbol: 'HTG'},
  {id: 4, country: "CR", currency: 'CRC', symbol: 'CRC'},
  {id: 5, country: "MX", currency: 'MXN', symbol: 'MX$'},
  {id: 6, country: "CL", currency: 'CLP', symbol: 'CLP'},
  {id: 7, country: "DO", currency: 'DOP', symbol: 'DOP'},
  {id: 8, country: "PA", currency: 'PAB', symbol: 'PAB'},
  {id: 9, country: "CO", currency: 'COP', symbol: 'COP'},
  {id: 11, country: "HN", currency: 'HNL', symbol: 'HNL'},
  {id: 13, country: "AR", currency: 'ARS', symbol: 'ARS'},
  {id: 14, country: "GT", currency: 'GTQ', symbol: 'GTQ'},
  {id: 15, country: "PY", currency: 'PYG', symbol: 'PYG'},
  {id: 16, country: "UY", currency: 'UYU', symbol: 'UYU'},
  {id: 17, country: "CA", currency: 'CAD', symbol: 'CA$'},
  {id: 19, country: "NI", currency: 'NIO', symbol: 'NIO'},
  {id: 20, country: "TT", currency: 'TTD', symbol: 'TTD'},
  {id: 21, country: "PE", currency: 'PEN', symbol: 'PEN'},
  {id: 22, country: "BO", currency: 'BOB', symbol: 'BOB'},
  {id: 24, country: "GY", currency: 'GYD', symbol: 'GYD'},
  {id: 25, country: "SR", currency: 'SRD', symbol: 'SRD'},
  {id: 26, country: "BS", currency: 'BSD', symbol: 'BSD'},
  {id: 27, country: "BZ", currency: 'BZD', symbol: 'BZD'},
  {id: 29, country: "DM", currency: 'XCD', symbol: 'EC$'},
  {id: 30, country: "BM", currency: 'BMD', symbol: 'BMD'},
  {id: 31, country: "KY", currency: 'KYD', symbol: 'KYD '},
  {id: 32, country: "RU", currency: 'RUB', symbol: 'RUB '},
  {id: 33, country: "TR", currency: 'TRY', symbol: 'TRY '},
  {id: 35, country: "FR", currency: 'EUR', symbol: '€'},
  {id: 36, country: "GB", currency: 'GBP', symbol: '£', nation: 'United Kingdom', flag: ukFlag},
  {id: 39, country: "UA", currency: 'UAH', symbol: 'UAH '},
  {id: 40, country: "PL", currency: 'PLN', symbol: 'PLN'},
  {id: 41, country: "RO", currency: 'RON', symbol: 'RON'},
  {id: 42, country: "KZ", currency: 'KZT', symbol: 'KZT'},
  {id: 46, country: "CZ", currency: 'CZK', symbol: 'CZK'},
  {id: 47, country: "SE", currency: 'SEK', symbol: 'SEK'},
  {id: 49, country: "AZ", currency: 'AZN', symbol: 'AZN'},
  {id: 50, country: "HU", currency: 'HUF', symbol: 'HUF'},
  {id: 52, country: "CH", currency: 'CHF', symbol: 'CHF'},
  {id: 53, country: "BG", currency: 'BGN', symbol: 'BGN'},
  {id: 54, country: "RS", currency: 'RSD', symbol: 'RSD'},
  {id: 55, country: "DK", currency: 'DKK', symbol: 'DKK'},
  {id: 58, country: "NO", currency: 'NOK', symbol: 'NOK'},
  {id: 60, country: "HR", currency: 'HRK', symbol: 'HRK'},
  {id: 61, country: "GE", currency: 'GEL', symbol: 'GEL'},
  {id: 62, country: "AL", currency: 'ALL', symbol: 'ALL'},
  {id: 70, country: "IS", currency: 'ISK', symbol: 'ISK'},
  {id: 78, country: "NG", currency: 'NGN', symbol: 'NGN'},
  {id: 79, country: "EG", currency: 'EGP', symbol: 'E£'},
  {id: 80, country: "ZA", currency: 'ZAR', symbol: 'ZAR'},
  {id: 81, country: "TZ", currency: 'TZS', symbol: 'TZS'},
  {id: 82, country: "UG", currency: 'UGX', symbol: 'UGX'},
  {id: 83, country: "MA", currency: 'MAD', symbol: 'MAD'},
  {id: 84, country: "MZ", currency: 'MZN', symbol: 'MZN'},
  {id: 86, country: "AO", currency: 'AOA', symbol: 'AOA'},
  {id: 88, country: "GN", currency: 'GNF', symbol: 'GNF'},
  {id: 89, country: "SL", currency: 'SLL', symbol: 'SLL'},
  {id: 90, country: "LR", currency: 'LRD', symbol: 'LRD'},
  {id: 91, country: "MU", currency: 'MUR', symbol: 'MUR'},
  {id: 92, country: "SC", currency: 'SCR', symbol: 'SCR'},
  {id: 93, country: "CN", currency: 'CNY', symbol: 'CN¥'},
  {id: 94, country: "HK", currency: 'HKD', symbol: 'HK$'},
  {id: 95, country: "TW", currency: 'TWD', symbol: 'NT$'},
  {id: 96, country: "IN", currency: 'INR', symbol: '₹'},
  {id: 97, country: "ID", currency: 'IDR', symbol: 'IDR'},
  {id: 99, country: "IL", currency: 'ILS', symbol: '₪'},
  {id: 100, country: "JP", currency: 'JPY', symbol: 'JP¥'},
  {id: 103, country: "LB", currency: 'LBP', symbol: 'LBP'},
  {id: 104, country: "MO", currency: 'MOP', symbol: 'MOP'},
  {id: 105, country: "MY", currency: 'MYR', symbol: 'MYR'},
  {id: 106, country: "MV", currency: 'MVR', symbol: 'MVR'},
  {id: 107, country: "PK", currency: 'PKR', symbol: 'PKR'},
  {id: 108, country: "PH", currency: 'PHP', symbol: 'PHP'},
  {id: 109, country: "QA", currency: 'QAR', symbol: 'QAR'},
  {id: 110, country: "SA", currency: 'SAR', symbol: 'SAR'},
  {id: 111, country: "SG", currency: 'SGD', symbol: 'SGD'},
  {id: 112, country: "KR", currency: 'KRW', symbol: '₩'},
  {id: 113, country: "LK", currency: 'LKR', symbol: 'LKR'},
  {id: 114, country: "TH", currency: 'THB', symbol: '฿'},
  {id: 116, country: "AE", currency: 'AED', symbol: 'AED'},
  {id: 117, country: "VN", currency: 'VND', symbol: '₫'},
  {id: 118, country: "WS", currency: 'WST', symbol: 'WST'},
  {id: 119, country: "AU", currency: 'AUD', symbol: 'AU$'},
  {id: 120, country: "NZ", currency: 'NZD', symbol: 'NZ$'},
  {id: 121, country: "PF", currency: 'XPF', symbol: 'CFPF'},

  {id: 124, country: "AF", currency: 'AFN', symbol: 'AFN'},
  {id: 125, country: "AM", currency: 'AMD', symbol: 'AMD'},
  // {id: 126, country: "", currency: 'ANG', symbol: 'ANG'},
  {id: 127, country: "AW", currency: 'AWG', symbol: 'AWG'},
  {id: 128, country: "BA", currency: 'BAM', symbol: 'BAM'},
  {id: 129, country: "BB", currency: 'BBD', symbol: 'BBD'},
  {id: 130, country: "BD", currency: 'BDT', symbol: 'BDT'},
  {id: 131, country: "BI", currency: 'BIF', symbol: 'BIF'},
  {id: 132, country: "CD", currency: 'CDF', symbol: 'CDF'},
  {id: 133, country: "CV", currency: 'CVE', symbol: 'CVE'},
  {id: 134, country: "DJ", currency: 'DJF', symbol: 'DJF'},
  {id: 135, country: "ET", currency: 'ETB', symbol: 'ETB'},
  {id: 136, country: "FJ", currency: 'FJD', symbol: 'FJD'},
  {id: 137, country: "GI", currency: 'GIP', symbol: 'GIP'},
  {id: 138, country: "GM", currency: 'GMD', symbol: 'GMD'},
  {id: 139, country: "JM", currency: 'JMD', symbol: 'JMD'},
  {id: 140, country: "KE", currency: 'KES', symbol: 'KES'},
  {id: 141, country: "KG", currency: 'KGS', symbol: 'KGS'},
  {id: 142, country: "KH", currency: 'KHR', symbol: 'KHR'},
  {id: 143, country: "LA", currency: 'LAK', symbol: 'LAK'},
  {id: 144, country: "LS", currency: 'LSL', symbol: 'LSL'},
  {id: 145, country: "MD", currency: 'MDL', symbol: 'MDL'},
  {id: 146, country: "MW", currency: 'MWK', symbol: 'MWK'},
  {id: 147, country: "NA", currency: 'NAD', symbol: 'NAD'},
  {id: 148, country: "NP", currency: 'NPR', symbol: 'NPR'},
  {id: 149, country: "PG", currency: 'PGK', symbol: 'PGK'},
  {id: 150, country: "RW", currency: 'RWF', symbol: 'RWF'},
  {id: 151, country: "SB", currency: 'SBD', symbol: 'SBD'},
  {id: 152, country: "SO", currency: 'SOS', symbol: 'SOS'},
  {id: 153, country: "ST", currency: 'STD', symbol: 'STD'},
  {id: 154, country: "SZ", currency: 'SZL', symbol: 'SZL'},
  {id: 155, country: "TJ", currency: 'TJS', symbol: 'TJS'},
  {id: 156, country: "KW", currency: 'TOP', symbol: 'TOP'},
  {id: 157, country: "UZ", currency: 'UZS', symbol: 'UZS'},
  {id: 158, country: "VU", currency: 'VUV', symbol: 'VUV'},
  {id: 159, country: "CF", currency: 'XAF', symbol: 'XAF'},
  {id: 171, country: "TG", currency: 'XOF', symbol: 'XOF'},
  {id: 172, country: "YE", currency: 'YER', symbol: 'YER'},
  {id: 173, country: "ZM", currency: 'ZMW', symbol: 'ZMW'},
  
]

export default currencyAndSymbolUnique;