import create from "zustand";
import { persist } from "zustand/middleware";

type Session = {
  token: string;
  expiryAt: string;
  storeId: number;
  entityType: string;
  businessId: number;
  role: string;
  name: string;
  hasCommissioning: boolean;
};

interface AuthState {
  session: Session;
  setSession: (session: Session) => void;
}

const initialState: AuthState["session"] = {
  token: "",
  expiryAt: "",
  storeId: 0,
  entityType: "",
  businessId: 0,
  role: "",
  name: "",
  hasCommissioning: false,
};

export const useAuth = create(
  persist<AuthState>(
    (set) => ({
      session: { ...initialState },
      setSession: (session) => {
        set(() => ({
          session: { ...session },
        }));
      },
    }),
    { name: "@Primefy:session" }
  )
);
