import styled, { css } from 'styled-components';

interface CardInputProps {
  hasCardError: boolean;
  color?: string;
}

interface ExpirationInputProps {
  hasExpirationError: boolean;
  color: string;
}

interface CvvInputProps {
  hasCvvError: boolean;
  color: string;
}

export const InputElement = styled.div`
  padding: 0px 8px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  background: ${({ theme }) => theme.backgroundColors.primary};
  border-radius: 3px;
  border: 1px solid ${props => props.theme.backgroundColors.eighth};
  justify-content: space-between;

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
  }

  .card-band {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: 'fade-icon-card' 0.2s; 
    max-width: 25px;
    margin-right: 8px;
    height: 20px;

    img {
      width: 25px !important;
      height: 20px !important;
      margin: 0 !important;
    }
  }

  @keyframes fade-icon-card{
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media screen and (max-width: 600px){
    justify-content: space-between;
  }

  @media screen and (min-width: 400px){
    max-width: 100%;
  }

  .container {
    width: 50%;
    margin: 0px;
    padding: 0px;
  }

  .container-expiration {
    width: 48px;
    padding: 0px;
  }

  .container-cvv { 
    width: 13%;
    padding: 0px;
  }
  
`;

export const CardIcon = styled.div<CardInputProps>`
  background-color: ${props => props.theme.secondaryTextColors.fourth};
  -webkit-mask: url('/images/icon-card.svg') no-repeat center;
  mask: url('/images/icon-card.svg') no-repeat center;
  max-width: 25px;
  min-width: 25px;
  height: 20px;
  margin-right: 8px;
  animation: 'fade-icon-card' 0.2s;

  ${props =>
    props.hasCardError &&
    css`
      background-color: #ff0000;
    `}

  @keyframes fade-icon-card{
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media screen and (max-width: 600px){
    display: none;
  }
`;

export const InputNumberCard = styled.input<CardInputProps>`
  border: 0px;
  font-size: 14px;
  /* width: 48% !important; */
  transition: width 0.5s;
  padding: 0px;
`;

export const InputValidate = styled.input<ExpirationInputProps>`
  border: 0px;
  font-size: 14px;
  width: 100% !important;
  text-align: left;
  transition: width 0.5s;
  padding: 0px;
`;

export const InputCVV = styled.input<CvvInputProps>`
  border: 0px;
  font-size: 14px;
  width: 100% !important;
  text-align: left;
  transition: font-size 0.5s;
  transition: width 0.5s;
  padding: 0px;
`;
