import visaIcon from '../assets/images/cards/icon-card-visa.svg';
import masterIcon from '../assets/images/cards/icon-card-mastercard.svg';
import maestroIcon from '../assets/images/cards/icon-card-maestro.svg';
import auraIcon from '../assets/images/cards/icon-card-aura.svg';
import hiperIcon from '../assets/images/cards/icon-card-hipercard.svg';
import jcbIcon from '../assets/images/cards/icon-card-jcb.svg';
import americanExpressIcon from '../assets/images/cards/icon-card-americanexpress.svg';
import dinersIcon from '../assets/images/cards/icon-card-dinersclub.svg';
import eloIcon from '../assets/images/cards/icon-card-elo.svg';
import discoverIcon from '../assets/images/cards/icon-card-discover.svg';
import aleloIcon from '../assets/images/cards/icon-card-alelo.svg';
import banesIcon from '../assets/images/cards/icon-card-banes.svg';
import hiper2Icon from '../assets/images/cards/icon-card-hiper.svg';
import vrIcon from '../assets/images/cards/icon-card-vr.svg';

import nonecardIcon from '../assets/images/cards/icon-card.svg';

const creditCardNetwork = [
  { network: 'Visa', icon: visaIcon },
  { network: 'Mastercard', icon: masterIcon },
  { network: 'Elo', icon: eloIcon },
  { network: 'Amex', icon: americanExpressIcon },
  { network: 'JCB', icon: jcbIcon },
  { network: 'Hipercard', icon: hiperIcon },
  { network: 'Aura', icon: auraIcon },
  { network: 'Discover', icon: discoverIcon },
  { network: 'Maestro', icon: maestroIcon },
  { network: 'Diners', icon: dinersIcon },
  { network: 'Hiper', icon: hiper2Icon },
  { network: 'Alelo', icon: aleloIcon },
  { network: 'Banescard', icon: banesIcon },
  { network: 'VR', icon: vrIcon },
]

export default creditCardNetwork;