import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useIntl } from "react-intl";

//Helpers
import formatDateWithFuso from "helpers/formatDateWithFuso";

import * as styled from "./styles";

type DetailData = {
  subscription: any;
  loading?: {
    width: number;
  };
  isTheLastItem: boolean;
};

export const SubscriptionItem = ({
  subscription,
  loading,
  isTheLastItem,
}: DetailData) => {
  const intl = useIntl();
  const [finalStatus, setFinalStatus] = useState("");

  useEffect(() => {
    let statusSwitch = "";

    switch (subscription.status) {
      case "Pending":
        statusSwitch = "pending";
        break;
      case "Active":
        statusSwitch = "succeeded";
        break;
      case "Canceled":
        statusSwitch = "canceled";
        break;
      default:
        statusSwitch = "";
        break;
    }

    setFinalStatus(statusSwitch);
  }, [subscription.status]);

  return (
    <styled.SubscriptionItemContainer isTheLastItem={isTheLastItem}>
      <styled.SubscriptionDetailItem className="g-detail-item">
        <div className="g-detail-item__label">
          {" "}
          {intl.formatMessage({
            id: "customers.details.subscriptions.status",
          })}{" "}
        </div>
        {loading ? (
          <Skeleton width={loading.width} className="skeleton" />
        ) : (
          <div className="g-detail-item__value">
            <span className={`status-${finalStatus}`}>
              {intl.formatMessage({ id: `status.${finalStatus}` })}
            </span>
          </div>
        )}
      </styled.SubscriptionDetailItem>

      <styled.SubscriptionDetailItem className="g-detail-item">
        <div className="g-detail-item__label">
          {" "}
          {intl.formatMessage({
            id: "customers.details.subscriptions.name",
          })}{" "}
        </div>
        {loading ? (
          <Skeleton width={loading.width} className="skeleton" />
        ) : (
          <div className="g-detail-item__value">{subscription.name}</div>
        )}
      </styled.SubscriptionDetailItem>

      <styled.SubscriptionDetailItem className="g-detail-item">
        <div className="g-detail-item__label">
          {" "}
          {intl.formatMessage({
            id: "customers.details.subscriptions.frequency",
          })}{" "}
        </div>
        {loading ? (
          <Skeleton width={loading.width} className="skeleton" />
        ) : (
          <div className="g-detail-item__value">
            {intl.formatMessage({
              id: `request.payment.plan.${subscription.frequency.toLowerCase()}`,
            })}
          </div>
        )}
      </styled.SubscriptionDetailItem>

      <styled.SubscriptionDetailItem className="g-detail-item">
        <div className="g-detail-item__label">
          {" "}
          {intl.formatMessage({
            id: "customers.details.subscriptions.payment",
          })}{" "}
        </div>
        {loading ? (
          <Skeleton width={loading.width} className="skeleton" />
        ) : (
          <div className="g-detail-item__value">
            {intl.formatMessage({
              id: `customers.page.subscription.${
                subscription.status === "Active" ? "next" : "previous"
              }.payment`,
            })}
            {formatDateWithFuso(subscription.nextPayment, "DD, MMM")}
            {intl.formatMessage({
              id: `customers.page.subscription.${
                subscription.status === "Active" ? "next" : "previous"
              }.payment.for`,
            })}
            {intl.formatNumber(subscription.total, {
              style: "currency",
              currency: "BRL",
            })}
            {intl.formatMessage({
              id: `customers.page.subscription.${
                subscription.status === "Active"
                  ? "next.payment.scheduled"
                  : "previous.payment.failed"
              }`,
            })}
          </div>
        )}
      </styled.SubscriptionDetailItem>
    </styled.SubscriptionItemContainer>
  );
};
