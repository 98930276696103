const axios = require("axios");

interface ICredential {
  username: string;
  password: string;
  code?: string;
}

class SessionService {
  async getToken(credential: ICredential) {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/authentication`,
      credential
    );
  }

  async refreshToken() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/token/reload`, {
      headers: {
        Authorization: `Bearer ${session.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  async getUserInfos(token: string) {
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/session`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }

  async getUserInfosWithoutTokenParam() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/session`, {
      headers: {
        Authorization: `Bearer ${session.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  async getGoogleRecaptchaValidation(token: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/authentication/recaptcha/verify`,
      {
        recaptchaToken: token,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  isValid() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return new Date(session.expiryAt) > new Date();
  }

  clean() {
    localStorage.removeItem("session");
    localStorage.removeItem("isAdmin");
  }
}

export default new SessionService();
