import styled, { css } from 'styled-components';

type TPercentage = {
  percentage: number;
}

export const LoaderContainer = styled.div<TPercentage>`
  position: fixed;
  background: ${props => props.theme.backgroundColors.primary} 0% 0% no-repeat padding-box;
  width: 350px;
  border-radius: 6px;
  bottom: -10em;
  left: 45%;
  padding: 12px;
  box-shadow: 0 10px 30px -15px rgba(255, 114, 118, .8);
  animation: downTopAnimation 1s ease-in-out forwards;

  /* ${
    ({ percentage }) => percentage < 100 ? css`
      animation: downTopAnimation 1s ease-in-out forwards;
    ` : css `
      animation: topToDownAnimation 1s 5s ease-in-out forwards;
    `
  } */

  p {
    color: ${props => props.theme.contentColors.primary};
  }

  strong {
    color: ${props => props.theme.primaryColors.primary};
    font-size: 18px;
  }

  @keyframes downTopAnimation {
    50% {
      bottom: 4.5em;
    }
    100% {
      bottom: 4em;
    }
  }

  @keyframes topToDownAnimation {
    50% {
      bottom: -4em;
    }
    100% {
      bottom: -10em;
    }
  }
`;

export const ProgressContainer = styled.div`
  height: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  background: ${props => props.theme.backgroundColors.seventh};
  border-radius: 10px;
  display: flex;
  transition: all .3s ease;
  position: relative;
`;

export const ProgressBar = styled.div<TPercentage>`
  height: 10px;
  margin-bottom: 8px;
  width: ${(props) => props.percentage + '%'};
  background: ${props => props.theme.primaryColors.primary};
  border-radius: 10px;
  display: flex;
  transition: all .3s ease;
  position: absolute;

  p {
    position: absolute;
    color: #fff;
    margin-bottom: 12px;
  }

  &:after {
    content: "";
    width: ${(props) => props.percentage + '%'};
    box-shadow: 0 0 10px rgba(255, 114, 118, .8);
    height: 10px;
    position: absolute;
    border-radius: 10px;
    transition: all .3s ease;
  }
`