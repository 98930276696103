import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";

//Hooks
import useClickOutside from "../../../hooks/useClickOutside";

//Services
import SplitsServices from "services/Splits";

//Components
import Buttons from "../../Forms/Buttons";
import Input from "../../Forms/InputFloatLabel";

//Assets
import arrowIcon from "assets/images/topbar/select-arrow.svg";

//Styles
import * as styled from "./modals-styles";

type TSplitRule = {
  disputeLiable: boolean;
  fixed: number;
  id: number;
  percentage: number;
  recipient: {
    id: number;
    name: string;
  };
  refundLiable: boolean;
  type: string;
};

type TModal = {
  handler: any;
  selectedSplits: TSplitRule[];
  setIsCreatingSplit: any;
};

const SelectSplitRuleButtonModal = ({
  handler,
  selectedSplits,
  setIsCreatingSplit,
}: TModal) => {
  const [isMounted, setIsMounted] = useState(false);

  const [showSelectAndButtonModal, setShowSelectAndButtonModal] =
    useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [searchedEvent, setSearchedEvent] = useState("");

  const [selectedSplitsNames, setSelectedSplitsNames] = useState<string[]>([]);

  const [selectedSplitsIds, setSelectedSplitsIds] = useState<number[]>([]);
  const [splits, setSplits] = useState<TSplitRule[]>([]);
  const [backupSplits, setBackupSplits] = useState<TSplitRule[]>([]);

  const selectSplitsModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  useEffect(() => {
    if (searchedEvent !== "") {
      let filteredStores = backupSplits.filter((split: TSplitRule) =>
        split.recipient.name.toLowerCase().includes(searchedEvent.toLowerCase())
      );

      setSplits(filteredStores);
    }

    if (searchedEvent === "") {
      setSplits(backupSplits);
    }
  }, [searchedEvent]);

  function getallSplitsData() {
    SplitsServices.getSplitRules()
      .then((response) => {
        const sorttedSplitsByCreatedOn = response.data.sort(
          (a: TSplitRule, b: TSplitRule) => {
            return b.id - a.id;
          }
        );

        setSplits(sorttedSplitsByCreatedOn);
        setBackupSplits(sorttedSplitsByCreatedOn);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getallSplitsData();
  }, []);

  function handleShowModal() {
    if (selectSplitsModalRef.current) {
      setIsMounted(false);
      setShowSelectAndButtonModal(true);
    } else {
      setIsMounted(true);
      setShowSelectAndButtonModal(true);
    }
  }

  function handleFilter(property: any) {
    if (selectedSplitsIds) {
      let currentLabels: string[] = [...selectedSplitsNames];
      let currentIds: number[] = [...selectedSplitsIds];
      const currentSplits: TSplitRule[] = [...selectedSplits];

      if (currentIds.includes(property.id)) {
        const newSelectedSplits: TSplitRule[] = selectedSplits.filter(
          (split) => split.id !== property.id
        );
        const newSelectedSplitsIds: number[] = selectedSplitsIds.filter(
          (split) => split !== property.id
        );

        const newSelectedSplitsLabel = newSelectedSplits.map(
          (split) => split.recipient.name
        );

        setSelectedSplitsNames(newSelectedSplitsLabel);
        currentLabels = newSelectedSplitsLabel;
        currentIds = newSelectedSplitsIds;

        handler(newSelectedSplits);
      } else {
        currentLabels.push(property.recipient.name);
        setSelectedSplitsNames(currentLabels);

        handler([
          ...currentSplits,
          {
            ...property,
          },
        ]);

        currentIds.push(property.id);
      }

      setSelectedLabel(currentLabels.join(", "));
      setSelectedSplitsIds(currentIds);
    }
  }

  function handleCreateSplit() {
    setIsCreatingSplit(true);
    setSelectedLabel("");
    setSelectedSplitsIds([]);
    setSelectedSplitsNames([]);
  }

  useClickOutside(selectSplitsModalRef, isMounted, setIsMounted);

  return (
    <styled.RelativeContainer>
      <styled.LabelSelectPlan onClick={handleShowModal}>
        <p>
          {selectedLabel !== ""
            ? selectedLabel
            : intl.formatMessage({ id: "splits.rules.select.placeholder" })}
        </p>

        <img src={arrowIcon} alt="arrow" />
      </styled.LabelSelectPlan>

      {showSelectAndButtonModal && (
        <styled.SelectSplitsContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={selectSplitsModalRef}
          onAnimationEnd={() => {
            if (!isMounted) setShowSelectAndButtonModal(false);
          }}
        >
          <div className="header-container--flex">
            <p>{intl.formatMessage({ id: "filter.label.title" })}</p>
          </div>

          <div className="header-container--search">
            <div>
              <Input
                required={false}
                mode="search"
                name="wantedStore"
                value={searchedEvent}
                placeholder={intl.formatMessage({
                  id: "header.search.country",
                })}
                handler={setSearchedEvent}
              />
            </div>
          </div>

          <div className="buttons-container">
            {splits.map((split) => {
              return (
                <div className="button__item--large" key={split.id}>
                  <div className="flex">
                    <Input
                      required={false}
                      mode="checkbox"
                      valueToCheck={selectedSplitsIds.includes(split.id)}
                      name="events"
                      value={split.id}
                      placeholder={split.recipient.name}
                      readOnly={true}
                      handler={() => handleFilter(split)}
                    />
                  </div>
                  <div className="span-id-number">
                    {split.percentage > 0 ? `${split.percentage}%` : null}
                    {split.percentage > 0 && split.fixed > 0 ? ` + ` : null}
                    {split.fixed > 0
                      ? `${intl.formatNumber(split.fixed, {
                          style: "currency",
                          currency: "BRL",
                        })}`
                      : null}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="create-product-container">
            <Buttons
              mode="unfilled"
              handler={() => {}}
              type="button"
              onClick={handleCreateSplit}
              label={intl.formatMessage({ id: "splits.add.label" })}
              disable={false}
            />
          </div>
        </styled.SelectSplitsContainer>
      )}
    </styled.RelativeContainer>
  );
};

export default SelectSplitRuleButtonModal;
