import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import moment from "moment";

//Services
import SessionService from "services/Session";
import ProductServices from "services/Product";

//Components
import MyLoader from "components/ContentLoader";
import Buttons from "components/Forms/Buttons";
import { ContentBox, LayoutWrapper } from "components/Layout/Index";
import Search from "components/Layout/Search";
import DownloadLoading from "components/DownloadLoading";
import FilterExportButtonModal from "components/Layout/Modals/FilterExportButtonModal";
import Input from "components/Forms/InputFloatLabel";
import FilterExport from "./components/FilterExport";

//Assets
import searchIcon from "assets/images/global/search-transactions.svg";

import * as indexStyled from "../../styles/index-styles";
import * as S from "./products-styles";

//Types
import { Product } from "./types";

//Hooks
import { useProducts } from "./hooks";

import { useSettings } from "../../store/settings";

function Products() {
  const intl = useIntl();
  const history = useHistory();
  const {
    exportTransactions,
    handleSelectFilter,
    redirectToOrderDetails,
    handleSearchedOrder,
    rangeDateFilter,
    setRangeDateFilter,
    searchedOrder,
    isWaitingDownload,
    percentageDownload,
  } = useProducts();

  const session = JSON.parse(localStorage.getItem("session") || "{}");

  const { environment } = useSettings((state) => state.settings); 
  const validBusinessIds = [3739, 110]

  const viewOnlyAndAgent = 
  ( session.role === 'Store.Agent' || 
    session.role === 'Store.ViewOnly' || 
    session.role === 'Business.ViewOnly' || 
    session.role === 'Business.Agent') && validBusinessIds.includes(Number(session.businessId));

  if (viewOnlyAndAgent) {
    history.push("/transactions")
  }

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  const [isLinksLoaded, setIsLinksLoaded] = useState(false);


  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    setIsLinksLoaded(false);

    let productsGET: Product[] = [];

    ProductServices.getAllProducts()
      .then((response) => {
        const sorttedProductsByCreatedOn = response.data.sort(
          (a: Product, b: Product) => {
            return (
              new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
            );
          }
        );

        sorttedProductsByCreatedOn.forEach((product: Product) => {
          let isActive: boolean;

          if (product.status === "Active") {
            isActive = true;
          } else {
            isActive = false;
          }

          productsGET.push({
            id: product.id,
            name: product.name,
            unitPrice: product.unitPrice,
            createdOn: format(new Date(product.createdOn), "P, HH:mm", {
              locale: navigator.language === "pt-BR" ? ptBR : undefined,
            }),
            status: isActive,
            currency: product.currency,
          });
        });

        setProducts(productsGET);
        setIsLinksLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLinksLoaded(true);
      });
  }, []);

  return (
    <>
      <LayoutWrapper>
        <indexStyled.RowListWithMarginBottom>
          <ContentBox cssClass="m-top-25 list-transactions">
            <indexStyled.TableList>
              <indexStyled.ContextBoxHeader>
                <div className="title">
                  <FormattedMessage id="view.all.product" />
                </div>

                <S.HeaderActions>
                  <Buttons
                    className="create-product-cta"
                    mode="filled"
                    label={intl.formatMessage({ id: "create.a.product" })}
                    handler={() => {
                      history.push(`/request-payment/create-product`);
                    }}
                  />

                  <S.SearchFilterExportActions>
                    <Input
                      mode="expand-search"
                      placeholder={`${intl.formatMessage({
                        id: `transactions.search`,
                      })} ${intl.formatMessage({
                        id: `products.search.by.${searchedOrder.type}`,
                      })}`}
                      value={searchedOrder.value}
                      handler={handleSearchedOrder}
                      icon={searchIcon}
                      type="text"
                      onKeyDown={(e) =>
                        e.keyCode === 13 ? () => { } : null
                      }
                      searchFunction={() => { }}
                    />

                    <FilterExport
                      action={(
                        statusFilter: string,
                        periodFilter: string,
                        methodFilter: string,
                        typeFilter: string,
                        periodType: string
                      ) => {
                        handleSelectFilter(
                          statusFilter,
                          periodFilter,
                          methodFilter,
                          typeFilter,
                          periodType
                        );
                      }}
                      clear={() => {
                        // clearFilter();
                      }}
                      rangeDateFilter={rangeDateFilter}
                      setRangeDateFilter={setRangeDateFilter}
                      rightHandler={exportTransactions}
                    />
                  </S.SearchFilterExportActions>
                </S.HeaderActions>
              </indexStyled.ContextBoxHeader>
              
              <DownloadLoading
                isLoading={isWaitingDownload}
                percentage={percentageDownload}
              />

              {products.length === 0 && isLinksLoaded && (
                <h3>{intl.formatMessage({ id: "items.not.found" })}</h3>
              )}

              <indexStyled.FullTableProducts>
                <thead>
                  <tr className="remove--border-bottom">
                    <th>
                      <FormattedMessage id="manager.links.header.table.status" />
                    </th>
                    <th>
                      <FormattedMessage id="manager.links.header.table.name" />
                    </th>
                    <th>
                      <FormattedMessage id="manager.links.header.table.price" />
                    </th>
                    {/* <th><FormattedMessage id="manager.links.header.table.created" /></th> */}
                    <th></th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {isLinksLoaded ? (
                    products.map((product: Product) => {
                      let status;

                      if (product.status) {
                        status = (
                          <span className="status-succeeded">
                            <FormattedMessage id="status.active" />
                          </span>
                        );
                      } else {
                        status = (
                          <span className="status-deactivated">
                            <FormattedMessage id="status.deactivated" />
                          </span>
                        );
                      }

                      return (
                        <tr key={product.id}>
                          <td>{status}</td>
                          <td>{product.name}</td>
                          <td>
                            {intl.formatNumber(product.unitPrice, {
                              style: "currency",
                              currency: product.currency,
                            })}
                          </td>
                          <th></th>
                          {/* <td>{`${product.createdOn.split(',')[0].substr(0, 5)}, ${product.createdOn.split(',')[1]}`}</td> */}
                          <td>
                            <Buttons
                              mode="unfilled"
                              label="Editar"
                              handler={() => {
                                history.push(`/product/${product.id}`);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <MyLoader />
                  )}
                </tbody>
              </indexStyled.FullTableProducts>
            </indexStyled.TableList>
            {/* <indexStyled.PaginationWrapper>
              <Pagination  
                pageIndex={pageIndex} 
                setPageIndex={setPageIndex} 
                disableNextButton={Orders.length < 50 ? true : false}
                pageIndexProcessing={pageIndexProcessing}
                totalOrder={numberTotalOfOrders}
              />
            </indexStyled.PaginationWrapper> */}
          </ContentBox>
        </indexStyled.RowListWithMarginBottom>
      </LayoutWrapper>
    </>
  );
}

export default Products;
