import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import Input from "../../../../Forms/InputFloatLabel";
import Buttons from "../../../../Forms/Buttons";
import TipButtonModal from "../../../Modals/TipButtonModal";
import SelectProductsButtonModalV2 from "../../../Modals/SelectProductsButtonModalV2";

import * as styled from "../../payment-request-styles";

export default function OrderBumpUpCrossSell({
  valueToCheck,
  placeholder,
  toggle,
  orderBump,
  setOrderBump,
  upSell,
  setUpSell,
  crossSell,
  setCrossSell,
  applyOrderBump,
  setApplyOrderBump,
  applyUpSell,
  setApplyUpSell,
  applyCrossSell,
  setApplyCrossSell,
  ...rest
}: any) {
  const intl = useIntl();
  const [numberOfOrderBump, setNumberOfOrderBump] = useState(["orderBump 1"]);
  const [numberOfCrossSell, setNumberOfCrossSell] = useState(["crossSell 1"]);
  const [orderBumpSelectedNames, setOrderBumpSelectedNames] = useState([]);
  const [upSellSelectedNames, setupSellSelectedNames] = useState([]);
  const [crossSellSelectedNames, setCrossSellSelectedNames] = useState([]);

  function addOrderBumpItem() {
    let currentOrderBumpItems = [...numberOfOrderBump];
    currentOrderBumpItems.push(`orderBump ${currentOrderBumpItems.length + 1}`);
    setNumberOfOrderBump(currentOrderBumpItems);
  }

  function addCrossSellItem() {
    let currentOrderBumpItems = [...numberOfCrossSell];
    currentOrderBumpItems.push(`crossSell ${currentOrderBumpItems.length + 1}`);
    setNumberOfCrossSell(currentOrderBumpItems);
  }

  function toggleCheckValue() {
    toggle(!valueToCheck);
  }

  return (
    <>
      <div className="form-item">
        <div className="flex">
          <Input
            valueToCheck={valueToCheck}
            required={false}
            mode="checkbox"
            name="customize"
            value="customize"
            placeholder={intl.formatMessage({ id: "order.buc.label" })}
            handler={toggleCheckValue}
            readOnly={true}
          />
        </div>
      </div>

      {!!valueToCheck && (
        <div className="insider-container">
          <styled.PaymentRequestItems>
            <div className="form-item">
              <div className="flex">
                <Input
                  valueToCheck={applyOrderBump}
                  required={false}
                  mode="checkbox"
                  placeholder={intl.formatMessage({ id: "order.bump.label" })}
                  handler={() => setApplyOrderBump(!applyOrderBump)}
                  readOnly={true}
                />

                <TipButtonModal
                  text={intl.formatMessage({
                    id: "order.bump.tip.text",
                  })}
                />
              </div>

              {applyOrderBump ? (
                <div className="insider-container--few">
                  {numberOfOrderBump.map((orderBumpItem, index) => (
                    <div className="form-item" key={Math.random() + index}>
                      <h5>
                        {intl.formatMessage({ id: "choose.a.product.label" })}{" "}
                        {index >= 1 ? `${index + 1}` : ""}
                      </h5>

                      <SelectProductsButtonModalV2
                        handler={setOrderBump}
                        currentValue={orderBump}
                        handleNames={setOrderBumpSelectedNames}
                        selectedNames={orderBumpSelectedNames}
                        selectedName={orderBumpSelectedNames[index]}
                      />
                    </div>
                  ))}

                  <div className="button-container">
                    <Buttons
                      mode="unfilled"
                      handler={() => {}}
                      onClick={addOrderBumpItem}
                      type="button"
                      label={intl.formatMessage({ id: "order.add.bump.label" })}
                      disable={numberOfOrderBump.length === 3}
                    />
                  </div>
                </div>
              ) : null}
            </div>

            <div className="form-item">
              <div className="flex">
                <Input
                  valueToCheck={applyUpSell}
                  required={false}
                  mode="checkbox"
                  placeholder={intl.formatMessage({
                    id: "order.up.sell.label",
                  })}
                  handler={() => setApplyUpSell(!applyUpSell)}
                  readOnly={true}
                />

                <TipButtonModal
                  text={intl.formatMessage({
                    id: "up.sell.tip.text",
                  })}
                />
              </div>

              {applyUpSell ? (
                <div className="insider-container--few">
                  <div className="form-item">
                    <h5>
                      {intl.formatMessage({ id: "choose.a.product.label" })}
                    </h5>

                    <SelectProductsButtonModalV2
                      handler={setUpSell}
                      currentValue={upSell}
                      handleNames={setupSellSelectedNames}
                      selectedNames={upSellSelectedNames}
                      selectedName={upSellSelectedNames[0]}
                    />
                  </div>
                </div>
              ) : null}
            </div>

            <div className="form-item">
              <div className="flex">
                <Input
                  valueToCheck={applyCrossSell}
                  required={false}
                  mode="checkbox"
                  placeholder={intl.formatMessage({
                    id: "order.cross.sell.label",
                  })}
                  handler={() => setApplyCrossSell(!applyCrossSell)}
                  readOnly={true}
                />
                <TipButtonModal
                  text={intl.formatMessage({
                    id: "cross.sell.tip.text",
                  })}
                />
              </div>

              {applyCrossSell ? (
                <div className="insider-container--few">
                  {numberOfCrossSell.map((crossSellItem, index) => (
                    <div className="form-item" key={Math.random() + index}>
                      <h5>
                        {intl.formatMessage({ id: "choose.a.product.label" })}{" "}
                        {index >= 1 ? `${index + 1}` : ""}
                      </h5>

                      <SelectProductsButtonModalV2
                        handler={setCrossSell}
                        currentValue={crossSell}
                        handleNames={setCrossSellSelectedNames}
                        selectedNames={crossSellSelectedNames}
                        selectedName={crossSellSelectedNames[index]}
                      />
                    </div>
                  ))}

                  <div className="button-container">
                    <Buttons
                      mode="unfilled"
                      handler={() => {}}
                      onClick={addCrossSellItem}
                      type="button"
                      label={intl.formatMessage({
                        id: "order.add.cross.sell.label",
                      })}
                      disable={numberOfCrossSell.length === 3}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </styled.PaymentRequestItems>
        </div>
      )}
    </>
  );
}
