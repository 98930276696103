import axios from "axios";
interface IWebhook {
  endpoint: string;
  description: string;
  destination: number;
  events: any;
  id?: number;
  storeId?: number;
}

class WebhooksServices {
  async listAllWebhooks() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session.entityType === "Business") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/businesses/${session.businessId}/endpoints`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    } else {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/endpoints`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
  }

  async connectAnWebhook(webhook: IWebhook, storeId: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/endpoints`,
      webhook,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async editAWebhook(webhook: IWebhook) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${webhook.storeId}/endpoints/${webhook.id}`,
      webhook,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async sendNuvemshopCode(nuvemshopCode: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/nuvem-shop/app/authorize?code=${nuvemshopCode}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async updateNuvemshopMaxInstallments(
    maxInstallments: number,
    storeId: string | number,
    paymentMethods: any) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    
    const paymentsToSet = [{
      payment_method_options: {
        payment_type: "credit",
        max_installments: maxInstallments,
        is_active: true,
    }}, {
      payment_method_options: {
        payment_type: "boleto",
        max_installments: 1,
        is_active: true,
    }}, {
      payment_method_options: {
        payment_type: "pix",
        max_installments: 1,
        is_active: true,
    }}]
    
    if(!paymentMethods.ccard) {
      paymentsToSet[0].payment_method_options.is_active = false
    }
    
    if(!paymentMethods.boleto) {
      paymentsToSet[1].payment_method_options.is_active = false
    }
    
    if(!paymentMethods.pix) {
      paymentsToSet[2].payment_method_options.is_active = false
    }

    return axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/payment-provider`,
      {
        supported_payment_methods: paymentsToSet,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async createPaymentProvider(storeId: number, code: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/nuvem-shop/${storeId}/create-payment-provider?code=${code}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }
}

export default new WebhooksServices();
