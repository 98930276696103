import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { ErrorMessage } from "@hookform/error-message";

//Components
import { Input } from "components/Forms/Input/Input";
import InputFloat from "components/Forms/InputFloatLabel";
import Buttons from "components/Forms/Buttons";

//Assets
import blackArrowIcon from "assets/images/signup/black-arrow.svg";
import whiteArrowIcon from "assets/images/signup/white-arrow.svg";
import doneIcon from "assets/images/signup/done-icon.svg";
import noneIcon from "assets/images/signup/none-icon.svg";
import logo from "assets/images/global/logo-neon.svg";

//Hooks
import { useSignup } from "./hooks";

//Styles
import * as styled from "./signup-styles";

export default function Signup() {
  const intl = useIntl();

  const stringRequired = string().required(
    intl.formatMessage({ id: "webhook.error.message.default" })
  );

  const signupSchema = object({
    identificationNumber: stringRequired.matches(
      /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
      intl.formatMessage({ id: "personal.data.erro.identification.number" })
    ),
    phone: string()
      .matches(
        /[(][0-9]{2}[)][\s][0-9]{5}[-][0-9]{4}$/g,
        intl.formatMessage({ id: "finish.phone.error.message" })
      )
      .nullable()
      .required(intl.formatMessage({ id: "webhook.error.message.default" })),
    fullName: stringRequired.max(
      45,
      intl.formatMessage({ id: "create.account.name.limit" })
    ),
    email: stringRequired.email(
      intl.formatMessage({ id: "create.account.email.yup" })
    ),
    password: stringRequired,
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signupSchema),
  });

  const {
    toggleShowPassword,
    createDashboardUser,
    inputType,
    hideShowPassIcon,
    isCreatingUser,
    cpfCnpjLabel,
    setCpfCnpjLabel,
    isCnpj,
    setIsCnpj,
    verifyPass,
    setVerifyPass,
    showPassProperties,
    setShowPassProperties,
    signupError,
    isValidPass,
    timerToNuvemshopRegister,
    allParams,
    emailFromBlackFriday
  } = useSignup();

  const colorGreen = "#30B935";
  const colorBlack = "#000";

  const subscription = watch("identificationNumber");
  const passWordSubscription = watch("password");

  const { code, email } = allParams;

  useEffect(() => {
    if (email) {
      setValue("email", emailFromBlackFriday);
    }
  }, [allParams]);

  useEffect(() => {
    if (passWordSubscription) {
      if (/[A-Z]/.test(passWordSubscription)) {
        setVerifyPass((prevState) => {
          return {
            ...prevState,
            upper: true,
          };
        });
      } else {
        setVerifyPass((prevState) => {
          return {
            ...prevState,
            upper: false,
          };
        });
      }

      if (/[a-z]/.test(passWordSubscription)) {
        setVerifyPass((prevState) => {
          return {
            ...prevState,
            lower: true,
          };
        });
      } else {
        setVerifyPass((prevState) => {
          return {
            ...prevState,
            lower: false,
          };
        });
      }

      if (/[0-9]/.test(passWordSubscription)) {
        setVerifyPass((prevState) => {
          return {
            ...prevState,
            number: true,
          };
        });
      } else {
        setVerifyPass((prevState) => {
          return {
            ...prevState,
            number: false,
          };
        });
      }

      if (passWordSubscription.length >= 8) {
        setVerifyPass((prevState) => {
          return {
            ...prevState,
            characters: true,
          };
        });
      } else {
        setVerifyPass((prevState) => {
          return {
            ...prevState,
            characters: false,
          };
        });
      }
    }
  }, [passWordSubscription]);

  useEffect(() => {
    if (subscription) {
      if (subscription.length > 14) {
        setIsCnpj(true);
        setCpfCnpjLabel("CNPJ");
      } else if (subscription.length === 0) {
        setIsCnpj(false);
        setCpfCnpjLabel("CPF/CNPJ");
      } else {
        setIsCnpj(false);
        setCpfCnpjLabel("CPF");
      }
    }
  }, [subscription]);

  return (
    <styled.fullContainerLinearBg>
      <form onSubmit={handleSubmit(createDashboardUser)}>
        <div className="form-topbar">
          <img src={logo} alt="Primefy Logo" />
        </div>

        <div>
          <h1>{intl.formatMessage({ id: "signup.create.free.account" })}</h1>
          <h3>{intl.formatMessage({ id: "signup.start.to.sale" })}</h3>
        </div>

        <div className="form-container">
          <div className="form-item">
            <label>{cpfCnpjLabel}</label>

            <Controller
              name="identificationNumber"
              control={control}
              render={({ field }) => (
                <InputFloat
                  mode="normal-mask"
                  mask={isCnpj ? "99.999.999/9999-99" : "999.999.999-99"}
                  disabled={isCreatingUser}
                  handler={() => {}}
                  {...field}
                  maxLength={18}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="identificationNumber"
              render={({ message }) => <p className="error">{message}</p>}
            />
            {["identificationNumberExist"].includes(signupError) ? (
              <p className="error">
                {intl.formatMessage({ id: "create.account.exist.id" })}
              </p>
            ) : null}
          </div>

          <Controller
            name="fullName"
            control={control}
            render={({ field }) => (
              <Input
                label={intl.formatMessage({ id: "signup.form.full.name" })}
                disabled={isCreatingUser}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="fullName"
            render={({ message }) => <p className="error">{message}</p>}
          />
          {["shortFullName"].includes(signupError) ? (
            <p className="error">
              {intl.formatMessage({ id: "create.account.short.full.name.yup" })}
            </p>
          ) : null}

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input label={"Email"} disabled={isCreatingUser} {...field} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => <p className="error">{message}</p>}
          />
          {["emailExist"].includes(signupError) ? (
            <p className="error">
              {intl.formatMessage({ id: "create.account.exist.email" })}
            </p>
          ) : null}

          <div className="form-item">
            <label>Phone</label>

            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <InputFloat
                  mode="normal-mask"
                  mask={"(99) 99999-9999"}
                  disabled={isCreatingUser}
                  handler={() => {}}
                  {...field}
                  maxLength={18}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="phone"
              render={({ message }) => <p className="error">{message}</p>}
            />
          </div>

          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                label={intl.formatMessage({ id: "login.password" })}
                type={inputType}
                placeholder=""
                icon={hideShowPassIcon}
                iconHandler={toggleShowPassword}
                disabled={isCreatingUser}
                onFocus={() => setShowPassProperties(true)}
                {...field}
                onBlur={() => setShowPassProperties(false)}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => <p className="error">{message}</p>}
          />
          {["pass"].includes(signupError) ? (
            <p className="error">
              {intl.formatMessage({ id: "create.account.exist.pass" })}
            </p>
          ) : null}

          {showPassProperties && !isValidPass ? (
            <div className="c-password-rules">
              <p>
                {intl.formatMessage({
                  id: "create.account.form.password.rule.title",
                })}
              </p>

              <ul>
                <styled.Li cor={verifyPass.characters ? "#30B935" : "#000"}>
                  <span>
                    <img
                      src={verifyPass.characters ? doneIcon : noneIcon}
                      alt="item"
                    />
                  </span>
                  {intl.formatMessage({
                    id: "create.account.form.password.rule.1",
                  })}
                </styled.Li>
                <styled.Li cor={verifyPass.lower ? "#30B935" : "#000"}>
                  <span>
                    <img
                      src={verifyPass.lower ? doneIcon : noneIcon}
                      alt="item"
                    />
                  </span>
                  {intl.formatMessage({
                    id: "create.account.form.password.rule.2",
                  })}
                </styled.Li>
                <styled.Li cor={verifyPass.upper ? "#30B935" : "#000"}>
                  <span>
                    <img
                      src={verifyPass.upper ? doneIcon : noneIcon}
                      alt="item"
                    />
                  </span>
                  {intl.formatMessage({
                    id: "create.account.form.password.rule.3",
                  })}
                </styled.Li>
                <styled.Li cor={verifyPass.number ? colorGreen : colorBlack}>
                  <span>
                    <img
                      src={verifyPass.number ? doneIcon : noneIcon}
                      alt="item"
                    />
                  </span>
                  {intl.formatMessage({
                    id: "create.account.form.password.rule.4",
                  })}
                </styled.Li>
              </ul>
            </div>
          ) : null}
        </div>

        <div className="form-footer">
          <p>
            {intl.formatMessage({ id: "signup.terms.1" })}{" "}
            <a href="https://premepay.com/terms-and-conditions" target="_blank">
              {intl.formatMessage({ id: "signup.terms.2" })}
            </a>
          </p>

          <div className="terms">
            <p>
              {intl.formatMessage({ id: "login.text.existent.account.text" })}{" "}
            </p>
            <Link to="/login">
              {intl.formatMessage({ id: "login.text.existent.access.account" })}{" "}
              <img src={blackArrowIcon} alt="right arrow" />
            </Link>
          </div>
        </div>

        <div className="button-float">
          {isCreatingUser ? (
            <Buttons
              mode="filled-processing"
              handler={() => {}}
              type="submit"
              label={""}
              customClass="full-width"
              disabled
            />
          ) : (
            <Buttons
              mode="filled"
              handler={() => {}}
              type="submit"
              label={intl.formatMessage({
                id: "user.profile.team.create.account.button.label",
              })}
              customClass="full-width"
            />
          )}
        </div>
      </form>

      <div className="terms--desktop">
        <p>{intl.formatMessage({ id: "login.text.existent.account.text" })} </p>
        <Link
          to={`/login${
            code !== "undefined" && code !== "" ? `?code=${code}` : ""
          }`}
        >
          {intl.formatMessage({ id: "login.text.existent.access.account" })}{" "}
          <img src={whiteArrowIcon} alt="right arrow" />
        </Link>
      </div>
    </styled.fullContainerLinearBg>
  );
}
