import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as styled from "./styles";

//Assets
import logoPreme from "assets/images/logo-preme.svg";
import cloud1 from "assets/images/cloud1.png";
import cloud2 from "assets/images/cloud2.png";
import rocket from "assets/images/rocket.png";

import dashBoard2 from "assets/images/dashboard-view.svg";
import device1 from "assets/images/device1.png";
import device2 from "assets/images/device2.png";
import device3 from "assets/images/device3.png";

import maquininha from "assets/images/upper-body.svg";
import maquininhaLow from "assets/images/lower-body.svg";
import card1 from "assets/images/card-cless.svg";
import card2 from "assets/images/card-chip.png";
import card3 from "assets/images/card-magstripe.png";

type StepsType = {
  idStep: number;
  handleStep?: any;
};

const OnBoardingFlow = ({ idStep, handleStep }: StepsType) => {
  const [touchStart, setTouchStart] = useState(0);

  const intl = useIntl();

  function touhStartTests(event: React.TouchEvent) {
    let touch = event.changedTouches[0];
    setTouchStart(touch.pageX);
  }

  function touchTests(event: React.TouchEvent) {
    let touch = event.changedTouches[0];
    let td_x = touch.pageX - touchStart;

    if (td_x < 0) {
      if (idStep === 1) {
        handleStep(2);
      }

      if (idStep === 2) {
        handleStep(3);
      }
    } else {
      if (idStep === 3) {
        handleStep(2);
      }

      if (idStep === 2) {
        handleStep(1);
      }
    }
  }

  function alreadyHasAccount() {
    localStorage.setItem(
      "@PremeRecurringHasAccount",
      JSON.stringify({ alreadyHasAccount: true, alreadySkippedTour: false })
    );

    handleStep(0);
  }

  function createAccount() {
    window.location.href = "https://premepay.com/signup";
  }

  return (
    <styled.OnboardingContainer>
      <styled.Nav>
        <img src={logoPreme} alt="Primefy logo" />
      </styled.Nav>

      {(() => {
        switch (idStep) {
          case 1:
            return (
              <styled.StepOne
                onTouchStart={(event) => touhStartTests(event)}
                onTouchMove={(event) => touchTests(event)}
              >
                <div className="limitted-width">
                  <strong className="main-title">
                    <FormattedMessage id="onboarding.step.1.title" />
                  </strong>
                </div>

                <div className="c-illustration">
                  <img className="cloud__1" src={cloud1} alt="cloud" />
                  <img className="rocket" src={rocket} alt="rocket" />
                  <img className="cloud__2" src={cloud2} alt="cloud" />
                </div>

                <div className="c-text">
                  <p>
                    <FormattedMessage id="onboarding.step.1.text" />
                  </p>
                </div>
              </styled.StepOne>
            );
          case 2:
            return (
              <styled.StepTwo
                onTouchStart={(event) => touhStartTests(event)}
                onTouchMove={(event) => touchTests(event)}
              >
                <div className="limitted-height">
                  <strong className="main-title">
                    <FormattedMessage id="onboarding.step.2.title" />
                  </strong>
                </div>

                <div className="c-illustration-2">
                  <div className="dashboard">
                    <div className="checkout-examples">
                      <img
                        className="device__1"
                        src={device1}
                        alt="preme-checkout-example"
                      />
                      <img
                        className="device__2"
                        src={device2}
                        alt="preme-checkout-example"
                      />
                      <img
                        className="device__3"
                        src={device3}
                        alt="preme-checkout-example"
                      />
                    </div>
                    <img
                      src={dashBoard2}
                      className="dashboard-bg"
                      alt="Be global being local!"
                    />
                  </div>
                </div>

                <div className="c-text">
                  <p>
                    <FormattedMessage id="onboarding.step.2.text" />
                  </p>
                </div>
              </styled.StepTwo>
            );
          case 3:
            return (
              <styled.StepThree
                onTouchStart={(event) => touhStartTests(event)}
                onTouchMove={(event) => touchTests(event)}
              >
                <div className="limitted-height">
                  <strong className="main-title">
                    <FormattedMessage id="onboarding.step.3.title" />
                  </strong>
                </div>

                <div className="c-illustration-3">
                  <img
                    className="maquininha-low"
                    src={maquininhaLow}
                    alt="MPOS"
                  />
                  <img className="card__3" src={card3} alt="credit card" />
                  <img className="card__2" src={card2} alt="credit card" />
                  <img className="maquininha" src={maquininha} alt="MPOS" />
                  <img className="card__1" src={card1} alt="credit card" />
                </div>

                <div className="c-text">
                  <p>
                    <FormattedMessage id="onboarding.step.3.text" />
                  </p>
                </div>
              </styled.StepThree>
            );
          default:
            return <div></div>;
        }
      })()}

      <styled.StepsContainer step={idStep}>
        <div className="step__one"></div>
        <div className="step__two"></div>
        <div className="step__three"></div>
      </styled.StepsContainer>

      <styled.Footer>
        <a onClick={createAccount} className="main-CTA--white">
          <span>Criar conta</span>
        </a>

        <div className="already-have-account">
          <a onClick={alreadyHasAccount}>Já possuo conta</a>
        </div>
      </styled.Footer>
    </styled.OnboardingContainer>
  );
};

export default OnBoardingFlow;
