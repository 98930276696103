import React from 'react';
import { useHistory } from 'react-router-dom';

//Components
import { LayoutWrapper } from '../components/Layout/Index'
import UserDetails from '../components/Details/UserDetails'

//Services
import SessionService from '../services/Session';

//Styles
import * as indexStyled from '../styles/index-styles';

function UserProfile() {
  let history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  return (
    <>
      <LayoutWrapper>
        <indexStyled.RowList className=" transactions_details">
          <UserDetails />
        </indexStyled.RowList>
      </LayoutWrapper>
    </>
  )
}

export default UserProfile;
