import { useState, useEffect } from "react";

// Store
import { useSettings } from "../../../store/settings";

import { StoreRegisterServices } from "../../../services/StoreRegister";

export const useStoreRegister = () => {
  const { getStoreDetails } = StoreRegisterServices();

  const [store, setStore] = useState();
  const [isStoreWithCNPJ, setIsStoreWithCNPJ] = useState(false);
  const [progressStep, setProgressStep] = useState(1);

  const { handleStepOfStoreRegister, settings } = useSettings((state) => state);
  const { lastStepOfStoreRegister } = settings;

  const stepProgressTitle = isStoreWithCNPJ
    ? [
        "finish.register.business.details.title",
        "finish.register.business.address.title",
        "finish.register.partner.details.title",
        "finish.register.bank.account.title",
        "finish.register.documents.title",
      ]
    : [
        "finish.register.yours.details.title",
        "finish.register.business.details.title",
        "finish.register.your.address.title",
        "finish.register.bank.account.title",
        "finish.register.documents.title",
      ];

  useEffect(() => {
    getStoreDetails()
      .then((response) => {
        setIsStoreWithCNPJ(response.data.store.identificationNumber?.length > 11);
        setStore(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [progressStep]);

  return {
    lastStepOfStoreRegister,
    stepProgressTitle,
    handleStepOfStoreRegister,
    isStoreWithCNPJ,
    store,
    progressStep,
    setProgressStep,
  };
};
