import React, { useState, useEffect } from "react";

//Services
import ExportCSVService from 'services/ReportsCSV';

//Components
import SelectAndButtonModal from '../Layout/Modals/SelectAndButtonModal';
import NewCustomersChart from "./NewCustomersChart";

//Styles
import * as styled from './charts-styles';

function actionSelect() {
  return false;
}

type ReportChartData = {
  title: string;
  data: any;
  id: string;
};

const NewCustomersComponent = ({ title, data, id }: ReportChartData) => {
  const [year, setYear] = useState('');

  useEffect(() => {
    const date = new Date();

    setYear(date.getFullYear().toString());
  }, [])

  function exportToCSV(){
    ExportCSVService.newCustomersExport(year).then(response => {
      let downloadLink = document.createElement("a");
      let blob = new Blob([response.data], {type: 'text/csv'});
      let url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = "export-new-customers-premepay.csv";  //Name the file here
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
    
  }

  return (
    <styled.Chart className="full">
      <div className="box-chart">
        <div className="report-chart_top">
          <div className="left">
            <div className="title">{title}</div>
          </div>

          <div className="right">
            <SelectAndButtonModal rightHandler={exportToCSV}/>
          </div>
        </div>
        <div className="chart-wrapper">
          <NewCustomersChart />
        </div>
      </div>
    </styled.Chart>
  );
};

export default NewCustomersComponent;
