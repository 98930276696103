import create from "zustand";
import { persist } from "zustand/middleware";

type Settings = {
  environment: string;
  status: string;
  identificationNumber: string;
  fullName: string;
  lastStepOfStoreRegister: number;
  isForeigner: boolean;
  shouldCreateDefaultlLink: boolean;
};

interface SettingsState {
  settings: Settings;
  setSettings: (settings: Settings) => void;
  handleStepOfStoreRegister: (step: number) => void;
}

const initialValue: Settings = {
  environment: "prod",
  status: "",
  identificationNumber: "",
  fullName: "",
  lastStepOfStoreRegister: 1,
  isForeigner: false,
  shouldCreateDefaultlLink: false,
};

export const useSettings = create(
  persist<SettingsState>(
    (set) => ({
      settings: { ...initialValue },
      setSettings: (settings) => {
        set(() => ({
          settings: { ...settings },
        }));
      },
      handleStepOfStoreRegister: (newStep: number) => {
        set(({ settings }) => ({
          settings: {
            ...settings,
            lastStepOfStoreRegister: newStep,
          },
        }));
      },
    }),
    {
      name: "@Primefy:settings",
    }
  )
);
