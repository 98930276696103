import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

//Services
import ProductServices from "services/Product";

//Hooks
import useClickOutside from "../../../hooks/useClickOutside";

//Components
import Input from "../../Forms/InputFloatLabel";
import Buttons from "../../Forms/Buttons";

//Assets
import arrowIcon from "assets/images/topbar/select-arrow.svg";

//Styles
import * as styled from "./modals-styles";

type TProduct = {
  name: string;
  id: number;
  quantity: number;
  unitPrice: number;
  createdOn: string;
};

type TModal = {
  handler: (value: any) => void;
  currentValue: any[];
  handleNames: (value: any) => void;
  selectedName: string;
  selectedNames: string[];
};

const SelectProductsButtonModalV2 = ({
  handler,
  currentValue,
  handleNames,
  selectedNames,
  selectedName = "",
}: TModal) => {
  const [isMounted, setIsMounted] = useState(false);
  const [showRequestPaymentModal, setShowRequestPaymentModal] = useState(false);

  const [products, setProducts] = useState<TProduct[]>([]);
  const [backupProducts, setBackupProducts] = useState<TProduct[]>([]);
  const [searchedProduct, setSearchedProduct] = useState("");

  const selectProductsModalV2Ref = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const history = useHistory();
  let location = useLocation();

  useEffect(() => {
    if (searchedProduct !== "") {
      let filteredProducts = backupProducts.filter((product: TProduct) =>
        product.name.toLowerCase().includes(searchedProduct.toLowerCase())
      );

      setProducts(filteredProducts);
    }

    if (searchedProduct === "") {
      setProducts(backupProducts);
    }
  }, [searchedProduct]);

  useEffect(() => {
    ProductServices.getAllProducts()
      .then((response) => {
        const activeProducts = response.data.filter((product: any) => {
          return product.status === "Active";
        });

        const sorttedProductsByCreatedOn = activeProducts.sort(
          (a: TProduct, b: TProduct) => {
            return (
              new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
            );
          }
        );

        setProducts(sorttedProductsByCreatedOn);
        setBackupProducts(sorttedProductsByCreatedOn);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleShowModal() {
    if (selectProductsModalV2Ref.current) {
      setIsMounted(false);
      setShowRequestPaymentModal(true);
    } else {
      setIsMounted(true);
      setShowRequestPaymentModal(true);
    }
  }

  function handleSelectPlan(product: TProduct) {
    let currentProducts: any[] = [];

    if (currentValue) {
      currentProducts = [...currentValue];
    }

    setIsMounted(!isMounted);

    setTimeout(() => {
      setShowRequestPaymentModal(false);
    }, 300);

    let currentSelectedNames: string[] = [];
    if (selectedNames && selectedNames.length > 0) {
      currentSelectedNames = [...selectedNames];
    }

    currentSelectedNames.push(product.name);
    handleNames(currentSelectedNames);

    currentProducts.push(product);
    handler(currentProducts);
  }

  useClickOutside(selectProductsModalV2Ref, isMounted, setIsMounted);

  return (
    <styled.RelativeContainer>
      <styled.LabelSelectPlan onClick={handleShowModal}>
        {selectedName === "" && (
          <p>{intl.formatMessage({ id: "choose.a.product" })}</p>
        )}
        {selectedName !== "" && <p>{selectedName}</p>}

        <img src={arrowIcon} alt="arrow" />
      </styled.LabelSelectPlan>

      {showRequestPaymentModal && (
        <styled.SelectPlansContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={selectProductsModalV2Ref}
          onAnimationEnd={() => {
            if (!isMounted) setShowRequestPaymentModal(false);
          }}
        >
          <div className="header-container">
            <div>
              <Input
                required={false}
                mode="search"
                name="wantedStore"
                value={searchedProduct}
                placeholder={intl.formatMessage({
                  id: "view.as.search.establishment",
                })}
                handler={setSearchedProduct}
              />
            </div>
          </div>

          <div className="buttons-container">
            {products &&
              products.map((product) => (
                <div
                  className="button__item"
                  key={product.id}
                  onClick={() => handleSelectPlan(product)}
                >
                  <Buttons
                    mode="simple"
                    type="button"
                    label={product.name}
                    handler={() => {}}
                  />
                  <span>
                    {intl.formatNumber(product.unitPrice, {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                </div>
              ))}
          </div>

          <div className="create-store-container">
            <Buttons
              mode="unfilled"
              type="button"
              label={intl.formatMessage({ id: "create.new.product" })}
              handler={() => {
                history.push("/request-payment/create-product");
              }}
            />
          </div>
        </styled.SelectPlansContainer>
      )}
    </styled.RelativeContainer>
  );
};

export default SelectProductsButtonModalV2;
