import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useIntl } from "react-intl";
import { ApexOptions } from "apexcharts";

//Services
import OrderService from "services/Order";

export default function NewCustomersChart() {
  const [dataNewCustomers, setDataNewCustomers] = useState<number[]>([]);

  useEffect(() => {
    const currentMonth = new Date().getMonth() + 1;

    OrderService.netNewCustomers()
      .then((response) => {
        let dataLet = dataNewCustomers;
        for (let index = 1; index <= currentMonth; index++) {
          let month = response.data.find((x: any) => x.month === index);
          if (month === undefined) dataLet[index - 1] = 0;
          else dataLet[index - 1] = month.count;
        }
        setDataNewCustomers(dataLet);
        return dataLet;
      })
      .catch((err) => console.log(err));

    const count = dataNewCustomers.map((data) => {
      return data;
    });

    setDataNewCustomers(count);
  }, []);

  const options: ApexOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 1.5,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Out",
        "Nov",
        "Dez",
      ],
    },
    yaxis: {
      show: false,
    },
    colors: ["#FF7276", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
  };

  const intl = useIntl();

  const series = [
    {
      name: `${intl.formatMessage({ id: "reports.customers.title" })}`,
      data: dataNewCustomers,
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      width="100%"
      height="250"
    />
  );
}
