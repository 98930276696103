import React, { FC, TextareaHTMLAttributes } from 'react';

//Styles
import * as styled from './text-area-styles';

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  type?: string;
  value?: string;
  placeholder: string;
  handler: any;
  icon?: string;
  iconHandler?: any;
  autoComplete?: any;
  mask?: string;
  mode: 'named' | 'normal' | 'normal-mask' | 'named-mask';
}

const TextArea: FC<ITextAreaProps> = ({ autoComplete, mode, value, placeholder, handler, icon, iconHandler, type = 'text', mask, ...rest }) => {

  function handlerNamed(name: string, value: string) {
    handler(name, value);
  }

  return (<>
    {
    (() => {
      switch(mode) {
        case 'normal':
          return (<styled.TextArea>
            <textarea 
              placeholder={placeholder} {...rest} 
              value={value} 
              onChange={(e: any) => handler(e.target.value)} 
              autoComplete={'' + Math.random()}
            />
            {icon && <styled.Icon onClick={iconHandler} icon={icon}></styled.Icon>}
          </styled.TextArea>);

        case 'named':
          return (<styled.TextArea>
            <textarea 
              placeholder={placeholder} {...rest} 
              value={value} 
              onChange={(e: any) => handlerNamed(e.target.name, e.target.value)} 
              autoComplete={'' + Math.random()}
            />
            {icon && <styled.Icon onClick={iconHandler} icon={icon}></styled.Icon>}
          </styled.TextArea>);
        default:
          return (<div></div>);
      }
    })()
   }
  </>);
}

export default TextArea;
