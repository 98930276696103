import styled, { css } from "styled-components";

interface IconProps {
  icon: string;
}

type TProgressBar = {
  step: number;
};

export const Text = styled.p``;

export const IntegrationContainer = styled.div`
  background: ${(props) => props.theme.backgroundColors.primary};
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;

  .integration-progress-container {
    display: flex;
    flex-direction: column;

    .integration-progress-header {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      font-family: "Brandon Text Medium" !important;

      p {
        color: ${(props) => props.theme.contentColors.secondary};
      }
    }

    .text {
      margin: 16px 0 24px 0;
    }
  }
`;

export const InternBoxDetails = styled(IntegrationContainer)`
  width: 90%;
  padding: 0px;

  .width--80 {
    width: 80%;
  }
`;

export const InternBoxWithAutoHeight = styled.div`
  width: 100%;
  
  .intern-form-container {
    margin-left: 24px;
    margin-top: 0px;
  }

  .right-internal-container {
    max-width: 400px;
  }
`;

export const Header = styled.header`
  margin-bottom: 34px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-family: "Brandon Text Medium" !important;
  color: ${(props) => props.theme.contentColors.secondary};
`;

export const InfoIcon = styled.div<IconProps>`
  background-color: ${(props) => props.theme.contentColors.secondary};
  -webkit-mask: url(${(props) => props.icon}) no-repeat center;
  mask: url(${(props) => props.icon}) no-repeat center !important;
  min-width: 14px;
  min-height: 14px !important;
  margin-right: 8px;
`;

export const ProgressBar3StepsContainer = styled.div<TProgressBar>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .background {
    width: 32.5%;
    height: 4px;
    background: ${(props) => props.theme.backgroundColors.sixth};

    &:first-child {
      border-radius: 70px 0 0 70px;
    }

    &:last-child {
      border-radius: 0 70px 70px 0;
    }
  }

  .progress-1 {
    border-radius: 70px 0 0 70px;
  }

  .progress-3 {
    border-radius: 0 70px 70px 0;
  }

  .progress-1,
  .progress-2,
  .progress-3 {
    width: 0%;
    height: 4px;
    background: ${(props) => props.theme.primaryColors.sixth};
  }

  ${(props) =>
    props.step === 1 &&
    css`
      .progress-1 {
        width: 100%;
      }
    `}

  ${(props) =>
    props.step === 2 &&
    css`
      .progress-1,
      .progress-2 {
        width: 100%;
      }
    `}

  ${(props) =>
    props.step === 3 &&
    css`
      .progress-1,
      .progress-2,
      .progress-3 {
        width: 100%;
      }
    `}
`;

export const RowWith2GridColumns = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  /* padding-bottom: 8em; */

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;
