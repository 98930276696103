import React from 'react';
import { FormattedMessage } from 'react-intl';

//Styles
import * as styled from './small-processing-button-styles';

const SmallProcessingButton = () => {
  return (
    <styled.ProcessingButtonContainer type="button">
      <div className="spinner"></div>
      <FormattedMessage id="ui.cta.processing" />
    </styled.ProcessingButtonContainer>
  );
}

export default SmallProcessingButton;
