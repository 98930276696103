import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";

//Components
import DetailItem from "./DetailItem";

//Services
import SaleCommissionService from "../../services/SaleCommission";

//Styles
import * as styled from "./details-styles";

interface ISaleCommission {
  id: number;
  storeName: string;
  order: IOrder;
  amount: number;
  status: number;
  createdOn: string;
}

interface IOrder {
  status: number;
  number: string;
  payment: IPayment;
  createdOn: string;
}

interface IPayment {
  status: number;
  type: number;
  amount: number;
  number: string;
  receivedOn: string;
}

type TParams = {
  number: string;
};

const SaleCommissionBoxDetails = () => {
  let { number } = useParams<TParams>();

  let intl = useIntl();

  const [saleCommission, setSaleCommission] = useState<ISaleCommission>();

  useEffect(() => {
    SaleCommissionService.getByOrderNumber(number!)
      .then((response) => {
        setSaleCommission({
          id: response.data.id,
          storeName: response.data.store.name,
          order: {
            status: response.data.split.order.status,
            number: response.data.split.order.number,
            createdOn: response.data.split.order.createdOn,
            payment: {
              status: response.data.split.order.payment.status,
              type: response.data.split.order.payment.type,
              number: response.data.split.order.payment.number,
              amount: response.data.split.order.payment.amount,
              receivedOn: response.data.split.order.payment.receivedOn,
            },
          },
          amount: response.data.amount,
          status: response.data.status,
          createdOn: response.data.createdOn,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <styled.Left>
        <styled.BoxDetails>
          <styled.Header>
            <FormattedMessage id="transactions.order.details" />
          </styled.Header>
          <styled.DetailsItems>
            <DetailItem
              label={intl.formatMessage({
                id: "detail.sale.commission.seller",
              })}
              value={
                saleCommission !== undefined ? saleCommission.storeName : ""
              }
              loading={saleCommission == undefined ? { width: 100 } : undefined}
            />
            <DetailItem
              label={intl.formatMessage({ id: "detail.order.number" })}
              value={
                saleCommission !== undefined
                  ? `#${saleCommission.order.number}`
                  : ""
              }
              classStatus=""
              loading={saleCommission == undefined ? { width: 100 } : undefined}
            />
            {saleCommission !== undefined ? (
              (() => {
                let value = "";
                let classStatus = "";
                switch (saleCommission.order.payment.status) {
                  case 1:
                    value = "Pending";
                    classStatus = "status-pending";
                    break;
                  case 2:
                    value = "Confirmed";
                    classStatus = "status-succeeded";
                    break;
                  case 3:
                    value = "Canceled";
                    classStatus = "status-failed";
                    break;
                }
                return (
                  <DetailItem
                    label={intl.formatMessage({ id: "detail.order.status" })}
                    value={value}
                    classStatus={classStatus}
                  />
                );
              })()
            ) : (
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.status" })}
                value=""
                classStatus=""
                loading={{ width: 75 }}
              />
            )}

            <DetailItem
              label={intl.formatMessage({ id: "detail.order.date.created" })}
              value={
                saleCommission !== undefined
                  ? moment(saleCommission.order.createdOn).format(
                      "DD/MM/YYYY - HH:mm:ss"
                    )
                  : ""
              }
              loading={saleCommission == undefined ? { width: 125 } : undefined}
            />

            {(() => {
              return saleCommission !== undefined ? (
                (() => {
                  if (saleCommission.order.payment.type === 0)
                    return (
                      <DetailItem
                        label={intl.formatMessage({
                          id: "detail.order.date.received",
                        })}
                        value={
                          saleCommission !== undefined
                            ? moment(saleCommission.order.createdOn).format(
                                "DD/MM/YYYY - HH:mm:ss"
                              )
                            : ""
                        }
                      />
                    );
                  else if (saleCommission.order.payment.type === 1) {
                    if (saleCommission.order.payment.status === 1)
                      return (
                        <DetailItem
                          label={intl.formatMessage({
                            id: "detail.order.date.received",
                          })}
                          value=""
                        />
                      );
                    else
                      return (
                        <DetailItem
                          label={intl.formatMessage({
                            id: "detail.order.date.received",
                          })}
                          value={moment(
                            saleCommission.order.payment.receivedOn
                          ).format("DD/MM/YYYY - HH:mm:ss")}
                        />
                      );
                  }
                })()
              ) : (
                <DetailItem
                  label={intl.formatMessage({
                    id: "detail.order.date.received",
                  })}
                  value=""
                  loading={{ width: 125 }}
                />
              );
            })()}
          </styled.DetailsItems>
        </styled.BoxDetails>

        <styled.BoxDetails>
          <styled.Header>
            <FormattedMessage id="transactions.order.events" />
          </styled.Header>
          <styled.DetailsItems>
            <styled.EventItems>
              {(() => {
                if (saleCommission !== undefined) {
                  return (
                    <>
                      {(() => {
                        if (saleCommission.order.payment.type == 0) {
                          return (
                            <styled.EventItem>
                              <styled.Content>
                                <li className="description">
                                  <FormattedMessage id="detail.order.events.received" />
                                </li>
                                <li>
                                  {intl.formatNumber(
                                    saleCommission.order.payment.amount,
                                    { style: "currency", currency: "BRL" }
                                  )}
                                </li>
                                <li>
                                  {moment(
                                    saleCommission.order.createdOn
                                  ).format("DD/MM/YYYY - HH:mm:ss")}
                                </li>
                              </styled.Content>
                            </styled.EventItem>
                          );
                        } else if (
                          saleCommission.order.payment.type == 1 &&
                          saleCommission.order.payment.receivedOn !== null
                        ) {
                          return (
                            <styled.EventItem>
                              <styled.Content>
                                <li className="description">
                                  <FormattedMessage id="detail.order.events.received" />
                                </li>
                                <li>
                                  {intl.formatNumber(
                                    saleCommission.order.payment.amount,
                                    { style: "currency", currency: "BRL" }
                                  )}
                                </li>
                                <li>
                                  {moment(
                                    saleCommission.order.payment.receivedOn
                                  ).format("DD/MM/YYYY - HH:mm:ss")}
                                </li>
                              </styled.Content>
                            </styled.EventItem>
                          );
                        }
                      })()}

                      <styled.EventItem className="last">
                        <styled.Content>
                          <li className="description">
                            <FormattedMessage id="detail.order.events.created" />
                          </li>
                          <li>
                            {intl.formatNumber(
                              saleCommission.order.payment.amount,
                              { style: "currency", currency: "BRL" }
                            )}
                          </li>
                          <li>
                            {moment(saleCommission.order.createdOn).format(
                              "DD/MM/YYYY - HH:mm:ss"
                            )}
                          </li>
                        </styled.Content>
                      </styled.EventItem>
                    </>
                  );
                }
              })()}
            </styled.EventItems>
          </styled.DetailsItems>
        </styled.BoxDetails>
      </styled.Left>

      <styled.Right>
        <styled.BoxDetails>
          <styled.Header>
            <FormattedMessage id="transactions.payment.details" />
          </styled.Header>
          <styled.DetailsItems>
            {saleCommission !== undefined ? (
              (() => {
                let value = "";
                let classStatus = "";
                switch (saleCommission.order.payment.status) {
                  case 1:
                    value = "Pending";
                    classStatus = "status-pending";
                    break;
                  case 2:
                    value = "Confirmed";
                    classStatus = "status-succeeded";
                    break;
                  case 3:
                    value = "Canceled";
                    classStatus = "status-failed";
                    break;
                }
                return (
                  <DetailItem
                    label={intl.formatMessage({ id: "detail.order.status" })}
                    value={value}
                    classStatus={classStatus}
                  />
                );
              })()
            ) : (
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.status" })}
                value=""
                classStatus=""
                loading={{ width: 75 }}
              />
            )}
            <DetailItem
              label={intl.formatMessage({
                id: "detail.order.transaction.code",
              })}
              value={
                saleCommission !== undefined
                  ? saleCommission.order.payment.number
                  : ""
              }
              loading={saleCommission == undefined ? { width: 125 } : undefined}
            />
            {/*<DetailItem label="Fraud score:" value="73%" />*/}

            {/* todo: react intl */}
            {(() => {
              return saleCommission !== undefined ? (
                (() => {
                  let paymentMethod = "";
                  if (saleCommission.order.payment.type === 0)
                    paymentMethod = "Cartão de crédito";
                  else if (saleCommission.order.payment.type === 1)
                    paymentMethod = "Boleto";

                  return (
                    <DetailItem
                      label={intl.formatMessage({
                        id: "detail.order.payment.method",
                      })}
                      value={paymentMethod}
                    />
                  );
                })()
              ) : (
                <DetailItem
                  label={intl.formatMessage({
                    id: "detail.order.payment.method",
                  })}
                  value=""
                  loading={{ width: 75 }}
                />
              );
            })()}

            {/*
            <DetailItem label={intl.formatMessage({ id: "detail.order.installments.quantity" })} value={(order !== undefined) ? `${order.payment.installments}x` : ""} />
            <DetailItem label={intl.formatMessage({ id: "detail.order.card.type" })} value={(order !== undefined) ? order.payment.card.cardBrand : ""} />
            <DetailItem label={intl.formatMessage({ id: "detail.order.card.holder" })} value={(order !== undefined) ? order.payment.card.holderName : ""} />
            <DetailItem label={intl.formatMessage({ id: "detail.order.card.number" })} value={(order !== undefined) ? `**** **** **** ${order.payment.card.lastDigits}` : ""} />
            <DetailItem label={intl.formatMessage({ id: "detail.order.expiry.date" })} value={(order !== undefined) ? `${order.payment.card.expirationMonth.toString().padStart(2,'0')}/${order.payment.card.expirationYear}` : ""} />
            */}

            {/*<DetailItem label="GBP Amount:" value="£ 102.52" />*/}
            <DetailItem
              label={intl.formatMessage({ id: "detail.order.received.amount" })}
              value={
                saleCommission !== undefined
                  ? intl.formatNumber(saleCommission.order.payment.amount, {
                      style: "currency",
                      currency: "BRL",
                    })
                  : ""
              }
              loading={saleCommission == undefined ? { width: 50 } : undefined}
            />
          </styled.DetailsItems>
        </styled.BoxDetails>

        <styled.BoxDetails>
          <styled.Header>
            <FormattedMessage id="sale.commissions.cost" />
          </styled.Header>
          <styled.DetailsItems>
            <DetailItem
              label={intl.formatMessage({
                id: "detail.sale.commission.amount",
              })}
              value={
                saleCommission !== undefined
                  ? intl.formatNumber(saleCommission.amount, {
                      style: "currency",
                      currency: "BRL",
                    })
                  : ""
              }
              loading={saleCommission == undefined ? { width: 50 } : undefined}
            />
          </styled.DetailsItems>
        </styled.BoxDetails>
      </styled.Right>
    </>
  );
};

export default SaleCommissionBoxDetails;
