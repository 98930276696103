import styled from 'styled-components';

interface FormProps {
  background: string;
}

export const Sidebar = styled.div`
  width: 107px;
  background: ${props => props.theme.backgroundColors.fourth} 0% 0% no-repeat padding-box;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  display: none;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: env(safe-area-inset-bottom);

  .user-avatar{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    

    .avatar-photo{
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      display: inline-flex;
      background-color :${props => props.theme.secondaryBackgroundColors.fourth};
      padding: 3px;

      img{
        width: 100%;
      }
    }
    .businessName{
      padding: 0 2px;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      color: ${props => props.theme.textColors.primary};
      margin-top: 10px;
      font-size: 1.2em;
      &:last-of-type{
        margin-top: 5px;
      }
      strong{
        font-weight: 700;
      }
    }

    .name{
      padding: 0 2px;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      color: ${props => props.theme.textColors.primary};
      opacity: .8;
      margin-top: 10px;
      font-size: 1em;
      &:last-of-type{
        margin-top: 5px;
      }
      strong{
        font-weight: 700;
      }
    }

    @media screen and (max-width: 768px) {
      display: flex;
    }
  }

  

  @media screen and (max-width: 768px) {
    display: flex !important;
    width: 100vw;
    position: fixed;
    z-index: 234324;
    left: auto;
    top: auto;
    bottom: 0;
    height: 10vh;
    flex-direction: row;
    align-items: center;
    padding-top: 16px;
  }
`;

export const MenuItems = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .menu-nav_item{
    width: 100%;        
    margin-top: 20px;
    text-align: center;
    text-decoration: none;
    color: ${props => props.theme.contentColors.secondary};

    img{
      width: 24px;
      height: 18px;
    }

    .label{
      margin-top: 2px;
      font-size: 12px;
    }

    &:hover{
      text-decoration: none;
    }

    &.active{
      color: ${props => props.theme.contentColors.fourth};
      animation-duration: 1s;
      animation-name: opacityIn;
      position: relative;
      font-weight: bold;

      &::after{
        content: '';
        position: absolute;
        height: 100%;
        width: 2px;
        background: ${props => props.theme.backgroundColors.primary};
        right: 0;
        top:0;
        animation-duration: 1s;
        animation-name: growVertical;

        @media screen and (max-width: 916px) {
          height: 0px;
          width: 0px;
        }
      }

      .icon {
        background-color: ${props => props.theme.backgroundColors.primary};
      }

    }
    /* &.active{
        
      
    } */

    @media screen and (max-width: 916px) {
      margin-top: 0px;
    }
  }

  @keyframes opacityIn{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  } 


  @keyframes growVertical{
    from{
      height: 0;
      top: 50%;
    }
    to{
      top: 0;
      height: 100%;
    }
  }

  @media screen and (max-width: 916px) {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 53px;
    justify-content: space-evenly;
  }
`;

// export const MenuItem = styled.div`
//   a {
//     &.active *{
//         fill: ${props => props.theme.backgroundColors.primary};
//         border-color: ${props => props.theme.backgroundColors.primary};
//     }
//   }
// `;

export const IconItem = styled.div<FormProps>`
  background-color: ${props => props.theme.textColors.primary};
  -webkit-mask: url(${props => props.background}) no-repeat center;
  mask: url(${props => props.background}) no-repeat center;
  height: 24px;
`;

export const SwitchButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2em;
  

  p {
    margin-top: 5px;
    color: ${props => props.theme.textColors.primary};
  }
`;

export const TextSwitchButton = styled.p`
  color: ${props => props.theme.textColors.primary};
`;