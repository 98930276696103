import moment from 'moment';

function formatFinancialIncoming(responseReceivables: any) {

  const receivables = responseReceivables.reduce((accumulator: any, currentReceivable: any) => {
    const newCurrentReceivable = {...currentReceivable};
    let receivableResult = {};
    let newAccumulator = [...accumulator];
    let finalResult: any[] = [];

    const newPaidAt = moment(newCurrentReceivable.due_date).format('DD/MM/YYYY');
    newCurrentReceivable.due_date = newPaidAt;

    newAccumulator.forEach((receivable: any) => {
      if(receivable.date === newPaidAt) {
        let newPayIn: any[] = []

        newPayIn = [...receivable.payin, newCurrentReceivable];
        receivable.payin = newPayIn;
        receivable.payinAmount += newCurrentReceivable.amount;

        finalResult = [...newAccumulator]

      }else {
        receivableResult = {
          date: newPaidAt,
          payinAmount: newCurrentReceivable.amount, 
          payoutAmount: 0,
          payin: [newCurrentReceivable], 
          payout: [] 
        }

        finalResult = [...newAccumulator, receivableResult]
      }
    });

    return finalResult;

  }, [{date: '', payinAmount: 0, payoutAmount: 0, payin: [], payout: [] }]);

  const receivablesAndWithdrawals = [...receivables];
  const removedEmpty = receivablesAndWithdrawals.filter(item => item.date !== '')
  const sorttedResult = removedEmpty.sort((a, b) => {
    const newA = a.date.split('/').reverse().join('-');
    const newB = b.date.split('/').reverse().join('-');
    return +new Date(newA) - +new Date(newB)
  });

  return sorttedResult;
}

export default formatFinancialIncoming;