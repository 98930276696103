import React, { useState } from 'react';
import { useIntl } from 'react-intl';

//Assets
import arrowIcon from 'assets/images/topbar/select-arrow.svg';

//Styles
import * as styled from './details-styles';

type TFeeItem = {
  items: any;
  index: number;
  minFee: any;
}

const FeeItem = ({ items, index, minFee }: TFeeItem) => {
  let [isOpen, setIsOpen] = useState(false);

  const intl = useIntl();

  return (
    <styled.FeeItemContainer onClick={e => setIsOpen(!isOpen)}>
      <styled.FeeItem onClick={e => setIsOpen(!isOpen)}>
        <div className="installments">
          <span className={`${isOpen ? 'active' : ''}`}>{index} {intl.formatMessage({ id: "settings.store.fee.installments" })}{index > 1 ? 's' : ''}</span>
          {(!isOpen && minFee) && <p className="fees-info">{`From ${minFee[index - 1].percentage}% + ${intl.formatNumber(minFee[index - 1].amount, { style: 'currency', currency: 'BRL' })}`}</p>}
          <img src={arrowIcon} alt="arrow down" />
        </div>

        <div className="installments__content">
          <div className={`header ${isOpen ? 'header-open' : ''}`}>
            <div className="item">{intl.formatMessage({ id: "settings.store.fee.network" })}</div>
            <div className="item">{intl.formatMessage({ id: "settings.store.fee.fee" })}</div>
          </div>

          { items.map((creditCard: any) => (
            <div key={Math.random()} className={`fees ${isOpen ? 'fees--open' : ''}`}>
              <div>
                <img src={creditCard.networkIcon} alt="card" />
              </div>

              <p>{`${minFee[index - 1].percentage}% + ${intl.formatNumber(minFee[index - 1].amount, { style: 'currency', currency: 'BRL'})}`}</p>
            </div>
          ))}
        </div>
      </styled.FeeItem>
    </styled.FeeItemContainer>
  )
}

export default FeeItem
