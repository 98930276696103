import bancoDoBrasilLogo from "../assets/images/icon-banco-do-brasil.svg";
import bancoBradescoLogo from "../assets/images/icon-banco-bradesco.svg";
import bancoCaixaLogo from "../assets/images/icon-banco-caixa.svg";
import bancoInterLogo from "../assets/images/icon-banco-inter.svg";
import bancoItauLogo from "../assets/images/icon-banco-itau.svg";
import bancoSantanderLogo from "../assets/images/icon-banco-santander.svg";
import bancoAilosLogo from "../assets/images/icon-banco-ailos.svg";
import bancoBtgLogo from "../assets/images/icon-banco-btg.svg";
import bancoCitiLogo from "../assets/images/icon-banco-citi.svg";
import bancoHsbcLogo from "../assets/images/icon-banco-hsbc.svg";
import bancoSafraLogo from "../assets/images/icon-banco-safra.svg";
import bancoNubankLogo from "../assets/images/icon-banco-nubank.svg";
import noLogoBank from "../assets/images/icon-banco-nologo.svg";

const banksAndCodes = [
  {
    bankName: "Banco Bankpar S.A.",
    bankCode: "000",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco do Brasil S.A.",
    bankCode: "001",
    bankLogo: bancoDoBrasilLogo,
  },
  {
    bankName: "Banco da Amazônia S.A.",
    bankCode: "003",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco do Nordeste do Brasil S.A.",
    bankCode: "004",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Standard de Investimentos S.A.",
    bankCode: "012",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Natixis Brasil S.A. Banco Múltiplo",
    bankCode: "014",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Tricury S.A.",
    bankCode: "018",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Azteca do Brasil S.A.",
    bankCode: "019",
    bankLogo: noLogoBank,
  },
  {
    bankName: "BANESTES S.A. Banco do Estado do Espírito Santo",
    bankCode: "021",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco BANDEPE S.A.",
    bankCode: "024",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Alfa S.A.",
    bankCode: "025",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Banerj S.A.",
    bankCode: "029",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Beg S.A.",
    bankCode: "031",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Soluções",
    bankCode: "332",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Santander (Brasil) S.A.",
    bankCode: "033",
    bankLogo: bancoSantanderLogo,
  },
  {
    bankName: "Banco Bradesco BBI S.A.",
    bankCode: "036",
    bankLogo: bancoBradescoLogo,
  },
  {
    bankName: "Banco do Estado do Pará S.A.",
    bankCode: "037",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco do Estado do Piauí S.A.BEP",
    bankCode: "039",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Cargill S.A.",
    bankCode: "040",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco do Estado do Rio Grande do Sul S.A.",
    bankCode: "041",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco BVA S.A.",
    bankCode: "044",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Opportunity S.A.",
    bankCode: "045",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco do Estado de Sergipe S.A.",
    bankCode: "047",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Hipercard Banco Múltiplo S.A.",
    bankCode: "062",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Ibi S.A. Banco Múltiplo",
    bankCode: "063",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Goldman Sachs do Brasil Banco Múltiplo S.A.",
    bankCode: "064",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Bracce S.A.",
    bankCode: "065",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Morgan Stanley S.A.",
    bankCode: "066",
    bankLogo: noLogoBank,
  },
  {
    bankName: "BPN Brasil Banco Múltiplo S.A.",
    bankCode: "069",
    bankLogo: noLogoBank,
  },
  {
    bankName: "BRBBanco de Brasília S.A.",
    bankCode: "070",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Rural Mais S.A.",
    bankCode: "072",
    bankLogo: noLogoBank,
  },
  {
    bankName: "BB Banco Popular do Brasil S.A.",
    bankCode: "073",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco J. Safra S.A.",
    bankCode: "074",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco ABN AMRO S.A.",
    bankCode: "075",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco KDB S.A.",
    bankCode: "076",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Intermedium S.A.",
    bankCode: "077",
    bankLogo: bancoInterLogo,
  },
  {
    bankName: "BES Investimento do Brasil S.A.",
    bankCode: "078",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Original do Agronegócio S.A.",
    bankCode: "079",
    bankLogo: noLogoBank,
  },
  {
    bankName: "BBN Banco Brasileiro de Negócios S.A.",
    bankCode: "081",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco Topázio S.A.",
    bankCode: "082",
    bankLogo: noLogoBank,
  },
  {
    bankName: "Banco da China Brasil S.A.",
    bankCode: "083",
  },
  {
    bankName: "Unicred Norte do Paraná",
    bankCode: "084",
  },
  {
    bankName: "Cooperativa Central de Crédito Urbano-CECRED",
    bankCode: "085",
  },
  {
    bankName: "OBOE Crédito Financiamento e Investimento S.A.",
    bankCode: "086",
  },
  {
    bankName: "Cooperativa Unicred Central Santa Catarina",
    bankCode: "087",
  },
  {
    bankName: "Banco Randon S.A.",
    bankCode: "088",
  },
  {
    bankName: "Cooperativa de Crédito Rural da Região de Mogiana",
    bankCode: "089",
  },
  {
    bankName: "Cooperativa Central de Economia e Crédito Mutuo das Unicreds",
    bankCode: "090",
  },
  {
    bankName: "Unicred Central do Rio Grande do Sul",
    bankCode: "091",
  },
  {
    bankName: "Brickell S.A. Crédito, financiamento e Investimento",
    bankCode: "092",
  },
  {
    bankName: "Pólocred SCM",
    bankCode: "093",
  },
  {
    bankName: "Banco Petra S.A.",
    bankCode: "094",
  },
  {
    bankName: "Banco Confidence de Câmbio S.A.",
    bankCode: "095",
  },
  {
    bankName: "Banco BM&amp;FBOVESPA de Serviços de Liquidação e Custódia S.A",
    bankCode: "096",
  },
  {
    bankName: "Cooperativa Central de Crédito Noroeste Brasileiro Ltda.",
    bankCode: "097",
  },
  {
    bankName: "CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL",
    bankCode: "098",
  },
  {
    bankName: "Cooperativa Central de Economia e Credito Mutuo das Unicreds",
    bankCode: "099",
  },
  {
    bankName: "Caixa Econômica Federal",
    bankCode: "104",
    bankLogo: bancoCaixaLogo,
  },
  {
    bankName: "Banco BBM S.A.",
    bankCode: "107",
  },
  {
    bankName: "Central das Coop. de Economia e Crédito Mutuo do Est. do ES",
    bankCode: "114",
  },
  {
    bankName: "Banco Western Union do Brasil S.A.",
    bankCode: "119",
  },
  {
    bankName: "Banco Agibank S.A.",
    bankCode: "121",
  },
  {
    bankName: "Banco BERJ S.A.",
    bankCode: "122",
  },
  {
    bankName: "Brasil Plural S.A.Banco Múltiplo",
    bankCode: "125",
  },
  {
    bankName: "Banco Caruana SCFI",
    bankCode: "130",
  },
  {
    bankName: "Cresol Confederação",
    bankCode: "133",
  },
  {
    bankName: "CONFEDERACAO NACIONAL DAS COOPERATIVAS CENTRAIS UN",
    bankCode: "136",
  },
  {
    bankName: "HSBC Finance (Brasil) S.A.Banco Múltiplo",
    bankCode: "168",
  },
  {
    bankName: "Albatross Corretora",
    bankCode: "172",
  },
  {
    bankName: "Banco Itaú BBA S.A.",
    bankCode: "184",
    bankLogo: bancoItauLogo,
  },
  {
    bankName: "Stone Pagamentos S.A",
    bankCode: "197",
  },
  {
    bankName: "Banco Bradesco Cartões S.A.",
    bankCode: "204",
    bankLogo: bancoBradescoLogo,
  },
  {
    bankName: "Banco BTG Pactual S.A.",
    bankCode: "208",
    bankLogo: bancoBtgLogo,
  },
  {
    bankName: "Banco Original S.A.",
    bankCode: "212",
  },
  {
    bankName: "Banco Arbi S.A.",
    bankCode: "213",
  },
  {
    bankName: "Banco Dibens S.A.",
    bankCode: "214",
  },
  {
    bankName: "Banco Comercial e de Investimento Sudameris S.A.",
    bankCode: "215",
  },
  {
    bankName: "Banco John Deere S.A.",
    bankCode: "217",
  },
  {
    bankName: "Banco Bonsucesso S.A.",
    bankCode: "218",
  },
  {
    bankName: "Banco Credit Agricole Brasil S.A.",
    bankCode: "222",
  },
  {
    bankName: "Banco Fibra S.A.",
    bankCode: "224",
  },
  {
    bankName: "Banco Brascan S.A.",
    bankCode: "225",
  },
  {
    bankName: "Banco Cruzeiro do Sul S.A.",
    bankCode: "229",
  },
  // {
  //   "bankName": "Unicard Banco Múltiplo S.A.",
  //   "bankCode": "230"
  // },
  {
    bankName: "Banco Cifra S.A.",
    bankCode: "233",
  },
  {
    bankName: "Banco Bradesco S.A.",
    bankCode: "237",
    bankLogo: bancoBradescoLogo,
  },
  {
    bankName: "Banco Next",
    bankCode: "237N",
  },
  {
    bankName: "Banco Clássico S.A.",
    bankCode: "241",
  },
  {
    bankName: "Banco Máxima S.A.",
    bankCode: "243",
  },
  {
    bankName: "Banco ABC Brasil S.A.",
    bankCode: "246",
  },
  {
    bankName: "Banco Boavista Interatlântico S.A.",
    bankCode: "248",
  },
  {
    bankName: "Banco Investcred Unibanco S.A.",
    bankCode: "249",
  },
  {
    bankName: "BCVBanco de Crédito e Varejo S.A.",
    bankCode: "250",
  },
  {
    bankName: "Paraná Banco S.A.",
    bankCode: "254",
  },
  {
    bankName: "Nubank",
    bankCode: "260",
    bankLogo: bancoNubankLogo,
  },
  {
    bankName: "Banco Cacique S.A.",
    bankCode: "263",
  },
  {
    bankName: "Banco Fator S.A.",
    bankCode: "265",
  },
  {
    bankName: "Banco Cédula S.A.",
    bankCode: "266",
  },
  {
    bankName: "Sulcredi (Cooperativa de Credito Rural de Sao Miguel do Oest)",
    bankCode: "273",
  },
  {
    bankName:
      "Money Plus Sociedade de Crédito ao Microempreendedor e a Empresa de Pequeno Porte",
    bankCode: "274",
  },
  {
    bankName: "Avista S.A. CFI",
    bankCode: "280",
  },
  {
    bankName: "Pagseguro Internet S.A.",
    bankCode: "290",
  },
  {
    bankName: "Banco de La Nacion Argentina",
    bankCode: "300",
  },
  {
    bankName: "BPP INSTITUIÇÃO DE PAGAMENTO",
    bankCode: "301",
  },
  {
    bankName: "Banco BMG S.A.",
    bankCode: "318",
  },
  {
    bankName: "Banco Industrial e Comercial S.A.",
    bankCode: "320",
  },
  {
    bankName: "MercadoPago.com Representações LTDA.",
    bankCode: "323",
  },
  {
    bankName: "Banco C6 S.A.",
    bankCode: "336",
  },
  {
    bankName: "Itaú Unibanco S.A.",
    bankCode: "341",
    bankLogo: bancoItauLogo,
  },
  {
    bankName: "Banco Real S.A.",
    bankCode: "356",
  },
  {
    bankName: "GERENCIANET PAGTOS BRASIL LTDA",
    bankCode: "364",
  },
  {
    bankName: "Banco Société Générale Brasil S.A.",
    bankCode: "366",
  },
  {
    bankName: "Banco Mizuho do Brasil S.A.",
    bankCode: "370",
  },
  {
    bankName: "Banco J. P. Morgan S.A.",
    bankCode: "376",
  },
  {
    bankName: "Banco Mercantil do Brasil S.A.",
    bankCode: "389",
  },
  {
    bankName: "Banco Bradesco Financiamentos S.A.",
    bankCode: "394",
    bankLogo: bancoBradescoLogo,
  },
  {
    bankName: "Banco Finasa BMC S.A.",
    bankCode: "394F",
  },
  {
    bankName: "HSBC Bank Brasil S.A.Banco Múltiplo",
    bankCode: "399",
    bankLogo: bancoHsbcLogo,
  },
   {
    bankName: "CORA SCD S.A.",
    bankCode: "403",
  },
  {
    bankName: "UNIBANCOUnião de Bancos Brasileiros S.A.",
    bankCode: "409",
  },
  {
    bankName: "Banco Capital S.A.",
    bankCode: "412",
  },
  {
    bankName: "Banco Safra S.A.",
    bankCode: "422",
    bankLogo: bancoSafraLogo,
  },
  {
    bankName: "Banco Rural S.A.",
    bankCode: "453",
  },
  {
    bankName: "Banco de Tokyo-Mitsubishi UFJ Brasil S.A.",
    bankCode: "456",
  },
  {
    bankName: "Banco Sumitomo Mitsui Brasileiro S.A.",
    bankCode: "464",
  },
  {
    bankName: "Banco Caixa GeralBrasil S.A.",
    bankCode: "473",
  },
  {
    bankName: "Citibank S.A.",
    bankCode: "477",
  },
  {
    bankName: "Banco ItaúBank S.A.",
    bankCode: "479",
    bankLogo: bancoItauLogo,
  },
  {
    bankName: "Deutsche Bank S.A.Banco Alemão",
    bankCode: "487",
  },
  {
    bankName: "JPMorgan Chase Bank",
    bankCode: "488",
  },
  {
    bankName: "ING Bank N.V.",
    bankCode: "492",
  },
  {
    bankName: "Banco de La Republica Oriental del Uruguay",
    bankCode: "494",
  },
  {
    bankName: "Banco de La Provincia de Buenos Aires",
    bankCode: "495",
  },
  {
    bankName: "Banco Credit Suisse (Brasil) S.A.",
    bankCode: "505",
  },
  {
    bankName: "Banco Luso Brasileiro S.A.",
    bankCode: "600",
  },
  {
    bankName: "Banco Industrial do Brasil S.A.",
    bankCode: "604",
  },
  {
    bankName: "Banco VR S.A.",
    bankCode: "610",
  },
  {
    bankName: "Banco Paulista S.A.",
    bankCode: "611",
  },
  {
    bankName: "Banco Guanabara S.A.",
    bankCode: "612",
  },
  {
    bankName: "Banco Pecúnia S.A.",
    bankCode: "613",
  },
  {
    bankName: "Banco Panamericano S.A.",
    bankCode: "623",
  },
  {
    bankName: "Banco Ficsa S.A.",
    bankCode: "626",
  },
  {
    bankName: "Banco Intercap S.A.",
    bankCode: "230",
  },
  {
    bankName: "TRANSFEERA IP S.A.",
    bankCode: "593",
  },
  // {
  //   bankName: "Dock Instituição de Pagamentos S.A.",
  //   bankCode: "301",
  // },
  {
    bankName: "Banco Rendimento S.A.",
    bankCode: "633",
  },
  {
    bankName: "Banco Triângulo S.A.",
    bankCode: "634",
  },
  {
    bankName: "Banco Sofisa S.A.",
    bankCode: "637",
  },
  {
    bankName: "Banco Prosper S.A.",
    bankCode: "638",
  },
  {
    bankName: "Banco Alvorada S.A.",
    bankCode: "641",
  },
  {
    bankName: "Banco Pine S.A.",
    bankCode: "643",
  },
  {
    bankName: "Itaú Unibanco Holding S.A.",
    bankCode: "652",
    bankLogo: bancoItauLogo,
  },
  {
    bankName: "Banco Indusval S.A.",
    bankCode: "653",
  },
  {
    bankName: "Banco A.J.Renner S.A.",
    bankCode: "654",
  },
  {
    bankName: "Banco Votorantim S.A.",
    bankCode: "655",
  },
  {
    bankName: "Banco Daycoval S.A.",
    bankCode: "707",
  },
  {
    bankName: "Banco Ourinvest S.A.",
    bankCode: "712",
  },
  {
    bankName: "Banif-Banco Internacional do Funchal (Brasil)S.A.",
    bankCode: "719",
  },
  {
    bankName: "Banco Credibel S.A.",
    bankCode: "721",
  },
  {
    bankName: "Banco Porto Seguro S.A.",
    bankCode: "724",
  },
  {
    bankName: "Banco Gerdau S.A.",
    bankCode: "734",
  },
  {
    bankName: "Banco Pottencial S.A.",
    bankCode: "735",
  },
  {
    bankName: "Banco Morada S.A.",
    bankCode: "738",
  },
  {
    bankName: "Banco Cetelem S.A.",
    bankCode: "739",
  },
  {
    bankName: "Banco Barclays S.A.",
    bankCode: "740",
  },
  {
    bankName: "Banco Ribeirão Preto S.A.",
    bankCode: "741",
  },
  {
    bankName: "Banco Semear S.A.",
    bankCode: "743",
  },
  {
    bankName: "BankBoston N.A.",
    bankCode: "744",
  },
  {
    bankName: "Banco Citibank S.A.",
    bankCode: "745",
    bankLogo: bancoCitiLogo,
  },
  {
    bankName: "Banco Modal S.A.",
    bankCode: "746",
  },
  {
    bankName: "Banco Rabobank International Brasil S.A.",
    bankCode: "747",
  },
  {
    bankName: "Banco Cooperativo Sicredi S.A.",
    bankCode: "748",
  },
  {
    bankName: "Banco Simples S.A.",
    bankCode: "749",
  },
  {
    bankName: "Scotiabank Brasil S.A. Banco Múltiplo",
    bankCode: "751",
  },
  {
    bankName: "Banco BNP Paribas Brasil S.A.",
    bankCode: "752",
  },
  {
    bankName: "NBC Bank Brasil S.A.Banco Múltiplo",
    bankCode: "753",
  },
  {
    bankName: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
    bankCode: "755",
  },
  {
    bankName: "Banco Cooperativo do Brasil S.A.BANCOOB",
    bankCode: "756",
  },
  {
    bankName: "Banco KEB do Brasil S.A.",
    bankCode: "757",
  },
  {
    bankName: "Conductor FlexAssists",
    bankCode: "9991",
  },
  {
    bankName: "Conductor Alpha Card",
    bankCode: "9992",
  },
  {
    bankName: "MBanco Fiat S.A.",
    bankCode: "M03",
  },
  {
    bankName: "MBanco de Lage Landen Brasil S.A.",
    bankCode: "M06",
  },
  {
    bankName: "MBanco GMAC S.A.",
    bankCode: "M07",
  },
  {
    bankName: "MBanco Citicard S.A.",
    bankCode: "M08",
  },
  {
    bankName: "MBanco Itaucred Financiamentos S.A.",
    bankCode: "M09",
  },
  {
    bankName: "MBanco Moneo S.A.",
    bankCode: "M10",
  },
  {
    bankName: "MBanco IBM S.A.",
    bankCode: "M11",
  },
  {
    bankName: "MBanco Maxinvest S.A.",
    bankCode: "M12",
  },
  {
    bankName: "MBanco Volkswagen S.A.",
    bankCode: "M14",
  },
  {
    bankName: "MBanco BRJ S.A.",
    bankCode: "M15",
  },
  {
    bankName: "MBanco Rodobens S.A.",
    bankCode: "M16",
  },
  {
    bankName: "MBanco Ford S.A.",
    bankCode: "M18",
  },
  {
    bankName: "MBanco CNH Industrial Capital S.A.",
    bankCode: "M19",
  },
  {
    bankName: "MBanco Toyota do Brasil S.A.",
    bankCode: "M20",
  },
  {
    bankName: "MBanco Daimlerchrysler S.A.",
    bankCode: "M21",
  },
  {
    bankName: "MBanco Honda S.A.",
    bankCode: "M22",
  },
  {
    bankName: "MBanco Volvo (Brasil) S.A.",
    bankCode: "M23",
  },
  {
    bankName: "MBanco PSA Finance Brasil S.A.",
    bankCode: "M24",
  },
];

export default banksAndCodes;
