import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import ReactApexChart from "react-apexcharts";

import { ApexOptions } from "apexcharts";

//Services
import OrderService from "services/Order";

interface IProps {}

interface IState {
  data: number[];
}

interface Data {
  month: number;
  amount: number;
}

export default function GrossSalesChart() {
  const [data, setData] = useState<Data[]>([]);
  const [dataAmount, setDataAmount] = useState<number[]>([]);

  const premepayStore = localStorage.getItem("@PrimefyStore");
  const { isForeigner, currency, currencySymbol } = JSON.parse(
    premepayStore ? premepayStore : "{}"
  );

  const intl = useIntl();

  useEffect(() => {
    const currentMonth = new Date().getMonth() + 1;

    OrderService.getGrossSales()
      .then((response) => {
        let dataLet = dataAmount;
        for (let index = 1; index <= currentMonth; index++) {
          let month = response.data.find((x: any) => x.Month === index);
          if (month === undefined) {
            dataLet[index - 1] = 0;
          } else {
            dataLet[index - 1] = month.Amount;
          }
        }
        setDataAmount(dataLet);
        return dataLet;
      })
      .catch((err) => console.log(err));

    const amounts = dataAmount.map((data) => {
      return data;
    });

    setDataAmount(amounts);
  }, []);

  const options: ApexOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 1.5,
    },
    yaxis: {
      show: false,
      // type: 'string',
      labels: {
        formatter: function (value: any) {
          return intl
            .formatNumber(value, { style: "currency", currency: "BRL" })
            .replace("R$", "");
        },
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Out",
        "Nov",
        "Dez",
      ],
    },
    colors: ["#FB505A"],
  };

  const series = [
    {
      name: `${intl.formatMessage({
        id: "reports.gross.sales.title",
      })} ${currencySymbol}`,
      data: dataAmount,
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      width="100%"
      height="250"
    />
  );
}
