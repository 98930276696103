import axios from 'axios';

class GetUserDataService {
    
    async getUserData() {
      return await axios.post("https://api.whatismybrowser.com/api/v2/user_agent_parse", {
        "user_agent": navigator.userAgent
      }, {
        headers: {
          "x-api-key": "59a8d62c750b2bffe0c2f1616ca88344",
          'Content-Type': "application/json"
        }
      })
    }
}

export default new GetUserDataService();
