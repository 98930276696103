import React, { useState } from "react";
import { useIntl } from "react-intl";

//Components
import Buttons from "../Forms/Buttons";

//Styles
import * as styled from "./styles";

type IExternalServiceConnection = {
  icon: string;
  description: string;
  children: any;
  isConnected?: boolean;
  withSeparator?: boolean;
  setFinishedTheEdition?: any;
  openNewPageOnConnect?: boolean;
  handleOpenNewPageOnConnect?: () => void;
  handleSaveOnHeader?: () => void;
  conditionalOpenChildren?: boolean;
  useSaveButtonOnHeader?: boolean;
  loadingSaveOnHeader?: boolean;
  disable?: boolean;
  disableSave?: boolean;
};

const ExternalServiceConnection = ({
  icon,
  description,
  children,
  isConnected,
  withSeparator,
  setFinishedTheEdition,
  openNewPageOnConnect,
  handleOpenNewPageOnConnect,
  conditionalOpenChildren,
  useSaveButtonOnHeader,
  handleSaveOnHeader,
  loadingSaveOnHeader,
  disable,
  disableSave,
}: IExternalServiceConnection) => {
  const intl = useIntl();

  const [openForm, setOpenForm] = useState<boolean>(false);

  function toggleShow() {
    setFinishedTheEdition(false);
    setOpenForm(!openForm);
  }

  return (
    <>
      <styled.ServiceContainer>
        <div className="service-header">
          <div>
            <img src={icon} alt="logo" />
          </div>

          <div>
            {!openForm && (
              <Buttons
                mode="unfilled"
                label={intl.formatMessage({
                  id: isConnected
                    ? "settings.team.list.edit"
                    : "settings.integrations.connect",
                })}
                handler={toggleShow}
                disabled={disable}
              />
            )}
            {openForm && (
              <div className="flex-buttons-container">
                {useSaveButtonOnHeader && !loadingSaveOnHeader ? (
                  <Buttons
                    mode="filled"
                    label={intl.formatMessage({ id: "user.profile.ui.save" })}
                    handler={handleSaveOnHeader}
                    disable={disableSave}
                  />
                ) : useSaveButtonOnHeader && loadingSaveOnHeader ? (
                  <Buttons
                    mode="filled-processing"
                    label=""
                    handler={() => {}}
                  />
                ) : null}

                <Buttons
                  mode="unfilled"
                  label={intl.formatMessage({ id: "integrations.cancel" })}
                  handler={toggleShow}
                />
              </div>
            )}
          </div>
        </div>

        <p>{intl.formatMessage({ id: description })}</p>

        {openForm || conditionalOpenChildren ? children : null}
      </styled.ServiceContainer>

      {withSeparator ? <styled.Separator /> : null}
    </>
  );
};

export default ExternalServiceConnection;
