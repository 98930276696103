/* eslint-disable no-loop-func */
import React, { useEffect, useState, useContext, memo } from "react";
import Switch from "react-switch";
import { useIntl } from "react-intl";
import { useLocation, Link, useHistory } from "react-router-dom";

import { ToggleTemaContext } from "../../providers/toggleTheme";

//Assets
import homeIcon from "assets/images/sidebar/home.svg";
import homeActiveIcon from "assets/images/sidebar/home _active2.svg";
import salesIcon from "assets/images/sidebar/money.svg";
import salesActiveIcon from "assets/images/sidebar/money_active.svg";
import reportIcon from "assets/images/sidebar/report.svg";
import reportActiveIcon from "assets/images/sidebar/report_active.svg";
import developerstIcon from "assets/images/sidebar/book.svg";
import developerstActiveIcon from "assets/images/sidebar/book_active.svg";
import settingstIcon from "assets/images/sidebar/settings.svg";
import settingstActiveIcon from "assets/images/sidebar/settings_active.svg";
import financialsIcon from "assets/images/sidebar/financials.svg";
import financialsActiveIcon from "assets/images/sidebar/financials_active.svg";
import productsIcon from "assets/images/sidebar/products.svg";
import productsActiveIcon from "assets/images/sidebar/products_active.svg";
import customerIcon from "assets/images/sidebar/customer.svg";
import customerActiveIcon from "assets/images/sidebar/customer_active.svg";
import plansIcon from "assets/images/sidebar/plans.svg";
import plansActiveIcon from "assets/images/sidebar/plans_active.svg";

import { ThemeContext } from "styled-components";

// Store
import { useSettings } from "../../store/settings";

//Styles
import * as styled from "./sidebar-styles";

const Index = (props: any) => {
  const [isProd, setIsProd] = useState(false);
  const [isProcessingStore, setIsProcessingStore] = useState(false);
  const [role, setRole] = useState<boolean>(false);
  const [hasCommissioning, setHasCommissioning] = useState<boolean>(false);
  const [accessLevel, setAccessLevel] = useState<number | undefined>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [modeText, setModeText] = useState("escuro");

  const location = useLocation();

  const { title } = useContext(ThemeContext);

  const { environment, status } = useSettings((state) => state.settings);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    setIsProd(environment === "prod" ? true : false);

    if (status !== "enabled" && status !== "denied") {
      setIsProcessingStore(true);
    } else {
      setIsProcessingStore(false);
    }

    if (
      [
        "Business.Owner",
        "Business.API",
        "Business.Administrator",
        "Administrator.Owner",
      ].includes(session.role)
    ) {
      setRole(true);
    } else {
      setRole(false);
    }

    switch (session.role) {
      case "Business.Owner":
      case "Store.Owner":
      case "Administrator.Owner":
        setAccessLevel(0);
        break;
      case "Business.Administrator":
      case "Store.Administrator":
        setAccessLevel(1);
        break;
      // case "Business.Agent":

      //   setAccessLevel(2);
      //   break;
      // case "Business.ViewOnly":

      //   setAccessLevel(3);
      //   break;
      case "Business.API":
      case "Store.API":
        setAccessLevel(4);
        break;
        case "Store.Agent":
        case "Store.ViewOnly":
        case "Business.Agent":
        case "Business.ViewOnly":
        setAccessLevel(5);
        break;
      default:
        setAccessLevel(undefined);
    }

    
    /admin/.test(location.pathname) ? setIsAdmin(true) : setIsAdmin(false);
    
    setHasCommissioning(session.hasCommissioning);
  }, [location.pathname]);
  
  let intl = useIntl();
  const validBusinessIds = [3739, 110]
  const session = JSON.parse(localStorage.getItem("session") || "{}");
  const viewOnlyAndAgent = accessLevel === 5 && validBusinessIds.includes(Number(session.businessId));

  const [activeScreen, setActiveScreen] = useState({
    home: ["/", "login", "/admin"].includes(location.pathname) ? true : false,
    transactions: /transactions/.test(location.pathname) ? true : false,
    commissions: ["commissions"].includes(location.pathname) ? true : false,
    reports: ["reports"].includes(location.pathname) ? true : false,
    developers: ["developers"].includes(location.pathname) ? true : false,
    settings: ["settings"].includes(location.pathname) ? true : false,
    financials: ["financials"].includes(location.pathname) ? true : false,
    products: ["products"].includes(location.pathname) ? true : false,
    customers: ["customers"].includes(location.pathname) ? true : false,
    plans: ["plans"].includes(location.pathname) ? true : false,
  });

  useEffect(() => {
    title === "light" ? setModeText("claro") : setModeText("escuro");
  }, [title]);

  useEffect(() => {
    setActiveScreen({
      home: ["/", "login", "/admin"].includes(location.pathname) ? true : false,
      transactions: /transactions/.test(location.pathname) ? true : false,
      commissions: ["/sale-commissions", "/admin/sale-commissions"].includes(
        location.pathname
      )
        ? true
        : false,
      reports: ["/reports", "/admin/reports"].includes(location.pathname)
        ? true
        : false,
      developers: ["/developers", "/admin/developer"].includes(
        location.pathname
      )
        ? true
        : false,
      settings: ["/settings", "/admin/settings"].includes(location.pathname)
        ? true
        : false,
      financials: ["/financials"].includes(location.pathname) ? true : false,
      products: ["/products"].includes(location.pathname) ? true : false,
      customers: /customers/.test(location.pathname) ? true : false,
      plans: /plans/.test(location.pathname) ? true : false,
    });
  }, [location.pathname]);

  function handleEnvironment() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    // window.location.href = `https://${isProd ? 'test.' : ''}dashboard.premepay.com${location.pathname}?access_token=${session.token}`;
    window.location.href = `https://${
      isProd ? "test." : ""
    }dashboard.premepay.com/login`;
  }

  return ["/login", "/admin/login", "/new-password", "/signup"].includes(
    location.pathname
  ) ? (
    <span></span>
  ) : (
    <styled.Sidebar
      className={`${
        status !== "enabled" && status !== "denied" ? "double-top-margin" : ""
      }`}
    >
      <styled.MenuItems>
      {accessLevel !== undefined &&
        !viewOnlyAndAgent && (
          <Link
            to={isAdmin ? "/admin" : "/"}
            className={`menu-nav_item ${activeScreen.home ? "active" : ""}`}
          >
            <img src={activeScreen.home ? homeActiveIcon : homeIcon} alt="home" />
            <div className="label">
              {intl.formatMessage({ id: "button.sidebar.home" })}
            </div>
          </Link>
        )}

        <Link
          to={isAdmin ? "/admin/transactions" : "/transactions"}
          className={`menu-nav_item ${
            activeScreen.transactions ? "active" : ""
          }`}
        >
          <img
            src={activeScreen.transactions ? salesActiveIcon : salesIcon}
            alt="sales"
          />
          <div className="label">
            {intl.formatMessage({ id: "button.sidebar.transactions" })}
          </div>
        </Link>
        
        {accessLevel !== undefined &&
        !viewOnlyAndAgent && (
          <>
            <Link
              to={"/plans"}
              className={`menu-nav_item ${activeScreen.plans ? "active" : ""}`}
            >
              <img
                src={activeScreen.plans ? plansActiveIcon : plansIcon}
                alt="plans"
              />
              <div className="label">
                {intl.formatMessage({ id: "button.sidebar.plans" })}
              </div>
            </Link>

            <Link
              to={"/products"}
              className={`menu-nav_item ${activeScreen.products ? "active" : ""}`}
            >
              <img
                src={activeScreen.products ? productsActiveIcon : productsIcon}
                alt="products"
              />
              <div className="label">
                {intl.formatMessage({ id: "view.all.product" })}
              </div>
            </Link>

            <Link
              to={"/customers"}
              className={`menu-nav_item ${activeScreen.customers ? "active" : ""}`}
            >
              <img
                src={activeScreen.customers ? customerActiveIcon : customerIcon}
                alt="customers"
              />
              <div className="label">
                {intl.formatMessage({ id: "button.sidebar.customer" })}
              </div>
            </Link>
        </>
        )}


        {/* {hasCommissioning && <Link to={isAdmin ? "/admin/sale-commissions" : "/sale-commissions"} 
        onClick={() => handleClick("commissions")} className={`menu-nav_item ${activeScreen.commissions ? 'active' : ''}`}>
          <img src={homeIcon} alt="home" />
          <div className="label">{intl.formatMessage({ id: "button.sidebar.sale-commissions" })}</div>
      </Link>} */}

        {accessLevel !== undefined && accessLevel !== 2 && !viewOnlyAndAgent ? (
          <Link
            to={isAdmin ? "/admin/reports" : "/reports"}
            className={`menu-nav_item ${activeScreen.reports ? "active" : ""}`}
          >
            <img
              src={activeScreen.reports ? reportActiveIcon : reportIcon}
              alt="reports"
            />
            <div className="label">
              {intl.formatMessage({ id: "button.sidebar.reports" })}
            </div>
          </Link>
        ) : null}

        {role && (
          <Link
            to={isAdmin ? "/admin/integrations" : "/integrations"}
            className={`menu-nav_item ${
              activeScreen.developers ? "active" : ""
            }`}
          >
            <img
              src={
                activeScreen.developers
                  ? developerstActiveIcon
                  : developerstIcon
              }
              alt="developers"
            />
            <div className="label">
              {intl.formatMessage({ id: "button.sidebar.developers" })}
            </div>
          </Link>
        )}

        {accessLevel !== undefined &&
          (accessLevel === 0 || accessLevel === 1) && (
            <Link
              ref={props.settingsRef}
              to={"/financials"}
              className={`menu-nav_item ${
                activeScreen.financials ? "active" : ""
              }`}
            >
              <span>
                <img
                  src={
                    activeScreen.financials
                      ? financialsActiveIcon
                      : financialsIcon
                  }
                  alt="financials"
                />
                <div className="label">
                  {intl.formatMessage({ id: "button.sidebar.financials" })}
                </div>
              </span>
            </Link>
          )}

        {accessLevel !== undefined &&
          (accessLevel === 0 || accessLevel === 1) && (
            <Link
              ref={props.settingsRef}
              to={isAdmin ? "/admin/settings" : "/settings"}
              className={`menu-nav_item ${
                activeScreen.settings ? "active" : ""
              }`}
            >
              <span>
                <img
                  src={
                    activeScreen.settings ? settingstActiveIcon : settingstIcon
                  }
                  alt="settings"
                />
                <div className="label">
                  {intl.formatMessage({ id: "button.sidebar.settings" })}
                </div>
              </span>
            </Link>
          )}
      </styled.MenuItems>

      {!isAdmin && accessLevel !== 2 && (
        <styled.SwitchButton
          ref={props.testDataRef}
          disabled={isProcessingStore}
        >
          <Switch
            disabled={isProcessingStore}
            onChange={handleEnvironment}
            checked={!isProd}
            width={20}
            height={12}
            onColor="#808080"
            offColor="#808080"
            offHandleColor="#F0F0F0"
            onHandleColor="#282731"
            uncheckedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                }}
              ></div>
            }
            checkedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                }}
              ></div>
            }
          />
          <styled.TextSwitchButton disabled={isProcessingStore}>
            {intl.formatMessage({
              id: isProd
                ? "button.sidebar.switch.environment.prop"
                : "button.sidebar.switch.environment.test",
            })}
          </styled.TextSwitchButton>
        </styled.SwitchButton>
      )}

      {/* <styled.SwitchButton disabled={false}>
      <button></button>
      <Switch
        onChange={toggleTheme}
        checked={title === 'dark'}
        width={40}
        height={20}
        onColor="#808080"
        offColor="#808080"
        offHandleColor="#F0F0F0"
        onHandleColor="#282731"
        uncheckedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 15,
            }}
          >
            <img src={moonIcon} alt="moon" />
          </div>
        }
        checkedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 15,
            }}
          >
            <img src={sunIcon} alt="sun" />
          </div>
        }
      />
      <styled.TextSwitchButton disabled={false}><FormattedMessage id="button.sidebar.switch.mode" /> {modeText}</styled.TextSwitchButton>
    </styled.SwitchButton> */}
    </styled.Sidebar>
  );
};

export default memo(Index);
