import React, { useEffect, useState, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { FormattedMessage, useIntl } from "react-intl";
import { FilePond, registerPlugin } from "react-filepond";

import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";

import Map from "./Map";

//Components
import Buttons from "components/Forms/Buttons";
import Input from "components/Forms/InputFloatLabel";
import Select from "components/Forms/Select";

import MyLoader from "../ContentLoader";

//Helpers
import currencyAndSymbolUnique from "helpers/currencyAndSymbolUnique";

//Assets
import copyIcon from "assets/images/global/copy.svg";

//Styles
import * as styled from "./details-styles";

type DetailData = {
  label: string;
  value: string;
  imgValue?: string;
  type?: string;
  amount?: string;
  percentage?: string;
  nextValue?: string;
  classStatus?: string;
  loading?: {
    width: number;
  };
  copyButton?: boolean;
  isColumnListValues?: boolean;
  columnList?: string[];
  address?: any;
  orderStatus?: any;
  isReceivables?: boolean;
  receivableStatus?: string;
  anticipationValue?: string;
  isTheLastItem?: boolean;
  isMediaItem?: boolean;
  media?: any;
  isEditting?: boolean;
  handleName?: string;
  dataToHandler?: any;
  handlerData?: any;
  productCurrency?: string;
  setEditedImages?: any;
  imagesToDelete?: any;
  imagesIds?: any;
  setImagesIds?: any;
  setNewImages?: any;
  newImages?: any;
};

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType,
  FilePondPluginFilePoster
);

const DetailItem = ({
  label,
  value,
  imgValue,
  classStatus,
  loading,
  nextValue,
  type,
  percentage,
  amount,
  copyButton,
  isColumnListValues,
  columnList,
  address,
  orderStatus,
  isReceivables,
  receivableStatus,
  anticipationValue,
  isTheLastItem,
  isMediaItem,
  media,
  isEditting,
  handleName,
  dataToHandler,
  handlerData,
  productCurrency,
  setEditedImages,
  imagesToDelete,
  imagesIds,
  setImagesIds,
  setNewImages,
  newImages,
}: DetailData) => {
  const [low, setLow] = useState(false);
  const [medium, setMedium] = useState(false);
  const [high, setHigh] = useState(false);

  const [addressState, setAddressState] = useState(address);
  const [productMedia, setProductMedia] = useState<any[]>([]);
  const [productMediaBackup, setProductMediaBackup] = useState<any[]>([]);

  const shippableItems = [
    {
      label: "height",
      labelIntl: "create.product.height",
      placeholder: "create.product.height.placeholder",
    },
    {
      label: "length",
      labelIntl: "create.product.length",
      placeholder: "create.product.length.placeholder",
    },
    {
      label: "weight",
      labelIntl: "create.product.weight",
      placeholder: "create.product.weight.placeholder",
    },
    {
      label: "width",
      labelIntl: "create.product.width",
      placeholder: "create.product.width.placeholder",
    },
  ];

  useEffect(() => {
    setAddressState(address);
  }, [address]);

  useEffect(() => {
    let mediaIds: number[] = [];
    if (media !== undefined && media.length > 0) {
      const mediaPaths = media.reduce((acc: any, curr: any) => {
        mediaIds.push(curr.id);

        return [
          ...acc,
          {
            source: curr.filePath,
            options: {
              type: "local",
              metadata: {
                poster: curr.filePath,
                id: curr.id,
              },
            },
          },
        ];
      }, []);

      setProductMedia(mediaPaths);
      setProductMediaBackup(mediaPaths);
      setImagesIds(mediaIds);
    }
  }, [media]);

  const intl = useIntl();

  useEffect(() => {
    if (value) {
      let stringValue = value.toString();

      if (stringValue.indexOf("%") !== -1 && stringValue.indexOf(".") !== -1) {
        setLow(false);
        setMedium(false);
        setHigh(false);

        if (
          parseFloat(stringValue.replace("%", "")) >= 1 &&
          parseFloat(stringValue.replace("%", "")) <= 59
        ) {
          setLow(true);
        } else if (
          parseFloat(stringValue.replace("%", "")) >= 60 &&
          parseFloat(stringValue.replace("%", "")) <= 79
        ) {
          setMedium(true);
        } else if (
          parseFloat(stringValue.replace("%", "")) >= 80 &&
          parseFloat(stringValue.replace("%", "")) <= 100
        ) {
          setHigh(true);
        }
      }
    }
  }, [value]);

  const paymentLinkToCopy = useRef<HTMLInputElement>(null);

  function copyPaymentLink(paymentLink: string) {
    navigator.clipboard.writeText(paymentLink);
  }

  useEffect(() => {
    if (isEditting) {
      handleImages(productMedia);
    }
  }, [productMedia, isEditting]);

  function handleImages(e: any) {
    if (imagesIds && e.length > 0 && e[0].hasOwnProperty("getMetadata")) {
      const currentImages = [...imagesIds];

      //get to delete
      const remainImages = e.map((image: any) => {
        return image.getMetadata().id !== undefined
          ? image.getMetadata().id
          : 0;
      });

      const imagesIdToDelete = currentImages
        .filter((img: number) => {
          return img > 0 && !remainImages.includes(img);
        })
        .concat(
          remainImages.filter((img: number) => {
            return img > 0 && !currentImages.includes(img);
          })
        );

      //get to post
      let medias: any = [];
      const base64Array = e.map((media: any) => {
        if (!media.getMetadata().id) {
          const mediaBase64 = `data:${
            media.fileType
          };base64,${media.getFileEncodeBase64String()}`;

          medias.push({
            description: "",
            encodedFile: mediaBase64,
          });
        }
      });

      localStorage.setItem("newEdittedProductImages", JSON.stringify(medias));

      setEditedImages(true);
      imagesToDelete(imagesIdToDelete);
    }
  }

  function toDataUrl(url: string, callback: any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  function handleNamedInputData(name: string, value: string) {
    if (["height", "length", "weight", "width"].includes(name)) {
      const dimensionsIndexes = ["height", "length", "weight", "width"];
      handlerData((prevState: any) => {
        return {
          ...prevState,
          packageDimension: {
            ...prevState.packageDimension,
            [dimensionsIndexes.indexOf(name)]: `${
              name.charAt(0).toUpperCase() + name.slice(1)
            }: ${value} ${intl.formatMessage({
              id: `create.product.${
                name !== "weight" ? "centimeters" : "kilograms"
              }`,
            })}`,
          },
        };
      });
    } else {
      handlerData((prevState: any) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  }

  function handleNamedInputDataAmount(value: string) {
    handlerData((prevState: any) => {
      return {
        ...prevState,
        unitPrice: value,
      };
    });
  }

  return (
    <>
      {!isColumnListValues &&
        value !== "img-value" &&
        !isReceivables &&
        !isMediaItem && (
          <styled.DetailItem>
            <div className={`label`}> {label} </div>
            {loading ? (
              <MyLoader height={25} width="60%"/>
            ) : (
              <div
                className={`value  ${type === "link" ? "link-underline" : ""}`}
              >
                {!isEditting ? (
                  <span className={`${classStatus}`}>
                    {type === "fee" ? `${percentage} + ${amount}` : value}{" "}
                    {nextValue}
                  </span>
                ) : (
                  <>
                    {handleName !== "unitPrice" ? (
                      <Input
                        required={true}
                        mode="named"
                        name={handleName}
                        value={value}
                        placeholder=""
                        handler={handleNamedInputData}
                      />
                    ) : (
                      <div className="form-item select-and-input-container">
                        <div className="m-20-width">
                          <Select
                            mode="clean-named-child"
                            disabled={false}
                            name="currency"
                            placeholder=""
                            value={productCurrency}
                            handler={handleNamedInputData}
                          >
                            {currencyAndSymbolUnique.map((currency) => (
                              <option
                                className="bold-option"
                                key={currency.id}
                                value={currency.currency}
                              >
                                {currency.symbol}
                              </option>
                            ))}
                          </Select>
                        </div>

                        <div className="m-half-width">
                          <Input
                            disableCurrencyInput={false}
                            required={true}
                            mode="normal-currency"
                            name={handleName}
                            value={value}
                            placeholder={""}
                            handler={handleNamedInputDataAmount}
                            isInputWithoutBorder={true}
                          />
                        </div>
                      </div>
                    )}
                    {/* <ErrorMessage errors={formErrors} wantedError="planAmount" errorMessage={intl.formatMessage({ id: "error.request.payment.create.plan.amount" })} /> */}
                  </>
                )}

                {copyButton && (
                  <input
                    className="input-hidden"
                    onChange={() => {}}
                    ref={paymentLinkToCopy}
                    type="hidden"
                    value={value}
                  />
                )}

                {low && (
                  <styled.LowScore>
                    <FormattedMessage id="detail.order.risk.fraud.score.label.low" />
                  </styled.LowScore>
                )}
                {medium && (
                  <styled.MediumScore>
                    <FormattedMessage id="detail.order.risk.fraud.score.label.medium" />
                  </styled.MediumScore>
                )}
                {high && (
                  <styled.HighScore>
                    <FormattedMessage id="detail.order.risk.fraud.score.label.high" />
                  </styled.HighScore>
                )}

                {copyButton && (
                  <div className="copy-button-container">
                    <Buttons
                      mode="minimal-with-icon"
                      label={intl.formatMessage({ id: "ui.label.copy" })}
                      icon={copyIcon}
                      handler={() => copyPaymentLink(value)}
                    />
                  </div>
                )}

                {address &&
                  ![
                    addressState.street,
                    addressState.number,
                    addressState.district,
                    addressState.city,
                  ].includes("") && (
                    <div className="map-container">
                      <Map
                        street={addressState.street}
                        number={addressState.number}
                        district={addressState.district}
                        city={addressState.city}
                        status={orderStatus}
                      />
                    </div>
                  )}
              </div>
            )}
          </styled.DetailItem>
        )}

      {!isColumnListValues &&
        value === "img-value" &&
        !isReceivables &&
        !isMediaItem && (
          <styled.DetailItem>
            <div className={`label`}> {label} </div>
            {loading ? (
              <Skeleton width={loading.width} className="skeleton" />
            ) : (
              <div className={`value`}>
                <img className="card-network" src={imgValue} alt={imgValue} />
              </div>
            )}
          </styled.DetailItem>
        )}

      {isColumnListValues && !isReceivables && !isMediaItem && (
        <styled.DetailItem>
          <div className={`label`}> {label} </div>
          {loading ? (
            <Skeleton width={loading.width} className="skeleton" />
          ) : (
            <div className={`value value-column`}>
              {columnList &&
                !isEditting &&
                columnList.map((item) => {
                  return (
                    <div key={item} className={`value-column__item`}>
                      {item}{" "}
                    </div>
                  );
                })}

              {columnList &&
                isEditting &&
                shippableItems.map((shippableItem, index) => (
                  <div className="form-item--global" key={shippableItem.label}>
                    <h5>
                      {intl.formatMessage({ id: shippableItem.labelIntl })}
                    </h5>

                    <Input
                      required={false}
                      mode="named"
                      name={shippableItem.label.toLowerCase()}
                      value={
                        columnList[index]
                          ? parseFloat(columnList[index].split(":")[1])
                          : ""
                      }
                      placeholder={intl.formatMessage({
                        id: shippableItem.placeholder,
                      })}
                      handler={handleNamedInputData}
                    />
                  </div>
                ))}
            </div>
          )}
        </styled.DetailItem>
      )}

      {isReceivables && !isMediaItem ? (
        <styled.DetailItem
          className={`receivables ${isTheLastItem ? "receivable--last" : ""}`}
        >
          <div className={`label`}>{label} </div>
          {loading ? (
            <Skeleton width={loading.width} className="skeleton" />
          ) : (
            <div
              className={`value-line ${
                anticipationValue !== "" ? "withAnticipation" : ""
              }`}
            >
              <p>{receivableStatus}</p>
              <p>{anticipationValue}</p>
              <p>{value}</p>
            </div>
          )}
        </styled.DetailItem>
      ) : null}

      {isMediaItem ? (
        <styled.DetailItem>
          <div className={`label`}>{label} </div>
          {loading ? (
            <Skeleton width={loading.width} className="skeleton" />
          ) : (
            <div className="value">
              {!isEditting && (
                <div className="media-container">
                  {media[0] ? (
                    <img
                      className="first-media"
                      src={media[0].filePath}
                      alt={media[0].description}
                      key={media[0].description}
                    />
                  ) : null}
                  <div>
                    {media.map((img: any, index: number) => {
                      if (index > 0) {
                        return (
                          <img
                            src={img.filePath}
                            alt={img.description}
                            key={index}
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              )}

              {isEditting ? (
                <>
                  <div className="load-images-container">
                    <FilePond
                      files={productMedia}
                      onupdatefiles={setProductMedia}
                      allowMultiple={true}
                      acceptedFileTypes={[
                        "image/png",
                        "image/jpeg",
                        "image/svg",
                        "image/jpg",
                        "image/svg+xml",
                        "image/gif",
                      ]}
                      maxFiles={5}
                      name="productMedia"
                      server={{
                        // url: undefined,
                        process: null,
                        fetch: null,
                      }}
                    />
                  </div>
                </>
              ) : null}
            </div>
          )}
        </styled.DetailItem>
      ) : null}
    </>
  );
};

export default DetailItem;
