import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

//Services
import OrderService from "services/Order";

interface IProps {}
interface IState {
  data: number[];
}

class LineChart extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const currentMonth = new Date().getMonth() + 1;

    OrderService.getCountPerMonth()
      .then((response) => {
        let data = this.state.data;
        for (let index = 1; index <= currentMonth; index++) {
          let month = response.data.find((x: any) => x.month == index);
          if (month === undefined) data[index - 1] = 0;
          else data[index - 1] = month.count;
        }
        this.setState({
          data: [0, 0, 0, 0, 0, 0],
        });
      })
      .catch((err) => console.log(err));
  }

  render() {
    const options: ApexOptions = {
      chart: {
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 1.5,
      },
      xaxis: {
        categories: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
      },
      yaxis: {
        show: false,
      },
      colors: ["#FF7276", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
    };

    // const intl = useIntl();

    const series = [
      {
        // name: `${intl.formatMessage({ id: "reports.disputes.title" })}`,
        name: `Disputas`,
        data: this.state.data,
      },
    ];

    return (
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        width="100%"
        height="250"
      />
    );
  }
}

export default LineChart;
