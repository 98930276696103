export const countDown = (
  setTimerToNuvemshopRegister: React.Dispatch<React.SetStateAction<number>>
) => {
  const countDownDate = new Date().getTime() + 5 * 60000;
  let secondsTimer = 0;
  // Update the count down every 1 second
  const countDownPixCode = setInterval(pixCountDown, 1000);

  function pixCountDown() {
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance = countDownDate - now;

    // Time calculations for seconds
    secondsTimer = Math.floor((distance % (1000 * 30)) / 1000);

    // If the count down is finished, write some text
    if (distance < 272000) {
      clearInterval(countDownPixCode);
      setTimerToNuvemshopRegister(0);
    } else {
      setTimerToNuvemshopRegister(secondsTimer);
    }
  }
};
