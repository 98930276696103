import axios from 'axios';

interface ICreditCardFees {
  cardBrand: string;
  amount: number;
  paymentType: string;
}

interface IBoletoFees {
  amount: number;
  paymentType: string;
}

class OrderFeed {

  async creditCardFees({cardBrand, amount, paymentType}: ICreditCardFees ) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/fees?cardBrand=${cardBrand}&amount=${amount}&paymentType=${paymentType}`,
    {
      headers: {
        'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI0IiwidW5pcXVlX25hbWUiOiJjaGVja291dCIsInJvbGUiOiJQcmVtZS5DaGVja291dCIsIm5iZiI6MTYwMjY5OTk0NywiZXhwIjoxNzYwNDY2MzQ3LCJpYXQiOjE2MDI2OTk5NDd9.rkk_o-UqRApKtA-RHrSg6xDLqgN4pRZE3ZE5fz1VYSk`,
        'Content-Type': "application/json"
      }
    });
  }

  async boletoFees({amount, paymentType}: IBoletoFees) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/fees?amount=${amount}&paymentType=${paymentType}`,
    {
      headers: {
        'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI0IiwidW5pcXVlX25hbWUiOiJjaGVja291dCIsInJvbGUiOiJQcmVtZS5DaGVja291dCIsIm5iZiI6MTYwMjY5OTk0NywiZXhwIjoxNzYwNDY2MzQ3LCJpYXQiOjE2MDI2OTk5NDd9.rkk_o-UqRApKtA-RHrSg6xDLqgN4pRZE3ZE5fz1VYSk`,
        'Content-Type': "application/json"
      }
    });
  }
}

export default new OrderFeed();
