import { useState, useEffect } from "react";

import CustomersServices from "../../../../services/Customers";

import visaIcon from "../../../../assets/images/cards/icon-card-visa.svg";
import mastercardIcon from "../../../../assets/images/cards/icon-card-mastercard.svg";
import auraIcon from "../../../../assets/images/cards/icon-card-aura.svg";
import hiperIcon from "../../../../assets/images/cards/icon-card-hipercard.svg";
import jcbIcon from "../../../../assets/images/cards/icon-card-jcb.svg";
import americanExpressIcon from "../../../../assets/images/cards/icon-card-americanexpress.svg";
import eloIcon from "../../../../assets/images/cards/icon-card-elo.svg";
import discoverIcon from "../../../../assets/images/cards/icon-card-discover.svg";
import nonecardIcon from "../../../../assets/images/cards/icon-card.svg";

export const useCreditCardItem = ({ card, customerId }: any) => {
  const [networkIcon, setNetworkIcon] = useState(nonecardIcon);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    let icon = "";

    switch (card.cardBrand) {
      case "Visa":
        icon = visaIcon;
        break;
      case "Mastercard":
        icon = mastercardIcon;
        break;
      case "Elo":
        icon = eloIcon;
        break;
      case "Amex":
        icon = americanExpressIcon;
        break;
      case "JCB":
        icon = jcbIcon;
        break;
      case "Hipercard":
        icon = hiperIcon;
        break;
      case "Aura":
        icon = auraIcon;
        break;
      case "Discover":
        icon = discoverIcon;
        break;
      default:
        icon = nonecardIcon;
        break;
    }

    setNetworkIcon(icon);
  }, [card]);

  function handleDefaultCreditCard() {
    setIsProcessing(true);

    CustomersServices.makeDefaultCreditCard(customerId, card.id)
      .then((response) => {})
      .finally(() => {
        setIsProcessing(false);
      });
  }

  return {
    networkIcon,
    isProcessing,
    handleDefaultCreditCard,
  };
};
