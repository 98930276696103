import styled from 'styled-components';

type TFAQ = {
  arrowIcon: string;
}

export const FaqItem = styled.div`
  margin-top: 10px;
  color: ${props => props.theme.contentColors.primary};
  cursor: pointer;

  .answer {
    transition: all .3s ease;
    opacity: 0;
    animation: fadeIn .3s ease-in-out forwards;
    transform: translateY(-10px);
    font-size: 13px;

    @keyframes fadeIn {
      to {
        transition: all .3s ease;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
 
`;

export const Question = styled.div<TFAQ>`
  font-size: 14px;
  
  span{
    position: relative;

    &::after{
      content: '';
      -webkit-mask: url(${props => props.arrowIcon}) no-repeat center;
      mask: url(${props => props.arrowIcon}) no-repeat center;
      background-color: ${props => props.theme.contentColors.primary};
      background-size: cover;
      height: 10px;
      width: 10px;
      position: absolute;
      right:  -25px;
      bottom: calc(50% - 5px);
      transition: all .3s ease;

      @media screen and (max-width: 916px) {
        bottom: 0px;
      }

      @media screen and (max-width: 768px) {
        right:  -15px;
        bottom: 5px;
      }
    }

    &.active{
      transition: all .3s ease;

      &::after{
        transition: all .3s ease;
        transform: rotate(180deg);
      }
    }
  }
`;