import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { format } from "date-fns";

import SplitsServices from "../../../../../services/Splits";

import Input from "../../../../Forms/InputFloatLabel";
import Buttons from "../../../../Forms/Buttons";
import TipButtonModal from "../../../Modals/TipButtonModal";
import SelectProductsButtonModalV2 from "../../../Modals/SelectProductsButtonModalV2";
import SelectSplitRuleButtonModal from "../../../Modals/SelectSplitRuleButtonModal";
import SearchSplitRecipientButtonModal from "../../../Modals/SearchSplitRecipientButtonModal";

import * as styled from "../../payment-request-styles";

interface ISplit {
  id: number;
  storeId: number;
  percentage: number;
  fixed: number;
  expirationDate?: string;
  expirationTime?: string;
}

export default function Splits({
  valueToCheck,
  toggle,
  splits,
  setSplits,
  handleSplit,
  selectedSplits,
  setSelectedSplits,
  setIsCreatingSplit,
  isCreatingSplit,
  selectedRecipient,
  setSelectedRecipient,
  selectedRecipientIds,
  setSelectedRecipientIds,
}: any) {
  const intl = useIntl();

  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  function addAnotherSplit() {
    let currentSplits = [...splits];
    const nextId = currentSplits[currentSplits.length - 1].id + 1;

    currentSplits.push({
      id: nextId,
      storeId: 0,
      percentage: 0,
      fixed: 0,
      expirationDate: "",
      expirationTime: "",
    });

    setSplits(currentSplits);
  }

  return (
    <>
      <div className="form-item">
        <div className="flex">
          <Input
            valueToCheck={valueToCheck}
            required={false}
            mode="checkbox"
            name="define-split"
            value="define-split"
            placeholder={intl.formatMessage({
              id: "splits.checkbox.label",
            })}
            handler={toggle}
            readOnly={true}
          />
          <TipButtonModal
            text={intl.formatMessage({
              id: "splits.checkbox.tip.label",
            })}
          />
        </div>
      </div>

      {!!valueToCheck && (
        <>
          <div className="insider-container">
            {!isCreatingSplit ? (
              <styled.PaymentRequestItems>
                <h5>{intl.formatMessage({ id: "splits.rules.label" })}</h5>

                <SelectSplitRuleButtonModal
                  handler={setSelectedSplits}
                  selectedSplits={selectedSplits}
                  setIsCreatingSplit={setIsCreatingSplit}
                />
              </styled.PaymentRequestItems>
            ) : null}

            {isCreatingSplit &&
              splits.map((split: ISplit, index: number) => (
                <span key={split.id}>
                  <styled.PaymentRequestItems>
                    <h5>
                      {intl.formatMessage({
                        id: "splits.recipient.label",
                      })}
                    </h5>
                    <SearchSplitRecipientButtonModal
                      handler={setSelectedRecipient}
                      selectedRecipient={selectedRecipient}
                      setIsCreatingSplit={setIsCreatingSplit}
                      setSplits={setSplits}
                      splits={splits}
                      selectedRecipientIds={selectedRecipientIds}
                      setSelectedRecipientIds={setSelectedRecipientIds}
                      index={index}
                    />
                  </styled.PaymentRequestItems>

                  <styled.PaymentRequestItems>
                    <h5>
                      {intl.formatMessage({
                        id: "splits.commission.label",
                      })}
                    </h5>
                    <Input
                      required={true}
                      isCurrency={false}
                      mode="normal-fixed-label"
                      fixedSymbol={intl.formatMessage({
                        id: "request.payment.boleto.discount.percentual",
                      })}
                      name="commission-percent"
                      // value={newPlan.amount}
                      placeholder=""
                      handler={(e: any) =>
                        handleSplit(
                          index,
                          split.id,
                          "percentage",
                          e.target.value
                        )
                      }
                    />
                    {/* <ErrorMessage
                                      errors={formErrors}
                                      wantedError="planAmount"
                                      errorMessage={intl.formatMessage({
                                        id: "error.request.payment.create.plan.amount",
                                      })}
                                    /> */}
                  </styled.PaymentRequestItems>

                  <styled.PaymentRequestItems>
                    <Input
                      required={true}
                      isCurrency={true}
                      mode="normal-fixed-label"
                      fixedSymbol={intl.formatMessage({
                        id: "splits.add.commission.fixed.label",
                      })}
                      name="fixed"
                      // value={newPlan.amount}
                      placeholder=""
                      handler={(e: any) =>
                        handleSplit(index, split.id, "fixed", e.floatValue)
                      }
                    />
                    {/* <ErrorMessage
                                      errors={formErrors}
                                      wantedError="planAmount"
                                      errorMessage={intl.formatMessage({
                                        id: "error.request.payment.create.plan.amount",
                                      })}
                                    /> */}
                  </styled.PaymentRequestItems>

                  {/* <div className="form-item__optional">
                    <h5>
                      {intl.formatMessage({
                        id: "splits.expiration.date.label",
                      })}
                    </h5>
                    <p>
                      {intl.formatMessage({
                        id: "request.payment.optional.label",
                      })}
                    </p>
                  </div> */}

                  {/* <div className="form-item select-and-input-container">
                    <div className="m-half-width--flex">
                      <Input
                        type="date"
                        name="date"
                        mode="normal"
                        placeholder=""
                        isInputWithoutBorder={true}
                        minDate={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
                        // value={expirationLink.date}
                        handler={(e: any) =>
                          handleSplit(index, split.id, "expirationDate", e)
                        }
                      />
                    </div>

                    <div className="m-half-width--flex">
                      <Input
                        type="time"
                        name="time"
                        mode="normal"
                        placeholder=""
                        isInputWithoutBorder={true}
                        // value={expirationLink.time}
                        handler={(e: any) =>
                          handleSplit(index, split.id, "expirationTime", e)
                        }
                      />
                    </div>
                  </div> */}
                  {/* <ErrorMessage
                                      errors={formErrors}
                                      wantedError="planAmount"
                                      errorMessage={intl.formatMessage({
                                        id: "error.request.payment.create.plan.amount",
                                      })}
                                    /> */}
                </span>
              ))}

            {isCreatingSplit ? (
              <div className="flex-buttons-container margintop24--global">
                {/* <Buttons
                  mode={isLoadingCreate ? "filled-processing" : "filled"}
                  type="button"
                  handler={createNewSplits}
                  label={intl.formatMessage({
                    id: "user.profile.ui.save",
                  })}
                /> */}

                <Buttons
                  mode={"unfilled"}
                  type="button"
                  handler={() => {}}
                  onClick={addAnotherSplit}
                  label={intl.formatMessage({
                    id: "splits.add.2.label",
                  })}
                />

                {/* <Buttons
                  mode="unfilled"
                  type="button"
                  handler={() => {}}
                  onClick={() => setIsCreatingSplit(false)}
                  label={intl.formatMessage({
                    id: "home.withdrawall.cancel",
                  })}
                /> */}
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}
