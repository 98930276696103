import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import isBefore from "date-fns/isBefore";
import { parseISO } from "date-fns";

//Services
import ManagerLinksServices from "services/ManagerLinks";
import SessionService from "services/Session";

import DetailItem from "./DetailItem";
import QRCodeItem from "./QRCodeItem";

import Buttons from "../Forms/Buttons";

//Assets
import tipIcon from "assets/images/global/tip-icon.svg";

//Styles
import * as indexStyled from "../../styles/index-styles";
import * as styled from "./details-styles";

type TLink = {
  id: number;
  title: string;
  currency: string;
  url: string;
  amount: number;
  discount?: any;
  expiresOn?: string;
  installments: number;
  maxCharges: number;
  paymentMethods: string;
  description: string;
  qrCode: string | null | undefined;
  orderNumber: string | null;
  includeFeeTransfer: boolean;
  isUserDataRequired: boolean;
  frequency?: string;
  gracePeriod?: number;
  isExpiredLink?: boolean;
  store?: any;
  isActiveLink?: boolean;
  metadata?: any;
};

type TTax = {
  mode: number;
  amount: number;
};

type TPayment = {
  status: string;
  statusDescription: string;
  amount: number;
  installments: number;
};

type TRecentOrder = {
  id: number;
  status: number;
  payment: TPayment;
  number: string;
  customer: {
    firstName: string;
    surname: string;
  };
};

type TProps = {
  newPaymentLink?: boolean;
};

const PaymentLinkBoxDetails = ({ newPaymentLink = false }: TProps) => {
  const history = useHistory();
  let location = useLocation();

  if (!SessionService.isValid()) {
    SessionService.clean();

    // if (/test/.test(window.location.href)) {
    //   window.location.href = `https://dashboard.premepay.com/login`;
    // } else {
    //   history.push("/login");
    // }
    history.push("/login");
  }

  let { url } = useParams<any>();
  let intl = useIntl();

  const [tax, setTax] = useState<TTax>();
  const [processing, setProcessing] = useState(false);
  const [processingCancelSubscription, setProcessingCancelSubscription] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [columnLabelValue, setColumnLabelValue] = useState<string[]>([]);
  const [columnLabelValuePreFill, setColumnLabelValuePreFill] = useState<
    string[]
  >([]);

  const [isProcessingLinkStatus, setIsProcessingLinkStatus] =
    useState<boolean>(false);
  const [paymentLinkOrdersLoaded, setPaymentLinkOrdersLoaded] =
    useState<boolean>(false);
  const [paymentLinkOrders, setPaymentLinkOrders] = useState<TRecentOrder[]>(
    []
  );
  const [recentOrdersTemp, setRecentOrdersTemp] = useState([]);
  const [storeId, setStoreId] = useState(() => {
    const session = localStorage.getItem("session");

    const { storeId } = JSON.parse(session ? session : "{}");

    return storeId;
  });

  const [link, setLink] = useState<TLink>({
    id: 0,
    currency: "BRL",
    title: "",
    url: `https://pay.primefy.com/`,
    amount: 0,
    installments: 1,
    maxCharges: 1,
    paymentMethods: "manager.links.details.payment.methods.01",
    description: "",
    qrCode: null,
    gracePeriod: 0,
    orderNumber: null,
    includeFeeTransfer: false,
    isUserDataRequired: false,
  });

  // let paymentType = '';

  //       if ((link.paymentTypes.includes(0)) && (link.paymentTypes.includes(1))) {
  //         paymentType = 'manager.links.details.payment.methods.01';
  //       } else if (link.paymentTypes.includes(1)) {
  //         paymentType = 'manager.links.details.payment.methods.1';
  //       } else {
  //         paymentType = 'manager.links.details.payment.methods.0';
  //       }

  // if (response.data.subscription) {
  //   setSubscriptionId(response.data.subscription.id);

  //   switch (response.data.subscription.planFrequency) {
  //     case 'Daily':
  //       setRecurringDays('detail.order.cancel.subscription.day');
  //       break;
  //     case 'Weekly':
  //       setRecurringDays('detail.order.cancel.subscription.week');
  //       break;
  //     case 'Monthly':
  //       setRecurringDays('detail.order.cancel.subscription.month');
  //       break;
  //     case 'Quarterly':
  //       setRecurringDays('detail.order.cancel.subscription.quarterly');
  //       break;
  //     case 'HalfYearly':
  //       setRecurringDays('detail.order.cancel.subscription.halfYearly');
  //       break;
  //     case 'Yearly':
  //       setRecurringDays('detail.order.cancel.subscription.yearly');
  //       break;
  //   }
  //   setPlanId(response.data.subscription.planId);
  //   setOrderStatus(response.data.subscription.status);

  // }

  useEffect(() => {
    ManagerLinksServices.getLinkDetails(url).then((response) => {
      if (response.data.orders) {
        setPaymentLinkOrdersLoaded(true);
        setPaymentLinkOrders(response.data.orders);
      }

      let paymentTypes = "";

      if (
        (response.data.plan
          ? response.data.plan.paymentTypes
          : response.data.paymentTypes
        ).includes(0) &&
        (response.data.plan
          ? response.data.plan.paymentTypes
          : response.data.paymentTypes
        ).includes(1) &&
        (response.data.plan
          ? response.data.plan.paymentTypes
          : response.data.paymentTypes
        ).includes(3)
      ) {
        paymentTypes = "manager.links.details.payment.methods.013";
      } else if (
        (response.data.plan
          ? response.data.plan.paymentTypes
          : response.data.paymentTypes
        ).includes(0) &&
        (response.data.plan
          ? response.data.plan.paymentTypes
          : response.data.paymentTypes
        ).includes(1)
      ) {
        paymentTypes = "manager.links.details.payment.methods.01";
      } else if (
        (response.data.plan
          ? response.data.plan.paymentTypes
          : response.data.paymentTypes
        ).includes(0) &&
        (response.data.plan
          ? response.data.plan.paymentTypes
          : response.data.paymentTypes
        ).includes(3)
      ) {
        paymentTypes = "manager.links.details.payment.methods.03";
      } else if (
        (response.data.plan
          ? response.data.plan.paymentTypes
          : response.data.paymentTypes
        ).includes(1) &&
        (response.data.plan
          ? response.data.plan.paymentTypes
          : response.data.paymentTypes
        ).includes(3)
      ) {
        paymentTypes = "manager.links.details.payment.methods.13";
      } else if (
        (response.data.plan
          ? response.data.plan.paymentTypes
          : response.data.paymentTypes
        ).includes(1)
      ) {
        paymentTypes = "manager.links.details.payment.methods.1";
      } else if (
        (response.data.plan
          ? response.data.plan.paymentTypes
          : response.data.paymentTypes
        ).includes(3)
      ) {
        paymentTypes = "manager.links.details.payment.methods.3";
      } else {
        paymentTypes = "manager.links.details.payment.methods.0";
      }

      let finalFrequency = "";

      if (response.data.plan) {
        switch (response.data.plan.frequency) {
          case 0:
            finalFrequency = "request.payment.plan.daily";
            break;
          case 1:
            finalFrequency = "request.payment.plan.weekly";
            break;
          case 2:
            finalFrequency = "request.payment.plan.monthly";
            break;
          case 3:
            finalFrequency = "request.payment.plan.quarterly";
            break;
          case 4:
            finalFrequency = "request.payment.plan.halfYearly";
            break;
          case 5:
            finalFrequency = "request.payment.plan.yearly";
            break;
        }
      }

      let isExpiredLink: boolean;

      if (response.data.expiresOn && response.data.expiresOn !== "") {
        const formattedDate = parseISO(response.data.expiresOn);

        // if (link.store.isForeigner && (link.expiresOn.split('T')[1] !== '23:59:59')) {
        //   const timeZoneOffset = new Date(link.expiresOn).getTimezoneOffset();
        //   const timeZoneOffsetPositive = new Date(link.expiresOn).getTimezoneOffset() * (-1);

        //   const formatDate = timeZoneOffset > 0 ? new Date(new Date(link.expiresOn).getTime() - (timeZoneOffset * 60 * 1000)) : new Date(new Date(link.expiresOn).getTime() + (timeZoneOffsetPositive * 60 * 1000));
        //   const dateFormattedTimeZone = moment(formatDate).format();

        //   const parsedDate = parseISO(dateFormattedTimeZone.substring(0, 19));

        //   isExpiredLink = isBefore(parsedDate, new Date());
        // } else {
        isExpiredLink = isBefore(formattedDate, new Date());
        // }
      }

      let isActiveLink: boolean;

      if (response.data.isActive) {
        isActiveLink = true;
      } else {
        isActiveLink = false;
      }

      setLink((prevState: any) => {
        return {
          ...prevState,
          title: response.data.title,
          amount: response.data.plan
            ? response.data.plan.amount
            : response.data.amount,
          discount: response.data.discount,
          id: response.data.id,
          url: `https://pay.primefy.com/${url}`,
          paymentMethods: paymentTypes,
          description: response.data.plan
            ? response.data.plan.description
            : response.data.description,
          includeFeeTransfer: response.data.includeFeeTransfer,
          isUserDataRequired: response.data.isUserDataRequired,
          frequency: response.data.plan ? finalFrequency : undefined,
          gracePeriod: response.data.plan
            ? response.data.plan.gracePeriod
            : undefined,
          isExpiredLink: isExpiredLink,
          isActiveLink: isActiveLink,
          currency: response.data.currency ? response.data.currency : "BRL",
        };
      });

      let columnLabelValueString: string[] = [];
      let columnLabelValuePreFillString: string[] = [];

      if (response.data.discount) {
        const isPercentageMode = [0, 2, 4].includes(
          response.data.discount.mode
        );

        columnLabelValueString.push(
          `${intl.formatMessage({ id: "request.payment.boleto.discount" })}: ${
            isPercentageMode
              ? `${response.data.discount.percentage}%`
              : `${intl.formatNumber(response.data.discount.amount, {
                  style: "currency",
                  currency: "BRL",
                })}`
          }`
        );
      }

      if (response.data.lateFee) {
        const isPercentageMode = [0, 2, 4].includes(response.data.lateFee.mode);

        columnLabelValueString.push(
          `${intl.formatMessage({ id: "request.payment.boleto.fine" })}: ${
            isPercentageMode
              ? `${response.data.lateFee.percentage}%`
              : `${intl.formatNumber(response.data.lateFee.amount, {
                  style: "currency",
                  currency: "BRL",
                })}`
          }`
        );
      }

      if (response.data.dueDate) {
        columnLabelValueString.push(
          `${intl.formatMessage({ id: "request.payment.duedate" })}: ${moment(
            response.data.dueDate
          ).format("DD/MM/YYYY")}`
        );
      }

      if (response.data.customer) {
        let customer = response.data.customer;

        for (let property in customer) {
          if (
            customer[property] !== null &&
            customer[property] !== "" &&
            property !== "address"
          ) {
            columnLabelValuePreFillString.push(
              `${intl.formatMessage({
                id: `payment.link.details.dynamic.customer.${property}`,
              })}: ${
                property === "birthdate"
                  ? moment(customer[property]).format("DD/MM/YYYY")
                  : customer[property]
              }`
            );
          }
        }

        if (response.data.customer.address) {
          let address = response.data.customer.address;

          for (let property in address) {
            if (address[property] !== null && address[property] !== "") {
              columnLabelValuePreFillString.push(
                `${intl.formatMessage({
                  id: `payment.link.details.dynamic.customer.address.${property}`,
                })}: ${address[property]}`
              );
            }
          }
        }
      }

      setColumnLabelValue(columnLabelValueString);
      setColumnLabelValuePreFill(columnLabelValuePreFillString);
    });
  }, []);

  function handlePaymentLinkStatus() {
    setIsProcessingLinkStatus(true);

    ManagerLinksServices.changePAymentLinkStatus(url)
      .then((response) => {
        ManagerLinksServices.getLinkDetails(url).then((response) => {
          let isExpiredLink: boolean;

          if (response.data.expiresOn && response.data.expiresOn !== "") {
            const formattedDate = parseISO(response.data.expiresOn);
            isExpiredLink = isBefore(formattedDate, new Date());
          }

          let isActiveLink: boolean;

          if (response.data.isActive) {
            isActiveLink = true;
          } else {
            isActiveLink = false;
          }

          setLink((prevState: any) => {
            return {
              ...prevState,
              isExpiredLink: isExpiredLink,
              isActiveLink: isActiveLink,
            };
          });

          setIsProcessingLinkStatus(false);
        });
      })
      .catch((err) => {
        console.log(err);

        setIsProcessingLinkStatus(false);
      });
  }

  return (
    <styled.LeftRightContainer>
      <styled.LeftPaymentLinkDetails className="border-right">
        <styled.BoxDetails className="border-bottom">
          <styled.Header className={newPaymentLink ? "blue-title" : ""}>
            {!newPaymentLink && (
              <FormattedMessage id="manager.links.all.details.title" />
            )}
            {newPaymentLink && (
              <>
                <FormattedMessage id="payment.link.details.success.created.title" />

                <div>
                  <img src={tipIcon} alt="tip" />
                  <p>
                    {intl.formatMessage({ id: "manager.links.details.tip" })}
                  </p>
                </div>
                <p>
                  {intl.formatMessage({
                    id: "payment.link.details.success.created.note",
                  })}
                </p>
              </>
            )}
          </styled.Header>

          <styled.DetailsItems>
            {newPaymentLink && (
              <DetailItem
                copyButton={true}
                label={intl.formatMessage({ id: "request.payment.link" })}
                value={link.url}
                loading={link === undefined ? { width: 50 } : undefined}
              />
            )}
            {newPaymentLink && (
              <QRCodeItem
                link={link.url}
                label={intl.formatMessage({
                  id: "manager.links.details.qrcode",
                })}
                loading={link === undefined ? { width: 50 } : undefined}
              />
            )}

            {link !== undefined ? (
              (() => {
                let value = "";
                let classStatus = "";

                if (link.isExpiredLink) {
                  value = "expired";
                  classStatus = "status-failed";
                } else if (link.isActiveLink) {
                  value = "active";
                  classStatus = "status-succeeded";
                } else {
                  value = "deactivated";
                  classStatus = "status-deactivated";
                }

                return (
                  <DetailItem
                    label={intl.formatMessage({ id: "detail.order.status" })}
                    value={intl.formatMessage({ id: `status.${value}` })}
                    classStatus={classStatus}
                  />
                );
              })()
            ) : (
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.status" })}
                value=""
                classStatus=""
                loading={{ width: 75 }}
              />
            )}

            {!newPaymentLink && (
              <DetailItem
                copyButton={true}
                label={intl.formatMessage({ id: "request.payment.link" })}
                value={link.url}
                loading={link === undefined ? { width: 50 } : undefined}
              />
            )}
            <DetailItem
              label={intl.formatMessage({
                id: "manager.links.header.table.title",
              })}
              value={link.title}
              loading={link === undefined ? { width: 50 } : undefined}
            />
            <DetailItem
              label={intl.formatMessage({
                id: "manager.links.details.description",
              })}
              value={link.description}
              loading={link === undefined ? { width: 50 } : undefined}
            />
            <DetailItem
              label={intl.formatMessage({
                id: "manager.links.header.table.price",
              })}
              value={intl.formatNumber(link.amount, {
                style: "currency",
                currency: link.currency,
              })}
              loading={link === undefined ? { width: 50 } : undefined}
            />
            {link.frequency && (
              <DetailItem
                label={intl.formatMessage({
                  id: "manager.links.details.billing.period",
                })}
                value={intl.formatMessage({ id: link.frequency })}
                loading={link === undefined ? { width: 50 } : undefined}
              />
            )}
            {link.gracePeriod !== undefined && (
              <DetailItem
                label={intl.formatMessage({
                  id: "manager.links.details.trial.period",
                })}
                value={`${link.gracePeriod} ${intl.formatMessage({
                  id: `payment.link.details.grace.period${
                    link.gracePeriod > 1 || link.gracePeriod === 0 ? ".2" : ""
                  }`,
                })}`}
                loading={link === undefined ? { width: 50 } : undefined}
              />
            )}
            {!newPaymentLink && (
              <QRCodeItem
                link={link.url}
                label={intl.formatMessage({
                  id: "manager.links.details.qrcode",
                })}
                loading={link === undefined ? { width: 50 } : undefined}
              />
            )}

            <div className="border-bottom"></div>

            <div className="desactivate-link-container">
              {!isProcessingLinkStatus ? (
                <Buttons
                  mode="filled"
                  label={intl.formatMessage({
                    id: `manager.links.details.${
                      !link.isActiveLink ? "activate" : "desactivate"
                    }`,
                  })}
                  handler={() => handlePaymentLinkStatus()}
                />
              ) : null}
              {isProcessingLinkStatus ? (
                <Buttons mode="filled-processing" label="" handler={() => {}} />
              ) : null}

              <div className="margin-top-16">
                <img src={tipIcon} alt="tip" />
                <p>{intl.formatMessage({ id: "manager.links.details.tip" })}</p>
              </div>

              <p>
                {intl.formatMessage({ id: "manager.links.details.tip.text" })}
              </p>
            </div>
          </styled.DetailsItems>
        </styled.BoxDetails>
      </styled.LeftPaymentLinkDetails>

      <styled.Right>
        <styled.BoxDetails className="border-bottom">
          <styled.Header>
            <FormattedMessage id="manager.links.customized.details.title" />
          </styled.Header>

          <styled.DetailsItems>
            <DetailItem
              label={intl.formatMessage({
                id: "manager.links.details.payment.methods",
              })}
              value={intl.formatMessage({ id: link.paymentMethods })}
              loading={link === undefined ? { width: 50 } : undefined}
            />
            <DetailItem
              isColumnListValues={true}
              label={intl.formatMessage({
                id: "manager.links.details.customized.boleto",
              })}
              value={""}
              loading={link === undefined ? { width: 50 } : undefined}
              columnList={columnLabelValue}
            />
            <DetailItem
              label={intl.formatMessage({
                id: "manager.links.details.billing address",
              })}
              value={intl.formatMessage({
                id: `manager.links.details.billing address.${
                  !link.isUserDataRequired ? "no." : ""
                }required`,
              })}
              loading={link === undefined ? { width: 50 } : undefined}
            />
            <DetailItem
              label={intl.formatMessage({
                id: "manager.links.details.pre.fill.data",
              })}
              value={""}
              loading={link === undefined ? { width: 50 } : undefined}
              isColumnListValues={true}
              columnList={columnLabelValuePreFill}
            />
            <DetailItem
              label={intl.formatMessage({
                id: "manager.links.details.include.fee.transfer",
              })}
              value={intl.formatMessage({
                id: `manager.links.details.include.fee.transfer.${
                  link.includeFeeTransfer ? "yes" : "no"
                }`,
              })}
              loading={link === undefined ? { width: 50 } : undefined}
            />
          </styled.DetailsItems>
        </styled.BoxDetails>

        {!newPaymentLink && (
          <styled.BoxDetails className="border-bottom">
            <styled.Header>
              <FormattedMessage id="manager.links.details.sales.title" />
            </styled.Header>

            <indexStyled.TableList>
              <indexStyled.TableListHeader>
                <ul>
                  <li>
                    <FormattedMessage id="home.status" />
                  </li>
                  <li>
                    <FormattedMessage id="home.order" />
                  </li>
                  <li>
                    <FormattedMessage id="home.name" />
                  </li>
                  <li></li>
                </ul>
              </indexStyled.TableListHeader>

              <indexStyled.TableItems>
                {
                  paymentLinkOrdersLoaded ? (
                    paymentLinkOrdersLoaded &&
                    paymentLinkOrders.map(
                      (order: TRecentOrder, key: number) => {
                        let status;
                        switch (order.payment.statusDescription) {
                          case "New":
                            status = <li>
                              <span className="status-pending"><FormattedMessage id="payment.pending" /></span>
                            </li>
                            break;
                          case "Pending":
                            status = <li>
                              <span className="status-pending"><FormattedMessage id="payment.pending" /></span>
                            </li>
                            break;
                          case "Succeded":
                            status = <li>
                              <span className="status-succeeded"><FormattedMessage id="payment.confirmed" /></span>
                            </li>
                            break;
                          case "Canceled":
                            status = <li>
                              <span className="status-failed"><FormattedMessage id="payment.canceled" /></span>
                            </li>
                            break;
                          case "Failed":
                            status = <li>
                              <span className="status-failed"><FormattedMessage id="payment.failed" /></span>
                            </li>
                            break;
                          case "Disputed":
                            status = <li>
                              <span className="status-pending"><FormattedMessage id="payment.disputed" /></span>
                            </li>
                            break;
                          case "Expiration":
                            status = <li>
                              <span className="status-failed"><FormattedMessage id="payment.expiration" /></span>
                            </li>
                            break;
                          case "ChargedBack":
                            status = <li>
                              <span className="status-failed"><FormattedMessage id="payment.charged.back" /></span>
                            </li>
                            break;
                          case "DisputeSucceeded":
                            status = <li>
                              <span className="status-succeeded"><FormattedMessage id="payment.confirmed" /></span>
                            </li>
                            break;
                          case "PreAuthorized":
                            status = <li>
                              <span className="status-pending"><FormattedMessage id="payment.pre.authorized" /></span>
                            </li>
                            break;
                          case "PendingChallenge":
                            status = <li>
                              <span className="status-pending"><FormattedMessage id="payment.pending" /></span>
                            </li>
                            break;
                          case "Blocked":
                            status = <li>
                              <span className="status-failed"><FormattedMessage id="payment.blocked" /></span>
                            </li>
                            break;
                          case "PartiallySucceded":
                            status = <li>
                              <span className="status-deactivated"><FormattedMessage id="payment.partially.confirmed" /></span>
                            </li>
                            break;
                          case "PartiallyCanceled":
                            status = <li>
                              <span className="status-deactivated"><FormattedMessage id="payment.partially.canceled" /></span>
                            </li>
                            break;
                          default:
                            status = <li></li>
                            break;
                        }

                        return (
                          <a key={order.number}>
                            <ul>
                              {status}
                              <li>#{order.number}</li>
                              <li>{`${order.customer.firstName} ${order.customer.surname}`}</li>
                              <li>
                                <div>
                                  <Buttons
                                    mode="unfilled"
                                    label={intl.formatMessage({
                                      id: "manager.links.details.sales.view",
                                    })}
                                    handler={() =>
                                      history.push(
                                        `/transactions/${order.number}/${storeId}/details`
                                      )
                                    }
                                  />
                                </div>
                              </li>
                            </ul>
                          </a>
                        );
                      }
                    )
                  ) : (
                    // <Skeleton count={1} height={25} className="skeleton" />
                    <></>
                  )
                }
              </indexStyled.TableItems>
            </indexStyled.TableList>
          </styled.BoxDetails>
        )}
      </styled.Right>
    </styled.LeftRightContainer>
  );
};

export default PaymentLinkBoxDetails;
