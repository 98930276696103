import React, { createContext, useState } from "react";

export const RequestPaymentContext = createContext<any>({});

type Product = {
  id: number;
  name: string;
  quantity: number;
  classRoomIds: number[];
  unitPrice: number;
};

interface NewPaymentLink {
  planId?: number;
  name: string;
  frequency: number;
  title: string;
  orderNumber: string;
  discountLimit: string;
  amount: string;
  description: string;
  expiration: string;
  includeFeeTransfer: boolean;
  installments: number;
  boletoInstallments: number;
  dueDate: string;
  paymentTypes: number[];
  gracePeriod: string;
  productCategory: string;
  chargeDaysBefore: number;
  maxCharges: number;
  whenSend: string;
  sendToCustomer: string;
  warrantyDays: string;
  generateAllBoletos: boolean;
  currency: string;
  redirectUrl: string;
  products?: Product;
}

type NewProduct = {
  title: string;
  amount: string;
  description: string;
  currency: string;
  redirectUrl?: string;
  nameUnitLabel?: string;
  shippableProduct?: {
    [key: string]: string;
  };
};

type NewPlan = {
  name: string;
  amount: string;
  frequency: number;
  grace: number;
  description: string;
  productCategory: string;
  paymentTypes: number[];
  taxAmount: string;
  taxMode: string;
  isVisible: boolean;
};

type customer = {
  firstName: string;
  lastName: string;
  businessName: string;
  surname: string;
  identificationNumber: string;
  email: string;
  phone: string;
  birthdate: string;
  address: {
    street: string;
    number: string;
    zipcode: string;
    reference: string;
    district: string;
    city: string;
    state: number;
    country: number;
  };
};

type Tax = {
  mode: string;
  amount: string;
  errors: string[];
};

type CreditCard = {
  hasCardError: boolean;
  hasExpirationError: boolean;
  hasCvvError: boolean;
  errorMessage: string;
  successMessageState: string;
  cardNumber: string;
  expiration: string;
  cvv: string;
  holderName: string;
};

type ManualOrder = {
  planId?: number;
  name?: string;
  frequency: number;
  paymentTypes: number[];
  grace: number;
  productCategory: string;
  orderNumber: string;
  amount: string;
  description: string;
  expiration: string;
  includeFeeTransfer: boolean;
  installments: number;
  duedate: string;
  whenSend: string;
  sendToCustomer: string;
  warrantyDays: string;
  generateAllBoletos: boolean;
  maxCharges?: number;
  chargeDaysBefore: number;
};

export type ContextAllTypes = {
  newPaymentLink: NewPaymentLink;
  setNewPaymentLink: (newPaymentLink: any) => void;
  newPlan: NewPlan;
  setNewPlan: (newPlan: any) => void;
  customer: customer;
  setCustomer: (customer: any) => void;
  tax: Tax;
  setTax: (tax: any) => void;
  creditCard: CreditCard;
  setCreditCard: (credritCard: any) => void;
  manualOrder: ManualOrder;
  setManualOrder: (manualOrder: any) => void;
  product: NewProduct;
  setProduct: (product: NewProduct) => void;
  orderBump: any;
  setOrderBump: (orderBump: any) => void;
  upSell: any;
  setUpSell: (upSell: any) => void;
  crossSell: any;
  setCrossSell: (crossSell: any) => void;
};

export const RequestPaymentProvider = ({ children }: any) => {
  const [newPaymentLink, setNewPaymentLink] = useState<NewPaymentLink>({
    planId: undefined,
    name: "",
    frequency: 0,
    title: "",
    orderNumber: "",
    discountLimit: "",
    amount: "",
    description: "",
    expiration: "",
    includeFeeTransfer: false,
    installments: 12,
    boletoInstallments: 1,
    dueDate: "",
    paymentTypes: [0, 1, 3],
    gracePeriod: "",
    productCategory: "",
    chargeDaysBefore: 10,
    maxCharges: 0,
    whenSend: "",
    sendToCustomer: "",
    warrantyDays: "",
    generateAllBoletos: false,
    currency: "BRL",
    redirectUrl: "",
    products: undefined,
  });

  const [manualOrder, setManualOrder] = useState({
    planId: undefined,
    name: "",
    frequency: 0,
    paymentTypes: [0, 1, 3],
    grace: "",
    productCategory: "",
    orderNumber: "",
    amount: "",
    description: "",
    expiration: "",
    includeFeeTransfer: false,
    installments: 1,
    duedate: "",
    whenSend: "",
    sendToCustomer: "",
    warrantyDays: "",
    generateAllBoletos: false,
    maxCharges: 0,
    chargeDaysBefore: 10,
  });

  const [manualRecurringOrder, setManualRecurringOrder] = useState({
    id: "",
    name: "",
    frequency: 0,
    paymentTypes: [],
    amount: 0,
    grace: "",
    description: "",
    title: "",
    productCategory: "",
    dueDate: "",
  });

  const [creditCard, setCreditCard] = useState({
    hasCardError: false,
    hasExpirationError: false,
    hasCvvError: false,
    errorMessage: "",
    successMessageState: "",
    cardNumber: "",
    expiration: "",
    cvv: "",
    holderName: "",
  });

  const [customer, setCustomer] = useState({
    firstName: "",
    lastName: "",
    businessName: "",
    surname: "",
    identificationNumber: "",
    email: "",
    phone: "",
    birthdate: "",
    address: {
      street: "",
      number: "",
      zipcode: "",
      reference: "",
      district: "",
      city: "",
      state: 0,
      country: 0,
    },
  });

  const [newPlan, setNewPlan] = useState<NewPlan>({
    name: "",
    amount: "",
    frequency: 0,
    grace: 0,
    description: "",
    productCategory: "",
    paymentTypes: [0, 1, 3],
    taxAmount: "",
    taxMode: "",
    isVisible: true,
  });

  const [tax, setTax] = useState({
    mode: "",
    amount: "",
    errors: [],
  });

  const [product, setProduct] = useState<NewProduct>({
    title: "",
    amount: "",
    description: "",
    currency: "",
    redirectUrl: "",
    nameUnitLabel: "",
    shippableProduct: {
      weight: "",
      height: "",
      width: "",
      length: "",
    },
  });

  const [orderBump, setOrderBump] = useState<any>();
  const [upSell, setUpSell] = useState<any>();
  const [crossSell, setCrossSell] = useState<any>();

  return (
    <RequestPaymentContext.Provider
      value={{
        newPaymentLink,
        setNewPaymentLink,
        manualOrder,
        setManualOrder,
        manualRecurringOrder,
        setManualRecurringOrder,
        creditCard,
        setCreditCard,
        customer,
        setCustomer,
        newPlan,
        setNewPlan,
        tax,
        setTax,
        product,
        setProduct,
        orderBump,
        setOrderBump,
        upSell,
        setUpSell,
        crossSell,
        setCrossSell,
      }}
    >
      {children}
    </RequestPaymentContext.Provider>
  );
};
