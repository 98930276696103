import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";

//Hooks
import useClickOutside from "../../../hooks/useClickOutside";

//Services
import SplitsServices from "services/Splits";

//Components
import Buttons from "../../Forms/Buttons";
import Input from "../../Forms/InputFloatLabel";

//Assets
import arrowIcon from "../../../assets/images/topbar/select-arrow.svg";

//Styles
import * as styled from "./modals-styles";

type TRecipient = {
  business: {
    id: number;
    name: string;
    store: {
      id: number;
      name: string;
    };
  };
};

interface ISplit {
  id: number;
  storeId: number;
  percentage: number;
  fixed: number;
  expirationDate?: string;
  expirationTime?: string;
}

type TModal = {
  handler: any;
  selectedRecipient: TRecipient;
  setIsCreatingSplit: any;
  setSplits: any;
  splits: ISplit;
  selectedRecipientIds: any;
  setSelectedRecipientIds: any;
  index: number;
};

const SearchSplitRecipientButtonModal = ({
  handler,
  selectedRecipient,
  setIsCreatingSplit,
  setSplits,
  splits,
  selectedRecipientIds,
  setSelectedRecipientIds,
  index,
}: TModal) => {
  const [isMounted, setIsMounted] = useState(false);

  const [showSelectAndButtonModal, setShowSelectAndButtonModal] =
    useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [searchedEvent, setSearchedEvent] = useState("");
  const [notFound, setNotFound] = useState(false);

  const [selectedSplitsNames, setSelectedSplitsNames] = useState<string[]>([]);

  const [selectedSplitsIds, setSelectedSplitsIds] = useState<number[]>([]);
  const [recipient, setRecipient] = useState<TRecipient>();

  const selectRecipientModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  function getallSplitsData() {
    setNotFound(false);
    const cleanId = searchedEvent
      .replaceAll(".", "")
      .replaceAll("/", "")
      .replaceAll("-", "");
    SplitsServices.getExistentSplitRecipients(cleanId)
      .then((response) => {
        setRecipient(response.data);
      })
      .catch((err) => {
        console.log(err);
        setNotFound(true);
      });
  }

  useEffect(() => {
    if (
      searchedEvent.length === 11 ||
      searchedEvent.length === 14 ||
      searchedEvent.length === 18
    ) {
      getallSplitsData();
    }
  }, [searchedEvent]);

  function handleShowModal() {
    if (selectRecipientModalRef.current) {
      setIsMounted(false);
      setShowSelectAndButtonModal(true);
    } else {
      setIsMounted(true);
      setShowSelectAndButtonModal(true);
    }
  }

  function handleFilter(property: any, index: number) {
    if (selectedSplitsIds) {
      let currentLabels: string[] = [...selectedSplitsNames];
      let currentIds: number[] = [...selectedSplitsIds];
      let currentIdsglobal: number[] = [...selectedRecipientIds];

      if (currentIds.includes(property.business.store.id)) {
        setSelectedSplitsNames([""]);
        currentLabels = [""];
        currentIds = [];

        // const newCurrentIdsGlobal = currentIdsglobal.filter(
        //   (id) => id !== property.business.store.id
        // );

        handler([]);
      } else {
        currentLabels.push(property.business.store.name);
        setSelectedSplitsNames(currentLabels);

        handler([
          {
            ...property,
          },
        ]);

        currentIds.push(property.business.store.id);
      }

      if (currentIdsglobal.length >= index + 1) {
        currentIdsglobal[index] = property.business.store.id;
      } else {
        currentIdsglobal.push(property.business.store.id);
      }

      setSelectedLabel(currentLabels.join(", "));
      setSelectedSplitsIds(currentIds);
      setSelectedRecipientIds(currentIdsglobal);
    }
  }

  function handleCreateSplit() {
    setIsCreatingSplit(true);
    setSelectedLabel("");
    setSelectedSplitsIds([]);
    setSelectedSplitsNames([]);
  }

  useClickOutside(selectRecipientModalRef, isMounted, setIsMounted);

  return (
    <styled.RelativeContainer>
      <styled.LabelSelectPlan onClick={handleShowModal}>
        <p>
          {selectedLabel !== ""
            ? selectedLabel
            : intl.formatMessage({ id: "splits.search.label" })}
        </p>

        <img src={arrowIcon} alt="arrow" />
      </styled.LabelSelectPlan>

      {showSelectAndButtonModal && (
        <styled.SearchSplitRecipientContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={selectRecipientModalRef}
          onAnimationEnd={() => {
            if (!isMounted) setShowSelectAndButtonModal(false);
          }}
        >
          <div className="header-container--flex">
            <p>{intl.formatMessage({ id: "filter.label.title" })}</p>
          </div>

          <div className="header-container--search">
            <div>
              <Input
                required={false}
                mode="search"
                name="wantedStore"
                value={searchedEvent}
                placeholder={intl.formatMessage({
                  id: "header.search.country",
                })}
                handler={setSearchedEvent}
              />
            </div>
          </div>

          <div className="buttons-container">
            {recipient && !notFound ? (
              <div className="button__item--large">
                <div className="flex">
                  <Input
                    required={false}
                    mode="checkbox"
                    valueToCheck={selectedSplitsIds.includes(
                      recipient.business.store.id
                    )}
                    type="number"
                    name="events"
                    value={recipient.business.store.id}
                    placeholder={recipient.business.store.name}
                    readOnly={true}
                    handler={() => handleFilter(recipient, index)}
                  />
                </div>
              </div>
            ) : (
              <div className="button__item--large">
                <p>{intl.formatMessage({ id: "items.not.found.2" })}</p>
              </div>
            )}
          </div>
        </styled.SearchSplitRecipientContainer>
      )}
    </styled.RelativeContainer>
  );
};

export default SearchSplitRecipientButtonModal;
