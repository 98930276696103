import { useState, useEffect } from "react";
import { Controller, useForm, FieldValues } from "react-hook-form";
import { useIntl } from "react-intl";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Services
import CreateStoreService from "../../../../../services/CreateStore";

// Store
import { useSettings } from "../../../../../store/settings";

import { StoreRegisterServices } from "../../../../../services/StoreRegister";

// Types
type TCategory = {
  id: string;
  code: string;
  category: string;
  description: string;
};

interface ICategories {
  category: string;
  items: TCategory[];
}

type TRawCategory = {
  category: string;
  code: string;
  description: string;
  id: string;
  resource: string;
};
interface IRawCategories {
  items: TRawCategory[];
}

export const useBusinessInformation = ({
  setProgressStep,
  isStoreWithCNPJ,
  store,
}: any) => {
  const intl = useIntl();

  const businessSchema = object({
    businessName: string().required(
      intl.formatMessage({ id: "webhook.error.message.default" })
    ),
    category: string().required(
      intl.formatMessage({ id: "webhook.error.message.default" })
    ),
  });

  const businessWithCNPJSchema = object({
    businessName: string().required(
      intl.formatMessage({ id: "webhook.error.message.default" })
    ),
    category: string().required(
      intl.formatMessage({ id: "webhook.error.message.default" })
    ),
    legalCompanyName: string().required(
      intl.formatMessage({ id: "webhook.error.message.default" })
    ),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      isStoreWithCNPJ ? businessWithCNPJSchema : businessSchema
    ),
  });
  const { businessData } = StoreRegisterServices();
  const { settings } = useSettings((state) => state);

  const [logo, setLogo] = useState<any>([]);
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [rawCategories, setRawCategories] = useState<IRawCategories>();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showCategories, setShowCategories] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [selectedCategoryDescription, setSelectedCategoryDescription] =
    useState(intl.formatMessage({ id: "finish.register.select.category" }));
  const [searchedCategoriesState, setSearchedCategoriesState] = useState<
    ICategories[]
  >([]);

  const handleOpenCategoriesList = () => {
    showCategories ? setShowCategories(false) : setShowCategories(true);
  };

  const submitBusinessData = (data: FieldValues) => {
    setIsProcessing(true);
    const { businessName } = data;

    let logoBase64 = undefined;
    if (logo.length > 0) {
      logoBase64 = `data:${
        logo.fileType
      };base64,${logo[0].getFileEncodeBase64String()}`;
    }

    const finalCompanyName = isStoreWithCNPJ
      ? data.legalCompanyName
      : `${settings.fullName} ${settings.identificationNumber}`;

    const payload = {
      mcc: selectedCategory,
      companyName: finalCompanyName,
      socialName: businessName,
      encodedLogoImage: logoBase64,
    };

    businessData(payload)
      .then((response) => {
        setIsProcessing(false);

        let nextStep;
        if (store.address && store.address.city !== "") {
          if (isStoreWithCNPJ) {
            nextStep = 3;
          } else {
            nextStep = 4;
          }
        } else {
          if (isStoreWithCNPJ) {
            nextStep = 2;
          } else {
            nextStep = 3;
          }
        }

        setProgressStep(nextStep);
      })
      .catch((err) => {
        console.log(err);
        setIsProcessing(false);
      });
  };

  const handleSearchCategory = (value: string) => {
    let separatedCategories: any = [];

    let searchedCategory = categories.filter((category) => {
      let subcategories: any = [];

      category.items.filter((item: TCategory) => {
        if (item.description.toLowerCase().includes(value.toLowerCase())) {
          subcategories.push(item);
        }
      });

      if (subcategories.length > 0) {
        separatedCategories.push({
          category: category.category,
          items: subcategories,
        });
      }
    });

    setSearchedCategoriesState(separatedCategories);
  };

  const sortByCategory = (a: ICategories, b: ICategories) => {
    if (a.category < b.category) {
      return -1;
    }
    if (a.category > b.category) {
      return 1;
    }
    return 0;
  };

  const sortBySubCategory = (a: TCategory, b: TCategory) => {
    if (a.description < b.description) {
      return -1;
    }
    if (a.description > b.description) {
      return 1;
    }
    return 0;
  };

  const handleInput = (
    name: string,
    value: string,
    categoryDescription = ""
  ) => {
    if (name === "category") {
      setSelectedCategory(value);
      setSelectedCategoryDescription(categoryDescription);
    }

    setShowCategories(false);
  };

  useEffect(() => {
    CreateStoreService.getCategories().then((response) => {
      setRawCategories(response.data);

      let separatedCategories: any = [];
      let categoriesToDontRemake: any = [];

      response.data.items.reduce((accumulator: any, current: any) => {
        let subcategories: any = [];

        if (!categoriesToDontRemake.includes(current.category)) {
          response.data.items.filter((item: any) => {
            if (item.category === current.category) {
              subcategories.push(item);
            }
          });

          subcategories.sort(sortBySubCategory);
          separatedCategories.push({
            category: current.category,
            items: subcategories,
          });

          separatedCategories.sort(sortByCategory);
          categoriesToDontRemake.push(current.category);
        }
      });
      separatedCategories.shift();
      setCategories(separatedCategories);
    });
  }, []);

  useEffect(() => {
    if (store) {
      reset(
        (formValues) => ({
          ...formValues,
          identificationNumber: store.identificationNumber,
          category: selectedCategory,
          businessName: store.name,
        }),
        { keepErrors: true }
      );

      // if (store.logoPath && store.logoPath !== "") {
      //   setLogo(store.logoPath);
      // }

      if (store.mcc && selectedCategory === "") {
        const storeMcc: string = store.mcc.toString();

        reset(
          (formValues) => ({
            ...formValues,
            category: storeMcc,
          }),
          { keepErrors: true }
        );

        if (rawCategories) {
          const categoryDescription = rawCategories.items.filter((item) => {
            if (item.id === storeMcc) {
              return item;
            }
          });

          const popular = {
            ["40" as string]: intl.formatMessage({
              id: "create.account.popular.categories.education",
            }),
            ["106" as string]: intl.formatMessage({
              id: "create.account.popular.categories.entertainment",
            }),
            ["216" as string]: intl.formatMessage({
              id: "create.account.popular.categories.technology",
            }),
            ["221" as string]: intl.formatMessage({
              id: "create.account.popular.categories.clothing",
            }),
          };

          const finalCategoryDescription = ["40", "106", "216", "221"].includes(
            storeMcc
          )
            ? popular[storeMcc]
            : categoryDescription[0].description;

          handleInput("category", storeMcc, finalCategoryDescription);
        }
      }
    }
  }, [selectedCategory, store, rawCategories]);

  return {
    Controller,
    control,
    handleSubmit,
    submitBusinessData,

    showCategories,
    setShowCategories,
    handleOpenCategoriesList,
    selectedCategoryDescription,
    searchedCategoriesState,
    handleSearchCategory,
    categories,
    handleInput,
    logo,
    setLogo,
    isProcessing,
    errors,
  };
};
