import React, {useState, useEffect} from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from "react-intl";

//Services
import RedefinePasswordService from 'services/RedefinePassword';

//Components
import { LogoLogin } from 'components/Layout/Index';

import Input from 'components/Forms/InputFloatLabel';
import Buttons from 'components/Forms/Buttons';

//Assets
import eyeIcon from 'assets/images/icon-eye.svg';
import hideIcon from 'assets/images/icon-hide.svg';

//Styles
import "assets/css/login/main.css";
import "assets/css/login/util.css";
import * as styled from './styles/login-styles';

const RedefinePassword = () => {
  const [recoverPassEmail, setRecoverPassEmail] = useState(true);
  const [recoverPass, setRecoverPass] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  const [inputType, setInputType] = useState('password');
  const [hideShowPassIcon, setHideShowPassIcon] = useState(eyeIcon);

  const [redefineToken, setRedefineToken] = useState<string | null>('');

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [tokenEmail, setTokenEmail] = useState('');

  const intl = useIntl();

  const [formData, setFormData] = useState({email: "", password: ""});

  let history = useHistory();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  
  useEffect(() => {
    if(query.get("email")) {
      setRedefineToken(query.get("email"));
      setRecoverPassEmail(false);
      setRecoverPass(true);

      RedefinePasswordService.getEmail(query.get("email")).then(response => {
        setTokenEmail(response.data.username);
      })
    }else {
      setRedefineToken('');
      setRecoverPassEmail(true);
      setRecoverPass(false);
    }
  }, [])

  const handleEmailChange = (email: string) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        email: email
      };
    })
  }

  const handlePasswordChange = (password: string) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        password: password
      }
    })
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsProcessing(true);

    RedefinePasswordService.getLink({ username: formData.email.toLowerCase() }).then(response => {

      setSuccessMessage(intl.formatMessage({ id: "redefine.password.success.link.sent" }));
      setErrorMessage("");

      setIsProcessing(false);
      
    }).catch(err => {
      console.log(err);

      setErrorMessage(intl.formatMessage({ id: "redefine.password.user.not.found" }));
      setSuccessMessage("");

      setIsProcessing(false);
    });
  }

  function submitNewPass(event: any) {
    event.preventDefault();
    setIsProcessing(true);

    RedefinePasswordService.updatePass({ password: formData.password, token: redefineToken }).then(response => {
      setSuccessMessage(intl.formatMessage({ id: "redefine.password.success.redirect" }));
      setErrorMessage("");

      setIsProcessing(false);

      setTimeout(() => {
        history.push({
          pathname: '/login',
          state: { email: tokenEmail }
        });

      }, 3000);

    }).catch(err => {
      console.log(err);

      setErrorMessage(intl.formatMessage({ id: "redefine.password.failed" }));
      setSuccessMessage("");

      setIsProcessing(false);
    });

  }

  function toggleShowPassword() {
    if(inputType === 'password') {
      setInputType('text');
      setHideShowPassIcon(hideIcon);
    }else {
      setHideShowPassIcon(eyeIcon);
      setInputType('password');
    }
  }

  return (
    <styled.LoginSection>
      <styled.Limiter>
        <styled.ContainerLogin100>
          <LogoLogin />
          { recoverPassEmail && <styled.WrapLogin100>
            <styled.Login100Form
              className=" validate-form"
              // onSubmit={e => handleSubmit(e)}
              onSubmit={(e: any) => handleSubmit(e)}
            >
              <styled.Login100FormTitle className="p-b-26">
                <FormattedMessage id="login.recover.pass.dashboard" />
              </styled.Login100FormTitle>
              <p>
                <FormattedMessage id="login.text.recover.pass" />
              </p>
              <styled.WrapInput100>

                <div className="form-item">
                  <h5>Email</h5>

                  <Input required={false} mode="normal" name="email" value={formData.email} placeholder="" handler={handleEmailChange} />
                </div>

              </styled.WrapInput100>

              { successMessage !== '' && <styled.SuccessMessage>{successMessage}</styled.SuccessMessage> }
              { errorMessage !== '' && <styled.ErrorMessage>{errorMessage}</styled.ErrorMessage> }

              { !isProcessing && <div className="full-width-button margin-top-24"><Buttons
                mode="filled"
                label={intl.formatMessage({ id: "login.get.link.dashboard" })}
                handler={() => {}}
              /></div>}

              { isProcessing && <div className="full-width-button margin-top-24"><Buttons mode="filled-processing" label={intl.formatMessage({ id: "ui.cta.loading" })} handler={() => {}} /></div>}
             
            </styled.Login100Form>
          </styled.WrapLogin100>}

          { recoverPass && <styled.WrapLogin100>
            <styled.Login100Form
              className=" validate-form"
              onSubmit={e => submitNewPass(e)}
            >
              <styled.Login100FormTitle className="p-b-26">
                <FormattedMessage id="login.recover.pass.dashboard" />
              </styled.Login100FormTitle>
             
              <styled.Login100FormTitle className="p-b-48">
                <styled.Login100FormTitleI className="zmdi zmdi-font"></styled.Login100FormTitleI>
              </styled.Login100FormTitle>
              {/* <styled.WrapInput100 className="validate-input"> */}
              <styled.WrapInput100>
                <div className="form-item">
                  <h5>Email</h5>

                  <Input required={false} mode="normal" name="email" disabled={true} value={tokenEmail} placeholder="" handler={() => {}} />
                </div>

                <div className="form-item">
                  <h5>{intl.formatMessage({ id: "redefine.password.new.pass.label" })}</h5>

                  <Input required={false} mode="normal" type={inputType} name="password" value={formData.password} placeholder="" handler={handlePasswordChange} icon={hideShowPassIcon} iconHandler={toggleShowPassword}/>
                </div>

              </styled.WrapInput100>

              { successMessage !== '' && <styled.SuccessMessage>{successMessage}</styled.SuccessMessage> }
              { errorMessage !== '' && <styled.ErrorMessage>{errorMessage}</styled.ErrorMessage> }

              { !isProcessing && <div className="full-width-button margin-top-24"><Buttons
                mode="filled"
                label={intl.formatMessage({ id: "login.change.pass.dashboard" })}
                handler={() => {}}
              /></div>}

              { isProcessing && <div className="full-width-button margin-top-24"><Buttons mode="filled-processing" label={intl.formatMessage({ id: "ui.cta.loading" })} handler={() => {}} /></div>}

            </styled.Login100Form>
          </styled.WrapLogin100>}

        </styled.ContainerLogin100>
      </styled.Limiter>
      <div id="dropDownSelect1"></div>
    </styled.LoginSection>
  );
};

export default RedefinePassword;
