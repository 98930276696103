import React, { useState, useEffect, memo } from "react";
import { ContentBox, ContentBoxv2 } from "../Layout/Index";
import { useIntl, FormattedMessage, FormattedNumber } from "react-intl";

//Components
import GrossSalesChart from "./GrossSalesComponent";
import MyLoader from "components/ContentLoader";

//Services
import OrderService from 'services/Order';
import ExportCSVService from 'services/ReportsCSV';

//Styles
import * as styled from './charts-styles';

function actionSelect() {
  return false;
}

interface IOverview {
  amount: number,
  average: number,
  quantity: number,
  complains: number,
  loaded: boolean
}

const ReportChart = (props: any) => {
  const [year, setYear] = useState('');

  const [averageDays, setAverageDays] = useState('30');
  const [averageYear, setAverageYear] = useState('0');

  const intl = useIntl();

  useEffect(() => {
    const date = new Date();

    setYear(date.getFullYear().toString());
  }, [])

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  function exportToCSV() {
    ExportCSVService.grossSalesExport(year).then(response => {
      let downloadLink = document.createElement("a");
      let blob = new Blob([response.data], { type: 'text/csv' });
      let url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = "export-orders-premepay.csv";  //Name the file here
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });

  }

  function getDemonstrativeTotals() {
    // AdminServices.getDemonstrative(parseInt(averageDays), parseInt(averageYear)).then(response => {
    //   setAdminOverview({
    //     averageOrders: response.data.averageOrders,
    //     complains: response.data.complains,
    //     grossFees: response.data.grossFees,
    //     grossFeesPerClients: response.data.grossFeesPerClients,
    //     newClients: response.data.newClients,
    //     ordersQuantity: response.data.ordersQuantity,
    //     totalClients: response.data.totalClients,
    //     totalPaymentVolume: response.data.totalPaymentVolume,
    //     loaded: true
    //   });
    // }).catch(err => {
    //   console.log(err);
    // })
  }

  const [overview, setOverview] = useState<IOverview>({
    amount: 0,
    average: 0,
    quantity: 0,
    complains: 0,
    loaded: false
  });

  const overAll = () => {
    OrderService.getOverall()
      .then(response => {
        setOverview({
          amount: response.data.amount,
          average: response.data.average,
          quantity: response.data.quantity,
          complains: 0,
          loaded: true
        });
      })
      .catch(err => console.log(err));
  }

  useEffect(() => {
    overAll();
  }, []);


  return (
    <styled.ChartContainer ref={props.refs}>
      <styled.RowWith2GridColumns>
        <ContentBox>
          <div className="box-chart">
            <div className="report-chart_top">
              {/* <div className="left">
            <div className="title">
              <FormattedMessage id="home.overview" />
            </div>
          </div> */}
              {/* <div className="right">
            <Select
              name="currency"
              mainLabel="Currency"
              options={[{ value: 1, label: "$ Brititsh Pound" }]}
              action={actionSelect}
            />

            <Select
              name="currency"
              mainLabel="Filter"
              options={[{ value: 1, label: "Last 7 days" }]}
              action={actionSelect}
            />
            </div>*/}
              {/* <div className="right">
            <UnfilledButtonIconMask label="Exportar" icon='/images/icon-export.svg' handler={exportToCSV} />
          </div> */}
            </div>

            <GrossSalesChart isFull={true} id="gross-sales" title={intl.formatMessage({ id: 'home.overview' })} data={{}} />
          </div>
        </ContentBox>

        <ContentBoxv2>
          <div className="report-totals">
            <div className="list">
              <div className="list-item">
                <div className="list-item-label">
                  <FormattedMessage id="financial.gross.volume" />
                </div>
                <div className="list-item-value">
                  {overview.loaded ? <FormattedNumber value={overview.amount} style="currency" currency={props.currencyStore} /> :
                    <div className="skeleton-container">
                      <MyLoader />  
                    </div>}
                </div>
              </div>
              <div className="list-item">
                <div className="list-item-label">
                  <FormattedMessage id="financial.average.ticket.price" />
                </div>
                <div className="list-item-value">
                  {overview.loaded ? <FormattedNumber value={overview.average} style="currency" currency={props.currencyStore} /> :
                    <div className="skeleton-container">
                      <MyLoader />  
                    </div>}
                </div>
              </div>
              <div className="list-item">
                <div className="list-item-label">
                  <FormattedMessage id="financial.orders.quantity" />
                </div>
                <div className="list-item-value">
                  {overview.loaded ? <FormattedNumber value={overview.quantity} /> :
                    <div className="skeleton-container">
                      <MyLoader />  
                    </div>}
                </div>
              </div>
              {/* <div className="list-item">
            <div className="list-item-label">
              <FormattedMessage id="financial.dispute.count" />
            </div>
            <div className="list-item-value">
              {overview.loaded ? <FormattedNumber value={overview.complains} /> :
              <Skeleton width={100} className="skeleton"/>}
            </div>
          </div> */}
            </div>
            {/* <styled.Metrics>
          <div className="perfomance">
            <div className="left">
              <div className="label">
                <FormattedMessage id="home.performance" />
              </div>
              <div className="value">+29.%</div>
              <ProgressBar variant="success" now={29} />
            </div>
            <div className="right">
              <FormattedMessage id="home.performance.hint" />
            </div>
          </div>
        </styled.Metrics> */}
          </div>
        </ContentBoxv2>
      </styled.RowWith2GridColumns>

    </styled.ChartContainer>
  );
};

export default memo(ReportChart);
