import React from "react";
import { useIntl } from "react-intl";
import { ErrorMessage } from "@hookform/error-message";

import Buttons from "../../../../components/Forms/Buttons";
import InputFloat from "../../../../components/Forms/InputFloatLabel";
import Select from "../../../../components/Forms/Select";

import brStates from "../../../../helpers/states-format";
import countries from "../../../../helpers/countries-format";

import * as styled from "../../styles";

import { useAddressInformation } from "./hooks";

export const AddressInformation = ({
  setProgressStep,
  isStoreWithCNPJ,
  store,
}: any) => {
  const intl = useIntl();

  const {
    Controller,
    control,
    handleSubmit,
    submitAddressInformation,
    isProcessing,
    errors,
  } = useAddressInformation({ setProgressStep, isStoreWithCNPJ, store });
  return (
    <>
      <form onSubmit={handleSubmit(submitAddressInformation)}>
        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "request.payment.boleto.postal.code",
            })}
          </label>

          <Controller
            name="postalCode"
            control={control}
            render={({ field }) => (
              <InputFloat
                mode="normal-mask"
                mask="99999-999"
                handler={() => {}}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="postalCode"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "request.payment.boleto.address",
            })}
          </label>

          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <InputFloat mode="normal" handler={() => {}} {...field} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="address"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "request.payment.boleto.address.number",
            })}
          </label>

          <Controller
            name="houseNumber"
            control={control}
            render={({ field }) => (
              <InputFloat
                mode="normal"
                handler={() => {}}
                {...field}
                type="number"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="houseNumber"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "user.profile.right.reference",
            })}
          </label>

          <Controller
            name="reference"
            control={control}
            render={({ field }) => (
              <InputFloat mode="normal" handler={() => {}} {...field} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="reference"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "user.profile.right.district",
            })}
          </label>

          <Controller
            name="district"
            control={control}
            render={({ field }) => (
              <InputFloat mode="normal" handler={() => {}} {...field} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="district"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "request.payment.boleto.city",
            })}
          </label>

          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <InputFloat mode="normal" handler={() => {}} {...field} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="city"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "request.payment.boleto.state",
            })}
          </label>

          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <Select
                data={brStates}
                mode="normal"
                placeholder={""}
                handler={() => {}}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="state"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "financial.withdrawal.country",
            })}
          </label>

          <Select
            data={countries}
            mode="normal"
            placeholder={""}
            disabled={true}
            value="BR"
            handler={() => {}}
          />
        </div>

        <styled.ButtonFooterContainer>
          <Buttons
            mode={!isProcessing ? "filled" : "filled-processing"}
            label={intl.formatMessage({
              id: "login.button.login.continue",
            })}
            type="submit"
            handler={() => {}}
          />
        </styled.ButtonFooterContainer>
      </form>
    </>
  );
};
