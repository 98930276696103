import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import type { FieldValues } from "react-hook-form/dist/types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useIntl } from "react-intl";
import moment from "moment";
import axios from "axios";

import SessionService from "../../../../services/Session";
import CustomerServices from "../../../../services/Customers";

import brStates from "../../../../helpers/states-format";

interface ICustomer {
  name: string;
  surname: string;
  identificationNumber: string;
  email: string;
  phone: string;
  birthdate: string;
  address: {
    street: string;
    number: string;
    zipCode: string;
    reference: string;
    district: string;
    city: string;
    state: string;
    country?: string;
  };
}

function useCreateCustomer() {
  const history = useHistory();
  const intl = useIntl();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  const [dateLabel, setDateLabel] = useState("Data de Nascimento:");
  const [cpfCnpjLabel, setCpfCnpjLabel] = useState("CPF/CNPJ");
  const [processing, setProcessing] = useState(false);
  const [isCnpj, setIsCnpj] = useState(false);

  const stringRequired = string().required(
    intl.formatMessage({ id: "webhook.error.message.default" })
  );

  const createCustomerSchema = object({
    firstName: stringRequired,
    surname: string(),
    identificationNumber: string().matches(
      /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
      intl.formatMessage({ id: "personal.data.erro.identification.number" })
    ),
    birthdate: string(),
    address: object({
      zipcode: string(),
      street: string(),
      number: string(),
      district: string(),
      city: string(),
      state: string(),
    }),
    phone: string()
      .matches(
        /[(][0-9]{2}[)][\s][0-9]{5}[-][0-9]{4}$/g,
        intl.formatMessage({ id: "finish.phone.error.message" })
      )
      .nullable(),
    email: stringRequired.email(
      intl.formatMessage({ id: "create.account.email.yup" })
    ),
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createCustomerSchema),
  });

  const formWatchIdentificationNumber = watch("identificationNumber");
  const formWatchZipCode = watch("address.zipcode");
  const formWatch = watch();

  const customerAddressData = [
    {
      label: "zip.code",
      value: "",
      name: "zipcode",
    },
    {
      label: "street",
      value: "",
      name: "street",
    },
    {
      label: "house.number",
      value: "",
      name: "number",
    },
    {
      label: "district",
      value: "",
      name: "district",
    },
    { label: "city", value: "", name: "city" },
  ];

  useEffect(() => {
    if (formWatchIdentificationNumber?.length > 14) {
      setIsCnpj(true);
      setCpfCnpjLabel("CNPJ");
    } else {
      setIsCnpj(false);
      setCpfCnpjLabel("CPF");
    }

    if (
      formWatchIdentificationNumber === "" ||
      !formWatchIdentificationNumber
    ) {
      setCpfCnpjLabel("CPF/CNPJ");
    }
  }, [formWatchIdentificationNumber]);

  useEffect(() => {
    if (formWatchZipCode?.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${formWatchZipCode}/json/`)
        .then((response) => {
          const ufAsID = brStates.findIndex(
            (state) => state.value === response.data.uf
          );
          setValue("address.street", response.data.logradouro);
          setValue("address.district", response.data.bairro);
          setValue("address.city", response.data.localidade);
          setValue("address.state", ufAsID);
        });
    }
  }, [formWatchZipCode]);

  function submitNewCustomer(data: FieldValues) {
    setProcessing(true);

    const isAddressEmpty = Object.values(data.address).every(
      (el) => el === undefined
    );

    let birthdateData = moment(data.birthdate, "DD/MM/YYYY");
    let birthDateGenerated = birthdateData.format("YYYY-MM-DD");
    const newCustomer = {
      ...data,
      identificationNumber: data.identificationNumber
        ? data.identificationNumber?.length === 14
          ? data.identificationNumber
              .replace(".", "")
              .replace(".", "")
              .replace("-", "")
          : data.identificationNumber
              .replace(".", "")
              .replace(".", "")
              .replace("/", "")
              .replace("-", "")
        : undefined,
      birthdate:
        birthDateGenerated !== "Invalid date" ? birthDateGenerated : undefined,
      address: isAddressEmpty
        ? undefined
        : {
            ...data.address,
            reference: "",
            country: 0,
            state: parseInt(data.address.state),
          },
    };

    CustomerServices.createCustomer(newCustomer as ICustomer)
      .then((response) => {
        setProcessing(false);

        history.push(`/customers`);
      })
      .catch((err) => {
        console.log(err);
        setProcessing(false);
      });
  }

  return {
    processing,
    submitNewCustomer,
    handleSubmit,
    control,
    errors,
    customerAddressData,
    isCnpj,
    cpfCnpjLabel,
  };
}

export default useCreateCustomer;
