import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { StoreRegisterServices } from "../../../../../services/StoreRegister";

export const useDocumentsInformation = ({
  setProgressStep,
  isStoreWithCNPJ,
}: any) => {
  const history = useHistory();
  const { handleSubmit } = useForm();
  const { documentsData } = StoreRegisterServices();

  const [incorporationDocument, setIncorporationDocument] = useState<any>([]);
  const [proofOfActivity, setProofOfActivity] = useState<any>([]);
  const [identificationDocument, setIdentificationDocument] = useState<any>([]);
  const [proofOfAddress, setProofOfAddress] = useState<any>([]);
  const [additionalDocuments, setAdditionalDocuments] = useState<any>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);

  const validateFiles = () => {
    const errors: string[] = [];

    if (proofOfActivity.length === 0) {
      errors.push("proofOfActivity");
    }

    if (identificationDocument.length === 0) {
      errors.push("identificationDocument");
    }

    if (proofOfAddress.length === 0) {
      errors.push("proofOfAddress");
    }

    if (isStoreWithCNPJ) {
      if (incorporationDocument.length === 0) {
        errors.push("incorporationDocument");
      }
    }

    setGlobalErrors(errors);
    return errors.length === 0;
  };

  const submitBusinessData = () => {
    if (validateFiles()) {
      setIsProcessing(true);

      const proofOfActivityArray = proofOfActivity.map((document: any) => {
        return {
          type: "atividade",
          encodedFile: `data:${
            document.fileType
          };base64,${document.getFileEncodeBase64String()}`,
        };
      });

      const identificationDocumentArray = identificationDocument.map(
        (document: any) => {
          return {
            type: "identificacao",
            encodedFile: `data:${
              document.fileType
            };base64,${document.getFileEncodeBase64String()}`,
          };
        }
      );

      const proofOfAddressArray = proofOfAddress.map((document: any) => {
        return {
          type: "residencia",
          encodedFile: `data:${
            document.fileType
          };base64,${document.getFileEncodeBase64String()}`,
        };
      });

      let incorporationDocumentArray;
      if (isStoreWithCNPJ) {
        incorporationDocumentArray = incorporationDocument.map(
          (document: any) => {
            return {
              type: "identificacao",
              encodedFile: `data:${
                document.fileType
              };base64,${document.getFileEncodeBase64String()}`,
            };
          }
        );
      }

      let additionalDocumentsArray;

      if (additionalDocuments.length > 0) {
        additionalDocumentsArray = additionalDocuments.map((document: any) => {
          return {
            type: "atividade",
            encodedFile: `data:${
              document.fileType
            };base64,${document.getFileEncodeBase64String()}`,
          };
        });
      }

      const payload = [
        ...proofOfActivityArray,
        ...identificationDocumentArray,
        ...proofOfAddressArray,
        ...(additionalDocumentsArray || []),
        ...(incorporationDocumentArray || []),
      ];

      documentsData(payload)
        .then((response: any) => {
          history.push("/");
        })
        .catch((err) => {
          console.log(err);
          setIsProcessing(false);
        });
    }
  };

  return {
    handleSubmit,
    submitBusinessData,

    incorporationDocument,
    setIncorporationDocument,

    proofOfActivity,
    setProofOfActivity,
    identificationDocument,
    setIdentificationDocument,
    proofOfAddress,
    setProofOfAddress,
    additionalDocuments,
    setAdditionalDocuments,
    isProcessing,
    globalErrors,
  };
};
