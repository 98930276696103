const states = [
  { id: 0, label: "Acre", value: 0, uf: "AC" },
  { id: 1, label: "Alagoas", value: 1, uf: "AL" },
  { id: 2, label: "Amapá", value: 2, uf: "AP" },
  { id: 3, label: "Amazonas", value: 3, uf: "AM" },
  { id: 4, label: "Bahia", value: 4, uf: "BA" },
  { id: 5, label: "Ceará", value: 5, uf: "CE" },
  { id: 6, label: "Distrito Federal", value: 6, uf: "DF" },
  { id: 7, label: "Espírito Santo", value: 7, uf: "ES" },
  { id: 8, label: "Goiás", value: 8, uf: "GO" },
  { id: 9, label: "Maranhão", value: 9, uf: "MA" },
  { id: 10, label: "Mato Grosso", value: 10, uf: "MT" },
  { id: 11, label: "Mato Grosso do Sul", value: 11, uf: "MS" },
  { id: 12, label: "Minas Gerais", value: 12, uf: "MG" },
  { id: 13, label: "Pará", value: 13, uf: "PA" },
  { id: 14, label: "Paraíba", value: 14, uf: "PB" },
  { id: 15, label: "Paraná", value: 15, uf: "PR" },
  { id: 16, label: "Pernambuco", value: 16, uf: "PE" },
  { id: 17, label: "Piauí", value: 17, uf: "PI" },
  { id: 18, label: "Rio de Janeiro", value: 18, uf: "RJ" },
  { id: 19, label: "Rio Grande do Norte", value: 19, uf: "RN" },
  { id: 20, label: "Rio Grande do Sul", value: 20, uf: "RS" },
  { id: 21, label: "Rondônia", value: 21, uf: "RO" },
  { id: 22, label: "Roraima", value: 22, uf: "RR" },
  { id: 23, label: "Santa Catarina", value: 23, uf: "SC" },
  { id: 24, label: "São Paulo", value: 24, uf: "SP" },
  { id: 25, label: "Sergipe", value: 25, uf: "SE" },
  { id: 26, label: "Tocantins", value: 26, uf: "TO" },
];

export default states;
