import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import moment from "moment";

//Services
import OrderService from "../../../services/Order";

//Helpers
import brStates from "../../../helpers/states-format-id";

//Components
import Input from "../../Forms/InputFloatLabel";
import Select from "../../Forms/Select";

import ErrorMessage from "./FormValidations/ErrorMessage";

//Styles
import * as styled from "./payment-request-styles";

interface ICustomer {
  firstName: string;
  lastName: string;
  businessName: string;
  surname: string;
  identificationNumber: string;
  email: string;
  phone: string;
  birthdate: string;
  address: {
    street: string;
    number: string;
    zipcode: string;
    reference: string;
    district: string;
    city: string;
    state: number;
    country: number;
  };
}

interface IProps {
  customer: ICustomer;
  handleCustomer: any;
  isForeigner: boolean;
  card?: boolean;
  boleto?: boolean;
  showAddress: boolean;
  showCustomer: boolean;
  orderNumber?: string;
  setIsTrueOrderNumber?: any;
  customerCurrency?: string;
  isPaymentLink?: boolean;
  formErrors?: any;
}

const CustomerPersonalData = ({
  customer,
  handleCustomer,
  isForeigner,
  card,
  boleto,
  showAddress,
  showCustomer,
  orderNumber,
  setIsTrueOrderNumber,
  customerCurrency,
  isPaymentLink,
  formErrors,
}: IProps) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [isCnpj, setIsCnpj] = useState(false);
  const [customizeBoleto, setCustomizeBoleto] = useState(false);
  const [includeFeeTransfer, setIncludeFeeTransfer] = useState(false);

  const [valueOrder, setValueOrder] = useState("");
  let valueOrderNumber = parseFloat(valueOrder);

  const [dateLabel, setDateLabel] = useState("Data de Nascimento:");

  const [requireUserDataInCheckout, setRequireUserDataInCheckout] =
    useState(false);
  const [preFillUserDataInCheckout, setPreFillUserDataInCheckout] =
    useState(false);

  const [cpfCnpjLabel, setCpfCnpjLabel] = useState("CPF/CNPJ");

  const refName = useRef<HTMLInputElement>(null);
  const refLastName = useRef<HTMLInputElement>(null);
  const refCpf = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);
  const refBirth = useRef<HTMLInputElement>(null);
  const refPhone = useRef<HTMLInputElement>(null);
  const refCep = useRef<HTMLInputElement>(null);
  const refAddress = useRef<HTMLInputElement>(null);
  const refNumber = useRef<HTMLInputElement>(null);
  const refComplement = useRef<HTMLInputElement>(null);
  const refNeighbourhood = useRef<HTMLInputElement>(null);
  const refCity = useRef<HTMLInputElement>(null);
  const selectState = useRef<HTMLInputElement>(null);
  const refIdentificationNumber = useRef<HTMLInputElement>(null);

  const intl = useIntl();

  useEffect(() => {
    if (
      customer.identificationNumber.length > 14 &&
      (customerCurrency === "BRL" || boleto || isPaymentLink)
    ) {
      setIsCnpj(true);
      setDateLabel("Data de Registro:");
      setCpfCnpjLabel("CNPJ:");

      handleCustomer((prevState: any) => {
        return {
          ...prevState,
          firstName: "",
          lastName: "",
        };
      });

      if (refName.current && refLastName.current) {
        refName.current.disabled = true;
        refLastName.current.disabled = true;
      }
    } else if (customer.identificationNumber.length === 0) {
      if (refName.current && refLastName.current) {
        refName.current.disabled = false;
        refLastName.current.disabled = false;
      }

      setIsCnpj(false);
      setDateLabel("Data de Nascimento");
      setCpfCnpjLabel("CPF/CNPJ");
    } else {
      if (refName.current && refLastName.current) {
        refName.current.disabled = false;
        refLastName.current.disabled = false;
      }

      setIsCnpj(false);
      setDateLabel("Data de Nascimento");
      setCpfCnpjLabel("CPF");
    }
  }, [customer.identificationNumber]);

  function handleCustomerData(name: string, value: string) {
    handleCustomer((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handleCustomerAddressData(name: string, value: string) {
    handleCustomer((prevState: any) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  }

  useEffect(() => {
    if (orderNumber && orderNumber.match("^(#P)\\d{6}")) {
      const orderNumberClean = orderNumber!.replace("#", "");

      OrderService.getByNumber(orderNumberClean, "0")
        .then((response) => {
          setIsTrueOrderNumber(true);

          setErrorMessage("");

          const formattedDate = response.data.customer.birthdate
            ? moment(response.data.customer.birthdate).format("DD/MM/YYYY")
            : "";

          handleCustomer((prevState: any) => {
            return {
              ...prevState,
              identificationNumber: response.data.customer.identificationNumber,
              firstName: response.data.customer.firstName,
              lastName: response.data.customer.surname,
              businessName: response.data.customer.firstName,
              email: response.data.customer.email,
              phone: response.data.customer.phone
                ? response.data.customer.phone
                : "",
              birthdate: response.data.customer.birthdate
                ? formattedDate
                : null,
              address: response.data.customer.address
                ? {
                    street: response.data.customer.address.street,
                    number: response.data.customer.address.number,
                    zipcode: response.data.customer.address.zipCode,
                    reference: response.data.customer.address.reference,
                    district: response.data.customer.address.district,
                    city: response.data.customer.address.city,
                    state: response.data.customer.address.state,
                    country: "BR",
                  }
                : null,
            };
          });

          setTimeout(() => {
            if (response.data.customer.firstName) {
              refName.current!.disabled = true;
            }

            if (response.data.customer.surname) {
              refLastName.current!.disabled = true;
            }

            if (response.data.customer.identificationNumber) {
              if (refIdentificationNumber.current) {
                refIdentificationNumber.current.disabled = true;
              }
            }

            if (response.data.customer.address) {
              response.data.customer.birthdate !== ""
                ? (refBirth.current!.disabled = true)
                : (refBirth.current!.disabled = false);

              response.data.customer.address.zipCode !== ""
                ? (refCep.current!.disabled = true)
                : (refCep.current!.disabled = false);

              response.data.customer.address.street !== ""
                ? (refAddress.current!.disabled = true)
                : (refAddress.current!.disabled = false);

              response.data.customer.address.number !== ""
                ? (refNumber.current!.disabled = true)
                : (refNumber.current!.disabled = false);

              response.data.customer.address.reference !== ""
                ? (refComplement.current!.disabled = true)
                : (refComplement.current!.disabled = false);

              response.data.customer.address.district !== ""
                ? (refNeighbourhood.current!.disabled = true)
                : (refNeighbourhood.current!.disabled = false);

              response.data.customer.address.city !== ""
                ? (refCity.current!.disabled = true)
                : (refCity.current!.disabled = false);

              response.data.customer.address.state !== ""
                ? (selectState.current!.disabled = true)
                : (selectState.current!.disabled = false);
            }
          }, 300);
        })
        .catch((err) => {
          console.log(err);
          if (orderNumber && orderNumber.length === 8) {
            setErrorMessage(
              intl.formatMessage({
                id: "request.payment.not.found.order.number",
              })
            );
          }
        });
    } else {
      if (orderNumber && orderNumber.length === 8) {
        setErrorMessage(
          intl.formatMessage({ id: "request.payment.not.found.order.number" })
        );
      }
    }
  }, [orderNumber]);

  return (
    <>
      <styled.PaymentRequestItems>
        {showCustomer && (
          <>
            <div className="form-item">
              <h5>{cpfCnpjLabel}</h5>

              <Input
                mode="named-mask"
                name="identificationNumber"
                mask={
                  customer.identificationNumber.length > 14
                    ? "99.999.999/9999-99"
                    : "999.999.999-999"
                }
                value={customer.identificationNumber}
                placeholder={""}
                handler={handleCustomerData}
              />
              <ErrorMessage
                errors={formErrors}
                wantedError="cpf"
                errorMessage={intl.formatMessage({
                  id: "personal.data.erro.identification.cpf",
                })}
              />
              <ErrorMessage
                errors={formErrors}
                wantedError="cnpj"
                errorMessage={intl.formatMessage({
                  id: "personal.data.erro.identification.cnpj",
                })}
              />
            </div>

            {isCnpj && (
              <div className="form-item">
                <h5>
                  {intl.formatMessage({
                    id: "request.payment.boleto.business.name",
                  })}
                </h5>

                <Input
                  mode="named"
                  name="businessName"
                  value={customer.businessName}
                  placeholder={""}
                  handler={handleCustomerData}
                  pattern="[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
                />
                <ErrorMessage
                  errors={formErrors}
                  wantedError="businessName"
                  errorMessage={intl.formatMessage({
                    id: "personal.data.erro.business.name",
                  })}
                />
              </div>
            )}

            {!isCnpj && (
              <>
                <div className="form-item">
                  <h5>
                    {intl.formatMessage({ id: "request.payment.boleto.name" })}
                  </h5>

                  <Input
                    inputRef={refName}
                    mode="named"
                    name="firstName"
                    value={customer.firstName}
                    placeholder={""}
                    handler={handleCustomerData}
                    pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
                  />
                  <ErrorMessage
                    errors={formErrors}
                    wantedError="customerName"
                    errorMessage={intl.formatMessage({
                      id: "personal.data.erro.name",
                    })}
                  />
                </div>

                <div className="form-item">
                  <h5>
                    {intl.formatMessage({
                      id: "request.payment.boleto.lastname",
                    })}
                  </h5>

                  <Input
                    inputRef={refLastName}
                    mode="named"
                    name="lastName"
                    value={customer.lastName}
                    placeholder={""}
                    handler={handleCustomerData}
                    pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
                  />
                  <ErrorMessage
                    errors={formErrors}
                    wantedError="customerLastname"
                    errorMessage={intl.formatMessage({
                      id: "personal.data.erro.surname",
                    })}
                  />
                </div>
              </>
            )}

            <div className="form-item">
              <h5>
                {intl.formatMessage({ id: "request.payment.boleto.email" })}
              </h5>

              <Input
                inputRef={refEmail}
                mode="named"
                name="email"
                value={customer.email}
                placeholder={""}
                handler={handleCustomerData}
              />
              <ErrorMessage
                errors={formErrors}
                wantedError="customerEmail"
                errorMessage={intl.formatMessage({
                  id: "personal.data.erro.email",
                })}
              />
            </div>

            <div className="form-item">
              <h5>
                {intl.formatMessage({ id: "request.payment.boleto.phone" })}
              </h5>

              <Input
                inputRef={refPhone}
                mode="named-mask"
                name="phone"
                mask={
                  customer.phone?.length <= 14
                    ? "(99) 9999-99999"
                    : "(99) 99999-9999"
                }
                value={customer.phone}
                placeholder={""}
                handler={handleCustomerData}
              />
              <ErrorMessage
                errors={formErrors}
                wantedError="customerPhone"
                errorMessage={intl.formatMessage({
                  id: "payment.data.erro.phone",
                })}
              />
            </div>
          </>
        )}

        {showAddress && (
          <>
            {!isCnpj && (
              <div className="form-item">
                <h5>{dateLabel}</h5>

                <Input
                  inputRef={refBirth}
                  mode="named-mask"
                  name="birthdate"
                  mask="99/99/9999"
                  value={customer.birthdate}
                  placeholder={""}
                  handler={handleCustomerData}
                />
                <ErrorMessage
                  errors={formErrors}
                  wantedError="birthdate"
                  errorMessage={intl.formatMessage({
                    id: "payment.data.erro.birthdate",
                  })}
                />
              </div>
            )}

            {customerCurrency === "BRL" && (
              <div className="form-item">
                <h5>
                  {intl.formatMessage({
                    id: "request.payment.boleto.postal.code",
                  })}
                </h5>

                <Input
                  inputRef={refCep}
                  mode="named-mask"
                  name="zipcode"
                  mask="99999-999"
                  value={customer.address.zipcode}
                  placeholder={""}
                  handler={handleCustomerAddressData}
                />
                <ErrorMessage
                  errors={formErrors}
                  wantedError="customerZipcode"
                  errorMessage={intl.formatMessage({
                    id: "payment.data.erro.zipcode",
                  })}
                />
              </div>
            )}

            {customerCurrency !== "BRL" && (
              <div className="form-item">
                <h5>
                  {intl.formatMessage({
                    id: "request.payment.boleto.postal.code",
                  })}
                </h5>

                <Input
                  inputRef={refCep}
                  mode="named"
                  name="zipcode"
                  type="text"
                  value={customer.address.zipcode}
                  placeholder={""}
                  handler={handleCustomerAddressData}
                />
              </div>
            )}

            <div className="form-item">
              <h5>
                {intl.formatMessage({ id: "request.payment.boleto.address" })}
              </h5>

              <Input
                inputRef={refAddress}
                mode="named"
                name="street"
                value={customer.address.street}
                placeholder={""}
                handler={handleCustomerAddressData}
              />
              <ErrorMessage
                errors={formErrors}
                wantedError="street"
                errorMessage={intl.formatMessage({
                  id: "payment.data.erro.address",
                })}
              />
            </div>

            <div className="form-item">
              <h5>
                {intl.formatMessage({
                  id: "request.payment.boleto.address.number",
                })}
              </h5>

              <Input
                inputRef={refNumber}
                mode="named-mask"
                mask="99999999"
                name="number"
                value={customer.address.number}
                placeholder={""}
                handler={handleCustomerAddressData}
              />
              <ErrorMessage
                errors={formErrors}
                wantedError="number"
                errorMessage={intl.formatMessage({
                  id: "payment.data.erro.number",
                })}
              />
            </div>

            <div className="form-item">
              <h5>
                {intl.formatMessage({
                  id: "request.payment.boleto.address.complement",
                })}
              </h5>

              <Input
                required={false}
                inputRef={refComplement}
                maxLength={45}
                mode="named"
                name="reference"
                value={customer.address.reference}
                placeholder={""}
                handler={handleCustomerAddressData}
              />
            </div>

            <div className="form-item">
              <h5>
                {intl.formatMessage({
                  id: "request.payment.boleto.neighborhood",
                })}
              </h5>

              <Input
                inputRef={refNeighbourhood}
                mode="named"
                name="district"
                value={customer.address.district}
                placeholder={""}
                handler={handleCustomerAddressData}
              />
              <ErrorMessage
                errors={formErrors}
                wantedError="district"
                errorMessage={intl.formatMessage({
                  id: "payment.data.erro.district",
                })}
              />
            </div>

            <div className="form-item">
              <h5>
                {intl.formatMessage({ id: "request.payment.boleto.city" })}
              </h5>

              <Input
                inputRef={refCity}
                mode="named"
                name="city"
                value={customer.address.city}
                placeholder={""}
                handler={handleCustomerAddressData}
              />
              <ErrorMessage
                errors={formErrors}
                wantedError="city"
                errorMessage={intl.formatMessage({
                  id: "payment.data.erro.city",
                })}
              />
            </div>

            {customerCurrency === "BRL" && (
              <div className="form-item">
                <h5>
                  {intl.formatMessage({ id: "request.payment.boleto.state" })}
                </h5>

                <Select
                  data={brStates}
                  selectRef={selectState}
                  mode="named"
                  name="state"
                  value={customer.address?.state?.toString()}
                  placeholder={""}
                  handler={handleCustomerAddressData}
                />
                <ErrorMessage
                  errors={formErrors}
                  wantedError="state"
                  errorMessage={intl.formatMessage({
                    id: "payment.data.erro.state",
                  })}
                />
              </div>
            )}
          </>
        )}
      </styled.PaymentRequestItems>

      {errorMessage !== "" && <styled.Error>{errorMessage}</styled.Error>}
    </>
  );
};

export default CustomerPersonalData;
