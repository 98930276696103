import React, { useState } from "react";
import { useIntl } from "react-intl";

import * as styled from "../../../payment-request-styles";

import blueCheckIcon from "../../../../../../assets/images/global/blue-check.svg";

interface IProps {
  storeName: string;
  amount: number;
}

const ManualPaymentSucceded = ({ storeName, amount }: IProps) => {
  const [orderCurrency, setOrderCurrency] = useState(() => {
    const currencyObj = localStorage.getItem("@PrimefyDashboard-currency");
    const { currency } = JSON.parse(currencyObj ? currencyObj : "{}");

    return currency;
  });
  const intl = useIntl();

  return (
    <>
      <styled.ManualPaymentContainer>
        <img src={blueCheckIcon} alt="check icon" />

        <div className="text-container">
          <p className="medium">
            {intl.formatMessage({ id: "manual.payment.succeded.title" })}
          </p>
          {storeName ? (
            <p>
              {intl.formatMessage({ id: "manual.payment.succeded.text.1" })}{" "}
              {storeName}{" "}
              {intl.formatMessage({ id: "manual.payment.succeded.text.2" })}{" "}
              <strong>{storeName}</strong>.
            </p>
          ) : null}
        </div>

        <div className="text-container">
          <p className="medium">{storeName}</p>
          <p className="bold">
            {intl.formatNumber(amount, { style: "currency", currency: "BRL" })}
          </p>
        </div>
      </styled.ManualPaymentContainer>
    </>
  );
};

export default ManualPaymentSucceded;
