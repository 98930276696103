import React from 'react';
import { useIntl } from "react-intl";

import TipButtonModal from '../../../Modals/TipButtonModal';
import Input from '../../../../Forms/InputFloatLabel';

export default function SimpleCheckboxItem({ valueToCheckt, placeholder, tipText, insideDesc, toggle, children, ...rest }: any) {
  const intl = useIntl();

  return (
    <>
      <div className="form-item">
        <div className="flex">
          <Input 
            valueToCheck={valueToCheckt} 
            required={false} 
            mode="checkbox" 
            placeholder={intl.formatMessage({ id: placeholder })} 
            handler={() => toggle(!valueToCheckt)} 
            {...rest}
          />
          {tipText !== "" ? <TipButtonModal text={intl.formatMessage({ id: tipText })}/> : null}
        </div>
      </div>

      { valueToCheckt && <>{children}</>}
    </>
  )
}