import axios from "axios";

class AdminService {
  async getDemonstrative(days: number, year: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    let query = year > days ? `?Year=${year}` : `?NumberOfDays=${days}`;

    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/admin/demonstrative${query}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getSalesComission() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/admin/sale_commissions`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getOrders(pageSize: number, pageIndex = 1) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/admin/orders?pageSize=${pageSize}&pageIndex=${pageIndex}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getAllOrders() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/orders`, {
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
    });
  }

  async getOrderDetails(orderNumber: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/admin/store/${session.storeId}/orders/${orderNumber}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getStores() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/stores`, {
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
    });
  }

  async listAllStores(filter: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/admin/list-stores${filter}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async listAllStoresLogin(token: string) {
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/admin/list-stores`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}

export default new AdminService();
