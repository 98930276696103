import axios from 'axios';

class FinancialsServices {

  async getReceivables(pageIndex: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session.entityType === 'Business') {
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/receivables?pageSize=100&pageIndex=${pageIndex}&sort=time-descending&status=paid`, {
        headers: {
          'Authorization': `Bearer ${session.token}`
        }
      });
    }
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/receivables?pageSize=100&pageIndex=${pageIndex}&sort=time-descending&status=paid`, {
      headers: {
        'Authorization': `Bearer ${session.token}`
      }
    });
  }

  async getPendingReceivables(pageIndex: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session.entityType === 'Business') {
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/receivables?pageSize=100&pageIndex=${pageIndex}&sort=time-descending&status=pending`, {
        headers: {
          'Authorization': `Bearer ${session.token}`
        }
      });
    }
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/receivables?pageSize=100&pageIndex=${pageIndex}&sort=time-descending&status=pending`, {
      headers: {
        'Authorization': `Bearer ${session.token}`
      }
    });
  }

}

export default new FinancialsServices()
