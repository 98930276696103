import LangResource from "./LangResource";

const en = new LangResource();

en.langKeys = ["en"];
en.messages = {
  "view.label": "View",

  "items.not.found": "No items found.",
  "items.not.found.2":
    "No items found. Please enter the CPF or CNPJ of a registered partner.",
  "select.yes": "Yes",
  "select.no": "No",

  "cancel.boleto.cta": "Cancel Boleto",

  "status.active": "Active",
  "status.succeeded": "Active",
  "status.pending": "Pending",
  "status.blocked": "Blocked",
  "status.deactivated": "Deactivated",
  "status.expired": "Expired",
  "status.pre.authorized": "Pre authorized",

  "onboarding.step.1.title": "Open your account in 10 minutes",
  "onboarding.step.1.text":
    "The easiest and most efficient way to accept payments, either online or with a card",
  "onboarding.step.2.title": "Digital payments",
  "onboarding.step.2.text":
    "Scale your business by accepting PIX, Boleto and other digital payment methods",
  "onboarding.step.3.title": "Face-to-face payments",
  "onboarding.step.3.text":
    "With our machines, you can receive by main methods of face-to-face payments",

  "home.recent-orders": "Recent sales",
  "home.status": "Status",
  "home.order": "Order",
  "home.name": "Customer",
  "home.method": "Payment method",
  "home.brl.amount": "Amount",
  "home.new.currency.amount": "Amount",
  "home.gbp.amount": "Amount",
  "home.type": "Type",
  "home.date": "Date",
  "home.live.map": "Live map",
  "home.faq": "FAQ",
  "home.help": "Help",
  "home.help.text":
    "We’re always available to help you, assisting in grow your business with Primefy.",
  "home.complains.help":
    "Need help in any dispute? Get support with our experts by chat.",
  "home.chat.wait.time": "Average wait 4 minutes",
  "home.email.wait.time": "Average wait 2 hours",
  "home.phone.wait.time": "Average wait 10 minutes",
  "home.overview": "Overview",
  "home.report": "Report",
  "home.about": "about",
  "home.search.order":
    "Search by Sales Number or by (Identification number)...",

  "home.faq.question.1": "Why do I have Blocked Balances?",
  "home.faq.answer.1":
    "Blocked balances refer to amounts that are in dispute or chargeback. If you have a blocked balance, it means that the amount corresponds to transactions in which the user has requested a dispute over the amount.",
  "home.faq.question.2": "What is Balance Receivable?",
  "home.faq.answer.2":
    "It is the gross revenue from sales that have not yet been released for withdrawal. This amount already has the Premium fee deducted from its total.",
  "home.faq.question.3": "Do recurring payments happen automatically?",
  "home.faq.answer.3":
    "Yea! Our technology allows you to set recurring payments and the customer will be automatically charged.",
  "home.faq.question.4": "Can I set up Recurring Payments as desired?",
  "home.faq.answer.4":
    "Yes! You can choose to bill your customers daily, weekly, monthly and/or annually.",
  "home.faq.question.5": "How can I receive the Available Balance?",
  "home.faq.answer.5":
    "The amount will be transferred to the bank account previously registered, and you can choose the amount you want to transfer with the amount of Available Balance as a limit.",
  "home.faq.question.6":
    "Did the Customer say that he has already paid, because it is not yet confirmed?",
  "home.faq.answer.6":
    "For payment via boleto, the payment confirmation period can be up to 3 working days. For payments using a card in its various forms, confirmation must be instant. If you have any questions please contact our Help Center.",

  "home.withdrawall.info":
    "For requests for withdrawals made in the afternoon, on weekends or on holidays, the transfer will be made on the next business day.",
  "home.withdrawall.confirm": "Confirm withdrawal request",
  "home.withdrawall.confirm.foreigner": "Confirm withdrawal request of",
  "home.withdrawall.change.bank.details": "Add bank account",
  "home.withdrawall.history": "View withdrawal history",
  "home.withdrawall.search.bank": "Search the bank",
  "home.withdrawall.account": "Account",
  "home.withdrawall.branch": "Branch",
  "home.withdrawall.type.account": "Account type",
  "home.withdrawall.holder.name": "Card holdername",
  "home.withdrawall.bank.holder.name": "Holdername",
  "home.withdrawall.confirm.new.bank.details": "Confirm new bank details",
  "home.withdrawall.cancel": "Cancel",
  "home.withdrawall.withdrawall.history": "Withdrawal history",
  "home.withdrawall.withdrawall.history.date": "Date",
  "home.withdrawall.withdrawall.history.amount": "Amount",
  "home.withdrawall.withdrawall.history.user": "User",
  "home.withdrawall.withdrawall.history.status": "Status",
  "home.withdrawall.status.message.default": " ",
  "home.withdrawall.status.message.success":
    "Operation performed successfully. The deposit will be made within 24 hours",
  "home.withdrawall.status.message.failed":
    "An error occurred when requesting the withdrawal",
  "home.withdrawall.select.bank.account.placeholder": "Select a bank account",
  "home.withdrawall.select.bank.account.placeholder.register": "Select a bank",
  "home.withdrawall.error.available.amount.zero":
    "Establishment without sufficient balance. To make withdrawals, the establishment must have an available balance.",
  "home.withdrawall.error.amount.equal.zero":
    "Please enter a value greater than zero to withdrawal.",

  "withdrawall.merchant.number": "Merchant’s Business Number",
  "withdrawall.confirm.transfer": "Confirm transfer",

  "financial.title": "Finance",
  "financial.gross.volume": "Gross volume",
  "financial.net.volume": "Net volume",
  "financial.average.ticket.price": "Average ticket price",
  "financial.orders.quantity": "Successful sales",
  "financial.dispute.count": "Dispute count",
  "financial.amount.available.settlement": "Available",
  "financial.amount.to.receive": "Incoming",
  "financial.amount.dispute": "Amount in dispute",
  "financial.withdrawal.country": "Country",
  "financial.withdrawal.national": "Brazilian wallet",
  "financial.withdrawal.international": "Worldwide wallet",

  "financial.page.withdrawal.title": "Transfers & Withdrawals",
  "financial.page.withdrawal.subtitle":
    "You can withdrawal to your desired bank account or transfer to another merchant who has an account at Primefy with your available balance.",
  "financial.page.transactions.title": "Sales",
  "financial.page.transactions.subtitle":
    "Here you can see all the detailed withdrawals and upcoming payments.",
  "financial.page.transactions.upcoming.subtitle":
    "Here you can see all the detailed upcoming payments.",
  "financial.page.transactions.view.all": "View all",
  "financial.page.transactions.upcoming.amount": "Upcoming payin: ",

  "button.withdrawal": "Confirm withdrawal",
  "button.refund": "Refund customer",
  "button.sidebar.home": "Home",
  "button.sidebar.transactions": "Sales",
  "button.sidebar.sale-commissions": "Commissions",
  "button.sidebar.customer": "Customers",
  "button.sidebar.signout": "Logout",
  "button.sidebar.developers": "Integrations",
  "button.sidebar.reports": "Reports",
  "button.sidebar.financials": "Financials",
  "button.sidebar.settings": "Settings",
  "button.sidebar.plans": "Plans",
  "button.sidebar.switch.environment.prop": "Switch to test mode",
  "button.sidebar.switch.environment.test": "Switch to live mode",
  "button.sidebar.switch.mode": "Mode",
  "withdrawal.amount.info": "We will transfer to your bank account",
  "withdrawal.amount.inf.why": "Why?",
  "withdrawal.amount.inf.why.answer":
    "Due to the Brazilian regulation about AML (Anti-Money Laundering), we have to transfer the full amount of a specific order and identify the buyer.",

  "refund.amoun.label": "Amount in ",

  "withdrawal.transfer.type": "Transfer type",
  "withdrawal.your.accout": "Withdrawal to your bank",
  "withdrawal.merchant.accout": "Transfer to a merchant",

  "financial.withdrawal.title": "Settlement",
  "financial.withdrawal.available": "Available to Withdrawal",
  "financial.withdrawal.branch.code": "Branch code",
  "financial.withdrawal.account.number": "Account number",
  "financial.withdrawal.amount": "Amount to withdrawal",
  "financial.withdrawal.description": "Description",
  "financial.withdrawal.bank": "Bank",
  "financial.type.account.checking": "Checking",
  "financial.type.account.savings": "Savings",
  "financial.type.account.select.account": "Bank account",

  "admin.financial.total.payment": "Total payment volume",
  "admin.financial.average.order": "Average order",
  "admin.financial.gross.fees": "Gross fees",
  "admin.financial.total.clients": "Total clients",
  "admin.financial.orders.quantity": "Orders quantity",
  "admin.financial.complains": "Complains",
  "admin.financial.gross.fees.per.client": "Gross fees per client",
  "admin.financial.new.clients": "New clients",
  "admin.financial.availabble": "Available",
  "admin.financial.on.hold": "On hold",
  "admin.financial.refunded": "Refunded",
  "admin.order.list.store.name": "Store",
  "admin.order.list.fee": "Fee",

  "home.performance": "Performance",
  "home.performance.hint":
    "This is how much you have grown if we compare the same period before and after your company joins Preme",
  "payment.": "",
  "payment.new": "New",
  "payment.confirmed": "Approved",
  "payment.succeeded": "Approved",
  "payment.partially.confirmed": "Partially Confirmed",
  "payment.pending": "Pending",
  "payment.awaiting": "Awaiting",
  "payment.refused": "Refused",
  "payment.rejected": "Refused",
  "payment.PendingChallenge": "Pending",
  "payment.canceled": "Canceled",
  "payment.partially.canceled": "Partially Canceled",
  "payment.blocked": "Blocked",
  "payment.failed": "Refused",
  "payment.disputed": "In Dispute",
  "payment.charged.back": "Dispute closed",
  "payment.expiration": "Expired",
  "payment.expired": "Expired",
  "payment.pre.authorized": "Pre authorized",
  "payment.credit.card": "Credit Card",
  "payment.debit.card": "Debit Card",
  "payment.boleto.bancario": "Boleto Bancário (bank slip)",

  "request.payment": "Request payment",

  "request.payment.manual.payment.title": "Charge instantly",
  "request.payment.payment.link.title": "Create a payment link",
  "request.payment.payment.type": "Payment type",
  "request.payment.plan": "Plan",
  "request.payment.plan.single": "One time",
  "request.payment.plan.recurring": "Recurring",
  "request.payment.preview.title": "Preview",
  "request.payment.preview.tip.text":
    "You can update the store name and the logo in",
  "request.payment.preview.tip.text.link": "Settings",


  "create.product.title": "Create product",
  "cutomize.product.title": "Customize product",
  "create.a.product": "Create product",
  "view.all.product": "Products",
  "create.product.media.label": "Media",
  "create.product.price.label": "Price per unit",
  "deactivate.product.label": "Deactivate product",
  "delete.product.label": "Delete product",

  "create.product.shippable.product": "Shippable product",
  "create.product.weight": "Weight",
  "create.product.weight.placeholder": "Weight in kilograms",
  "create.product.width": "Width",
  "create.product.width.placeholder": "Width in centimeters",
  "create.product.height": "Height",
  "create.product.height.placeholder": "Height in centimeters",
  "create.product.length": "Length",
  "create.product.length.placeholder": "Length in centimeters",
  "create.product.centimeters": "centimeters",
  "create.product.kilograms": "kilograms",

  "create.product.name.unit.label": "Name unit label",
  "create.product.name.unit.label.custom": "Custom",
  "create.product.name.unit.label.custom.i.e": "i.e. Shoes, Books, Shirts",

  "create.product.sales.label": "Sales page",
  "create.product.page.label": "Product page",
  "create.product.sales.description": "Your sales page",
  "create.product.sales.placeholder": "https://example.com/product",

  "request.payment.header.return": "Return",

  "request.payment.credit.card.data": "Credit card details",

  "header.search.country": "Search...",
  "header.change.country": "Change country",
  "header.from.it": "From",

  "header.or.until": "or even",
  "header.installments.of": "installments of",
  "header.with.fees": "with fees",
  "header.without.fees": "without fees",
  "header.in": "In",
  "header.credit.card.once": "Credit card in cash.",
  "header.payment.data": "Payment data",

  "request.payment.optional.label": "Optional",

  "request.payment.customer.name": "Customer name",
  "request.payment.customer.email": "Customer email",
  "request.payment.currency": "Currency:",
  "request.payment.currency.value": "BRL (R$)",
  "request.payment.amount": "Price",
  "request.payment.boleto.days.before.generate.boleto":
    "Send Boleto to the customer how many days before expiration?",
  "request.payment.max.subcriptions.charge.label": "Max number of charge",
  "request.payment.recurring.tax": "Tax",
  "request.payment.title": "Title",
  "request.payment.card": "Card",
  "request.payment.order.number": "Order number",
  "request.payment.due.date": "Due payment date",
  "request.payment.processing": "Processing payment",
  "request.payment.confirmed": "Payment confirmed",
  "request.payment.description": "Description",
  "request.payment.duedate":
    "Due date for the first Boleto Bancário (bank slip)",
  "request.payment.expiration.link": "Payment link expiration date",
  "request.payment.expiration.link.placeholder": "dd/mm/yyyy, hh:mm",
  "request.payment.limit.boleto.error.message.1":
    "The maximum amount of Boleto Bancário can be",
  "request.payment.limit.boleto.error.message.2":
    ", talk to our team to verify the change to this limit.",
  "request.payment.min.amount.boleto.error.message":
    "The minimum amount for a payment slip is R $ 3.00",
  "request.payment.not.found.order.number": "Order number not found.",
  "request.payment.search.order.number": "If available, enter the sales number",
  "request.payment.invalid.boleto.duedate.error.message":
    "Expiration date entered is invalid.",
  "request.payment.personal.data.optional":
    "Inform personal data and full address (optional)",
  "request.payment.customer.currency": "Price",
  "request.payment.final.fx.amount": "Final amount to pay:",

  "request.payment.plan.daily": "Daily",
  "request.payment.plan.weekly": "Weekly",
  "request.payment.plan.monthly": "Monthly",
  "request.payment.plan.quarterly": "Quarterly",
  "request.payment.plan.halfYearly": "HalfYearly",
  "request.payment.plan.yearly": "Yearly",

  "request.payment.plan.daily.simple": "Day",
  "request.payment.plan.weekly.simple": "Week",
  "request.payment.plan.monthly.simple": "Month",
  "request.payment.plan.quarterly.simple": "Quarter",
  "request.payment.plan.halfYearly.simple": "Semester",
  "request.payment.plan.yearly.simple": "Year",

  "request.payment.payment.duedate": "Define Due date",
  "request.payment.payment.duedate.tip":
    "Define a due date to inform your customer about when you're expecting the payment.",

  "credit.card.number": "Card number",
  "credit.card.expiration": "MM/YY",
  "credit.card.cvv": "CVV",

  "request.payment.button.label": "Request payment",
  "request.payment.tip.title": "Tip",
  "request.payment.tip.text":
    "You can create a payment link or directly charge a customer if you already have their payment details.",
  "request.payment.modal.button.payment.link": "Create payment link",
  "request.payment.modal.button.manually": "Instantly charge a customer",
  "request.payment.modal.button.list.links": "View all payment links",
  "request.payment.charge.form.label": "Billing method",
  "request.payment.generate.payment.link.label": "Create payment link",
  "request.payment.generate.boleto.label": "Generate boleto of",
  "request.payment.generate.first.boleto.only.label": "Generate first boleto",
  "request.payment.generate.first.boleto.label": "Generate first boleto of",
  "request.payment.generate.all.boleto.label": "Generate boletos",
  "request.payment.credit.card.btn": "Confirm payment",
  "request.payment.credit.card.of.btn": "Confirm payment of",
  "request.payment.credit.card.in.btn": "Confirm payment in",
  "request.payment.charge.manually.label": "Charge manually",
  "request.payment.payment.method.label": "Payment method",
  "request.payment.payment.method.cc.label": "Credit card",
  "request.payment.payment.method.boleto.label": "Boleto Bancário",
  "request.payment.payment.method.recurring.label": "Recurrence",
  "request.payment.recurring": "Frequency",
  "request.payment.payment.types": "Payment method",
  "request.payment.payment.types.0": "Credit card",
  "request.payment.payment.types.1": "Boleto",
  "request.payment.payment.types.01": "Credit card or Boleto",
  "request.payment.recurring.description": "Description",
  "request.payment.charge.later": "Charge later:",
  "request.payment.link": "Payment link",
  "request.payment.recurring.cpf": "CPF (Identification number)",
  "request.payment.card.details": "Card details",
  "request.payment.card.name": "Card holdername",
  "request.payment.card.error": "What there was:",
  "request.payment.boleto.name": "Name",
  "request.payment.boleto.lastname": "Surname",
  "request.payment.boleto.email": "Email",
  "request.payment.boleto.birth": "Date of birth",
  "request.payment.boleto.phone": "Mobile phone number",
  "request.payment.boleto.postal.code": "Postal code",
  "request.payment.boleto.address": "Address",
  "request.payment.boleto.address.number": "House number",
  "request.payment.boleto.address.complement": "Complement",
  "request.payment.boleto.neighborhood": "Disctrict",
  "request.payment.boleto.city": "City",
  "request.payment.boleto.state": "State",
  "request.payment.boleto.bar.code": "Barcode",
  "request.payment.boleto.expiration": "Due date",
  "request.payment.boleto.cpf.cnpj": "CPF/CNPJ (Identification number)",
  "request.payment.boleto.business.name": "Company name",
  "request.payment.boleto.discount": "Discount",
  "request.payment.boleto.discount.none": "None",
  "request.payment.boleto.discount.percentual": "Percentage (%)",
  "request.payment.boleto.discount.percentual.daily": "Daily percentage (%)",
  "request.payment.boleto.discount.percentual.monthly":
    "Monthly Percentage (%)",
  "request.payment.boleto.discount.amount": "Amount (R$)",
  "request.payment.boleto.tex.fixed.amount": "Price",
  "request.payment.boleto.discount.limit": "Discount deadline",
  "request.payment.boleto.fine": "Fine",
  "request.payment.boleto.fees.rp": "Fees",
  "request.payment.boleto.installment": "Boleto with installments",
  "request.payment.boleto.installment.2": "Installments in the billet:",
  "request.payment.generate.all.boleto": "Generate all boletos instantly",
  "request.payment.boleto.installments.label":
    "Select up to how many installments to charge",
  "request.payment.with.fees": "(with fees)",
  "request.payment.without.fees": "(without fees)",
  "request.payment.of": "of",
  "request.payment.credit.card.installments": "Installments",
  "request.payment.brasilia.time": "Brasilia time",
  "request.payment.fee.transfer": "Fee transfer",
  "request.payment.fee.transfer.explanation":
    "The fees will be passed on to your consumer. Thus, you will receive the full amount.",
  "request.payment.pre.fill": "Pre-inform customer's data",
  "request.payment.pre.fill.explanation":
    "Enter buyer data to increase your sale's conversion rate",
  "request.payment.address.data": "Collect customer address",
  "request.payment.address.data.explanation":
    "For Credit Card payments, it will also be necessary to inform the complete data of the buyer",
  "request.payment.inform.tax": "Inform tax rates",
  "request.payment.inform.tax.explanation":
    "Enter the amount that will be withheld for payment of taxes and duties for a better view of the value",
  "request.payment.inform.cycle": "Define maximum number of charges",
  "request.payment.inform.cycle.explanation":
    "Enter a maximum amount of charges to be made per user on this plan",
  "request.payment.tax": "Tax mode",
  "request.payment.select.label": "Select",
  "request.payment.generate.payment.link.btn": "Generate payment link",
  "request.payment.select.plan": "Select a plan",

  "payment.link.details.success.created.title":
    "Payment link successfully created",
  "payment.link.details.success.created.note":
    "We don’t share the payment link with your client, so you can share on your preferred way using the payment link or QR Code.",

  "payment.link.fixed.installment": "Fixed installment",
  "payment.link.pay.later": "Pre-authorized card payments",
  "payment.link.pay.later.tip":
    "Instead of charging the customer instantly, you will successfully hold that amount on its customers card up to 28 days for a later charge.",
  "order.details.pay.later": "The amount is pre-authorized",
  "payment.link.pay.later.btn": "Charge now",

  "product.details.success.created.title": "Product successfully created",
  "product.details.success.created.subtitle":
    "Create a Checkout link to receive payments about this product.",
  "product.details.create.payment.link": "Create payment link",
  "product.details.shippable": "Shippable product",
  "product.details.package": "Package details",
  "product.details.unit.label": "Unit label",
  "product.details.payment.link": "Payment link",

  "request.payment.enota": "Auto generate invoice with eNotas",
  "request.payment.enota.when.generate": "When generate the invoice",
  "request.payment.enota.select": "Select a period",
  "request.payment.enota.select.on.sell": "On sell",
  "request.payment.enota.select.after.warranty": "After warranty days",
  "request.payment.enota.select.not.generate": "Do not issue",
  "request.payment.enota.select.warranty.days": "Number of days of warranty",
  "request.payment.enota.select.send": "Send invoice to customer",
  "request.payment.enota.select.send.select": "Select",
  "request.payment.enota.description": "NFe description",

  "settings.memberkit.description":
    "The partnership you were looking to sell educational products with Primefy. Upload your courses with Memberkit by Primefy.",
  "settings.memberkit.modal.title": "Memberkit",
  "settings.memberkit.modal.key.label": "Enter your API Key to connect",
  "settings.memberkit.modal.where.key.label.1": "Click here",
  "settings.memberkit.modal.where.key.label.2":
    " to find out where to find your Memberkit API Key",
  "settings.memberkit.modal.api.key.label": "API key",
  "settings.memberkit.modal.api.secret.key.label": "Enter your secret key here",
  "settings.memberkit.modal.cancel.label": "Cancel",
  "settings.memberkit.modal.add.label": "Add account",
  "settings.memberkit.modal.connecting.label": "Connecting to Memberkit...",
  "settings.memberkit.modal.connecting.label.2":
    "Registering Memberkit Courses as Products on Primefy...",
  "settings.memberkit.modal.success.connection.label":
    "Connection made successfully!",
  "settings.memberkit.modal.success.connection.desc":
    "Finally, we need you to set the price of each course.",
  "settings.memberkit.modal.do.you.sell.title": "Do you sell by subscription?",
  "settings.memberkit.modal.do.you.sell.desc":
    "You will be able to create your subscription plans later, giving access to one or more courses to your students, defining a custom value for your subscriptions.",
  "settings.memberkit.modal.finish.integration": "Finish integration",
  "settings.memberkit.modal.course.integration": "Course",
  "settings.memberkit.modal.price.integration": "Price",

  "request.payment.redirect.payment": "Redirect confirmation page",
  "request.payment.redirect.payment.instead":
    "Redirect the user to your website instead.",
  "request.payment.redirect.payment.page": "Confirmation page",
  "request.payment.redirect.payment.page.placeholder":
    "https://example.com/confirmation",

  "request.payment.create.plan": "Create a new plan",
  "request.payment.create.plan.trial": "Enter a number in days",
  "request.payment.create.plan.trial.placeholder":
    "How many days before the first payment",
  "request.payment.create.plan.customize.title": "Customize",
  "request.payment.create.plan.label.button": "Create plan",
  "request.payment.create.plan.limit.payment.methods": "Limit payment methods",

  "request.payment.charge.laterl.btn.label": "Charge later",
  "request.payment.charge.laterl.if.selected":
    "To charge, access the details of the sale. If there is no action in 7 days, the system will charge you automatically.",
  "request.payment.charge.laterl.btn.explaination":
    "Will be charged in 7 calendar days",
  "request.payment.success.link": "Successfully generated link",
  "request.payment.error.link": "Error generating payment link",
  "request.payment.success.charged": "Successfully charged",
  "request.payment.error.charged": "Error processing charge.",
  "request.payment.errors": "Errors:",
  "request.payment.success.boleto.generated": "Boleto successfully generated",
  "request.payment.error.boleto.generated": "Error generating boleto.",
  "request.payment.copy.barcode": "Copy barcode",
  "request.payment.view.boleto": "View Boleto Bancário",
  "request.payment.view.all.boleto": "View All Boleto Bancário",
  "request.payment.customize.boleto.btn":
    "Customize Boleto Bancário (bank slip)",
  "request.payment.customize.boleto.explanation":
    "Determine fine, interest and discounts for early payment, as well as customize the generation of boletos and the due date",
  "request.payment.customize.recurring.boleto.explanation":
    "Determine the generation of boletos and the due date",
  "request.payment.request.type": "Type",
  "request.payment.request.type.single": "One time",
  "request.payment.request.type.recurring": "Recurring",

  "request.payment.pix.copy.qrcode": "Copy code",
  "request.payment.pix.download.qrcode": "Download QR Code",
  "request.payment.pix.generate.pix": "Generate QR Code",

  "view.as.btn.label": "View as a store",
  "viewing.as.btn.label": "Viewing as ",
  "return.to.view.as.business": "Return to view as a business",
  "view.as.btn.establishments": "Stores",
  "view.as.processing": "Processing",
  "view.as.search.establishment": "Search",
  "view.as.tip.text":
    "You can create stores to organize better your sales, it can be with the same or different business numbers.",
  "view.as.create.store": "Create a new store",

  "plan.select.tip.text":
    "You can create a new plan and define billing period, payment methods and even trial period.",
  "bank.account.select.tip.text":
    "You can add as many bank accounts you want to best manage your accounting.",

  "request.payment.recurring.plan": "Plan",
  "request.payment.recurring.name.plan": "Plan name",
  "request.payment.recurring.name.plan.placeholder":
    "Sneakers, sunglasses, streaming, etc.",
  "request.payment.recurring.trial": "Trial Period",
  "request.payment.create.plan.success.message": "Plan successfully created.",
  "request.payment.create.plan.error.message":
    "There was an error creating a plan, please try again.",

  "limit.installments": "Limit installments by payment methods",

  "transactions.title": "Sales",
  "transactions.order.details": "Order details",
  "transactions.session": "Session",
  "transactions.customer.details": "Customer details",
  "transactions.payment.details": "Payment details",
  "transactions.order.events": "Events",
  "transactions.cost": "Transaction fees",
  "transactions.refund.control": "Refund control",
  "transactions.refund.control.reference": "Reason",
  "transactions.refund.control.reference.placeholder": "For internal use only",
  "transactions.refund.control.percentage": "Percentage:",
  "transactions.refund.error.message": "Couldn't reverse chargeback",
  "transactions.refund.success.message": "Chargeback successfully sent",
  "transactions.refund.select.card": "Choose card to charge back",
  "transactions.refund.select.card.placeholder": "Card",
  "transactions.type.recurring": "Recurring",
  "transactions.type.single": "One time",

  "transactions.search": "Search by",
  "transactions.search.number": "Order number",
  "transactions.search.identificationNumber": "Identification number",
  "transactions.search.name": "Name or Surname",
  "transactions.search.email": "Email address",
  "transactions.search.id": "Customer Id",

  "sale.commissions.title": "Commissions",
  "sale.commissions.cost": "Commission",
  "sale.commissions.seller.name": "Seller",
  "sale.commissions.brl.commission.amount": "Amount",

  "login.welcome": "Welcome",
  "login.button.login": "Continue",
  "login.button.login.continue": "Continue",
  "login.invalid.username.or.password": "Incorrect Username or Password",
  "login.password": "Password",
  "error.unknown.error": "Sorry, an unexpected error happened",
  "error.failed": "Check the entered data and try again, or contact your bank.",
  "login.access.dashboard": "Sign in to your account",
  "login.recover.pass.dashboard": "Reset your password",
  "login.get.link.dashboard": "Continue",
  "login.change.pass.dashboard": "Change Password",
  "login.button.recover.pass": "Forgot your password?",
  "login.text.recover.pass":
    "We will send you an email to your associated account.",
  "login.textcreate.account.text": "Don't have an account?",
  "login.text.existent.account.text": "Already have an account?",
  "login.text.existent.access.account": "Access now",
  "login.textcreate.account.link": "Sign up in 10 minutes",

  "redefine.password.success.link.sent":
    "Link sent successfully to the email provided.",
  "redefine.password.user.not.found": "User (email) not found.",
  "redefine.password.success.redirect":
    "Password changed successfully. Redirecting to login ..",
  "redefine.password.failed":
    "Sorry, we were unable to change your password. Try again.",
  "redefine.password.new.pass.label": "New Password",

  "user.profile": "Edit profile",
  "user.signout": "Sign out",
  "user.profile.title": "Personal data",
  "user.profile.email": "Email",
  "user.profile.name": "Name",
  "user.profile.password": "Password",
  "user.profile.new.password": "New password",
  "user.profile.old.password": "Current password",

  "settings.business.information.title": "Business information",
  "settings.business.information.text":
    "You've provided all the business information required to use Primefy right now. We might contact you to collect additional details.",
  "settings.business.information.legal.business.name": "Legal business name",
  "settings.business.information.trading.name": "Trading name",
  "settings.business.information.statement.descriptor": "Statement descriptor",
  "settings.business.information.category": "Category",
  "settings.business.information.website": "Business website",
  "settings.business.information.phone": "Support phone number",
  "settings.business.information.address": "Business address",
  "settings.business.information.logo": "Logo",

  "settings.business.api.key.enotas": "API key",
  "settings.business.business.number": "Sender",
  "settings.balance.recipient": "Recipient",
  "settings.select.store.placeholder": "Select a store",

  "settings.users.text":
    "Manage users access, defining level of access and which stores the users are able to see.",
  "settings.stores.text":
    "You can create many stores with the same Business Number to have a better management of your business, or even with different Business Numbers in case you’re a marketplace or a delivery company, for example.",
  "settings.fees.text":
    "Your current plan is the Standard. If you want to apply for a different pricing plan, ",
  "settings.fees.text.link": "contact our team",

  "settings.team.list.header.user": "User",
  "settings.team.list.header.email": "Email address",
  "settings.team.list.edit": "Edit",

  "settings.store.list.status": "Status",
  "settings.store.list.name": "Name of the establishment",
  "settings.store.list.id": "Identification number",
  "settings.store.list.created": "Creation date",

  "settings.store.fee.boleto": "Boleto Bancário (bank slip)",
  "settings.store.fee.credit": "Credit Card",
  "settings.store.fee.pix": "PIX",
  "settings.store.fee.installments": "Installment",
  "settings.store.fee.network": "Network",
  "settings.store.fee.fee": "Fee",

  "settings.store.create.user.label": "Create a new user",

  "settings.integrations.title": "Integrations",
  "settings.integrations.subtitle":
    "Integrate Primefy with apps and automate your sales without friction.",
  "settings.integrations.e.notas":
    "eNotas issue your invoices in Brazil automatically so you can avoid high tax fines and be able to focus on increase your sales, produce better content, serve your customers better, take care of what matters most: thrive and live longer!",
  "settings.integrations.connect": "Connect",
  "settings.integrations.connected": "Connected",
  "settings.integrations.events": "events",

  "settings.new.user.error":
    "Invalid name. The name must be 1 to 45 characters long.",
  "settings.new.user.email.error":
    "Invalid email. An email must have a maximum of 80 characters and follow the pattern with '.' and '@'.",
  "settings.new.user.store.error": "Please select a store.",

  "notification.item.snooze": "Snooze",
  "notification.item.time": "hours ago",
  "notification.item.view.all": "View all",

  "user.profile.page.title": "Profile",
  "user.profile.page.subtitle":
    "You've provided all the business information required to use Primefy right now. We might contact you to collect additional details.",
  "user.profile.page.notification.title": "Notifications",
  "user.profile.page.notification.subtitle":
    "Manage if you would like to receive notifications about your running business.",
  "user.profile.team.title": "Team",
  "user.profile.team.name": "Name",
  "user.profile.team.email": "Email",
  "user.profile.team.password": "Password",
  "user.profile.team.access": "Access",
  "user.profile.team.access.owner": "Owner",
  "user.profile.team.access.administrator": "Administrator",
  "user.profile.team.access.agent": "Agent",
  "user.profile.team.access.view.only": "View Only",
  "user.profile.team.access.developer": "Developer",
  "user.profile.team.access.business": "Business",
  "user.profile.team.access.store": "Store",
  "user.profile.team.entity.type": "Type",
  "user.profile.team.store": "Store",
  "user.profile.team.select.default": "Select",
  "user.profile.team.add.user.button.label": "Add user",
  "user.profile.team.cancel.button.label": "Cancel",
  "user.profile.team.delete.user.button.label": "Delete user",
  "user.profile.team.save.changes.button.label": "Save changes",
  "user.profile.team.create.account.button.label": "Create account",
  "user.profile.team.create.user.error.password":
    "Password must be more than 8 characters.",
  "user.profile.team.create.user.error.administrator":
    "An admin user must have an @premepay email.",
  "user.profile.team.create.user.error.already.exists":
    "User already registered.",
  "user.profile.team.create.user.error.update.try.again":
    "Error trying to update user information. Try again.",
  "user.profile.team.create.user.error.create.try.again":
    "Error trying to create new user. Try again.",

  "user.profile.notifications.success.payments": "Successful payments",
  "user.profile.notifications.payouts": "Payouts",
  "user.profile.notifications.disputes": "Disputes",
  "user.profile.notifications.refunds": "Refunds",
  "user.profile.notifications.failed.payments": "Failed payments",

  "user.profile.ui.create.store.button.label": "Add new store",

  "user.profile.ui.save": "Save",
  "user.profile.ui.save.changes": "Save changes",

  "user.profile.right.title": "Stores",
  "user.profile.right.company.name": "Company name",
  "user.profile.right.trading.name": "Trading name",
  "user.profile.right.cnpj": "CNPJ (Identification number)",
  "user.profile.right.phone": "Phone",
  "user.profile.right.zipcode": "Zipcode",
  "user.profile.right.address": "Address",
  "user.profile.right.reference": "Reference",
  "user.profile.right.district": "District",
  "user.profile.right.city": "City",
  "user.profile.right.state": "State",
  "user.profile.right.logo": "Logo",
  "user.profile.right.logo.suggest":
    "We suggest an SVG logo, with a transparent background to be applied to pages with a white background.",
  "user.profile.right.logo.placeholder":
    "Drag and Drop your files or <span class='filepond--label-action'>Browse</span>",
  "user.profile.right.fees.title": "Fees",
  "user.profile.right.fees.boleto": "Boleto Bancário (bank slip)",
  "user.profile.right.fees.boleto.after": "by compensated Boleto",
  "user.profile.right.fees.pix": "PIX",
  "user.profile.right.fees.pix.after": "per confirmed sale",
  "user.profile.right.fees.credit.1x": "Cash credit (Visa and Mastercard)",
  "user.profile.right.fees.credit.1x.after": "per confirmed sale",
  "user.profile.right.fees.credit.1x.others": "Cash credit (Elo and others)",
  "user.profile.right.fees.credit.2.6.x":
    "Credit 2x to 6x (Visa and Mastercard)",
  "user.profile.right.fees.credit.2.6.x.others":
    "Credit 2x to 6x (Elo and outros)",
  "user.profile.right.fees.credit.2.6.x.after": "per confirmed sale",
  "user.profile.right.fees.credit.7.12.x":
    "Credit 7x to 12x (Visa and Mastercard)",
  "user.profile.right.fees.credit.7.12.x.others":
    "Crédito 7x à 12x (Elo and outros)",
  "user.profile.right.fees.credit.7.12.x.after": "per confirmed sale",
  "user.profile.right.fees.anticipation": "Anticipation",
  "user.profile.right.fees.anticipation.after": "per advance installment",

  "detail.order.number": "Order number",
  "detail.order.status": "Status",
  "detail.order.risk.fraud.alert": "Order blocked due to its high risk",
  "detail.order.risk.fraud.button.allow": "Allow this order",
  "detail.order.risk.fraud.score": "Fraud score",
  "detail.order.risk.fraud.score.label.low": "Low",
  "detail.order.risk.fraud.score.label.medium": "Medium",
  "detail.order.risk.fraud.score.label.high": "High",
  "detail.order.cancel.subscription.day":
    "Recurring payment in progress every day",
  "detail.order.cancel.subscription.week":
    "Recurring payment in progress every week",
  "detail.order.cancel.subscription.month":
    "Recurring payment in progress every month",
  "detail.order.cancel.subscription.quarterly":
    "Recurring payment in progress every quarter",
  "detail.order.cancel.subscription.halfYearly":
    "Recurring payment in progress every halfyear",
  "detail.order.cancel.subscription.yearly":
    "Recurring payment in progress every year",

  // "detail.order.cancel.subscription": "Recurring payment in progress every ",
  // "detail.order.cancel.subscription.days": " days ",

  "detail.order.cancel.subscription.button": "Cancel subscription",
  "detail.order.cancel.subscription.canceled": "Recurring payment canceled",
  "detail.order.type": "Type",
  "detail.order.type.recurring": "Recurring",
  "detail.order.type.single": "One time",
  "detail.order.date.created": "Created",
  "detail.order.date.received": "Received",
  "detail.order.description": "Description",
  "detail.order.name.first": "Name",
  "detail.order.business.name": "Company name",
  "detail.order.name.surname": "Surname",
  "detail.order.cpf": "CPF (Identification number)",
  "detail.order.cnpj": "CNPJ (Identification number)",
  "detail.order.dob": "Date of birth",
  "detail.order.register": "Dage of register",
  "detail.order.email": "Email",
  "detail.order.phone": "Phone",
  "detail.order.zipcode": "Zipcode (CEP)",
  "detail.order.zip.code": "Zipcode (CEP) without dots or dash",
  "detail.order.street": "Street",
  "detail.order.house.number": "Number",
  "detail.order.house.reference": "Reference",
  "detail.order.district": "District",
  "detail.order.city": "City",
  "detail.order.state": "State",
  "detail.order.country": "Country",
  "detail.order.events.refunded": "Refunded",
  "detail.order.events.credit.card": "Credit card",
  "detail.order.events.received": "Payment received",
  "detail.order.events.expired.pix": "Expired PIX",
  "detail.order.events.failed": "Refused",
  "detail.order.events.created": "Created",
  "detail.order.events.boleto.n": "Boleto",
  "detail.order.events.boleto.of.the": "of",
  "detail.order.events.button.view.boleto": "View Boleto",
  "detail.order.events.request.sent": "Payment request sent",
  "detail.order.events.canceled.boleto": "Canceled Boleto",
  "detail.order.transaction.code": "Transaction code",
  "detail.order.payment.method": "Payment method",
  "detail.order.installments.quantity": "Installments",
  "detail.order.card.type": "Network",
  "detail.order.card.holder": "Card holdername",
  "detail.order.card.number": "Card number",
  "detail.order.expiry.date": "Expiry date",
  "detail.order.received.amount": "Amount",
  "detail.order.tax": "Pre-informed tax",
  "detail.order.preme.fee": "Fee",
  "detail.order.additional.fee": "Additional fees",
  "detail.sale.commission.amount": "Amount",
  "detail.sale.commission.seller": "Seller",
  "detail.order.boleto.barcode": "Barcode",
  "detail.order.boleto.due.date": "Due date",
  "detail.order.net.amount": "Net amount",
  "detail.order.others": "Others",
  "detail.order.commision": "Commission",
  "detail.order.net.amount.local.currency": "Amount",
  "detail.order.payment.link": "Payment link",
  "detail.order.already.paid": "Amount paid so far",
  "detail.order.iof": "IOF",

  "detail.order.ip.address": "Device IP Address",
  "detail.order.os": "Operating system",
  "detail.order.browser": "Browser",
  "detail.order.device": "Device ",
  "detail.order.location": "Location",

  "detail.order.verify.pix.status": "Check payment status",

  "detail.order.receivables.title": "Receivables",
  "detail.order.paid": "Received",
  "detail.order.scheduled": "Scheduled",
  "detail.order.pending": "Pending",
  "detail.order.canceled": "Canceled",
  "detail.order.refunded": "Refunded",
  "detail.order.anticipation.fee": "Anticipation fee",

  "ui.cta.processing": "Processing...",
  "ui.cta.loading": "Loading...",
  "ui.cta.create.plan": "Create plan",
  "ui.cta.next": "Continue",

  "manager.links.title": "Payment links",
  "manager.links.header.modal": "Manage payment links",
  "manager.links.header.table.status": "Status",
  "manager.links.header.table.title": "Title",
  "manager.links.header.table.name": "Name",
  "manager.links.header.table.price": "Price",
  "manager.links.header.table.quantity.sales": "Sales",
  "manager.links.header.table.store": "Store",
  "manager.links.header.table.url": "Link URL",
  "manager.links.header.table.created": "Creation date",
  "manager.links.details.url": "Payment link",
  "manager.links.details.amount": "Value",
  "manager.links.details.title": "Title",
  "manager.links.details.payment.methods": "Payment methods",
  "manager.links.details.payment.installments": "Installments",
  "manager.links.details.description": "Description",
  "manager.links.details.order.number": "Associated sales number",

  "manager.links.all.details.title": "Payment link details",
  "manager.links.customized.details.title": "Customized details",
  "manager.links.details.sales.title": "Sales",
  "manager.links.details.billing.period": "Billing period",
  "manager.links.details.trial.period": "Trial period",
  "manager.links.details.trial.period.days": "Days",
  "manager.links.details.activate": "Activate payment link",
  "manager.links.details.desactivate": "Deactivate payment link",
  "manager.links.details.tip": "Tip",
  "manager.links.details.tip.text":
    "You can deactivate and activate again your payment links as many times as you want.",

  "manager.links.details.payment.methods.0": "Credit card",
  "manager.links.details.payment.methods.1": "Boleto",
  "manager.links.details.payment.methods.01": "Credit card and Boleto",
  "manager.links.details.payment.methods.03": "Cartão de crédito and Pix",
  "manager.links.details.payment.methods.3": "Pix",
  "manager.links.details.payment.methods.13": "Boleto and Pix",
  "manager.links.details.payment.methods.013": "All available",
  "manager.links.details.customized.boleto": "Customized Boleto",
  "manager.links.details.billing address": "Billing address",
  "manager.links.details.billing address.required": "Required",
  "manager.links.details.billing address.no.required": "Not required",
  "manager.links.details.pre.fill.data": "Pre-informed customer’s data",
  "manager.links.details.include.fee.transfer": "Customer pay for payment fees",
  "manager.links.details.include.fee.transfer.yes": "Yes",
  "manager.links.details.include.fee.transfer.no": "No",
  "manager.links.details.sales.view": "View",

  "ui.label.copy": "Copy",

  "manager.links.details.copy.barcode": "Copy payment link",
  "manager.links.details.copy.barcode.copied": "Copied!",
  "manager.links.details.create.new.order": "Request new payment",

  "manager.links.details.generate.qrcode": "Generate QR Code",
  "manager.links.details.qrcode": "Download QR Code",
  "manager.links.details.download.qrcode": "Download",

  "manager.links.details.delete.link": "Delete link",

  "payment.error.credit.expiration": "Invalid expiration date",

  "error.card.number": "Invalid card number",
  "error.card.expiration":
    "Invalid expiration date. The month must be from 01 to 12 and the expiration year cannot be earlier than the current year. The expiration date must be in MM/YY format",
  "error.card.cvv": "The security code must be at least 3 characters long.",

  "create.store.page.title": "Create store",
  "create.store.page.header.pending": "Waiting approval",
  "create.store.page.header.created": "Store successfully created",

  "create.store.page.tip.1":
    "You can use a CNPJ already registered to create stores in order to better manage your dashboard",
  "create.store.page.tip.2": "The bank account is linked to the",
  "create.store.page.tip.2.1": "for security",

  "create.store.page.intern.text": "Business data",
  "create.store.page.intern.bank.data": "Bank data",
  "create.store.page.intern.socio.data": "Partner details",
  "create.store.page.intern.docs.data": "Documents",

  //visa
  "request.payment.api.visa.error.4":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.15":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.51":
    "Transação não autorizada. Cartão restrito por falta de limite, contate o seu banco emissor.",
  "request.payment.api.visa.error.39":
    "Transação não autorizada. Utilize a função débito.",
  "request.payment.api.visa.error.52":
    "Transação não autorizada. Utilize a função crédito.",
  "request.payment.api.visa.error.53":
    "Transação não autorizada. Utilize a função crédito.",
  "request.payment.api.visa.error.59":
    "Transação não autorizada. Compra com suspeita de fraude, contate o seu banco emissor.",
  "request.payment.api.visa.error.58":
    "Transação não autorizada. O cartão fornecido não tem permissão para realizar esse tipo de transação. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.93":
    "Transação não autorizada pelo seu banco por suspeita de fraude. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.6":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.visa.error.3":
    "Transação não autorizada. Comerciante inválido.",
  "request.payment.api.visa.error.19":
    "Transação não autorizada. Problema no adquirente.",
  "request.payment.api.visa.error.12":
    "Transação não autorizada. Erro no formato. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.82":
    "Transação não autorizada. Erro no cartão. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.75":
    "Transação não autorizada. Excedida a quantidade de tentativa de senha/compra.",
  "request.payment.api.visa.error.76":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.61":
    "Transação não autorizada. Valor excedido. Contate a central do seu cartão.",
  "request.payment.api.visa.error.64":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.N4":
    "Transação não autorizada. Valor excedido. Contate a central do seu cartão.",
  "request.payment.api.visa.error.74":
    "Transação não autorizada. Senha vencida. Contate a central do seu cartão.",
  "request.payment.api.visa.error.81":
    "Transação não autorizada. Senha vencida. Contate a central do seu cartão.",
  "request.payment.api.visa.error.78":
    "Transação não autorizada. Desbloqueie o cartão.",
  "request.payment.api.visa.error.91":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",
  "request.payment.api.visa.error.92":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.94":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.96":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",
  "request.payment.api.visa.error.N8":
    "Transação não autorizada. Valor diferente da pré autorização. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.N3":
    "Transação não autorizada. Saque não disponível. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.R0":
    "Transação não autorizada. Suspensão de pagamento recorente. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.R1":
    "Transação não autorizada. Suspensão de pagamento recorente. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.R3":
    "Transação não autorizada. Suspensão de pagamento recorente. Contate o seu banco para tentar novamente.",

  //master
  "request.payment.api.master.error.15":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.master.error.51":
    "Transação não autorizada. Cartão restrito por falta de limite, contate o seu banco emissor.",
  "request.payment.api.master.error.58":
    "Transação não autorizada. O cartão fornecido não tem permissão para realizar esse tipo de transação. Contate o seu banco para tentar novamente.",
  "request.payment.api.master.error.1":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.master.error.3":
    "Transação não autorizada. Comerciante inválido.",
  "request.payment.api.master.error.30":
    "Transação não autorizada. Problema no adquirente.",
  "request.payment.api.master.error.88":
    "Transação não autorizada. Erro no cartão. Contate o seu banco para tentar novamente.",
  "request.payment.api.master.error.12":
    "Transação não autorizada. Valor da parcela inválido.",
  "request.payment.api.master.error.75":
    "Transação não autorizada. Excedida a quantidade de tentativa de senha/compra. Contate a central do seu cartão.",
  "request.payment.api.master.error.61":
    "Transação não autorizada. Valor excedido. Contate a central do seu cartão.",
  "request.payment.api.master.error.91":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",
  "request.payment.api.master.error.96":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",

  //elo
  "request.payment.api.elo.error.51":
    "Transação não autorizada. Cartão restrito por falta de limite, contate o seu banco emissor.",
  "request.payment.api.elo.error.59":
    "Transação não autorizada. Compra com suspeita de fraude, contate o seu banco emissor.",
  "request.payment.api.elo.error.4":
    "Transação não concluída. Por favor tente novamente.",
  "request.payment.api.elo.error.58":
    "Transação não autorizada. Comerciante inválido.",
  "request.payment.api.elo.error.6":
    "Transação não autorizada. Consultar credenciador.",
  "request.payment.api.elo.error.19":
    "Transação não autorizada. Problema no adquirente.",
  "request.payment.api.elo.error.30":
    "Transação não autorizada. Erro no formato. Contate o seu banco para tentar novamente.",
  "request.payment.api.elo.error.82":
    "Transação não autorizada. Erro no cartão. Contate o seu banco para tentar novamente.",
  "request.payment.api.elo.error.64":
    "Transação não autorizada. Valor mínimo de transação inválido.",
  "request.payment.api.elo.error.23":
    "Transação não autorizada. Valor da parcela inválido.",
  "request.payment.api.elo.error.38":
    "Transação não autorizada. Excedida a quantidade de tentativa de senha/compra. Contate a central do seu cartão.",
  "request.payment.api.elo.error.75":
    "Transação não autorizada. Excedida a quantidade de tentativa de saque. Contate a central do seu cartão.",
  "request.payment.api.elo.error.61":
    "Transação não autorizada. Valor excedido. Contate a central do seu cartão.",
  "request.payment.api.elo.error.74":
    "Transação não autorizada. Senha vencida. Contate a central do seu cartão.",
  "request.payment.api.elo.error.P5":
    "Transação não autorizada. Senha inválida. Contate o seu banco para tentar novamente.",
  "request.payment.api.elo.error.76":
    "Transação não autorizada. Conta destino inválida ou inexistente. Contate o seu banco para tentar novamente.",
  "request.payment.api.elo.error.77":
    "Transação não autorizada. Conta de origem inválida ou inexistente. Contate o seu banco para tentar novamente.",
  "request.payment.api.elo.error.78":
    "Transação não autorizada. Desbloqueie o cartão.",
  "request.payment.api.elo.error.91":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",
  "request.payment.api.elo.error.96":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",
  "request.payment.api.elo.error.99":
    "Transação não autorizada. Valor diferente da pré autorização. Contate o seu banco para tentar novamente.",
  "request.payment.api.elo.error.AB":
    "Transação não autorizada. Utilize a função crédito.",
  "request.payment.api.elo.error.AC":
    "Transação não autorizada. Utilize a função débito.",
  "request.payment.api.elo.error.P6":
    "Transação não autorizada. Senha inválida. Utilize a nova senha.",

  //amex
  "request.payment.api.amex.error.116":
    "Transação não autorizada. Cartão restrito por falta de limite, contate o seu banco emissor.",
  "request.payment.api.amex.error.117":
    "Transação não autorizada. Senha inválida.",
  "request.payment.api.amex.error.200":
    "Transação não autorizada pelo seu banco por suspeita de fraude. Contate o seu banco para tentar novamente.",
  "request.payment.api.amex.error.122":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.amex.error.115":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.amex.error.101":
    "Transação não autorizada. Prazo de validade do cartão fornecido está vencido, por favor insira um cartão válido.",
  "request.payment.api.amex.error.109":
    "Transação não autorizada. Comerciante inválido.",
  "request.payment.api.amex.error.181":
    "Transação não autorizada. Erro no formato. Contate o seu banco para tentar novamente.",
  "request.payment.api.amex.error.180":
    "Transação não autorizada. Erro no cartão. Contate o seu banco para tentar novamente.",
  "request.payment.api.amex.error.110":
    "Transação não autorizada. Valor da transação inválido.",
  "request.payment.api.amex.error.106":
    "Transação não autorizada. Excedida a quantidade de tentativa de senha/compra. Contate a central do seu cartão.",
  "request.payment.api.amex.error.912":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",
  "request.payment.api.amex.error.911":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",

  //all networkds
  "request.payment.api.error.B1":
    "Transação não autorizada. Surcharge não suportado.",
  "request.payment.api.error.B2":
    "Transação não autorizada. Surcharge não suportado pela rede de débito.",
  "request.payment.api.error.N0":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.error.R2": "Transação não autorizada para Visa PIN.",

  "request.payment.api.error.14":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",

  "request.payment.api.error.63":
    "Transação não autorizada. Compra com suspeita de fraude ou com violação de segurança, contate o seu banco emissor.",

  "request.payment.api.error.100":
    "Transação não autorizada. Compra com suspeita de fraude, contate o seu banco emissor.",
  "request.payment.api.error.101":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.102":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão ou o seu banco.",
  "request.payment.api.error.103":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.104":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.105":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito ou suspeita de fraude, contate o seu banco emissor.",
  "request.payment.api.error.106":
    "Desculpe, houve um erro no processamento com o emissor do cartão, por favor tente novamente.",
  "request.payment.api.error.107":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.108":
    "Transação não autorizada. O valor da compra não é permitido para o tipo do cartão fornecido.",
  "request.payment.api.error.109":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.error.110":
    "Transação não autorizada. O cartão fornecido não tem permissão para realizar esse tipo de transação.",
  "request.payment.api.error.111":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito, contate o seu banco emissor.",
  "request.payment.api.error.112":
    "Transação não autorizada. Prazo de validade do cartão fornecido está vencido, por favor insira um cartão válido.",
  "request.payment.api.error.113":
    "Transação não autorizada. Compra com suspeita moderada de fraude, contate o seu banco emissor.",
  "request.payment.api.error.114":
    "Transação não autorizada. A compra foi bloqueada pelo emissor do cartão.",
  "request.payment.api.error.115":
    "Transação não autorizada. Você excedeu o limite de transações permitidas em um período de tempo.",
  "request.payment.api.error.116":
    "Transação não autorizada. O cartão fornecido não tem permissão para realizar esse tipo de transação. Contate o seu banco para tentar novamente.",
  "request.payment.api.error.117":
    "Desculpe, não encontramos registros dessa transação.",
  "request.payment.api.error.118":
    "Transação não autorizada. O cartão fornecido está bloqueado, por favor insira um cartão válido.",
  "request.payment.api.error.119":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.121":
    "Desculpe, houve um erro no processamento, por favor tente novamente.",
  "request.payment.api.error.122":
    "Transação não autorizada. Uma transação similar foi enviada recentemente.",
  "request.payment.api.error.123":
    "Transação não autorizada. O estabelecimento cancelou assinaturas recorrentes do emissor.",
  "request.payment.api.error.124":
    "Transação não autorizada. Por favor entre em contato com o emissor.",
  "request.payment.api.error.170":
    "O cartão fornecido não tem permissão para realizar transações de verificação.",
  "request.payment.api.error.175": "Transação de verificação negada.",

  "request.payment.api.error.1":
    "Transação não autorizada. Prazo de validade do cartão incorreto, por favor verifique e tente novamente.",
  "request.payment.api.error.2":
    "Transação não autorizada. Prazo de validade do cartão incorreto, por favor verifique e tente novamente.",
  "request.payment.api.error.3":
    "Transação não autorizada. Prazo de validade do cartão incorreto, por favor verifique e tente novamente.",

  "request.payment.api.error.5":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.6":
    "Transação não autorizada. O CEP é inválido, por favor insira um CEP válido.",

  "request.payment.api.error.8":
    "Transação não autorizada. O CEP é inválido, por favor insira um CEP válido.",
  "request.payment.api.error.9":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.error.10":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.error.11":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",

  "request.payment.api.error.16":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.17":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.18":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.19":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.20":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.21":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.22":
    "Transação não autorizada. Informe o seu endereço completo.",
  "request.payment.api.error.23":
    "Transação não autorizada. Informe o seu endereço completo.",
  "request.payment.api.error.24":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.25":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.26":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.27":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.28":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.29":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.30":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.31":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.32":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.33":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.34":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.35":
    "Transação não autorizada. Prazo de validade do cartão incorreto, por favor verifique e tente novamente.",
  "request.payment.api.error.36":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.error.37":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.error.38":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.error.39":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.40":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",

  "request.payment.api.error.42":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",

  "request.payment.api.error.44":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.45":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.46":
    "Transação não autorizada. Quantidade de parcelas acima do permitido, por favor verifique e tente novamente.",
  "request.payment.api.error.47":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.49":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito ou suspeita de fraude, contate o seu banco emissor.",
  "request.payment.api.error.50":
    "Transação não autorizada. Quantidade de parcelas inválida, por favor verifique e tente novamente.",

  "request.payment.api.error.53":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",

  "request.payment.api.error.59":
    "Transação não autorizada. O nome do portador do cartão fornecido é inválido, por favor verifique e tente novamente.",
  "request.payment.api.error.60":
    "Transação não autorizada. Informe o seu endereço completo.",
  "request.payment.api.error.61":
    "Transação não autorizada. O nome do portador do cartão fornecido é inválido, por favor verifique e tente novamente.",

  "request.payment.api.error.64": "Transação não autorizada. Tente novamente.",

  "request.payment.api.error.66":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.67":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.68":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",

  "request.payment.api.error.70":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito, tente novamente ou contate o seu banco emissor.",
  "request.payment.api.error.71":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito, tente novamente ou contate o seu banco emissor.",
  "request.payment.api.error.72":
    "Transação não autorizada. Por favor entre em contato com o emissor.",
  "request.payment.api.error.73":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito, tente novamente ou contate o seu banco emissor.",
  "request.payment.api.error.74": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.75":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.76": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.78": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.79":
    "Transação não autorizada. Prazo de validade do cartão fornecido está vencido, por favor insira um cartão válido.",
  "request.payment.api.error.80":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito, tente novamente ou contate o seu banco emissor.",
  "request.payment.api.error.82":
    "Transação não autorizada. O cartão fornecido não tem permissão para realizar esse tipo de transação. Cartões de Débito não são aceitos em compras online.",
  "request.payment.api.error.83":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.84":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.85":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",

  "request.payment.api.error.87":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.88":
    "Transação não autorizada. O estabemecimento comercial não pode processar sua compra neste momento.",
  "request.payment.api.error.89":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.97":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.98":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.132":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.133":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.150": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.151":
    "Transação não autorizada. Quantidade de parcelas inválida, por favor verifique e tente novamente.",
  "request.payment.api.error.153":
    "Transação não autorizada. O CPF/CNPJ não existe ou possui dados incorretos, por favor verifique e tente novamente.",
  "request.payment.api.error.154":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.155":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.156":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.157":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.158":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.159": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.160": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.161": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.167": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.169": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.171":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.173": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.176": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.899":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.1018":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.1019":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.1020":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.1021":
    "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.1023":
    "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.1030":
    "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.1034":
    "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.1036":
    "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.1038":
    "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.3025":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3026":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3027":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3028":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3029":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3030":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3031":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3032":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3033":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3034":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3035":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.4":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.7":
    "Transação não autorizada. O CEP é inválido, por favor insira um CEP válido.",
  "request.payment.api.error.12":
    "Transação não autorizada. O CPF/CNPJ não existe ou possui dados incorretos, por favor verifique e tente novamente.",
  "request.payment.api.error.13":
    "Transação não autorizada. O CPF/CNPJ não existe ou possui dados incorretos, por favor verifique e tente novamente.",
  "request.payment.api.error.15":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.41":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.43":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.51":
    "Transação não autorizada. Cartão restrito por falta de limite, contate o seu banco emissor.",
  "request.payment.api.error.54":
    "Transação não autorizada. Quantidade de parcelas inválida, por favor verifique e tente novamente.",
  "request.payment.api.error.55":
    "Transação não autorizada. O nome do portador do cartão fornecido é inválido, por favor verifique e tente novamente.",
  "request.payment.api.error.56": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.57":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.58":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.62":
    "Transação não autorizada. O nome do portador do cartão fornecido é inválido, por favor verifique e tente novamente.",
  "request.payment.api.error.65": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.69":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.86":
    "Transação não autorizada. Prazo de validade do cartão fornecido está vencido, por favor insira um cartão válido.",

  "request.payment.discount.error":
    "The discount amount must be less than or equal to the total value of the ticket.",
  "request.payment.late.fee.error":
    "The amount of the fine must be less than or equal to 2% of the total value of the ticket and greater than R$ 1.00.",
  "request.payment.interest.error":
    "The total amount of interest cannot be greater than 1% of the total value of the boleto and less than R$ 1.00.",
  "request.payment.interest.error.monthly":
    "The total amount of interest cannot be greater than 1% of the total amount of the boleto.",
  "request.payment.interest.error.daily.percentage":
    "The total amount of interest cannot be greater than 0.033% (per day) of the total amount of the boleto.",

  "developer.page.integration.title": "Build a custom integration",
  "developer.page.integration.subtitle": "Receive your first test sale",
  "developer.page.integration.3.subtitle":
    "Congratulations, you are ready to run in live mode!",
  "developer.page.integration.text":
    "Your account is already active and enabled to run live payments. To start your integration, we’ve our test environment ready for you.",
  "developer.page.api.doc.button": "API Documentation",
  "developer.page.list.api.keys": "API Keys",
  "developer.page.list.store.name": "Store name",
  "developer.page.list.store.id": "Store ID",
  "developer.page.list.username": "User",
  "developer.page.list.password": "Secret key",
  "developer.page.list.stores": "Your stores",
  "developer.page.token.title": "Tokens",
  "developer.page.token.description": "Create access tokens so that external integrations can use your company's account on Primefy.",
  "developer.page.token.create.button": "Create new token",

  "default.error.state.message": "default",

  "filter.label.btn": "Filter",
  "filter.label.btn.export": "Export",
  "filter.label.products": "Products",
  "filter.label.title": "Filter",
  "filter.label.period": "Period",
  "filter.label.status": "Status",
  "filter.label.status.all": "All",
  "filter.label.status.confirmed": "Approved",
  "filter.label.status.pending": "Pending",
  "filter.label.status.canceled": "Canceled",
  "filter.label.status.Rejected": "Refused",
  "filter.label.status.PendingChallenge": "Pending",
  "filter.label.status.failed": "Refused",
  "filter.label.status.dispute": "Dispute",
  "filter.label.status.chargedBack": "Charged Back",
  "filter.label.status.Succeded": "Approved",
  "filter.label.status.Pending": "Pending",
  "filter.label.status.Canceled": "Canceled",
  "filter.label.status.Failed": "Refused",
  "filter.label.status.Disputed": "In dispute",
  "filter.label.status.ChargedBack": "Dispute lost",
  "filter.label.status.Blocked": "Blocked",
  "filter.label.status.DisputeSucceeded": "Disputa won",

  "filter.label.payment.methods": "Payment method",
  "filter.label.payment.methods.all": "All",
  "filter.label.payment.methods.Boleto": "Boleto (bank slip)",
  "filter.label.payment.methods.Credit": "Credit card",
  "filter.label.payment.methods.Pix": "Pix",
  "filter.label.type": "Type",
  "filter.label.type.all": "All",
  "filter.label.type.single": "One time",
  "filter.label.type.recurring": "Recurring",
  "filter.cleaner": "Clear",
  "filter.apply": "Apply",

  "download.loading.text": "Your Excel file download is in progress",
  "download.loading.text.to.complete": "to complete",

  "reports.filter.period.7": "Last 7 days",
  "reports.filter.period.30": "Last 30 days",

  "reports.gross.sales.title": "Gross Sales",
  "reports.sales.title": "Sales",
  "reports.payment.methods.title": "Payment Methods",
  "reports.customers.title": "New Clientes",
  "reports.disputes.title": "Disputes",
  "reports.refunds.title": "Refunds",
  "reports.refunds.pix.error":
    "For Pix payments you can only refund the full amount.",

  "tour.homepage.0.title": "Your new panel at Primefy",
  "tour.homepage.0":
    "Increase your sales and track your cash flow for your company's success with our financial automation solution.",
  "tour.homepage.1":
    "Do you want to organize your business by branches, departments or regions? You can create multiple establishments with the same CNPJ, or even with different CNPJs.",
  "tour.homepage.2": "Here you can see an overview of your sales by period.",
  "tour.homepage.3":
    "Your 5 most recent sales appear here, updated in real time, and you don't even have to refresh the page.",
  "tour.homepage.4":
    "As you receive your recent sales, it will automatically update on the map in real time.",
  "tour.homepage.5":
    "You can create payment links or even manually bill a customer. Here you can do everything, without needing a technology team.",
  "tour.homepage.6":
    "Your finances are organized as Available Balance and Receivables Balance. The Balance Available is the amount that can already be transferred to your bank account, while the Balance Receivable will be available soon.",
  "tour.homepage.7":
    "You can view your withdrawal history, detailed future cash flow and make withdrawals.",
  "tour.homepage.8":
    "In Settings, you can customize all the details of your business, establishments and users. Also in Settings, you can view your rates and integrations on the platform.",
  "tour.homepage.9":
    "You can test as much as you like before making an actual sale, with test credit cards and more.",
  "tour.homepage.10":
    "We are available to help you at any time, via email or chat.",
  "tour.start": "Continue with the tour",
  "tour.finished": "End tour",
  "tour.next": "Next tip",
  "tour.finish": "Skip tour",

  "payment.link.details.dynamic.customer.firstName": "Name",
  "payment.link.details.dynamic.customer.surname": "Surname",
  "payment.link.details.dynamic.customer.address": "Address",
  "payment.link.details.dynamic.customer.birthdate": "Birthdate",
  "payment.link.details.dynamic.customer.email": "Email",
  "payment.link.details.dynamic.customer.identificationNumber":
    "Identification number",
  "payment.link.details.dynamic.customer.phone": "Phone",

  "payment.link.details.dynamic.customer.address.city": "City",
  "payment.link.details.dynamic.customer.address.district": "District",
  "payment.link.details.dynamic.customer.address.number": "Number",
  "payment.link.details.dynamic.customer.address.reference": "Reference",
  "payment.link.details.dynamic.customer.address.state": "State",
  "payment.link.details.dynamic.customer.address.street": "Street",
  "payment.link.details.dynamic.customer.address.zipCode": "Zipcode",

  "filter.label.period.creation": "Creation date",
  "filter.label.order.period.creation": "Order creation date",
  "filter.label.period.payment": "Payment date",
  
  "filter.label.store.id": "Inform Store ID",
  "filter.label.procuct.name": "Inform Product Name",
  "filter.label.procuct.id": "Inform Product ID",
  
  "products.search.by.name": "product name",

  "payment.link.details.grace.period": "Day",
  "payment.link.details.grace.period.2": "Days",

  "header.tax": "Tax included",

  "manual.payment.succeded.title": "Payment made successfully",
  "manual.payment.succeded.text.1": "A payment for",
  "manual.payment.succeded.text.2": "will appear on your customer statement as",

  "tip.home.finances.available.amount":
    "You can withdrawal this amount at any time.",
  "tip.home.finances.incoming.amount":
    "Estimated amount to become available, after fees, disputes and splits applied.",
  "tip.settings.fees.boleto":
    "This fee is only applied if your customer pay the Boleto.",
  "tip.settings.fees.pix":
    "This fee is only applied if your customer pay the PIX.",
  "tip.settings.fees.credit.card":
    "This fee is only applied if the sales become confirmed.",

  "request.payment.address.data.tip":
    "In case you need to send a packacge to your customer, or need billing address to generate an invoice, for example.",
  "limit.installments.tip":
    "By standard, your customers can pay in up to 12 installments, you can customize it.",
  "request.payment.customize.boleto.btn.tip":
    "Do you want to define a fine in case the customer pay after the due date, or give a discount if they pay before it? You can customize it.",
  "request.payment.create.plan.limit.payment.methods.tip":
    "You can decide to do not accept payments with Boleto Bancário (bank slip), for example.",
  "request.payment.pre.fill.tip":
    "Already have some details about your customer? Fill beforehand so you can increase your conversion rate!",
  "manager.links.details.include.fee.transfer.tip":
    "Do you want to receive the defined amount, regardless of your customer's payment method?",
  "request.payment.expiration.link.tip":
    "Are you launching a product with expiration date and time? Define to avoid late payments.",
  "request.payment.redirect.payment.tip":
    "Redirect customers to your website if the payment is successful.",
  "request.payment.recurring.trial.tip":
    "You can offer a trial period for your customer to test your product before you start to charge them.",
  "request.payment.inform.cycle.tip":
    "Define the maximum amount of charges to be made per user on this plan.",

  "request.payment.banner.label": "Add images to be displayed at checkout",
  "request.payment.banner": "Banners",
  "request.payment.top.banner": "Top Banner",
  "request.payment.top.banner.dimensions":
    "Recommended dimensions: 500px (width) by 200px (height)",
  "request.payment.right.banner": "Right Banner",
  "request.payment.right.banner.dimensions":
    "Recommended dimensions: 390px (width) by 456px (height)",

  "error.request.payment.link.title":
    "Enter a title so your customers can see what they're buying.",
  "error.request.payment.link.description":
    "Please enter a description for the payment link.",
  "error.request.payment.link.amount":
    "You need to set the amount you want to charge in the payment link.",
  "error.request.payment.link.amount.less.3":
    "The value of the link needs to be at least equivalent to R$ 3.00.",
  "error.request.payment.link.payment.types":
    "Please select at least one payment method.",
  "error.request.payment.link.payment.boleto.duedate.valid":
    "Choose a valid date.",
  "error.request.payment.link.description.onblur":
    "The description must be a maximum 255 characters.",
    
      
  "error.request.payment.amount.more.than.10k":
  "The amount must be a maximum of ",
  "error.request.payment.amount.more.than.limit.2":
    "Please uncheck the payment option with boleto or change the value of your link to proceed.",

  "error.request.payment.product.description":
    "Please enter a description for the product.",
  "error.request.payment.product.amount":
    "You need to define the amount you want to charge for the product.",
  "request.payment.create.product.label": "Create product",

  "error.request.payment.create.plan.name": "Enter the plan name.",
  "error.request.payment.create.plan.amount": "Enter the plan value.",
  "error.request.payment.url.redirect": "Please enter a valid URL.",

  "error.request.payment.amount": "Please enter value for sale.",

  "personal.data.erro.name":
    "Invalid name. The name must be 1 to 45 characters long.",
  "personal.data.erro.surname":
    " Invalid surname. The surname must be 1 to 45 characters long.",
  "personal.data.erro.identification.cpf":
    "Invalid CPF number. Number entered does not match the format of a valid CPF.",
  "personal.data.erro.identification.cnpj":
    "Invalid CNPJ number. Number entered does not match the format of a valid CNPJ.",
  "personal.data.erro.email":
    "Invalid email. An email must have a maximum of 80 characters and follow the pattern with '.' and '@'.",
  "personal.data.erro.business.name":
    "Invalid company name. The name must be 1 to 45 characters long.",
  "personal.data.erro.identification.number":
    "Invalid CPF or CNPJ. The number entered does not match the format of a valid CPF or a CNPJ.",

  "payment.data.erro.birthdate":
    "Invalid date of birth. The date of birth cannot be after the current date and must be in the format mm/dd/yyyy",
  "payment.data.erro.phone":
    "Invalid phone. Number entered does not match the format of a valid phone number.",
  "payment.data.erro.zipcode":
    "Invalid zip code. Zip code must be 8 characters in the format 12345-678",
  "payment.data.erro.address":
    "Invalid address. The address must be 1 to 70 characters long.",
  "payment.data.erro.number":
    "Invalid number. Enter the address number. 0 (zero) for when there is none.",
  "payment.data.erro.district":
    "Invalid neighborhood. The neighborhood name must be 1 to 70 characters long.",
  "payment.data.erro.city":
    "Invalid city. The City name must be 1 to 70 characters long.",
  "payment.data.erro.state":
    "Invalid state. The state name must be 1 to 70 characters long.",

  "fees.from": "From",

  "integrations.title": "Integrations",
  "integrations.description":
    "Integrate Primefy with apps and automate your sales without friction.",

  "integrations.cancel": "Cancel",
  "integrations.add": "Register webhook",
  "integrations.add.first": "Add first webhook",
  "integrations.add.new": "Connect",

  "integrations.list.status": "Status",
  "integrations.list.description": "Description",
  "integrations.list.events": "Events",

  "webhook.title": "Webhooks",
  "webhook.description":
    "Create webhooks to receive events and notifications from Primefy.",
  "webhook.connection.endpoint.label": "Endpoint",
  "webhook.connection.description.label": "Description",
  "webhook.connection.store.label": "Store",
  "webhook.connection.events.label": "Events",

  "webhook.connection.events.placeholder": "Select an event",
  "webhook.connection.events.0": "All events",
  "webhook.connection.events.1": "Pending payments",
  "webhook.connection.events.2": "Aproved payments",

  "webhook.connection.nuvemshop.store": "Store",
  "webhook.connection.nuvemshop.installments": "Maximum installments allowed",
  "webhook.connection.nuvemshop.installments.1": "No installments",
  "webhook.connection.nuvemshop.installments.2": "Up to 2 installments",
  "webhook.connection.nuvemshop.installments.3": "Up to 12 installments",
  "webhook.connection.nuvemshop.ccard": "Credit card",
  "webhook.connection.nuvemshop.boleto": "Bank slip",
  "webhook.connection.nuvemshop.pix": "Pix",
  "webhook.connection.nuvemshop.payment.methods.error": "At least one payment method must be entered",

  "webhook.connection.pluga":
    "Pluga is an integration platform that connects the tools you use in your company without having to have any kind of technical knowledge.",
  "webhook.connection.zapier":
    "Connect your apps and automate workflows. Easy automation for busy people. Zapier moves info between your web apps automatically, so you can focus on your most important work.",
  "webhook.connection.ifttt":
    "Every thing works better together. Quickly and easily automate your favorite apps and devices. Customize and control your integrations with filter code, queries and multiple actions.",
  "webhook.connection.nuvemshop":
    "Nuvemshop is Latin America's leading e-commerce platform. It currently empowers 85,000+ merchants to launch, develop and promote their own online businesses. Sell worldwide at Nuvemshop with Primefy payments’ solution.",

  "webhook.success.message": "Successfully registered webhook",
  "webhook.error.message": "Error registering Webhook",

  "nuvemshop.success.message":
    "Maximum number of installments changed successfully",
  "nuvemshop.error.message":
    "Error when changing number of installments. Please try again later.",

  "webhook.error.message.default":
    "Mandatory field, please enter a valid value.",

  "webhook.event.created": "Created transactions",
  "webhook.event.succeded": "Succeded transactions",
  "webhook.event.canceled": "Canceled transactions",
  "webhook.event.failed": "Failed transactions",
  "webhook.event.disputed": "Disputed transactions",
  "webhook.event.charged": "Charged transactions",
  "webhook.event.dispute.succeeded": "Disputed succeeded transactions",

  "open.purchase.payment.link.label":
    "The customer defines how much he wants to pay",
  "open.purchase.payment.link":
    "The customer can set any amount starting from R$1.00. NGOs and Open Products use this functionality a lot to receive donations or rewards from users.",

  "apply.smart.installments.label": "Offer Smart Installments",
  "apply.smart.installments.tip":
    "If a card payment in installments is denied because of available limit, the installments will be charged as recurring payments to make conversion rate higher.",
  "apply.smart.installments.description":
    "This feature doesn't guarantee that future payments will be sucessfully made and you understand the risk of future failed payments.",

  "financials.sales.payin": "Payin",
  "financials.sales.payout": "Payout",
  "financials.sales.bank.account": "Account",
  "financials.sales.agency": "Branch",
  "financials.sales.upcoming": "Upcoming",

  "pagination.page": "Page",

  "choose.a.product.label": "Product",
  "choose.a.product": "Choose a product",
  "create.new.product": "Add a new product",
  "product.quantity": "Quantity",

  "order.buc.label": "Define Order bump, Upsell and Cross sell",
  "order.bump.label": "Order bump",
  "order.up.sell.label": "Upsell",
  "order.cross.sell.label": "Cross sell",
  "order.add.bump.label": "Add another product as order bump",
  "order.add.upsell.label": "Add another product as upsell",
  "order.add.cross.sell.label": "Add another product as cross sell",
  "order.product.1.label": "Product 1",
  "order.product.2.label": "Product 2",

  "order.details.product.title": "Products",
  "order.details.product.name": "Name",
  "order.details.product.quantity": "Quantity",
  "order.details.product.price": "Price per unit",

  "products.tip.text":
    "Offer additional products during checkout and increase your revenue",
  "order.bump.tip.text":
    "Offer extra products for your customer to add during checkout",
  "cross.sell.tip.text":
    "List products that other customers buy frequently to suggest at checkout",
  "up.sell.tip.text":
    "After a sale is confirmed, offer a special or promotional price product and your customer can buy with just 1 click",

  "request.payment.smart.retrial.label": "Adjust Smart Retrials",
  "request.payment.smart.retrial.tip":
    "Smart retrials is a Primefy’s feature that automatically retry due payments for subscriptions. By default, we retry every 3 days for a maximum of 60 days for active late payments.",
  "request.payment.smart.retrial.days": "Days between retries for due payments",
  "request.payment.smart.retrial.limit": "Maximum retries for due payments",
  "request.payment.smart.retrial.days.error":
    "The number must be less than 30.",
  "request.payment.smart.retrial.limit.error":
    "The number must be less than 30.",

  "customers.page.customer": "Customer",
  "customers.page.email": "Email address",
  "customers.page.total.spent": "Total spent",
  "customers.page.sales": "Sales",
  "customers.page.last.payment": "Last payment",
  "customers.page.last.created": "Created",
  "customers.page.create.customer": "Create customer",
  "customers.page.finish.create.customer": "Save",
  "select.customers.modal": "Select a customer or create a new one",

  "customers.details.spent": "Spent",
  "customers.details.created": "Created",
  "customers.details.disputes": "Disputes",
  "customers.details.subscriptions.title": "Subscriptions",
  "customers.details.subscriptions.status": "Status",
  "customers.details.subscriptions.name": "Name",
  "customers.details.subscriptions.frequency": "Frequency",
  "customers.details.subscriptions.payment": "Payment",

  "customers.page.saved.cards": "Saved cards",
  "customers.page.saved.cards.network": "Network",
  "customers.page.saved.cards.default": "Default",
  "customers.page.saved.cards.make.default": "Make default",

  "customers.page.subscription.next.payment": "Next payment on ",
  "customers.page.subscription.next.payment.for": " for ",
  "customers.page.subscription.next.payment.scheduled": " scheduled",

  "customers.page.subscription.previous.payment": "Previous payment on ",
  "customers.page.subscription.previous.payment.for": " for ",
  "customers.page.subscription.previous.payment.failed": " failed",

  "signup.form.full.name": "Full name",
  "signup.create.free.account": "Create your account",
  "signup.start.to.sale": "Start to sell in minutes",
  "signup.terms.1": "By continuing, you agree to our",
  "signup.terms.2": "terms and conditions",

  "finish.register.top.banner":
    "To start selling, you need to complete your registration.",

  "finish.register.settings.title": "Business information",
  "finish.register.settings.subtitle":
    "Fill the remaining details to start selling with Primefy.",
  "finish.register.settings.button": "Finish account creation",
  "finish.register.business.details.title": "Business details",
  "finish.register.yours.details.title": "Your details",
  "finish.register.partner.details.title": "Partner details",
  "finish.register.business.name": "Business name",
  "finish.register.search.category": "Search a category",
  "finish.register.select.category": "Select a category",
  "finish.register.your.address.title": "Your address",
  "finish.register.business.address.title": "Business address",
  "finish.register.bank.account.title": "Bank account",
  "finish.register.documents.title": "Documents",
  "finish.register.documents.proof.activity": "Proof of activity",
  "finish.register.documents.proof.address": "Proof of address",
  "finish.register.documents.proof.address.partner":
    "Proof of address of the partner",
  "finish.register.documents.proof.personal": "Identification document",
  "finish.register.documents.proof.partner":
    "Identification document of the partner",
  "finish.register.documents.additional.documents": "Additional documents",
  "finish.register.logo.label": "Add image",
  "finish.register.documents.label": "Add images",
  "finish.register": "Submit",
  "finish.phone.error.message": "Insert a value phone number",
  "finish.register.legal.company.name": "Legal company name",
  "finish.register.incorporation.document.label": "Incorporation document",

  "create.account.form.password.rule.title":
    "Choose a strong, memorable password that contains at least:",
  "create.account.form.password.rule.1": "8 or more characters",
  "create.account.form.password.rule.2": "1 lowercase letter (a-z)",
  "create.account.form.password.rule.3": "1 uppercase letter (A-Z)",
  "create.account.form.password.rule.4": "1 number (0-9)",
  "create.account.exist.id":
    "Identification number already registered in our base. Please enter a new number.",
  "create.account.exist.email":
    "Email already registered in our database. Please enter a new Email.",
  "create.account.exist.pass":
    "The password needs to contain some defined characteristics. Please review your password.",
  "create.account.email.yup":
    "Invalid email format. Please send a valid email.",
  "create.account.short.full.name.yup":
    "Name too short. Please enter your full name.",
  "create.account.bank.name": "Bank",
  "create.account.popular.categories.title": "Popular",
  "create.account.popular.categories.education": "Education",
  "create.account.popular.categories.entertainment": "Entertainment",
  "create.account.popular.categories.technology": "Technology",
  "create.account.popular.categories.clothing": "Clothing",
  "create.account.name.limit":
    "Please insert a name with a limit of 45 characters",

  "products.details.product.source": "Product source",
  "create.payment.link.courses.selected.title": "Course",
  "create.payment.link.products.selected.title": "Products",
  "create.payment.link.courses.selected.classroom": "Class",
  "create.payment.link.courses.selected.select.classroom": "Select a class",
  "memberkit.error.register": "Invalid API key. Please enter a valid key.",
  "memberkit.error.register.dob": "You can't select a future date",

  "splits.checkbox.label": "Define split",
  "splits.checkbox.tip.label":
    "For every sale coming from this payment link, part of the amount will be split with another merchant. The merchant needs to have an account on Primefy.",
  "splits.recipient.label": "Recipient",
  "splits.search.label": "Search for CPF/CNPJ",
  "splits.commission.label": "Commission",
  "splits.expiration.date.label": "Expiration date",
  "splits.add.label": "Create new split",
  "splits.add.2.label": "Add another split",
  "splits.add.commission.fixed.label": "Fixed (R$)",
  "splits.rules.label": "Split rule",
  "splits.rules.select.placeholder": "Select a split rule",

  "cancel.order.title": "Cancel Order",
  "cancel.order.text":
    "Cancel the order, including the issuance of future bills.",
  "cancel.order.cta": "Cancel Order",

  "cancel.boleto.modal.title": "Cancel Boleto",
  "cancel.boleto.modal.desc.1":
    "You are about to cancel the Boleto Bancário installment's ",
  "cancel.boleto.modal.desc.2": "due for ",
  "cancel.boleto.modal.desc.3":
    "When canceled by the bank, it can no longer be paid.",

  "cancel.order.modal.title": "Cancel Order",
  "cancel.order.modal.desc.1":
    "You are about to cancel all outstanding Boletos for this sale. Boletos already paid will not be refunded.",

  "cancel.modal.cancel.cta": "I don't want to cancel",
  "cancel.boleto.modal.yes.cta": "Cancel Boleto",
  "cancel.order.modal.yes.cta": "Cancel Order",

  "subscriptions.page.subscriptions": "Plans",
  "subscriptions.page.subscription": "Plan",
  "subscriptions.page.price": "Price",
  "subscriptions.page.description": "Description",
  "subscriptions.page.frequency": "Frequency",
  "subscriptions.page.name": "Name",
  "subscriptions.page.grace.period": "Grace period",
  "subscriptions.page.max.charges": "Max charge",
  "subscriptions.page.max.charges.until.cancel": "By cancellation",
  "subscriptions.page.create.subscription": "Create plan",
  "subscriptions.page.edit.subscription": "Edit plan",
  "subscriptions.page.finish.create.subscription": "Save",
  "subscriptions.page.create.subscription.payment.type":
    "Select at least one payment type for the subscription.",
  "subscriptions.page.grace.period.error":
    "Enter a date on or after the current date",
  "subscriptions.page.grace.period.error.invalid": "Inform a valid date",
  "pix.expiration.date": "Nuvemshop register link expires in ",
  "pix.expiration.minutes": " minutes ",
  "pix.expiration.minutes.and": " and ",
  "pix.expiration.seconds": " seconds",
  "pix.expiration.date.expired": "Link de cadastro Nuvemshop expirado.",
  "pix.expiration.date.expired.2":
    "Por favor desinstale e instale novamente o aplicativo da Primefy na sua conta nuvemshop para tentar novamente.",
};

export default en;
