import axios from 'axios';

interface UsernameProps {
  username: string;
}

interface PasswordProp {
  password: string;
  token: string | null;
}

class RedefinePassword {

  async getLink(username: UsernameProps) {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/authentication/password/recovery`, username);
  }

  async getEmail(token: string | null) {
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/authentication/password/recovery`, {
      headers: {
          'Authorization': `Bearer ${token}`
      }
  });
  }

  async updatePass({password, token}: PasswordProp) {
    return axios.put(`${process.env.REACT_APP_API_BASE_URL}/authentication/password/recovery`, {
      password: password
    }, {
      headers: {
          'Authorization': `Bearer ${token}`
      }
  });
  }
}

export default new RedefinePassword();
