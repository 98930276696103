import Resource from "./Resource";
import en from "./en";
import cn from "./cn";
import pt from "./pt";

const resources = new Resource();

resources.messages.push(en);
resources.messages.push(cn);
resources.messages.push(pt);

export default resources;
