import { useState, useEffect } from "react";
import moment from "moment";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

//Services
import CustomerServices from "services/Customers";

interface ICustomer {
  id: number;
  customer: string;
  email: string;
  totalSpent: number;
  sales: number;
  lastPayment: string;
  createdOn: string;
  identificationNumber: string;
  storeId: number;
}

export const useCustomer = () => {
  const [isCustomersLoaded, setIsCustomersLoaded] = useState<boolean>(false);
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [numberTotalOfCustomers, setNumberTotalOfCustomers] = useState(1);
  const [isSearch, setIsSearch] = useState(false);
  const [startShowOrders, setStartShowOrders] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageIndexProcessing, setPageIndexProcessing] = useState(false);
  const [isWaitingDownload, setIsWaitingDownload] = useState(false);
  const [showNotFound, setShowNotFound] = useState(false);
  const [percentageDownload, setPercentageDownload] = useState(0);

  const [searchedCustomer, setSearchedCustomer] = useState({
    type: "email",
    value: "",
  });

  useEffect(() => {
    getCustomers();
  }, [pageIndex]);

  useEffect(() => {
    if (percentageDownload === 100) {
      setTimeout(() => {
        setIsWaitingDownload(false);

        setPercentageDownload(0);
      }, 1000);
    }
  }, [percentageDownload]);

  function getCustomers() {
    setPageIndexProcessing(true);
    setIsCustomersLoaded(false);

    CustomerServices.listAllCustomers({
      pageSize: 100,
      pageIndex: pageIndex,
      query:
        searchedCustomer.value !== ""
          ? `&${searchedCustomer.type}=${searchedCustomer.value}`
          : "",
    }).then((response) => {
      let allCustomers: ICustomer[] = [];

      response.data.customers.forEach((customer: ICustomer) => {
        let timeZoneOffset = new Date(customer.createdOn).getTimezoneOffset();
        let timeZoneOffsetPositive =
          new Date(customer.createdOn).getTimezoneOffset() * -1;

        let formatDate =
          timeZoneOffset > 0
            ? new Date(
                new Date(customer.createdOn).getTime() -
                  timeZoneOffset * 60 * 1000
              )
            : new Date(
                new Date(customer.createdOn).getTime() +
                  timeZoneOffsetPositive * 60 * 1000
              );
        let dateFormattedTimeZone = moment(formatDate).format();

        const newCustomer = {
          ...customer,
          createdOn: format(new Date(dateFormattedTimeZone), "P, HH:mm", {
            locale: navigator.language === "pt-BR" ? ptBR : undefined,
          }),
        };

        allCustomers.push(newCustomer);
      });

      setCustomers(allCustomers);

      setPageIndexProcessing(false);
      setNumberTotalOfCustomers(response.data.total);
      setIsCustomersLoaded(true);
    });
  }

  function handleSearchedCustomer(name: string, value: string) {
    setSearchedCustomer((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  return {
    isSearch,
    searchedCustomer,
    isWaitingDownload,
    percentageDownload,
    showNotFound,
    customers,
    isCustomersLoaded,
    pageIndex,
    setPageIndex,
    startShowOrders,
    pageIndexProcessing,
    numberTotalOfCustomers,
    handleSearchedCustomer,
    getCustomers,
  };
};
