import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams, useHistory, useLocation } from "react-router-dom";

//Services
import OrderService from "../../../services/Order";
import StoreService from "../../../services/Store";

//Components
import DetailItem from "../DetailItem";
import DetailItemWithForeignAmount from "../DetailItemWithForeignAmount";
import {
  Button,
  InputValueCurrency,
  UnfilledButtonWithoutIcon,
} from "../../Layout/Index";

import Input from "components/Forms/InputFloatLabel";
import Select from "components/Forms/Select";
import Buttons from "components/Forms/Buttons";
import { ProductItem } from "../ProductItem";

import CancelBoletoButtonModal from "./components/CancelBoletoModal";
import CancelOrderModalButtonModal from "./components/CancelOrderModal";

//Assets
import alertFraudIcon from "assets/images/alert-octagon.svg";
import acceptScoreIcon from "assets/images/button-add.png";
import refreshIcon from "assets/images/icon-refresh-cw.svg";
import visaIcon from "assets/images/cards/icon-card-visa.svg";
import masterIcon from "assets/images/cards/icon-card-mastercard.svg";
import maestroIcon from "assets/images/cards/icon-card-maestro.svg";
import auraIcon from "assets/images/cards/icon-card-aura.svg";
import hiperIcon from "assets/images/cards/icon-card-hipercard.svg";
import jcbIcon from "assets/images/cards/icon-card-jcb.svg";
import americanExpressIcon from "assets/images/cards/icon-card-americanexpress.svg";
import dinersIcon from "assets/images/cards/icon-card-dinersclub.svg";
import eloIcon from "assets/images/cards/icon-card-elo.svg";
import discoverIcon from "assets/images/cards/icon-card-discover.svg";
import nonecardIcon from "assets/images/cards/icon-card.svg";

import filledArrow from "assets/images/global/filled-arrow.svg";

// Hooks
import { useBoxDetailsHook } from "./hooks";

import * as styled from "../details-styles";

// Types
import {
  IRefund,
  INewRefund,
  IOrder,
  IBoletoInstallments,
  IBoletoReceivables,
  IOrderNumberReceivables,
  IDispute,
  TTax,
} from "./types";

const BoxDetails = () => {
  const history = useHistory();
  let location = useLocation();

  const [canceledOrder, setCanceledOrder] = useState<boolean>(false);
  const { handleOpenBoletoToCancel, openBoletos, runAfterCancelBoleto } =
    useBoxDetailsHook({ loadBoletoInstallments, setCanceledOrder });

  const [isAdmin, setIsAdmin] = useState(false);
  const [isCnpj, setIsCnpj] = useState(false);
  const [storeId, setStoreId] = useState(0);

  let { number, id } = useParams<any>();
  let intl = useIntl();

  const [tax, setTax] = useState<TTax>();
  const [order, setOrder] = useState<IOrder>();
  const [Disputed, setDisputed] = useState<IDispute>();
  const [Disputed2, setDisputed2] = useState<IDispute>();
  const [newRefund, setNewRefund] = useState<INewRefund>({
    amount: undefined,
    percentage: undefined,
  });
  const [hasAddress, setHasAddress] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [processingChargeNow, setProcessingChargeNow] = useState(false);
  const [processingAllowPayment, setProcessingAllowPayment] = useState(false);
  const [processingCancelSubscription, setProcessingCancelSubscription] =
    useState(false);
  const [processingCheckPixPayment, setProcessingCheckPixPayment] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentId, setPaymentId] = useState(0);
  const [fraudScore, setFraudScore] = useState(0);
  const [orderNumberByUrl, setOrderNumberByUrl] = useState<string | undefined>(
    ""
  );
  const [recurringDays, setRecurringDays] = useState("");
  const [subscriptionCanceled, setSubscriptionCanceled] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(0);
  const [planId, setPlanId] = useState(0);
  const [orderStatus, setOrderStatus] = useState("");
  const [boletoReceivables, setBoletoReceivables] = useState<
    IBoletoReceivables[]
  >([]);
  const [orderNumberReceivables, setOrderNumberReceivables] = useState<
    IOrderNumberReceivables[]
  >([]);
  const [currentlyInstallment, setCurrentlyInstallment] =
    useState<IBoletoInstallments>({ installment: 0 });

  const [cardNetwork, setCardNetwork] = useState(nonecardIcon);
  const [cardNetwork2, setCardNetwork2] = useState(nonecardIcon);

  const [accessLevel, setAccessLevel] = useState<number | undefined>(undefined);
  const [isForeigner, setIsForeigner] = useState(false);
  const [isLoadingOrderReceivables, setIsLoadingOrderReceivables] =
    useState(false);
  const [currencyStore, setCurrencyStore] = useState(() => {
    const premepayStore = localStorage.getItem("@PrimefyStore");
    const { currency } = JSON.parse(premepayStore ? premepayStore : "{}");

    return currency;
  });
  const [canRefundPix, setCanRefundPix] = useState(false);

  const [hasSplit, setHasSplit] = useState(false);
  const [paidBoletoReceivables, setPaidBoletoReceivables] = useState<
    IBoletoReceivables[]
  >([]);
  const [showCancelBoletoOrder, setShowCancelBoletoOrder] =
    useState<boolean>(false);

  const latestChat = useRef<any>(null);

  const [paymentIdToRefund, setPaymentIdToRefund] = useState("");
  const [isMultiplePaymentsOrderStatus, setIsMultiplePaymentsOrderStatus] =
    useState("");

  useEffect(() => {
    if (/admin/.test(location.pathname)) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  const countryCurrencyArray = ["BRL", "GBP", "USD"];

  useEffect(() => {
    StoreService.get().then((response) => {
      setIsForeigner(response.data.isForeigner);
    });
  }, []);

  const handleRefundAmount = (value: number | undefined) => {
    setNewRefund((prevState) => {
      let percentage;
      if (order !== undefined && value !== undefined) {
        percentage = (value / order.payment.amount) * 100;
      }
      return {
        ...prevState,
        amount: value,
        percentage: percentage,
      };
    });
  };

  const handleRefundReference = (value: string) => {
    setNewRefund((prevState) => {
      return {
        ...prevState,
        reference: value,
      };
    });
  };

  const submitRefund = (event: React.SyntheticEvent) => {
    event.preventDefault();

    setSuccessMessage("");
    setErrorMessage("");

    setProcessing(true);

    let storeIdToRefund = order!.storeId;

    if (order !== undefined && newRefund.amount !== undefined) {
      if (order.payment.type === "Pix") {
        if (newRefund.amount < order.payment.amount) {
          setErrorMessage(
            `${intl.formatMessage({ id: "reports.refunds.pix.error" })}`
          );
          setProcessing(false);
        } else {
          OrderService.sendRefund({
            paymentId: order.payment.id,
            amount: newRefund.amount,
            storeId: storeId,
          })
            .then(() => {
              setSuccessMessage(
                `${intl.formatMessage({
                  id: "transactions.refund.success.message",
                })}`
              );
              setProcessing(false);
            })
            .catch(() => {
              setErrorMessage(
                `${intl.formatMessage({
                  id: "transactions.refund.error.message",
                })}`
              );
              setProcessing(false);
            });
        }
      } else {
        if (order.isMultiplePayments) {
          OrderService.sendRefund({
            paymentId: parseInt(paymentIdToRefund),
            amount: newRefund.amount,
            storeId: storeId,
          })
            .then(() => {
              setSuccessMessage(
                `${intl.formatMessage({
                  id: "transactions.refund.success.message",
                })}`
              );
              setProcessing(false);
            })
            .catch(() => {
              setErrorMessage(
                `${intl.formatMessage({
                  id: "transactions.refund.error.message",
                })}`
              );
              setProcessing(false);
            });
        } else {
          OrderService.sendRefund({
            paymentId: order.payment.id,
            amount: newRefund.amount,
            storeId: storeId,
          })
            .then(() => {
              setSuccessMessage(
                `${intl.formatMessage({
                  id: "transactions.refund.success.message",
                })}`
              );
              setProcessing(false);
            })
            .catch(() => {
              setErrorMessage(
                `${intl.formatMessage({
                  id: "transactions.refund.error.message",
                })}`
              );
              setProcessing(false);
            });
        }
      }
    }
  };

  useEffect(() => {
    if (!order || order.payment.type !== "Boleto") return;

    const hasPendingReceivables = boletoReceivables.some(
      (boleto) => boleto.status === "Pending"
    );
    const isPendingOrder = order.payment.status === "Pending";

    if (hasPendingReceivables || isPendingOrder) setShowCancelBoletoOrder(true);
  }, [order, boletoReceivables]);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    
    switch (session.role) {
      case "Business.Owner":
      case "Store.Owner":
        setAccessLevel(0);
        break;
      case "Business.Administrator":
      case "Store.Administrator":
        setAccessLevel(1);
        break;
      case "Business.Agent":
      case "Store.Agent":
        setAccessLevel(2);
        break;
      case "Business.ViewOnly":
      case "Store.ViewOnly":
        setAccessLevel(3);
        break;
      case "Business.API":
      case "Store.API":
        setAccessLevel(4);
        break;
      default:
        setAccessLevel(undefined);
    }

    setOrderNumberByUrl(number);

    OrderService.getByNumber(number, id)
      .then((response) => {
        setStoreId(response.data.storeId);

        if (response.data.isMultiplePayments) {
          if (response.data.payments[0].Disputed) {
            setDisputed({
              createdOn: response.data.payments[0].Disputed.createdOn,
              endedOn: response.data.payments[0].Disputed.endedOn
                ? response.data.payments[0].Disputed.endedOn
                : "",
              status: response.data.payments[0].Disputed.status,
            });
          }

          if (
            response.data.isMultiplePayments &&
            response.data.payments[1].Disputed
          ) {
            setDisputed2({
              createdOn: response.data.payments[1].Disputed.createdOn,
              endedOn: response.data.payments[1].Disputed.endedOn
                ? response.data.payments[1].Disputed.endedOn
                : "",
              status: response.data.payments[1].Disputed.status,
            });
          }
        } else {
          if (response.data.payment.Disputed) {
            setDisputed({
              createdOn: response.data.payment.Disputed.createdOn,
              endedOn: response.data.payment.Disputed.endedOn
                ? response.data.payment.Disputed.endedOn
                : "",
              status: response.data.payment.Disputed.status,
            });
          }
        }

        if (response.data.payment) {
          if (response.data.payment.fraudScore) {
            setFraudScore(response.data.payment.fraudScore.score);
          }
        }

        let refunds: IRefund[] = [];
        let refunds2: IRefund[] = [];

        OrderService.getRefunds(
          response.data.payment.id,
          response.data.storeId
        ).then((responseRefunds) => {
          responseRefunds.data.map((value: any) => {
            let timeZoneOffsetRefund = new Date(
              value.createdOn
            ).getTimezoneOffset();
            let timeZoneOffsetPositiveRefund =
              new Date(value.createdOn).getTimezoneOffset() * -1;

            let formatDateRefund =
              timeZoneOffsetRefund > 0
                ? new Date(
                    new Date(value.createdOn).getTime() -
                      timeZoneOffsetRefund * 60 * 1000
                  )
                : new Date(
                    new Date(value.createdOn).getTime() +
                      timeZoneOffsetPositiveRefund * 60 * 1000
                  );
            let dateFormattedTimeZoneRefund = moment(formatDateRefund).format();

            refunds.unshift({
              amount: value.amount,
              createdOn: dateFormattedTimeZoneRefund,
            });
          });

          if (response.data.isMultiplePayments) {
            OrderService.getRefunds(
              response.data.payments[1].id,
              response.data.storeId
            ).then((responseRefunds2) => {
              responseRefunds2.data.map((value: any) => {
                let timeZoneOffsetRefund = new Date(
                  value.createdOn
                ).getTimezoneOffset();
                let timeZoneOffsetPositiveRefund =
                  new Date(value.createdOn).getTimezoneOffset() * -1;

                let formatDateRefund =
                  timeZoneOffsetRefund > 0
                    ? new Date(
                        new Date(value.createdOn).getTime() -
                          timeZoneOffsetRefund * 60 * 1000
                      )
                    : new Date(
                        new Date(value.createdOn).getTime() +
                          timeZoneOffsetPositiveRefund * 60 * 1000
                      );
                let dateFormattedTimeZoneRefund =
                  moment(formatDateRefund).format();

                refunds2.unshift({
                  amount: value.amount,
                  createdOn: dateFormattedTimeZoneRefund,
                });
              });

              setOrder((prevState: any) => {
                return {
                  ...prevState,
                  payment2: {
                    ...prevState.payment2,
                    refunds: refunds2,
                  },
                };
              });
            });
          }

          const timeZoneOffset = new Date(
            response.data.createdOn
          ).getTimezoneOffset();
          const timeZoneOffsetPositive =
            new Date(response.data.createdOn).getTimezoneOffset() * -1;

          const formatDate =
            timeZoneOffset > 0
              ? new Date(
                  new Date(response.data.createdOn).getTime() -
                    timeZoneOffset * 60 * 1000
                )
              : new Date(
                  new Date(response.data.createdOn).getTime() +
                    timeZoneOffsetPositive * 60 * 1000
                );
          const dateFormattedTimeZone = moment(formatDate).format();

          let timeZoneOffsetReceived = 0;
          let timeZoneOffsetReceivedPositive = 0;

          let formatDateReceived: any = "";
          let dateFormattedTimeZoneReceived = "";

          if (response.data.payment.receivedOn) {
            timeZoneOffsetReceived = new Date(
              response.data.payment.receivedOn
            ).getTimezoneOffset();
            timeZoneOffsetReceivedPositive =
              new Date(response.data.payment.receivedOn).getTimezoneOffset() *
              -1;

            formatDateReceived =
              timeZoneOffsetReceived > 0
                ? new Date(
                    new Date(response.data.payment.receivedOn).getTime() -
                      timeZoneOffsetReceived * 60 * 1000
                  )
                : new Date(
                    new Date(response.data.payment.receivedOn).getTime() +
                      timeZoneOffsetReceivedPositive * 60 * 1000
                  );
            dateFormattedTimeZoneReceived = moment(formatDateReceived).format();
          }

          //pix expiration date
          let timeZoneOffsetPixExpirationDate = 0;
          let timeZoneOffsetPixExpirationDatePositive = 0;

          let formatDatePixExpirationDate: any = "";
          let dateFormattedTimeZonePixExpirationDate = "";

          if (
            response.data.payment.pix &&
            response.data.payment.pix.expirationDate
          ) {
            timeZoneOffsetPixExpirationDate = new Date(
              response.data.payment.pix.expirationDate
            ).getTimezoneOffset();
            timeZoneOffsetPixExpirationDatePositive =
              new Date(
                response.data.payment.pix.expirationDate
              ).getTimezoneOffset() * -1;

            formatDatePixExpirationDate =
              timeZoneOffsetPixExpirationDate > 0
                ? new Date(
                    new Date(
                      response.data.payment.pix.expirationDate
                    ).getTime() -
                      timeZoneOffsetPixExpirationDate * 60 * 1000
                  )
                : new Date(
                    new Date(
                      response.data.payment.pix.expirationDate
                    ).getTime() +
                      timeZoneOffsetPixExpirationDatePositive * 60 * 1000
                  );
            dateFormattedTimeZonePixExpirationDate = moment(
              formatDatePixExpirationDate
            ).format();
          }

          let paidBoletosGlobal: IBoletoReceivables[] = [];

          if (
            response.data.payment.installments > 1 &&
            response.data.payment.type === "Boleto"
          ) {
            paidBoletosGlobal = loadBoletoInstallments();
          }

          let identificationNumberFormatted = "";

          if (response.data.customer.identificationNumber) {
            if (response.data.customer.identificationNumber.length > 11) {
              identificationNumberFormatted =
                response.data.customer.identificationNumber
                  .replace(/\D/g, "")
                  .replace(
                    /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
                    "$1.$2.$3/$4-$5"
                  );

              setIsCnpj(true);
            } else {
              identificationNumberFormatted =
                response.data.customer.identificationNumber
                  .replace(/\D/g, "")
                  .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

              setIsCnpj(false);
            }
          }

          if (response.data.isMultiplePayments) {
            if (
              response.data.payments[0] === "Disputed" ||
              response.data.payments[1] === "Disputed"
            ) {
              setIsMultiplePaymentsOrderStatus("Disputed");
            } else if (
              response.data.payments[0] === "ChargedBack" ||
              response.data.payments[1] === "ChargedBack"
            ) {
              setIsMultiplePaymentsOrderStatus("ChargedBack");
            } else if (
              (response.data.payments[0] === "ChargedBack" ||
                response.data.payments[0] === "Disputed") &&
              (response.data.payments[1] === "ChargedBack" ||
                response.data.payments[1] === "Disputed")
            ) {
              setIsMultiplePaymentsOrderStatus("Disputed");
            } else {
              setIsMultiplePaymentsOrderStatus(
                response.data.payments[0].status
              );
            }
          }

          if (response.data.payment.card) {
            switch (response.data.payment.card.cardBrand) {
              case "Visa":
                setCardNetwork(visaIcon);
                break;
              case "Mastercard":
                setCardNetwork(masterIcon);
                break;
              case "Elo":
                setCardNetwork(eloIcon);
                break;
              case "Amex":
                setCardNetwork(americanExpressIcon);
                break;
              case "JCB":
                setCardNetwork(jcbIcon);
                break;
              case "Hipercard":
                setCardNetwork(hiperIcon);
                break;
              case "Aura":
                setCardNetwork(auraIcon);
                break;
              case "Discover":
                setCardNetwork(discoverIcon);
                break;
              default:
                setCardNetwork(nonecardIcon);
                break;
            }
          }

          if (
            response.data.isMultiplePayments &&
            response.data.payments[1].card
          ) {
            switch (response.data.payments[1].card.cardBrand) {
              case "Visa":
                setCardNetwork2(visaIcon);
                break;
              case "Mastercard":
                setCardNetwork2(masterIcon);
                break;
              case "Elo":
                setCardNetwork2(eloIcon);
                break;
              case "Amex":
                setCardNetwork2(americanExpressIcon);
                break;
              case "JCB":
                setCardNetwork2(jcbIcon);
                break;
              case "Hipercard":
                setCardNetwork2(hiperIcon);
                break;
              case "Aura":
                setCardNetwork2(auraIcon);
                break;
              case "Discover":
                setCardNetwork2(discoverIcon);
                break;
              default:
                setCardNetwork2(nonecardIcon);
                break;
            }
          }

          response.data.split ? setHasSplit(true) : setHasSplit(false);

          let finalNetAmount = 0;
          let totalAdditionalFee = 0;
          let othersAmount = 0;
          let maxValue = 0;
          let minValue = 0;

          if (
            response.data.splits &&
            response.data.splits.length >= 1 &&
            response.data.splits[0].saleCommission
          ) {
            totalAdditionalFee = response.data.splits.reduce(
              (accumulator: number, split: any) => {
                const finalAmount = split.saleCommission
                  ? split.saleCommission.amount
                  : 0;

                return accumulator + finalAmount;
              },
              0
            );
          }

          maxValue = Math.max(response.data.netAmount, totalAdditionalFee);
          minValue = Math.min(response.data.netAmount, totalAdditionalFee);
          
          setOrder({
            id: response.data.id,
            paymentLink: response.data.paymentLink,
            number: response.data.number,
            description: response.data.description ? response.data.description : "",
            status: response.data.status,
            netAmount:
              response.data.payment.status === "Canceled" ||
              response.data.payment.status === "Failed"
                ? 0
                : maxValue - minValue,
                amount: response.data.amount,
            othersAmount: 0,
            fee:
              response.data.payment.status === "Canceled" ||
              response.data.payment.status === "Failed"
                ? 0
                : response.data.fee,
            additionalFee:
              response.data.payment.status === "Canceled" ||
              response.data.payment.status === "Failed"
                ? 0
                : totalAdditionalFee,
            createdOn: dateFormattedTimeZone,
            receivedOn:
              dateFormattedTimeZoneReceived === ""
                ? undefined
                : dateFormattedTimeZoneReceived,
            customer: {
              firstName: response.data.customer.firstName,
              surname: response.data.customer.surname,
              cpf: identificationNumberFormatted,
              birthdate: response.data.customer.birthdate,
              email: response.data.customer.email,
              phone: response.data.customer.phone,
              address: response.data.customer.address
                ? {
                    zipCode: response.data.customer.address.zipCode,
                    street: response.data.customer.address.street,
                    number: response.data.customer.address.number,
                    reference: response.data.customer.address.reference,
                    district: response.data.customer.address.district,
                    city: response.data.customer.address.city,
                    state: response.data.customer.address.state,
                    country: response.data.customer.address.country,
                  }
                : undefined,
            },
            payment: {
              id: response.data.payment.id,
              status: response.data.payment.status,
              number: response.data.payment.number,
              type: response.data.payment.type,
              installments: response.data.payment.installments,
              amount: response.data.payment.amount,
              card: response.data.payment.card
                ? {
                    cardBrand: response.data.payment.card.cardBrand,
                    holderName: response.data.payment.card.holderName,
                    lastDigits: response.data.payment.card.lastDigits,
                    firstDigits: response.data.payment.card.firstDigits,
                    expirationMonth: response.data.payment.card.expirationMonth,
                    expirationYear: response.data.payment.card.expirationYear,
                  }
                : undefined,
              receivedOn: response.data.payment.receivedOn,
              barcode: response.data.payment.barcode,
              boletoUrl: response.data.payment.boletoUrl,
              dueDate: response.data.payment.dueDate,
              refunds: refunds,
            },
            payment2: response.data.isMultiplePayments
              ? {
                  id: response.data.payments[1].id,
                  status: response.data.payments[1].status,
                  number: response.data.payments[1].number,
                  type: response.data.payments[1].type,
                  installments: response.data.payments[1].installments,
                  amount: response.data.payments[1].amount,
                  card: {
                    cardBrand: response.data.payments[1].card.cardBrand,
                    holderName: response.data.payments[1].card.holderName,
                    lastDigits: response.data.payments[1].card.lastDigits,
                    firstDigits: response.data.payments[1].card.firstDigits,
                    expirationMonth:
                      response.data.payments[1].card.expirationMonth,
                    expirationYear:
                      response.data.payments[1].card.expirationYear,
                  },
                  receivedOn: response.data.payment.receivedOn,
                  refunds: refunds2,
                }
              : undefined,
            isMultiplePayments: response.data.isMultiplePayments,
            storeId: response.data.storeId,
            storeName: response.data.storeName,
            subscription: response.data.subscription
              ? response.data.subscription
              : undefined,
            userTracking: response.data.userTracking
              ? {
                  browser: response.data.userTracking.browser,
                  device: response.data.userTracking.device,
                  ip: response.data.userTracking.ip,
                  operatingSystem: response.data.userTracking.operatingSystem,
                }
              : undefined,
            fxRate: response.data.fxPayment
              ? response.data.fxPayment.exchangeRate
              : 0,
            iof: response.data.fxPayment ? response.data.fxPayment.iof : 0,
            errorCode: response.data.errorCode,
            currency: response.data.currency ? response.data.currency : "BRL",
            pix: response.data.payment.pix
              ? {
                  qrCode: response.data.payment.pix.url,
                  qrCodeToCopyPast: response.data.payment.pix.copyPaste,
                  expirationDate:
                    dateFormattedTimeZonePixExpirationDate === ""
                      ? undefined
                      : dateFormattedTimeZonePixExpirationDate,
                }
              : undefined,
            products:
              response.data.products && response.data.products.length > 0
                ? response.data.products
                : undefined,
          });

          if (!response.data.currency || response.data.currency === "BRL") {
            setIsLoadingOrderReceivables(true);

            OrderService.getOrderNumberReceivables(
              number,
              response.data.storeId
            )
              .then((responseReceivables) => {
                let amountFromReceivables =
                  responseReceivables.data.items.reduce(
                    (accumulator: number, receivable: any) => {
                      return accumulator + receivable.amount;
                    },
                    0
                  );

                finalNetAmount = amountFromReceivables;

                let finalOthersAmount = 0;

                if (
                  response.data.payment.installments > 1 &&
                  response.data.payment.type === "Boleto"
                ) {
                  let amountFromPaids = paidBoletosGlobal.reduce(
                    (accumulator: number, paid: any) => {
                      return accumulator + paid.amount;
                    },
                    0
                  );

                  finalOthersAmount =
                    amountFromPaids - response.data.fee - finalNetAmount;
                } else {
                  finalOthersAmount = response.data.netAmount - finalNetAmount;
                }

                setOrder((prevState: any) => {
                  return {
                    ...prevState,
                    netAmount:
                      response.data.payment.status === "Canceled" ||
                      response.data.payment.status === "Failed"
                        ? 0
                        : finalNetAmount,
                    othersAmount: finalOthersAmount,
                  };
                });

                setOrderNumberReceivables(responseReceivables.data.items);
                setIsLoadingOrderReceivables(false);
              })
              .catch((err) => {
                console.log(err);
                setIsLoadingOrderReceivables(false);
              });
          }

          if (response.data.subscription) {
            if (response.data.subscription.tax) {
              let newTax = {
                amount: response.data.subscription.tax.amount,
                mode: response.data.subscription.tax.mode,
              };

              setTax(newTax);
            }
          } else {
            if (response.data.tax) {
              let newTax = {
                amount: response.data.tax.amount,
                mode: response.data.tax.mode,
              };

              setTax(newTax);
            }
          }

          if (response.data.payment.pix) {
            const isToday =
              moment(0, "HH").diff(
                dateFormattedTimeZonePixExpirationDate,
                "days"
              ) === 0;

            setCanRefundPix(isToday);
          }

          if (response.data.subscription) {
            setSubscriptionId(response.data.subscription.id);

            switch (response.data.subscription.planFrequency) {
              case "Daily":
                setRecurringDays("detail.order.cancel.subscription.day");
                break;
              case "Weekly":
                setRecurringDays("detail.order.cancel.subscription.week");
                break;
              case "Monthly":
                setRecurringDays("detail.order.cancel.subscription.month");
                break;
              case "Quarterly":
                setRecurringDays("detail.order.cancel.subscription.quarterly");
                break;
              case "HalfYearly":
                setRecurringDays("detail.order.cancel.subscription.halfYearly");
                break;
              case "Yearly":
                setRecurringDays("detail.order.cancel.subscription.yearly");
                break;
            }
            setPlanId(response.data.subscription.planId);
            setOrderStatus(response.data.subscription.status);
          }

          setPaymentId(response.data.payment.id);

          response.data.customer.address !== null
            ? setHasAddress(true)
            : setHasAddress(false);
        });
      })
      .catch((err) => {
        if (err) {
          history.push("/");
          console.log(err);
        }
      });
  }, [history, number, canceledOrder]);

  function loadBoletoInstallments() {
    let paidBoletosGlobal: IBoletoReceivables[] = [];

    OrderService.getBoletoInstallments(number, id).then((response) => {
      setBoletoReceivables(response.data.reverse());

      const paidBoletos = response.data.filter((boleto: IBoletoReceivables) => {
        if (boleto.status === "Paid") {
          return boleto;
        }
      });

      paidBoletosGlobal = paidBoletos.reverse();
      setPaidBoletoReceivables(paidBoletos.reverse());

      setCurrentlyInstallment(
        response.data.filter((item: any) => item.orderNumber === number)[0]
          .installment
      );
    });

    return paidBoletosGlobal;
  }

  function submitCancelSubscription() {
    setProcessingCancelSubscription(true);

    OrderService.cancelSubscription(planId, subscriptionId, storeId)
      .then((response) => {
        setSubscriptionCanceled(true);
        setProcessingCancelSubscription(false);
      })
      .catch((err) => {
        console.log(err);
        setProcessingCancelSubscription(false);
      });
  }

  function allowTokenizedPayments() {
    setProcessingAllowPayment(true);
    OrderService.allowTokenizedOrder(orderNumberByUrl)
      .then((response) => {
        setProcessingAllowPayment(false);
      })
      .catch((err) => {
        console.log(err);
        setProcessingAllowPayment(false);
      });
  }

  function handleViewBoleto(urlToViewBoleto: string | undefined) {
    window.open(`${urlToViewBoleto}`, "_blank");
  }

  function handleViewQrCode(urlToViewQrCode: string | undefined) {
    window.open(`${urlToViewQrCode}`, "_blank");
  }

  function checkPixPaymentStatus() {
    setProcessingCheckPixPayment(true);

    if (order) {
      OrderService.verifyPixPayment(order.number)
        .then((response: any) => {
          setProcessingCheckPixPayment(false);
        })
        .catch((err: any) => {
          console.log(err);
          setProcessingCheckPixPayment(false);
        });
    }
  }

  function captureOrderAmount() {
    setProcessingChargeNow(true);

    if (order !== undefined) {
      OrderService.captureAnOrder(
        order.payment.type === "Boleto" && order.payment.installments > 1
          ? order.payment.amount
          : order.payment.amount,
        order.number
      )
        .then((response: any) => {
          setProcessingChargeNow(false);
        })
        .catch((err: any) => {
          console.log(err);
          setProcessingChargeNow(false);
        });
    }
  }
  
  return (
    <styled.LeftRightContainer>
      <styled.Left className="border-right">
        <styled.BoxDetails className="border-bottom">
          <styled.Header>
            <FormattedMessage id="transactions.order.details" />
          </styled.Header>

          <styled.DetailsItems>
            <DetailItem
              label={intl.formatMessage({ id: "detail.order.number" })}
              value={order !== undefined ? `#${order.number}` : ""}
              classStatus=""
              loading={order == undefined ? { width: 100 } : undefined}
            />
            {order !== undefined ? (
              (() => {
                let value = "";
                let classStatus = "";
                let switcher = "";

                // if(order.payment.type === 'Boleto' && paidBoletoReceivables.length > 0) {
                //   switcher = 'Succeded'
                // }else {
                switcher = order.isMultiplePayments
                  ? isMultiplePaymentsOrderStatus
                  : order.status;
                // }

                switch (switcher) {
                  case "Pending":
                    value = "pending";
                    classStatus = "status-pending";
                    break;
                  case "PreAuthorized":
                    value = "pre.authorized";
                    classStatus = "status-pending";
                    break;
                  case "Succeded":
                    value = "confirmed";
                    classStatus = "status-succeeded";
                    break;
                  case "Canceled":
                  case "Expired":
                    value = "canceled";
                    classStatus = "status-failed";
                    break;
                  case "Rejected":
                    value = "failed";
                    classStatus = "status-failed";
                    break;
                  case "PendingChallenge":
                    value = "pending";
                    classStatus = "status-pending";
                    break;
                  case "Failed":
                    value = "failed";
                    classStatus = "status-failed";
                    break;
                  case "New":
                    value = "new";
                    classStatus = "status-pending";
                    break;
                  case "Blocked":
                    value = "blocked";
                    classStatus = "status-failed";
                    break;
                  case "Disputed":
                    value = "disputed";
                    classStatus = "status-pending";
                    break;
                  case "ChargedBack":
                    value = "charged.back";
                    classStatus = "status-failed";
                    break;
                  case "Expiration":
                    value = "expiration";
                    classStatus = "status-failed";
                    break;
                  case "DisputeSucceeded":
                    value = "confirmed";
                    classStatus = "status-succeeded";
                    break;
                  case "PartiallySucceded":
                    value = "partially.confirmed";
                    classStatus = "status-deactivated";
                    break;
                  case "PartiallyCanceled":
                    value = "partially.canceled";
                    classStatus = "status-deactivated";
                    break;
                }
                return (
                  <DetailItem
                    label={intl.formatMessage({ id: "detail.order.status" })}
                    value={intl.formatMessage({ id: `payment.${value}` })}
                    classStatus={classStatus}
                  />
                );
              })()
            ) : (
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.status" })}
                value=""
                classStatus=""
                loading={{ width: 75 }}
              />
            )}

            {/* { (order && order.payment.status === 'Pending' && order.payment.type === 'Pix') && <styled.CheckPixPaymentStatus>
              {!processingCheckPixPayment && <Buttons mode="unfilled" label={intl.formatMessage({ id: "detail.order.verify.pix.status" })} handler={checkPixPaymentStatus} />}
              {processingCheckPixPayment && <Buttons mode="unfilled-processing" label="" handler={() => {}}/>}
            </styled.CheckPixPaymentStatus> } */}

            {order && order.paymentLink && (
              <DetailItem
                type="link"
                label={intl.formatMessage({ id: "detail.order.payment.link" })}
                value={`https://pay.primefy.com/${order.paymentLink}`}
                loading={order == undefined ? { width: 75 } : undefined}
              />
            )}

            <DetailItem
              label={intl.formatMessage({ id: "detail.order.type" })}
              value={
                order !== undefined
                  ? intl.formatMessage({
                      id: order.subscription
                        ? "detail.order.type.recurring"
                        : "detail.order.type.single",
                    })
                  : ""
              }
              loading={order == undefined ? { width: 75 } : undefined}
            />

            {orderStatus === "Active" && !subscriptionCanceled && (
              <styled.CancelSubscription>
                <div className="div-header">
                  <img src={refreshIcon} alt="refresh icon" />
                  <p>
                    <FormattedMessage id={recurringDays} />
                  </p>
                </div>

                {!processingCancelSubscription && (
                  <Buttons
                    mode="unfilled"
                    label={intl.formatMessage({
                      id: "detail.order.cancel.subscription.button",
                    })}
                    handler={submitCancelSubscription}
                  />
                )}
                {processingCancelSubscription && (
                  <Buttons
                    mode="unfilled-processing"
                    label=""
                    handler={() => {}}
                  />
                )}
              </styled.CancelSubscription>
            )}

            {(subscriptionCanceled || orderStatus === "Canceled") && (
              <styled.CanceledSubscription>
                <div className="div-header-canceled">
                  <img src={refreshIcon} alt="refresh icon" />
                  <p>
                    <FormattedMessage id="detail.order.cancel.subscription.canceled" />
                  </p>
                </div>
              </styled.CanceledSubscription>
            )}

            {order !== undefined && order.payment.status === "PreAuthorized" ? (
              <styled.ChargeNow>
                <p className="label">
                  {intl.formatMessage({ id: "order.details.pay.later" })}
                </p>

                {!processingChargeNow ? (
                  <Buttons
                    mode="filled"
                    label={intl.formatMessage({
                      id: "payment.link.pay.later.btn",
                    })}
                    handler={captureOrderAmount}
                  />
                ) : (
                  <Buttons
                    mode="unfilled-processing"
                    label=""
                    handler={() => {}}
                  />
                )}
              </styled.ChargeNow>
            ) : null}

            <DetailItem
              label={intl.formatMessage({ id: "detail.order.date.created" })}
              value={
                order !== undefined
                  ? moment(order.createdOn).format("DD/MM/YYYY - HH:mm:ss")
                  : ""
              }
              loading={order == undefined ? { width: 125 } : undefined}
            />

            {(() => {
              return order !== undefined ? (
                (() => {
                  if (
                    order.payment.type === "Credit" &&
                    order.payment.status !== "Blocked" &&
                    order.payment.status !== "Pending"
                  )
                    return (
                      <DetailItem
                        label={intl.formatMessage({
                          id: "detail.order.date.received",
                        })}
                        value={
                          order !== undefined
                            ? moment(order.createdOn).format(
                                "DD/MM/YYYY - HH:mm:ss"
                              )
                            : ""
                        }
                      />
                    );
                  else if (
                    order.payment.type === "Pix" &&
                    order.payment.status === "Succeded"
                  )
                    return (
                      <DetailItem
                        label={intl.formatMessage({
                          id: "detail.order.date.received",
                        })}
                        value={
                          order !== undefined
                            ? moment(order.receivedOn).format(
                                "DD/MM/YYYY - HH:mm:ss"
                              )
                            : ""
                        }
                      />
                    );
                  else if (order.payment.type === "Boleto") {
                    if (order.payment.status === "Pending") return <div></div>;
                    else
                      return order.receivedOn ? (
                        <DetailItem
                          label={intl.formatMessage({
                            id: "detail.order.date.received",
                          })}
                          value={
                            order !== undefined
                              ? moment(order.receivedOn).format(
                                  "DD/MM/YYYY - HH:mm:ss"
                                )
                              : ""
                          }
                        />
                      ) : (
                        <div></div>
                      );
                  }
                })()
              ) : (
                <DetailItem
                  label={intl.formatMessage({
                    id: "detail.order.date.received",
                  })}
                  value=""
                  loading={{ width: 125 }}
                  />
                  );
                })()}

              {order === undefined ? (
                <DetailItem
                    label={intl.formatMessage({
                      id: "detail.order.description",
                    })}
                    value=""  
                    loading={{ width: 125 }}
                />
                ) : (
                order && order.description && (
                    <DetailItem
                      label={intl.formatMessage({
                        id: "detail.order.description",
                      })}
                      value={order.description}
                      loading={order === undefined ? { width: 125 } : undefined}
                    />
                )
              )}

            {isAdmin && (
              <DetailItem
                label="Store:"
                value={order !== undefined ? order.storeName : ""}
                loading={order === undefined ? { width: 125 } : undefined}
              />
            )}
          </styled.DetailsItems>
        </styled.BoxDetails>

        {order && order.products && order.products.length > 0 ? (
          <styled.BoxDetails className="border-bottom">
            <styled.Header>
              {intl.formatMessage({ id: "order.details.product.title" })}
            </styled.Header>
            <styled.DetailsItems>
              <ProductItem products={order.products} />
            </styled.DetailsItems>
          </styled.BoxDetails>
        ) : null}

        <styled.BoxDetails className="border-bottom">
          <styled.Header>
            <FormattedMessage id="transactions.customer.details" />
          </styled.Header>
          <styled.DetailsItems>
            {!isCnpj && order && order.customer.firstName !== "" && (
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.name.first" })}
                value={order !== undefined ? order.customer.firstName : ""}
                loading={order === undefined ? { width: 100 } : undefined}
              />
            )}
            {isCnpj && order && order.customer.firstName !== "" && (
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.business.name" })}
                value={order !== undefined ? order.customer.firstName : ""}
                loading={order === undefined ? { width: 100 } : undefined}
              />
            )}
            {!isCnpj && order && order.customer.surname !== "" && (
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.name.surname" })}
                value={order !== undefined ? order.customer.surname : ""}
                loading={order === undefined ? { width: 100 } : undefined}
              />
            )}
            {order && order.customer.cpf !== "" && (
              <DetailItem
                label={intl.formatMessage({
                  id: `detail.order.${isCnpj ? "cnpj" : "cpf"}`,
                })}
                value={order !== undefined ? order.customer.cpf : ""}
                loading={order === undefined ? { width: 75 } : undefined}
              />
            )}
            {!isCnpj && (
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.dob" })}
                value={
                  order
                    ? order.customer.birthdate
                      ? moment(order.customer.birthdate).format("DD/MM/YYYY")
                      : ""
                    : ""
                }
                loading={order === undefined ? { width: 75 } : undefined}
              />
            )}
            {/*<DetailItem label="Gender:" value="11/2020" />*/}
            <DetailItem
              label={intl.formatMessage({ id: "detail.order.email" })}
              value={order !== undefined ? order.customer.email : ""}
              loading={order == undefined ? { width: 125 } : undefined}
            />
            <DetailItem
              label={intl.formatMessage({ id: "detail.order.phone" })}
              value={order !== undefined ? order.customer.phone : ""}
              loading={order == undefined ? { width: 75 } : undefined}
            />
            {order && order.customer.address && (
              <>
                {" "}
                <DetailItem
                  label={intl.formatMessage({ id: "detail.order.zip.code" })}
                  value={
                    order.customer.address.zipCode ? order.customer.address.zipCode.replace(
                      /(\d{2})(\d{3})(\d{3})/,
                      "$1.$2-$3"
                    ) : ""
                  }
                  loading={order === undefined ? { width: 125 } : undefined}
                />
                <DetailItem
                  label={intl.formatMessage({ id: "detail.order.street" })}
                  value={
                    order !== undefined ? order.customer.address.street : ""
                  }
                  loading={order === undefined ? { width: 125 } : undefined}
                />
                <DetailItem
                  label={intl.formatMessage({
                    id: "detail.order.house.number",
                  })}
                  value={
                    order !== undefined ? order.customer.address.number : ""
                  }
                  loading={order === undefined ? { width: 50 } : undefined}
                />
                <DetailItem
                  label={intl.formatMessage({
                    id: "detail.order.house.reference",
                  })}
                  value={
                    order !== undefined ? order.customer.address.reference : ""
                  }
                  loading={order === undefined ? { width: 125 } : undefined}
                />
                <DetailItem
                  label={intl.formatMessage({ id: "detail.order.district" })}
                  value={
                    order !== undefined ? order.customer.address.district : ""
                  }
                  loading={order === undefined ? { width: 100 } : undefined}
                />
                <DetailItem
                  label={intl.formatMessage({ id: "detail.order.city" })}
                  value={order !== undefined ? order.customer.address.city : ""}
                  loading={order === undefined ? { width: 100 } : undefined}
                />
                <DetailItem
                  label={intl.formatMessage({ id: "detail.order.state" })}
                  value={
                    order !== undefined ? order.customer.address.state : ""
                  }
                  loading={order === undefined ? { width: 100 } : undefined}
                />
                <DetailItem
                  label={intl.formatMessage({ id: "detail.order.country" })}
                  value={
                    order !== undefined ? order.customer.address.country : ""
                  }
                  loading={order === undefined ? { width: 100 } : undefined}
                />
              </>
            )}
          </styled.DetailsItems>
        </styled.BoxDetails>

        {(hasAddress || (order && order.userTracking)) && (
          <styled.BoxDetails className="border-bottom">
            <styled.Header>
              <FormattedMessage id="transactions.session" />
            </styled.Header>
            <styled.DetailsItems>
              {order !== undefined && order.userTracking && (
                <>
                  <DetailItem
                    label={intl.formatMessage({
                      id: "detail.order.ip.address",
                    })}
                    value={order !== undefined ? order.userTracking.ip : ""}
                    loading={order == undefined ? { width: 75 } : undefined}
                  />
                  <DetailItem
                    label={intl.formatMessage({ id: "detail.order.os" })}
                    value={
                      order !== undefined
                        ? order.userTracking.operatingSystem
                        : ""
                    }
                    loading={order == undefined ? { width: 75 } : undefined}
                  />
                  <DetailItem
                    label={intl.formatMessage({ id: "detail.order.browser" })}
                    value={
                      order !== undefined ? order.userTracking.browser : ""
                    }
                    loading={order == undefined ? { width: 75 } : undefined}
                  />
                  {order && order.userTracking.device && (
                    <DetailItem
                      label={intl.formatMessage({ id: "detail.order.device" })}
                      value={order.userTracking.device}
                      loading={order == undefined ? { width: 75 } : undefined}
                    />
                  )}
                </>
              )}

              {order && order.customer.address && (
                <DetailItem
                  label={intl.formatMessage({ id: "detail.order.location" })}
                  value=""
                  loading={undefined}
                  address={order.customer.address}
                  orderStatus={order.payment.status}
                />
              )}
            </styled.DetailsItems>
          </styled.BoxDetails>
        )}

        <styled.BoxDetails className="border-bottom">
          <styled.Header>
            <FormattedMessage id="transactions.order.events" />
          </styled.Header>
          <styled.DetailsItems>
            <styled.EventItems>
              {(() => {
                if (order !== undefined) {
                  return (
                    <>
                      {order.isMultiplePayments && (
                        <div className="card-title">
                          <strong>{`${intl.formatMessage({
                            id: "detail.order.events.credit.card",
                          })} ${order.payment.card!.firstDigits} **** **** ${
                            order.payment.card!.lastDigits
                          }`}</strong>
                        </div>
                      )}

                      {order.payment.refunds.map((value: IRefund) => {
                        return (
                          <styled.EventItem key={value.amount}>
                            <styled.Content>
                              <li className="description">
                                <FormattedMessage id="detail.order.events.refunded" />
                              </li>
                              <li>
                                {intl.formatNumber(value.amount, {
                                  style: "currency",
                                  currency: order.currency,
                                })}
                              </li>
                              <li>
                                {moment(value.createdOn).format(
                                  "DD/MM/YYYY - HH:mm:ss"
                                )}
                              </li>
                            </styled.Content>
                          </styled.EventItem>
                        );
                      })}

                      {Disputed && Disputed.endedOn && (
                        <styled.EventItem>
                          <styled.Content>
                            {(() => {
                              switch (Disputed.status) {
                                case "ChargedBack":
                                  return (
                                    <li className="description">
                                      <FormattedMessage id="payment.charged.back" />
                                    </li>
                                  );
                                case "DisputeSucceeded":
                                  return (
                                    <li className="description">
                                      <FormattedMessage id="payment.confirmed" />
                                    </li>
                                  );
                                default:
                                  return <li></li>;
                              }
                            })()}
                            <li>
                              {moment(Disputed.endedOn).format(
                                "DD/MM/YYYY - HH:mm:ss"
                              )}
                            </li>
                          </styled.Content>
                        </styled.EventItem>
                      )}

                      {Disputed && (
                        <styled.EventItem>
                          <styled.Content>
                            <li className="description">
                              <FormattedMessage id="payment.disputed" />
                            </li>
                            <li>
                              {moment(Disputed.createdOn).format(
                                "DD/MM/YYYY - HH:mm:ss"
                              )}
                            </li>
                          </styled.Content>
                        </styled.EventItem>
                      )}

                      {order && order.errorCode && order.payment.card && (
                        <styled.EventItem>
                          <styled.Content>
                            <li className="description">
                              <FormattedMessage id="detail.order.events.failed" />
                            </li>
                            <li>
                              <FormattedMessage
                                id={
                                  order?.errorCode &&
                                  [
                                    "elo",
                                    "visa",
                                    "mastercard",
                                    "amex",
                                  ].includes(
                                    order.payment.card.cardBrand.toLowerCase()
                                  )
                                    ? `request.payment.api${`.${order.payment.card.cardBrand.toLowerCase()}`}.error.${
                                        order.errorCode
                                      }`
                                    : `request.payment.api.error.${order.errorCode}`
                                }
                                defaultMessage={intl.formatMessage({
                                  id: "error.failed",
                                })}
                              />
                            </li>
                            <li>
                              {intl.formatNumber(order.payment.amount, {
                                style: "currency",
                                currency: order.currency,
                              })}
                            </li>
                            <li>
                              {moment(order.createdOn).format(
                                "DD/MM/YYYY - HH:mm:ss"
                              )}
                            </li>
                          </styled.Content>
                        </styled.EventItem>
                      )}

                      {(() => {
                        if (
                          order.payment.type === "Credit" &&
                          order.payment.status !== "Blocked" &&
                          order.payment.status !== "Pending" &&
                          order.payment.status !== "Failed"
                        ) {
                          return (
                            <styled.EventItem>
                              <styled.Content>
                                <li className="description">
                                  <FormattedMessage id="detail.order.events.received" />
                                </li>
                                <li>
                                  {intl.formatNumber(order.payment.amount, {
                                    style: "currency",
                                    currency: order.currency,
                                  })}
                                </li>
                                <li>
                                  {moment(order.createdOn).format(
                                    "DD/MM/YYYY - HH:mm:ss"
                                  )}
                                </li>
                              </styled.Content>
                            </styled.EventItem>
                          );
                        } else if (
                          order.payment.type === "Pix" &&
                          order.payment.status === "Succeded"
                        ) {
                          return (
                            <styled.EventItem>
                              <styled.Content>
                                <li className="description">
                                  <FormattedMessage id="detail.order.events.received" />
                                </li>
                                <li>
                                  {intl.formatNumber(order.payment.amount, {
                                    style: "currency",
                                    currency: order.currency,
                                  })}
                                </li>
                                <li>
                                  {moment(order.receivedOn).format(
                                    "DD/MM/YYYY - HH:mm:ss"
                                  )}
                                </li>
                              </styled.Content>
                            </styled.EventItem>
                          );
                        } else if (
                          order.payment.type === "Pix" &&
                          order.payment.status === "Expired" &&
                          order.pix
                        ) {
                          return (
                            <styled.EventItem>
                              <styled.Content>
                                <li className="description">
                                  <FormattedMessage id="detail.order.events.expired.pix" />
                                </li>
                                <li>
                                  {moment(order.pix.expirationDate).format(
                                    "DD/MM/YYYY - HH:mm:ss"
                                  )}
                                </li>
                              </styled.Content>
                            </styled.EventItem>
                          );
                        } else if (
                          (order.payment.type === "Boleto" &&
                            (order.payment.status === "Succeded" ||
                              order.payment.status === "DisputeSucceeded" ||
                              order.payment.status === "Disputed" ||
                              order.payment.status === "ChargedBack") &&
                            order.payment.installments <= 1) ||
                          (order.payment.barcode &&
                            order.payment.installments <= 1)
                        ) {
                          return (
                            <>
                              {order.payment.type === "Boleto" &&
                                (order.payment.status === "Succeded" ||
                                  order.payment.status === "DisputeSucceeded" ||
                                  order.payment.status === "Disputed" ||
                                  order.payment.status === "ChargedBack") && (
                                  <styled.EventItem>
                                    <styled.Content>
                                      <li className="description">
                                        <FormattedMessage id="detail.order.events.received" />
                                      </li>
                                      <li>
                                        {intl.formatNumber(
                                          order.payment.amount,
                                          {
                                            style: "currency",
                                            currency: order.currency,
                                          }
                                        )}
                                      </li>
                                      <li>
                                        {moment(
                                          order.payment.receivedOn
                                        ).format("DD/MM/YYYY - HH:mm:ss")}
                                      </li>
                                    </styled.Content>
                                  </styled.EventItem>
                                )}

                              {order.payment.barcode && (
                                <styled.EventItem className="last">
                                  <div className="c-event-item--flex">
                                    <styled.Content>
                                      <li className="description">
                                        <FormattedMessage
                                          id={
                                            order.payment.status !== "Canceled"
                                              ? "detail.order.events.request.sent"
                                              : "detail.order.events.canceled.boleto"
                                          }
                                        />
                                      </li>
                                      <li>{`${intl.formatMessage({
                                        id: "detail.order.events.boleto.n",
                                      })} | ${intl.formatNumber(
                                        order.payment.amount,
                                        {
                                          style: "currency",
                                          currency: order.currency,
                                        }
                                      )}`}</li>
                                      <li>
                                        {moment(order.createdOn).format(
                                          "DD/MM/YYYY - HH:mm:ss"
                                        )}
                                      </li>
                                    </styled.Content>

                                    <div className="flex-container--cancel-boleto">
                                      <Buttons
                                        mode="unfilled"
                                        handler={() =>
                                          handleViewBoleto(
                                            order.payment.boletoUrl
                                          )
                                        }
                                        type="submit"
                                        label={intl.formatMessage({
                                          id: "detail.order.events.button.view.boleto",
                                        })}
                                        disable={false}
                                      />
                                    </div>
                                  </div>
                                </styled.EventItem>
                              )}
                            </>
                          );
                        }
                      })()}

                      {paidBoletoReceivables.map((boleto) => {
                        let timeZoneOffsetReceived = new Date(
                          boleto.receivedOn
                        ).getTimezoneOffset();
                        let timeZoneOffsetPositiveReceived = Math.abs(
                          timeZoneOffsetReceived
                        );

                        let formatDateReceived =
                          timeZoneOffsetReceived > 0
                            ? new Date(
                                new Date(boleto.receivedOn).getTime() -
                                  timeZoneOffsetReceived * 60 * 1000
                              )
                            : new Date(
                                new Date(boleto.receivedOn).getTime() +
                                  timeZoneOffsetPositiveReceived * 60 * 1000
                              );
                        let dateFormattedTimeZoneReceived =
                          moment(formatDateReceived).format();

                        return (
                          <>
                            {
                              <styled.EventItem
                                className="last"
                                key={boleto.barcode}
                              >
                                <styled.Content>
                                  <li className="description">
                                    <FormattedMessage id="detail.order.events.received" />
                                  </li>
                                  <li>{`${intl.formatMessage({
                                    id: "detail.order.events.boleto.n",
                                  })} ${
                                    boleto.installment
                                  } ${intl.formatMessage({
                                    id: "detail.order.events.boleto.of.the",
                                  })} ${
                                    order.payment.installments
                                  } | ${intl.formatNumber(boleto.amount, {
                                    style: "currency",
                                    currency: "BRL",
                                  })}`}</li>
                                  <li>
                                    {moment(
                                      dateFormattedTimeZoneReceived
                                    ).format("DD/MM/YYYY - HH:mm:ss")}
                                  </li>
                                </styled.Content>
                              </styled.EventItem>
                            }
                          </>
                        );
                      })}

                      {order.payment.type === "Boleto" &&
                        order.payment.installments > 1 &&
                        boletoReceivables.map((boleto) => {
                          if (
                            boleto.status === "Sent" ||
                            boleto.status === "Paid" ||
                            boleto.status === "Canceled"
                          ) {
                            let timeZoneOffset = new Date(
                              boleto.createdOn
                            ).getTimezoneOffset();
                            let timeZoneOffsetPositive =
                              Math.abs(timeZoneOffset);

                            let formatDate =
                              timeZoneOffset > 0
                                ? new Date(
                                    new Date(boleto.createdOn).getTime() -
                                      timeZoneOffset * 60 * 1000
                                  )
                                : new Date(
                                    new Date(boleto.createdOn).getTime() +
                                      timeZoneOffsetPositive * 60 * 1000
                                  );
                            let dateFormattedTimeZone =
                              moment(formatDate).format();

                            let timeZoneOffsetReceived = new Date(
                              boleto.receivedOn
                            ).getTimezoneOffset();
                            let timeZoneOffsetPositiveReceived = Math.abs(
                              timeZoneOffsetReceived
                            );

                            let formatDateReceived =
                              timeZoneOffsetReceived > 0
                                ? new Date(
                                    new Date(boleto.receivedOn).getTime() -
                                      timeZoneOffsetReceived * 60 * 1000
                                  )
                                : new Date(
                                    new Date(boleto.receivedOn).getTime() +
                                      timeZoneOffsetPositiveReceived * 60 * 1000
                                  );
                            let dateFormattedTimeZoneReceived =
                              moment(formatDateReceived).format();

                            return (
                              <>
                                {boleto.status === "Paid" &&
                                  boletoReceivables.length !==
                                    order.payment.installments && (
                                    <styled.EventItem
                                      className="last"
                                      key={boleto.createdOn}
                                    >
                                      <styled.Content>
                                        <li className="description">
                                          <FormattedMessage id="detail.order.events.received" />
                                        </li>
                                        <li>{`${intl.formatMessage({
                                          id: "detail.order.events.boleto.n",
                                        })} ${
                                          boleto.installment
                                        } ${intl.formatMessage({
                                          id: "detail.order.events.boleto.of.the",
                                        })} ${
                                          order.payment.installments
                                        } | ${intl.formatNumber(boleto.amount, {
                                          style: "currency",
                                          currency: "BRL",
                                        })}`}</li>
                                        <li>
                                          {moment(
                                            dateFormattedTimeZoneReceived
                                          ).format("DD/MM/YYYY - HH:mm:ss")}
                                        </li>
                                      </styled.Content>
                                    </styled.EventItem>
                                  )}

                                <styled.EventItem
                                  className="last"
                                  key={boleto.createdOn}
                                >
                                  <div className="c-event-item--flex">
                                    <styled.Content>
                                      <li className="description">
                                        {intl.formatMessage({
                                          id:
                                            boleto.status !== "Canceled"
                                              ? "detail.order.events.request.sent"
                                              : "detail.order.events.canceled.boleto",
                                        })}
                                      </li>
                                      <li>{`${intl.formatMessage({
                                        id: "detail.order.events.boleto.n",
                                      })} ${
                                        boleto.installment
                                      } ${intl.formatMessage({
                                        id: "detail.order.events.boleto.of.the",
                                      })} ${
                                        order.payment.installments
                                      } | ${intl.formatNumber(boleto.amount, {
                                        style: "currency",
                                        currency: "BRL",
                                      })}`}</li>
                                      <li>
                                        {moment(dateFormattedTimeZone).format(
                                          "DD/MM/YYYY - HH:mm:ss"
                                        )}
                                      </li>
                                    </styled.Content>

                                    {boleto.status !== "Canceled" ? (
                                      <div className="flex-container--cancel-boleto">
                                        <Buttons
                                          mode="unfilled"
                                          handler={() =>
                                            handleViewBoleto(boleto.boletoUrl)
                                          }
                                          type="submit"
                                          label={intl.formatMessage({
                                            id: "detail.order.events.button.view.boleto",
                                          })}
                                          disable={false}
                                        />

                                        {["Sent", "Pending"].includes(
                                          boleto.status
                                        ) ? (
                                          <styled.UnfilledButtoncustom
                                            onClick={() =>
                                              handleOpenBoletoToCancel(
                                                boleto.id
                                              )
                                            }
                                          >
                                            <img
                                              src={filledArrow}
                                              alt="arrow"
                                              className={`arrow__button ${
                                                openBoletos.includes(boleto.id)
                                                  ? "isOpen"
                                                  : ""
                                              }`}
                                            />
                                          </styled.UnfilledButtoncustom>
                                        ) : null}
                                      </div>
                                    ) : null}
                                  </div>

                                  {openBoletos.includes(boleto.id) ? (
                                    <CancelBoletoButtonModal
                                      storeId={id}
                                      orderNumber={number}
                                      boleto={boleto}
                                      afterCancel={runAfterCancelBoleto}
                                    />
                                  ) : null}
                                </styled.EventItem>
                              </>
                            );
                          }
                        })}

                      <styled.EventItem className="last e-last--without-bar">
                        <div className="c-event-item--flex">
                          <styled.Content>
                            <li className="description">
                              <FormattedMessage id="detail.order.events.created" />
                            </li>
                            <li>
                              {intl.formatNumber(
                                order.payment.type === "Boleto" &&
                                  order.payment.installments > 1
                                  ? order.amount 
                                  : order.payment.amount,
                                { style: "currency", currency: order.currency }
                              )}
                            </li>
                            <li>
                              {moment(order.createdOn).format(
                                "DD/MM/YYYY - HH:mm:ss"
                              )}
                            </li>
                          </styled.Content>

                          {order.payment.type === "Pix" && (
                            <div className="c-event-item--flex">
                              <UnfilledButtonWithoutIcon
                                label="Visualizar QR Code"
                                icon=""
                                handler={() =>
                                  handleViewQrCode(
                                    order.pix ? order.pix.qrCode : ""
                                  )
                                }
                              />
                              <UnfilledButtonWithoutIcon
                                label="Copiar código"
                                icon=""
                                handler={() =>
                                  navigator.clipboard.writeText(
                                    order.pix ? order.pix.qrCodeToCopyPast : ""
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </styled.EventItem>
                    </>
                  );
                }
              })()}
            </styled.EventItems>
          </styled.DetailsItems>
        </styled.BoxDetails>
      </styled.Left>

      <styled.Right>
        <styled.BoxDetails className="border-bottom">
          <styled.Header>
            <FormattedMessage id="transactions.payment.details" />
          </styled.Header>
          <styled.DetailsItems>
            {order !== undefined ? (
              (() => {
                let value = "";
                let classStatus = "";
                let switcher = "";

                // if(order.payment.type === 'Boleto' && paidBoletoReceivables.length > 0) {
                //   switcher = 'Succeded'
                // }else {
                switcher = order.isMultiplePayments
                  ? isMultiplePaymentsOrderStatus
                  : order.payment.status;
                // }

                switch (switcher) {
                  case "Pending":
                    value = "pending";
                    classStatus = "status-pending";
                    break;
                  case "Succeded":
                    value = "confirmed";
                    classStatus = "status-succeeded";
                    break;
                  case "Canceled":
                  case "Expired":
                    value = "canceled";
                    classStatus = "status-failed";
                    break;
                  case "Expiration":
                    value = "expiration";
                    classStatus = "status-failed";
                    break;
                  case "Failed":
                    value = "failed";
                    classStatus = "status-failed";
                    break;
                  case "Blocked":
                    value = "blocked";
                    classStatus = "status-failed";
                    break;
                  case "PendingChallenge":
                    value = "pending";
                    classStatus = "status-pending";
                  break;
                  case "Disputed":
                    value = "disputed";
                    classStatus = "status-pending";
                    break;
                  case "ChargedBack":
                    value = "charged.back";
                    classStatus = "status-failed";
                    break;
                  case "DisputeSucceeded":
                    value = "confirmed";
                    classStatus = "status-succeeded";
                    break;
                  case "New":
                    value = "new";
                    classStatus = "status-pending";
                    break;
                  case "PartiallySucceded":
                    value = "partially.confirmed";
                    classStatus = "status-deactivated";
                    break;
                  case "PartiallyCanceled":
                    value = "partially.canceled";
                    classStatus = "status-deactivated";
                    break;
                }
                return (
                  <DetailItem
                    label={intl.formatMessage({ id: "detail.order.status" })}
                    value={intl.formatMessage({ id: `payment.${value}` })}
                    classStatus={classStatus}
                  />
                );
              })()
            ) : (
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.status" })}
                value=""
                classStatus=""
                loading={{ width: 75 }}
              />
            )}
            <DetailItem
              label={intl.formatMessage({
                id: "detail.order.transaction.code",
              })}
              value={order !== undefined ? order.payment.number : ""}
              loading={order == undefined ? { width: 125 } : undefined}
            />
            {/* { (fraudScore > 0) && <DetailItem label={intl.formatMessage({ id: "detail.order.risk.fraud.score" })} value={`${fraudScore}%`} loading={order == undefined ? {width: 75} : undefined}/>} */}

            {/* todo: react intl */}
            {(() => {
              return order !== undefined ? (
                (() => {
                  let paymentMethod = "";
                  if (order.payment.type === "Credit")
                    paymentMethod = order.isMultiplePayments
                      ? "Múltiplos cartões de crédito"
                      : "Cartão de crédito";
                  else if (order.payment.type === "Pix") paymentMethod = "Pix";
                  else if (order.payment.type === "Boleto")
                    paymentMethod = "Boleto";

                  return (
                    <DetailItem
                      label={intl.formatMessage({
                        id: "detail.order.payment.method",
                      })}
                      value={paymentMethod}
                    />
                  );
                })()
              ) : (
                <DetailItem
                  label={intl.formatMessage({
                    id: "detail.order.payment.method",
                  })}
                  value=""
                  loading={{ width: 75 }}
                />
              );
            })()}

            {(() => {
              return order !== undefined
                ? (() => {
                    if (order.payment.type === "Credit") {
                      return order.payment.card ? (
                        <>
                          <DetailItem
                            label={intl.formatMessage({
                              id: "detail.order.installments.quantity",
                            })}
                            value={`${order.payment.installments}x`}
                            loading={
                              order == undefined ? { width: 50 } : undefined
                            }
                          />
                          <DetailItem
                            label={intl.formatMessage({
                              id: "detail.order.card.type",
                            })}
                            imgValue={cardNetwork}
                            value="img-value"
                            loading={
                              order == undefined ? { width: 75 } : undefined
                            }
                          />
                          <DetailItem
                            label={intl.formatMessage({
                              id: "detail.order.card.holder",
                            })}
                            value={order.payment.card.holderName}
                            loading={
                              order == undefined ? { width: 100 } : undefined
                            }
                          />
                          <DetailItem
                            label={intl.formatMessage({
                              id: "detail.order.card.number",
                            })}
                            value={`${order.payment.card.firstDigits} **** **** ${order.payment.card.lastDigits}`}
                            loading={
                              order == undefined ? { width: 125 } : undefined
                            }
                          />
                          <DetailItem
                            label={intl.formatMessage({
                              id: "detail.order.expiry.date",
                            })}
                            value={`${order.payment.card.expirationMonth
                              .toString()
                              .padStart(2, "0")}/${
                              order.payment.card.expirationYear
                            }`}
                            loading={
                              order == undefined ? { width: 75 } : undefined
                            }
                          />
                        </>
                      ) : (
                        ""
                      );
                    } else if (order.payment.type === "Boleto") {
                      return (
                        <>
                          <DetailItem
                            label={intl.formatMessage({
                              id: "detail.order.installments.quantity",
                            })}
                            value={`${order.payment.installments}x`}
                            loading={
                              order == undefined ? { width: 50 } : undefined
                            }
                          />
                          {/* <DetailItem label={`${intl.formatMessage({ id: "detail.order.boleto.barcode" })} ${order.payment.installments > 1 ? `${currentlyInstallment} de ${order.payment.installments}: ` : ``}`} value={order.payment.barcode} loading={order == undefined ? {width: 125} : undefined}/> */}
                          {/* <DetailItem label={intl.formatMessage({ id: "detail.order.boleto.due.date" })} value={moment(order.payment.dueDate).format("DD/MM/YYYY")} loading={order == undefined ? {width: 75} : undefined}/> */}
                        </>
                      );
                    }
                  })()
                : "";
            })()}

            {/*
            <DetailItem label={intl.formatMessage({ id: "detail.order.installments.quantity" })} value={(order !== undefined) ? `${order.payment.installments}x` : ""} />
            <DetailItem label={intl.formatMessage({ id: "detail.order.card.type" })} value={(order !== undefined) ? order.payment.card.cardBrand : ""} />
            <DetailItem label={intl.formatMessage({ id: "detail.order.card.holder" })} value={(order !== undefined) ? order.payment.card.holderName : ""} />
            <DetailItem label={intl.formatMessage({ id: "detail.order.card.number" })} value={(order !== undefined) ? `**** **** **** ${order.payment.card.lastDigits}` : ""} />
            <DetailItem label={intl.formatMessage({ id: "detail.order.expiry.date" })} value={(order !== undefined) ? `${order.payment.card.expirationMonth.toString().padStart(2,'0')}/${order.payment.card.expirationYear}` : ""} />
            */}

            {/*<DetailItem label="GBP Amount:" value="£ 102.52" />*/}
            <DetailItem
              label={intl.formatMessage({ id: "detail.order.received.amount" })}
              value={
                order !== undefined
                  ? intl.formatNumber(
                      order.payment.type === "Boleto" &&
                        order.payment.installments > 1
                        ? order.amount
                        : order.payment.amount,
                      { style: "currency", currency: order.currency }
                    )
                  : ""
              }
              loading={order == undefined ? { width: 75 } : undefined}
            />
            {order && tax && (
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.tax" })}
                value={
                  tax
                    ? tax.mode === 3
                      ? intl.formatNumber(tax.amount, {
                          style: "currency",
                          currency: order.currency,
                        })
                      : `${tax.amount}%`
                    : ""
                }
                loading={order == undefined ? { width: 75 } : undefined}
              />
            )}

            {paidBoletoReceivables.length > 0 && (
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.already.paid" })}
                value={intl.formatNumber(
                  order!.payment.amount,  
                  { style: "currency", currency: order!.currency }
                )}
                loading={order == undefined ? { width: 75 } : undefined}
              />
            )}

            {/* IsMultiplePayment */}

            {order !== undefined && order.isMultiplePayments && (
              <>
                <hr />

                {order !== undefined ? (
                  (() => {
                    let value = "";
                    let classStatus = "";
                    switch (order.payment2!.status) {
                      case "Pending":
                        value = "pending";
                        classStatus = "status-pending";
                        break;
                      case "Succeded":
                        value = "confirmed";
                        classStatus = "status-succeeded";
                        break;
                      case "Canceled":
                      case "Expired":
                        value = "canceled";
                        classStatus = "status-failed";
                        break;
                      case "Failed":
                        value = "failed";
                        classStatus = "status-failed";
                        break;
                      case "Blocked":
                        value = "blocked";
                        classStatus = "status-failed";
                        break;
                      case "Expiration":
                        value = "expiration";
                        classStatus = "status-failed";
                        break;
                      case "Disputed":
                        value = "disputed";
                        classStatus = "status-pending";
                        break;
                      case "ChargedBack":
                        value = "charged.back";
                        classStatus = "status-failed";
                        break;
                      case "DisputeSucceeded":
                        value = "confirmed";
                        classStatus = "status-succeeded";
                        break;
                      case "New":
                        value = "new";
                        classStatus = "status-pending";
                        break;
                      case "PartiallySucceded":
                        value = "partially.confirmed";
                        classStatus = "status-deactivated";
                        break;
                      case "PartiallyCanceled":
                        value = "partially.canceled";
                        classStatus = "status-deactivated";
                        break;
                    }
                    return (
                      <DetailItem
                        label={intl.formatMessage({
                          id: "detail.order.status",
                        })}
                        value={intl.formatMessage({ id: `payment.${value}` })}
                        classStatus={classStatus}
                      />
                    );
                  })()
                ) : (
                  <DetailItem
                    label={intl.formatMessage({ id: "detail.order.status" })}
                    value=""
                    classStatus=""
                    loading={{ width: 75 }}
                  />
                )}
                <DetailItem
                  label={intl.formatMessage({
                    id: "detail.order.transaction.code",
                  })}
                  value={order !== undefined ? order.payment2!.number : ""}
                  loading={order == undefined ? { width: 125 } : undefined}
                />

                {/* todo: react intl */}
                {(() => {
                  return order !== undefined ? (
                    (() => {
                      let paymentMethod = "Múltiplos cartões de crédito";
                      // if(order.payment2!.type === 'Credit')
                      //   paymentMethod = "Cartão de crédito"
                      // else if(order.payment2!.type === 'Boleto')
                      //   paymentMethod = "Boleto"
                      return (
                        <DetailItem
                          label={intl.formatMessage({
                            id: "detail.order.payment.method",
                          })}
                          value={paymentMethod}
                        />
                      );
                    })()
                  ) : (
                    <DetailItem
                      label={intl.formatMessage({
                        id: "detail.order.payment.method",
                      })}
                      value=""
                      loading={{ width: 75 }}
                    />
                  );
                })()}

                {(() => {
                  return order !== undefined
                    ? (() => {
                        if (order.payment2!.type === "Credit") {
                          return order.payment2!.card ? (
                            <>
                              <DetailItem
                                label={intl.formatMessage({
                                  id: "detail.order.installments.quantity",
                                })}
                                value={`${order.payment2!.installments}x`}
                                loading={
                                  order == undefined ? { width: 50 } : undefined
                                }
                              />
                              <DetailItem
                                label={intl.formatMessage({
                                  id: "detail.order.card.type",
                                })}
                                imgValue={cardNetwork2}
                                value="img-value"
                                loading={
                                  order == undefined ? { width: 75 } : undefined
                                }
                              />
                              <DetailItem
                                label={intl.formatMessage({
                                  id: "detail.order.card.holder",
                                })}
                                value={order.payment2!.card.holderName}
                                loading={
                                  order == undefined
                                    ? { width: 100 }
                                    : undefined
                                }
                              />
                              <DetailItem
                                label={intl.formatMessage({
                                  id: "detail.order.card.number",
                                })}
                                value={`${
                                  order.payment2!.card.firstDigits
                                } **** **** ${order.payment2!.card.lastDigits}`}
                                loading={
                                  order == undefined
                                    ? { width: 125 }
                                    : undefined
                                }
                              />
                              <DetailItem
                                label={intl.formatMessage({
                                  id: "detail.order.expiry.date",
                                })}
                                value={`${order
                                  .payment2!.card.expirationMonth.toString()
                                  .padStart(2, "0")}/${
                                  order.payment2!.card.expirationYear
                                }`}
                                loading={
                                  order == undefined ? { width: 75 } : undefined
                                }
                              />
                            </>
                          ) : (
                            ""
                          );
                        } else if (order.payment2!.type === "Boleto") {
                          return (
                            <>
                              <DetailItem
                                label={intl.formatMessage({
                                  id: "detail.order.installments.quantity",
                                })}
                                value={`${order.payment2!.installments}x`}
                                loading={
                                  order == undefined ? { width: 50 } : undefined
                                }
                              />
                            </>
                          );
                        }
                      })()
                    : "";
                })()}

                <DetailItem
                  label={intl.formatMessage({
                    id: "detail.order.received.amount",
                  })}
                  value={
                    order !== undefined
                      ? intl.formatNumber(
                          order.payment2!.type === "Boleto" &&
                            order.payment2!.installments > 1
                            ? order.payment2!.amount 
                            : order.payment2!.amount,
                          { style: "currency", currency: order.currency }
                        )
                      : ""
                  }
                  loading={order == undefined ? { width: 75 } : undefined}
                />
              </>
            )}
          </styled.DetailsItems>
        </styled.BoxDetails>

        <styled.BoxDetails className="border-bottom">
          <styled.Header>
            <FormattedMessage id="transactions.cost" />
          </styled.Header>
          <styled.DetailsItems>
            {(() => {
              if (
                order &&
                order.payment.installments > 1 &&
                order.payment.type === "Boleto"
              ) {
                let paids = boletoReceivables.filter(
                  (boleto: IBoletoReceivables) => boleto.status === "Paid"
                );

                return (
                  <DetailItem
                    label={intl.formatMessage({ id: "detail.order.preme.fee" })}
                    value={
                      order !== undefined
                        ? intl.formatNumber(order.fee, {
                            style: "currency",
                            currency: order.currency,
                          })
                        : ""
                    }
                    classStatus=""
                    loading={order == undefined ? { width: 75 } : undefined}
                  />
                );
              } else {
                return (
                  <>
                    {isForeigner && (
                      <DetailItemWithForeignAmount
                        foreignValue={
                          order !== undefined
                            ? intl.formatNumber(order.fee / order.fxRate, {
                                style: "currency",
                                currency: currencyStore,
                              })
                            : ""
                        }
                        label={intl.formatMessage({
                          id: "detail.order.preme.fee",
                        })}
                        value={
                          order !== undefined
                            ? intl.formatNumber(order.fee, {
                                style: "currency",
                                currency: order.currency,
                              })
                            : ""
                        }
                        loading={order == undefined ? { width: 75 } : undefined}
                      />
                    )}
                    {!isForeigner && (
                      <DetailItem
                        label={intl.formatMessage({
                          id: "detail.order.preme.fee",
                        })}
                        value={
                          order !== undefined
                            ? intl.formatNumber(order.fee, {
                                style: "currency",
                                currency: order.currency,
                              })
                            : ""
                        }
                        loading={order == undefined ? { width: 75 } : undefined}
                      />
                    )}
                  </>
                );
              }
            })()}

            {isForeigner && (
              <>
                <DetailItemWithForeignAmount
                  foreignValue={
                    order !== undefined
                      ? intl.formatNumber(order.additionalFee / order.fxRate, {
                          style: "currency",
                          currency: currencyStore,
                        })
                      : ""
                  }
                  label={intl.formatMessage({
                    id: "detail.order.additional.fee",
                  })}
                  value={
                    order !== undefined
                      ? intl.formatNumber(order.additionalFee, {
                          style: "currency",
                          currency: order.currency,
                        })
                      : ""
                  }
                  loading={order == undefined ? { width: 75 } : undefined}
                />

                <DetailItemWithForeignAmount
                  label={intl.formatMessage({ id: "detail.order.net.amount" })}
                  value={
                    order !== undefined
                      ? intl.formatNumber(
                          order.isMultiplePayments
                            ? order.netAmount * 2
                            : order.netAmount,
                          { style: "currency", currency: order.currency }
                        )
                      : ""
                  }
                  foreignValue={
                    order !== undefined
                      ? intl.formatNumber(
                          (order.payment.installments > 1 &&
                          order.payment.type === "Boleto"
                            ? order.netAmount * order.payment.installments
                            : order.isMultiplePayments
                            ? order.netAmount * 2
                            : order.netAmount) / order.fxRate,
                          { style: "currency", currency: currencyStore }
                        )
                      : ""
                  }
                />

                <DetailItemWithForeignAmount
                  label={intl.formatMessage({ id: "detail.order.iof" })}
                  value={
                    order !== undefined && order.iof
                      ? intl.formatNumber(order.iof, {
                          style: "currency",
                          currency: "BRL",
                        })
                      : ""
                  }
                  foreignValue={
                    order !== undefined
                      ? intl.formatNumber(order.iof / order.fxRate, {
                          style: "currency",
                          currency: currencyStore,
                        })
                      : ""
                  }
                />
              </>
            )}

            {/* <DetailItem label={intl.formatMessage({ id: "detail.order.additional.fee" })} value={(order !== undefined) ? intl.formatNumber(order.additionalFee, {style: "currency", currency: "BRL"}) : ""} loading={order == undefined ? {width: 75} : undefined}/> */}
            {/*<DetailItem label={intl.formatMessage({ id: "detail.order.additional.fee" })} value="R$ 0,00" />*/}
            {/* <DetailItem
              label={intl.formatMessage({ id: "detail.order.net.amount" })}
              value={(order !== undefined) ? intl.formatNumber((order.payment.installments > 1 && order.payment.type === 'Boleto') ? (order.netAmount * order.payment.installments) : order.netAmount, {style: "currency", currency: "BRL"}) : ""}
              color="" loading={order == undefined ? {width: 75} : undefined}
            /> */}
            {!isForeigner && hasSplit && (
              <>
                <DetailItem
                  label={intl.formatMessage({
                    id: "detail.order.additional.fee",
                  })}
                  value={
                    order !== undefined
                      ? intl.formatNumber(order.additionalFee, {
                          style: "currency",
                          currency: order.currency,
                        })
                      : ""
                  }
                  loading={order == undefined ? { width: 75 } : undefined}
                />
              </>
            )}

            {!isForeigner ? (
              <>
                <DetailItem
                  label={intl.formatMessage({ id: "detail.order.others" })}
                  value={
                    order !== undefined
                      ? intl.formatNumber(
                          order.othersAmount ? order.othersAmount : 0,
                          { style: "currency", currency: order.currency }
                        )
                      : ""
                  }
                  loading={order === undefined ? { width: 75 } : undefined}
                />

                <DetailItem
                  label={intl.formatMessage({ id: "detail.order.net.amount" })}
                  value={
                    order !== undefined
                      ? intl.formatNumber(
                          order.isMultiplePayments
                            ? order.netAmount * 2
                            : order.netAmount,
                          { style: "currency", currency: order.currency }
                        )
                      : ""
                  }
                  loading={
                    order === undefined && isLoadingOrderReceivables
                      ? { width: 75 }
                      : undefined
                  }
                />
              </>
            ) : null}

            {/* <DetailItem
              label={intl.formatMessage({ id: "detail.order.net.amount.local.currency" })}
              value={(order !== undefined) ? intl.formatNumber(order.netAmount, {style: "currency", currency: "EUR"}) : ""}
              color="" loading={order == undefined ? {width: 75} : undefined}
            /> */}
          </styled.DetailsItems>
        </styled.BoxDetails>

        {orderNumberReceivables.length >= 1 ? (
          <styled.BoxDetails className="border-bottom">
            <styled.Header>
              <FormattedMessage id="detail.order.receivables.title" />
            </styled.Header>
            {orderNumberReceivables.map((receivable, index) => (
              <styled.DetailsItems key={receivable.id}>
                <DetailItem
                  isReceivables={true}
                  label={moment.utc(receivable.due_date).format("ll")}
                  receivableStatus={intl.formatMessage({
                    id: `detail.order.${
                      receivable.status === "pending"
                        ? "scheduled"
                        : receivable.status
                    }`,
                  })}
                  loading={order === undefined ? { width: 75 } : undefined}
                  value={intl.formatNumber(receivable.amount, {
                    style: "currency",
                    currency: "BRL",
                  })}
                  isTheLastItem={index === orderNumberReceivables.length - 1}
                  anticipationValue={
                    receivable.anticipation_fee > 0
                      ? `${intl.formatMessage({
                          id: "detail.order.anticipation.fee",
                        })} ${intl.formatNumber(receivable.amount, {
                          style: "currency",
                          currency: "BRL",
                        })}`
                      : undefined
                  }
                />
              </styled.DetailsItems>
            ))}
          </styled.BoxDetails>
        ) : null}

        {(() => {
          return order !== undefined &&
            order.payment.status !== "Blocked" &&
            order.payment.status !== "Failed" &&
            order.payment.status !== "Disputed" &&
            order.payment.status !== "ChargedBack" &&
            order.payment.status !== "Canceled" &&
            accessLevel !== 3 &&
            order.currency === "BRL"
            ? (() => {
                if (
                  order.payment.type === "Credit" ||
                  order.payment.type === "Pix"
                ) {
                  return (
                    <styled.BoxDetails className="border-bottom">
                      <styled.Header>
                        <FormattedMessage id="transactions.refund.control" />
                      </styled.Header>
                      <styled.DetailsItems>
                        <form onSubmit={submitRefund}>
                          {order !== undefined &&
                            order.isMultiplePayments &&
                            order.payment2 !== undefined && (
                              <div className="detail-item--margin-bottom">
                                <Select
                                  required={true}
                                  value={paymentIdToRefund}
                                  mode="normal-child"
                                  name="card-to-refund"
                                  placeholder={intl.formatMessage({
                                    id: "transactions.refund.select.card.placeholder",
                                  })}
                                  handler={setPaymentIdToRefund}
                                >
                                  <option value=""></option>
                                  <option value={order.payment.id}>{`${
                                    order.payment.card!.firstDigits
                                  } **** **** ${
                                    order.payment.card!.lastDigits
                                  }`}</option>
                                  <option value={order.payment2.id}>{`${
                                    order.payment2.card!.firstDigits
                                  } **** **** ${
                                    order.payment2.card!.lastDigits
                                  }`}</option>
                                </Select>
                              </div>
                            )}

                          {/* <styled.DetailItem>
                        <div className="label">
                          <FormattedMessage id="request.payment.currency" />
                        </div>
                        <div className="value">
                          {order ? order.currency : 'BRL'}
                        </div>
                      </styled.DetailItem> */}

                          <div className="form-item--global">
                            <h5>
                              {intl.formatMessage({ id: "refund.amoun.label" })}
                              {order ? order.currency : "BRL"}
                            </h5>

                            <div className="input-container">
                              <Input
                                required={true}
                                isCurrency={true}
                                mode="normal-fixed-symbol"
                                fixedSymbol="R$"
                                name="refund-amount"
                                value={newRefund.amount}
                                placeholder={intl.formatMessage({
                                  id: "request.payment.amount",
                                })}
                                handler={handleRefundAmount}
                              />
                            </div>
                          </div>

                          <div className="form-item--global">
                            <div className="form-item__optional">
                              <h5>
                                {intl.formatMessage({
                                  id: "transactions.refund.control.reference",
                                })}
                              </h5>
                              <p>
                                {intl.formatMessage({
                                  id: "request.payment.optional.label",
                                })}
                              </p>
                            </div>

                            <div className="input-container">
                              <Input
                                required={false}
                                mode="normal"
                                name="description-refund"
                                value={newRefund.reference}
                                placeholder={intl.formatMessage({
                                  id: "transactions.refund.control.reference.placeholder",
                                })}
                                handler={handleRefundReference}
                              />
                            </div>
                          </div>

                          {/* <styled.DetailItem>
                        <div className="label">
                          <FormattedMessage id="transactions.refund.control.percentage" />
                        </div>
                        <div className="value"> {(newRefund.percentage !== undefined) ? `${newRefund.percentage.toFixed(2)}%` : ""} </div>
                      </styled.DetailItem> */}

                          <div className="margintop24--global">
                            {!processing && (
                              <Buttons
                                mode="filled"
                                handler={() => {}}
                                type="submit"
                                label={intl.formatMessage({
                                  id: "button.refund",
                                })}
                                disable={false}
                              />
                            )}
                            {processing && (
                              <Buttons
                                mode="filled-processing"
                                handler={() => {}}
                                type="submit"
                                label=""
                                disable={false}
                              />
                            )}
                          </div>

                          {successMessage !== "" && (
                            <span className="success">{successMessage}</span>
                          )}
                          {errorMessage !== "" && (
                            <span className="error">{errorMessage}</span>
                          )}
                        </form>
                      </styled.DetailsItems>
                    </styled.BoxDetails>
                  );
                }
              })()
            : null;
        })()}

        {showCancelBoletoOrder ? (
          <styled.BoxDetails>
            <styled.Header>
              <FormattedMessage id="cancel.order.title" />

              <p className="subtitle">
                {intl.formatMessage({ id: "cancel.order.text" })}
              </p>
            </styled.Header>

            <styled.DetailsItems>
              <CancelOrderModalButtonModal
                storeId={id}
                orderNumber={number}
                afterCancel={runAfterCancelBoleto}
              />
            </styled.DetailsItems>
          </styled.BoxDetails>
        ) : null}
      </styled.Right>
    </styled.LeftRightContainer>
  );
};

export default BoxDetails;
