import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Tour } from "antd";


//Components
import Input from "../../../Forms/InputFloatLabel";
import Buttons from "../../../Forms/Buttons";


//Assets
import tipIcon from "assets/images/global/tip-icon.svg";
import arrowIcon from "assets/images/topbar/select-arrow.svg";

//Styles
import * as styled from "../modals-styles";

//Hooks
import { useViewAsModal } from "./hooks";

const ViewAsButtonModal = (props: any) => {
  const intl = useIntl();
  const {
    handleShowModal,
    showRequestPaymentModal,
    textButtonView,
    isMounted,
    haveStoreName,
    setShowRequestPaymentModal,
    wantedStore,
    clearRadioButton,
    setWantedStore,
    stores,
    selectStore,
    status,
    viewasModalRef,
    steps,
    openViewAsHighLight,
    openViewAsDueToCode,
  } = useViewAsModal();

  return (
    <>
      <Tour open={openViewAsHighLight} onClose={() => {}} steps={steps} />

      <styled.RelativeContainer ref={props.viewAsRef}>
        <styled.LabelViewAs onClick={handleShowModal}>
          <strong>{textButtonView}</strong>

          <img src={arrowIcon} alt="arrow" />
        </styled.LabelViewAs>

        {(showRequestPaymentModal || openViewAsDueToCode) && (
          <styled.ViewAsContainer
            style={
              isMounted || openViewAsDueToCode
                ? { animation: "scaleModal .2s ease forwards" }
                : { animation: "reveserScameModal .1s ease-out forwards" }
            }
            ref={viewasModalRef}
            onAnimationEnd={() => {
              if (!isMounted) setShowRequestPaymentModal(false);
            }}
          >
            <div className="header-container">
              {!haveStoreName ? (
                <p className="view-as__title">
                  {intl.formatMessage({ id: "view.as.btn.label" })}
                </p>
              ) : null}
              {haveStoreName ? (
                <p className="view-as__title">
                  {intl.formatMessage({ id: "viewing.as.btn.label" })}{" "}
                  {textButtonView}
                </p>
              ) : null}

              {haveStoreName ? (
                <div className="return-to-business-container">
                  <Buttons
                    mode="unfilled-with-event"
                    label={intl.formatMessage({
                      id: "return.to.view.as.business",
                    })}
                    handler={clearRadioButton}
                  />
                </div>
              ) : null}

              <div>
                <Input
                  required={false}
                  mode="search"
                  name="wantedStore"
                  value={wantedStore}
                  placeholder={intl.formatMessage({
                    id: "view.as.search.establishment",
                  })}
                  handler={setWantedStore}
                />
              </div>
            </div>

            <div className="buttons-container-stores">
              {stores &&
                stores.map((store) => {
                  let storeStatus;
                  switch (status) {
                    case "onboarding":
                    case "pending":
                      storeStatus = (
                        <span className="status-pending">
                          <FormattedMessage id="status.pending" />
                        </span>
                      );
                      break;
                    case "enabled":
                      storeStatus = (
                        <span className="status-succeeded">
                          <FormattedMessage id="status.active" />
                        </span>
                      );
                      break;
                    case "denied":
                      storeStatus = (
                        <span className="status-failed">
                          <FormattedMessage id="status.blocked" />
                        </span>
                      );
                      break;
                    default:
                      storeStatus = (
                        <span className="status-succeeded">
                          <FormattedMessage id="status.active" />
                        </span>
                      );
                      break;
                  }

                  return (
                    <div
                      className="button__item"
                      onClick={(e) => selectStore(e, store.id)}
                      key={`${Math.random()}${store.id}`}
                    >
                      <Buttons
                        mode="simple-limited"
                        label={store.socialName}
                        handler={() => {}}
                      />{" "}
                      <div>{storeStatus}</div>
                    </div>
                  );
                })}

              {/* <div className="create-store-container">
            <Buttons mode="unfilled" label={intl.formatMessage({ id: "view.as.create.store" })} handler={() => {}} />
          </div> */}
            </div>

            <styled.Tip>
              <div className="tip-intern-container">
                <div>
                  <img src={tipIcon} alt="tip" />
                  <p>
                    {intl.formatMessage({ id: "request.payment.tip.title" })}
                  </p>
                </div>

                <p>{intl.formatMessage({ id: "view.as.tip.text" })}</p>
              </div>
            </styled.Tip>
          </styled.ViewAsContainer>
        )}
      </styled.RelativeContainer>
    </>
  );
};

export default ViewAsButtonModal;
