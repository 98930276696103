import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import ErrorMessage from "../../../FormValidations/ErrorMessage";

import OrderFeesServices from "../../../../../../services/OrderFees";
import MinimalistInputElement from "../../../../../Forms/CreditCardInput";

import Input from "../../../../../Forms/InputFloatLabel";
import Select from "../../../../../Forms/Select";

import currencyAndSymbolUnique from "../../../../../../helpers/currencyAndSymbolUnique";

import * as styled from "../../../payment-request-styles";

//Types
import { CreditCardProps } from "../../types";

const CardManual = ({
  customer,
  handleCustomer,
  isForeigner,
  card,
  manualOrder,
  handleManualOrder,
  creditCard,
  handleCreditCard,
  colorsTheme,
  tax,
  handleTax,
  selected,
  setSelected,
  conversionRates,
  hasExchangeRates,
  applySplitsAfterOrder,
  formErrors,
  createPlanFirst,
}: CreditCardProps) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [isCnpj, setIsCnpj] = useState(false);
  const [customerFees, setCustomerFees] = useState(false);
  const [includeFeeTransfer, setIncludeFeeTransfer] = useState(false);

  const [valueOrder, setValueOrder] = useState("0");
  const [brValueOrder, setBrValueOrder] = useState(manualOrder.amount);
  const [brlTaxAmount, setBrlTaxAmount] = useState("0");
  let valueOrderNumber = parseFloat(valueOrder);

  const [dateLabel, setDateLabel] = useState("Data de Nascimento:");
  const [cardInstallmentsAmount, setCardInstallmentsAmount] = useState<any>([]);

  const [urlExpandIcon, setUrlExpandIcon] = useState("/images/expand-icon.svg");
  const [expandInfos, setExpandInfos] = useState(false);

  const [isTrueOrderNumber, setIsTrueOrderNumber] = useState(false);

  const [chargeLater, setChargeLater] = useState(false);
  const [informTax, setInformTax] = useState(false);

  const [cpfCnpjLabel, setCpfCnpjLabel] = useState("CPF/CNPJ");

  const [customerCurrency, setCustomerCurrency] = useState("BRL");
  const [customerCurrencySymbol, setCustomerCurrencySymbol] = useState("R$");

  const applyFeesAfterOrder = [
    0, 0, 3.45, 5.22, 7.02, 8.85, 10.71, 12.6, 14.53, 16.49, 18.48, 20.5, 22.56,
  ];

  const refName = useRef<HTMLInputElement>(null);
  const refLastName = useRef<HTMLInputElement>(null);

  const intl = useIntl();

  useEffect(() => {
    if (hasExchangeRates) {
      let filteredCountry = currencyAndSymbolUnique.filter((currency) => {
        if (currency.currency === customerCurrency) {
          return currency.currency;
        }
      });

      localStorage.setItem(
        "@PrimefyDashboard-currency",
        JSON.stringify({
          currency: customerCurrency,
        })
      );

      setCustomerCurrencySymbol(
        filteredCountry.length > 0 ? filteredCountry[0].symbol : "US$"
      );

      handleManualOrder((prevState: any) => {
        return {
          ...prevState,
          amount: (
            (parseFloat(brValueOrder) +
              (customerCurrency !== "BRL"
                ? parseFloat(brValueOrder) * 0.496
                : 0)) *
            conversionRates[
              filteredCountry.length > 0 ? customerCurrency : "USD"
            ]
          ).toString(),
        };
      });

      if (tax.mode === "Fixed") {
        const taxAmount = tax.amount;
        handleTax(
          "amount",
          (
            parseFloat(brlTaxAmount) *
            conversionRates[
              filteredCountry.length > 0 ? customerCurrency : "USD"
            ]
          ).toString()
        );
      }
    } else {
      let filteredCountry = currencyAndSymbolUnique.filter((currency) => {
        if (currency.currency === "BRL") {
          return currency.currency;
        }
      });

      localStorage.setItem(
        "@PrimefyDashboard-currency",
        JSON.stringify({
          currency: customerCurrency,
        })
      );

      setCustomerCurrency(
        filteredCountry.length > 0 ? filteredCountry[0].currency : "USD"
      );

      handleManualOrder((prevState: any) => {
        return {
          ...prevState,
          amount: parseFloat(brValueOrder).toString(),
        };
      });
    }
  }, [customerCurrency, customer]);

  useEffect(() => {
    if (customerCurrency === "BRL") {
      setBrValueOrder(manualOrder.amount);
    }
  }, [manualOrder.amount]);

  useEffect(() => {
    if (customerCurrency === "BRL") {
      setBrlTaxAmount(tax.amount);
    }
  }, [tax.amount]);

  useEffect(() => {
    if (card) {
      if (includeFeeTransfer) {
        OrderFeesServices.creditCardFees({
          cardBrand: "",
          amount: parseFloat(manualOrder.amount),
          paymentType: "Credit",
        }).then((response) => {
          let amountsWithFees = [];

          if (applySplitsAfterOrder) {
            for (let index = 0; index <= 11; index++) {
              let addictionalFee =
                (response.data[index].amount * applyFeesAfterOrder[index + 1]) /
                100;

              amountsWithFees.push(
                (response.data[index].amount + addictionalFee) /
                  (index === 0 ? 1 : index + 1)
              );
            }
          } else {
            for (let index = 0; index <= 11; index++) {
              amountsWithFees.push(
                response.data[index].amount / (index === 0 ? 1 : index + 1)
              );
            }
          }

          setCardInstallmentsAmount(amountsWithFees);
        });
      } else {
        let amountsWithoutFees = [];
        let addictionalFee = 0;

        for (let index = 1; index <= 12; index++) {
          if (applySplitsAfterOrder) {
            addictionalFee =
              (parseFloat(manualOrder.amount) * applyFeesAfterOrder[index]) /
              100;
          }

          amountsWithoutFees.push(
            selected === "BR"
              ? (
                  (parseFloat(manualOrder.amount) + addictionalFee) /
                  index
                ).toFixed(2)
              : parseFloat(manualOrder.amount) / index
          );
        }

        setCardInstallmentsAmount(amountsWithoutFees);
      }
    }
  }, [includeFeeTransfer, manualOrder.amount, applySplitsAfterOrder]);

  useEffect(() => {
    if (customerFees) {
      setIncludeFeeTransfer(true);
    } else {
      setIncludeFeeTransfer(false);
    }
  }, [customerFees]);

  function handleInstallments(event: string) {
    let selectedInstallment = parseInt(event);

    handleManualOrder((prevState: any) => {
      return {
        ...prevState,
        installments: selectedInstallment,
      };
    });
  }

  useEffect(() => {
    if (
      customer.identificationNumber.length > 14 &&
      customerCurrency === "BRL"
    ) {
      setIsCnpj(true);
      setDateLabel("Data de Registro:");
      setCpfCnpjLabel("CNPJ:");

      handleCustomer((prevState: any) => {
        return {
          ...prevState,
          firstName: "",
          lastName: "",
        };
      });

      if (refName.current && refLastName.current) {
        refName.current.disabled = true;
        refLastName.current.disabled = true;
      }
    } else if (customer.identificationNumber.length === 0) {
      if (refName.current && refLastName.current) {
        refName.current.disabled = false;
        refLastName.current.disabled = false;
      }

      setIsCnpj(false);
      setDateLabel("Data de Nascimento");
      setCpfCnpjLabel("CPF/CNPJ");
    } else {
      if (refName.current && refLastName.current) {
        refName.current.disabled = false;
        refLastName.current.disabled = false;
      }

      setIsCnpj(false);
      setDateLabel("Data de Nascimento");
      setCpfCnpjLabel("CPF");
    }
  }, [customer.identificationNumber]);

  function removeError(status: boolean) {
    handleCreditCard((prevState: any) => {
      return {
        ...prevState,
        hasCardError: status,
      };
    });
  }

  function handleCustomerData(name: string, value: string) {
    handleCustomer((prevState: any) => {
      return {
        ...prevState,
        [name]: name === "amount" ? parseFloat(value) : value,
      };
    });
  }

  function handleCreditCardNumber(value: any) {
    handleCreditCard((prevState: any) => {
      return {
        ...prevState,
        cardNumber: value,
      };
    });
  }

  function handleCreditCardExpiration(value: any) {
    handleCreditCard((prevState: any) => {
      return {
        ...prevState,
        expiration: value,
      };
    });
  }

  function handleCreditCardCvv(value: any) {
    handleCreditCard((prevState: any) => {
      return {
        ...prevState,
        cvv: value,
      };
    });
  }

  function handleCreditCardNamed(name: any, value: any) {
    handleCreditCard((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function validateCreditCard() {
    let errors = [];

    if (creditCard.cardNumber.length < 13) {
      handleCreditCardNamed(true, "hasCardError");
      errors.push("cardNumber");
    }

    if (creditCard.expiration.length < 5) {
      handleCreditCardNamed(true, "hasExpirationError");
      errors.push("expiration");
    } else {
      let expirationSplitted = creditCard.expiration.split("/");
      let currentYear = new Date().getFullYear();

      if (
        parseInt(expirationSplitted[0]) === 0 ||
        parseInt(expirationSplitted[0]) > 12
      ) {
        errors.push("expiration");
        handleCreditCardNamed(true, "hasExpirationError");
      }

      let completeYear = parseInt(`20${expirationSplitted[1]}`);
      if (completeYear < currentYear) {
        errors.push("expiration");
        handleCreditCardNamed(true, "hasExpirationError");
      }
    }

    if (creditCard.cvv.length < 3) {
      handleCreditCardNamed(true, "hasCvvError");
      errors.push("cvv");
    }

    return errors.length === 0;
  }

  function handleManualOrderAmount(value: string) {
    handleManualOrder((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  return (
    <>
      {/* <div className="form-item">
      <h5>{intl.formatMessage({ id: "request.payment.amount" })}</h5>

      <Input  mode="normal-currency" currencySymbol={customerCurrencySymbol} name="value-order" value={manualOrder.amount} placeholder={""} handler={handleManualOrderAmount} />
    </div> */}

      {!isForeigner && !manualOrder.planId && !createPlanFirst ? (
        <>
          <h5>
            {intl.formatMessage({ id: "request.payment.customer.currency" })}
          </h5>
          <div className="form-item select-and-input-container">
            <div className="m-20-width">
              <Select
                mode="clean-normal-child"
                name="currency"
                placeholder=""
                handler={setCustomerCurrency}
              >
                {currencyAndSymbolUnique.map((currency) => (
                  <option
                    className="bold-option"
                    key={currency.id}
                    value={currency.currency}
                  >
                    {currency.symbol}
                  </option>
                ))}
              </Select>
            </div>

            <div className="m-half-width">
              <Input
                mode="normal-currency"
                name="amount"
                value={manualOrder.amount}
                placeholder={""}
                handler={handleManualOrderAmount}
                isInputWithoutBorder={true}
              />
            </div>
          </div>
          <ErrorMessage
            errors={formErrors}
            wantedError="orderAmount"
            errorMessage={intl.formatMessage({
              id: "error.request.payment.amount",
            })}
          />
        </>
      ) : null}

      {parseFloat(manualOrder.amount) > 0 ||
      manualOrder.planId ||
      createPlanFirst ? (
        <>
          {/* {!isForeigner && <div className="form-item">
        <h5>{intl.formatMessage({ id: "request.payment.customer.currency" })}</h5>

        <Select mode="normal-child" name="currency" placeholder={intl.formatMessage({ id: "request.payment.customer.currency" })} handler={setCustomerCurrency} >
          {currencyAndSymbolUnique.map((currency) => (
            <option key={currency.id} value={currency.currency}>
              {currency.currency} ({currency.symbol})
            </option>
          ))}
        </Select>
      </div>} */}

          {customerCurrency === "BRL" && (
            <div className="form-item">
              <h5>{cpfCnpjLabel}</h5>

              <Input
                mode="named-mask"
                name="identificationNumber"
                mask={
                  customer.identificationNumber.length > 14
                    ? "99.999.999/9999-99"
                    : "999.999.999-999"
                }
                value={customer.identificationNumber}
                placeholder={""}
                handler={handleCustomerData}
              />
              <ErrorMessage
                errors={formErrors}
                wantedError="cpf"
                errorMessage={intl.formatMessage({
                  id: "personal.data.erro.identification.cpf",
                })}
              />
              <ErrorMessage
                errors={formErrors}
                wantedError="cnpj"
                errorMessage={intl.formatMessage({
                  id: "personal.data.erro.identification.cnpj",
                })}
              />
            </div>
          )}

          {isCnpj && (
            <div className="form-item">
              <h5>
                {intl.formatMessage({
                  id: "request.payment.boleto.business.name",
                })}
              </h5>

              <Input
                required={false}
                mode="named"
                name="businessName"
                value={customer.businessName}
                placeholder={""}
                handler={handleCustomerData}
                pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
              />
              <ErrorMessage
                errors={formErrors}
                wantedError="businessName"
                errorMessage={intl.formatMessage({
                  id: "personal.data.erro.business.name",
                })}
              />
            </div>
          )}

          {!isCnpj && (
            <>
              <div className="form-item">
                <h5>
                  {intl.formatMessage({ id: "request.payment.boleto.name" })}
                </h5>

                <Input
                  required={false}
                  mode="named"
                  name="firstName"
                  value={customer.firstName}
                  placeholder={""}
                  handler={handleCustomerData}
                  pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
                />
                <ErrorMessage
                  errors={formErrors}
                  wantedError="customerName"
                  errorMessage={intl.formatMessage({
                    id: "personal.data.erro.name",
                  })}
                />
              </div>

              <div className="form-item">
                <h5>
                  {intl.formatMessage({
                    id: "request.payment.boleto.lastname",
                  })}
                </h5>

                <Input
                  required={false}
                  mode="named"
                  name="lastName"
                  value={customer.lastName}
                  placeholder={""}
                  handler={handleCustomerData}
                  pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
                />
                <ErrorMessage
                  errors={formErrors}
                  wantedError="customerLastname"
                  errorMessage={intl.formatMessage({
                    id: "personal.data.erro.surname",
                  })}
                />
              </div>
            </>
          )}

          <div className="form-item">
            <h5>
              {intl.formatMessage({ id: "request.payment.boleto.email" })}
            </h5>

            <Input
              required={false}
              mode="named"
              name="email"
              value={customer.email}
              placeholder={""}
              handler={handleCustomerData}
            />
            <ErrorMessage
              errors={formErrors}
              wantedError="customerEmail"
              errorMessage={intl.formatMessage({
                id: "personal.data.erro.email",
              })}
            />
          </div>

          <div className="form-item">
            <h5>
              {intl.formatMessage({ id: "request.payment.boleto.phone" })}
            </h5>

            <Input
              required={false}
              mode="named-mask"
              name="phone"
              mask={
                customer.phone.length <= 14
                  ? "(99) 9999-99999"
                  : "(99) 99999-9999"
              }
              value={customer.phone}
              placeholder={""}
              handler={handleCustomerData}
            />
            <ErrorMessage
              errors={formErrors}
              wantedError="customerPhone"
              errorMessage={intl.formatMessage({
                id: "payment.data.erro.phone",
              })}
            />
          </div>

          <div className="form-item">
            <h5>
              {intl.formatMessage({ id: "request.payment.credit.card.data" })}
            </h5>

            <div className="value-component">
              <MinimalistInputElement
                hasCardError={creditCard.hasCardError}
                removeError={removeError}
                hasExpirationError={creditCard.hasExpirationError}
                hasCvvError={creditCard.hasCvvError}
                cardNumber={creditCard.cardNumber}
                setCardNumber={handleCreditCardNumber}
                expiration={creditCard.expiration}
                setExpiration={handleCreditCardExpiration}
                cvv={creditCard.cvv}
                setCvv={handleCreditCardCvv}
                color={colorsTheme.color}
              />
            </div>
          </div>
          {creditCard.hasCardError && (
            <styled.ErrorMessage>
              {intl.formatMessage({ id: "error.card.number" })}
            </styled.ErrorMessage>
          )}
          {creditCard.hasExpirationError && (
            <styled.ErrorMessage>
              {intl.formatMessage({ id: "error.card.expiration" })}
            </styled.ErrorMessage>
          )}
          {creditCard.hasCvvError && (
            <styled.ErrorMessage>
              {intl.formatMessage({ id: "error.card.cvv" })}
            </styled.ErrorMessage>
          )}

          {!manualOrder.planId && (
            <div className="form-item">
              <h5>
                {intl.formatMessage({
                  id: "request.payment.credit.card.installments",
                })}
              </h5>

              <Select
                mode="normal-child"
                name="installments"
                placeholder={""}
                handler={handleInstallments}
                disabled={customerCurrency !== "BRL"}
              >
                {cardInstallmentsAmount.map(
                  (installment: number, index: number) => (
                    <option key={index + 1} value={index + 1}>
                      {`${index + 1}x ${intl.formatMessage({
                        id: "request.payment.of",
                      })} ${intl.formatNumber(cardInstallmentsAmount[index], {
                        style: "currency",
                        currency: customerCurrency,
                        minimumSignificantDigits: 2,
                      })} ${intl.formatMessage({
                        id:
                          includeFeeTransfer ||
                          (applySplitsAfterOrder && index + 1 > 1)
                            ? "request.payment.with.fees"
                            : "request.payment.without.fees",
                      })}`}
                    </option>
                  )
                )}
              </Select>
            </div>
          )}

          <div className="form-item">
            <h5>{intl.formatMessage({ id: "request.payment.card.name" })}</h5>

            <Input
              mode="named"
              name="holderName"
              value={creditCard.holderName}
              placeholder={""}
              handler={handleCreditCardNamed}
              pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default CardManual;
