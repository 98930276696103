import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import StoreService from '../../services/Store';
import UserProfileService from '../../services/UserProfile';

//Components
import Buttons from '../Forms/Buttons';
import Input from 'components/Forms/InputFloatLabel';

//Styles
import * as styled from './details-styles';

//Assets
import eyeIcon from 'assets/images/icon-eye.svg';
import hideIcon from 'assets/images/icon-hide.svg';

const UserDetails = () => {
  const [inputType, setInputType] = useState('password');
  const [hideShowPassIcon, setHideShowPassIcon] = useState(eyeIcon);
  const [idUser, setIdUser] = useState(0);
  const [logoPathState, setLogoPathState] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [createUserErrors, setCreateUserErrors] = useState({
    username: '',
    password: ''
  });

  const [passWords, setPasswords] = useState({
    newPass: '',
    oldPass: ''
  });

  const [personalData, setPersonalData] = useState({
    id: 0,
    username: '',
    name: '',
    pass: '',
  });

  const [edittingUserData, setEdittingUserData] = useState(false);

  let intl = useIntl();

  useEffect(() => {
    setAllData();
  }, [])

  function setAllData() {
    StoreService.getInfos().then(response => {
      // UserProfileService.getInfos().then(response2 => {
      //   const hidenPassArray: string[] = [];
      //   for (let i = 1; i <= response2.data.countCaracteres; i++) {
      //     hidenPassArray.push("*");
      //   }
      //   setIdUser(response2.data.id);

      //   setPersonalData(() => {
      //     return {
      //       username: response2.data.username,
      //       id: response2.data.id,
      //       name: response2.data.name,
      //       pass: hidenPassArray.join('')
      //     }
      //   });
      // });

      setLogoPathState(response.data.logoPath);
    });
  }

  function changePersonalData(name: string, value: string) {
    if ((name === 'newPass') || (name === 'oldPass')) {
      setPasswords((prevState) => {
        return {
          ...prevState,
          [name]: value,
        }
      });
    } else {
      setPersonalData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        }
      });
    }

  }
  function savePersonalData() {
    setIsProcessing(true);

    if (passWords.newPass.length <= 8) {
      setIsProcessing(false);
      setCreateUserErrors((prevState) => {
        return {
          ...prevState,
          password: "user.profile.team.create.user.error.password"
        }
      });
    }else if (passWords.oldPass !== '' && passWords.newPass !== '') {
      const personalDataIntern = {
        id: idUser.toString(),
        name: personalData.name,
        username: personalData.username,
        confirmPassword: passWords.oldPass,
        password: passWords.newPass
      }

      UserProfileService.putUserInfos(idUser, personalDataIntern).then(response => {
        setIsProcessing(false);
        setEdittingUserData(false);
        setAllData();
      }).catch(err => { 
        setIsProcessing(false);
        console.log(err) 
      });

    } else {
      const personalDataIntern = {
        id: idUser.toString(),
        name: personalData.name,
        username: personalData.username,
        confirmPassword: null,
        password: null
      }

      UserProfileService.putUserInfos(idUser, personalDataIntern).then(response => {
        setIsProcessing(false);
        setEdittingUserData(false);
        setAllData();
      }).catch(err => { 
        setIsProcessing(false);
        console.log(err) 
      });
    }
  }
  
  function toggleShowPassword() {
    if (inputType === 'password') {
      setInputType('text');
      setHideShowPassIcon(hideIcon);
    } else {
      setHideShowPassIcon(eyeIcon);
      setInputType('password');
    }
  }

  return (
    <styled.LeftRightContainer>
      <styled.Left>
        <styled.BoxDetails>
        
          <styled.Header>
            <FormattedMessage id="user.profile.page.title" />

            <p className="subtitle">{intl.formatMessage({ id: "user.profile.page.subtitle" })}</p>
          </styled.Header>
          <styled.DetailsItems>

            <div className="info-detail-item">
              <h5>{intl.formatMessage({ id: "user.profile.email" })}</h5>
              {!edittingUserData && <p>{personalData.username}</p>}
              {edittingUserData && <Input required={false} mode="named" name="username" value={personalData.username} placeholder="" handler={changePersonalData} />}
            </div>
            
            <div className="info-detail-item">
              <h5>{intl.formatMessage({ id: "user.profile.name" })}</h5>
              {!edittingUserData &&  <p>{personalData.name}</p>}
              {edittingUserData && <Input required={false} mode="named" name="name" value={personalData.name} placeholder="" handler={changePersonalData} />}
            </div>

            <div className="info-detail-item">
              <h5>{intl.formatMessage({ id: !edittingUserData ? "user.profile.password" : "user.profile.old.password" })}</h5>
              {!edittingUserData &&  <p>{personalData.pass}</p>}
              {edittingUserData && <>
                <Input required={false} mode="named" name="oldPass" value={passWords.oldPass} placeholder="" handler={changePersonalData} type={inputType} icon={hideShowPassIcon} iconHandler={toggleShowPassword} />
              </>}
            </div>

            { edittingUserData && <div className="info-detail-item">
              <h5>{intl.formatMessage({ id: "user.profile.new.password" })}</h5>
              <Input required={false} mode="named" name="newPass" value={passWords.newPass} placeholder="" handler={changePersonalData} type={inputType} icon={hideShowPassIcon} iconHandler={toggleShowPassword} />
            </div> }

          </styled.DetailsItems>

          { !edittingUserData && <div>
            <Buttons mode="filled" label={intl.formatMessage({ id: "settings.team.list.edit" })} handler={() => setEdittingUserData(!edittingUserData)} />
          </div> }

          { edittingUserData && <div className="flex-buttons-container">
          { isProcessing ? <Buttons mode='filled-processing' label="" handler={() => {}} /> :
            <Buttons mode='filled' label={intl.formatMessage({ id: "user.profile.ui.save" })} handler={savePersonalData} />
          }
            <Buttons mode='unfilled' label={intl.formatMessage({ id: "home.withdrawall.cancel" })} disable={isProcessing} handler={() => setEdittingUserData(false)} />
          </div> }
        </styled.BoxDetails>
      </styled.Left>

      {/* <styled.Right>
        <styled.BoxDetails>
          <styled.Header>
            <FormattedMessage id="user.profile.page.notification.title" />

            <p className="subtitle">{intl.formatMessage({ id: "user.profile.page.notification.subtitle" })}</p>
          </styled.Header>

          <styled.DetailsItems>
            <div>
              <Input required={false} mode="checkbox" name="notifications" value="success-payments" placeholder={intl.formatMessage({ id: "user.profile.notifications.success.payments" })} handler={() => { }} />
              <Input required={false} mode="checkbox" name="notifications" value="payouts" placeholder={intl.formatMessage({ id:  "user.profile.notifications.payouts" })} handler={() => { }} />
              <Input required={false} mode="checkbox" name="notifications" value="disputes" placeholder={intl.formatMessage({ id:  "user.profile.notifications.disputes" })} handler={() => { }} />
              <Input required={false} mode="checkbox" name="notifications" value="refunds" placeholder={intl.formatMessage({ id: "user.profile.notifications.refunds" })} handler={() => { }} />
              <Input required={false} mode="checkbox" name="notifications" value="failed-payments" placeholder={intl.formatMessage({ id: "user.profile.notifications.failed.payments" })} handler={() => { }} />
            </div>
          </styled.DetailsItems>
        </styled.BoxDetails>
      </styled.Right> */}
    </styled.LeftRightContainer>
  );
};

export default UserDetails;
