import LangResource from "./LangResource";

const pt = new LangResource();

pt.langKeys = ["pt"];
pt.messages = {
  "view.label": "Visualizar",

  "items.not.found": "Nenhum item encontrado.",
  "items.not.found.2":
    "Nenhum item encontrado. Por favor insira o CPF ou CNPJ de um parceiro cadastrado.",
  "select.yes": "Sim",
  "select.no": "Não",

  "cancel.boleto.cta": "Cancelar Boleto",

  "status.active": "Ativo",
  "status.succeeded": "Ativo",
  "status.pending": "Pendente",
  "status.blocked": "Bloqueado",
  "status.deactivated": "Desativado",
  "status.expired": "Expirado",
  "status.pre.authorized": "Pré autorizado",

  "onboarding.step.1.title": "Abra a sua conta em 10 minutos",
  "onboarding.step.1.text":
    "A forma mais fácil e eficiente para aceitar pagamentos,  seja online ou com maquininha",
  "onboarding.step.2.title": "Pagamentos digitais",
  "onboarding.step.2.text":
    "Escale seu negócio aceitando PIX, Boleto e demais métodos de pagamentos digitalmente",
  "onboarding.step.3.title": "Pagamentos presenciais",
  "onboarding.step.3.text":
    "Com as nossas maquininhas, você pode receber pelos principais métodos de pagamentos presenciais",

  "home.recent-orders": "Últimas vendas",
  "home.status": "Status",
  "home.order": "Venda",
  "home.name": "Cliente",
  "home.method": "Método de pagamento",
  "home.brl.amount": "Valor da venda",
  "home.new.currency.amount": "Valor",
  "home.gbp.amount": "Valor em Libra",
  "home.type": "Tipo de cobrança",
  "home.date": "Data",
  "home.live.map": "Mapa em tempo real",
  "home.faq": "Dúvidas frequentes",
  "home.help": "Ajuda",
  "home.help.text":
    "Estamos sempre disponíveis para ajudá-lo, auxiliando no crescimento de seus negócios com o Primefy.",
  "home.complains.help":
    "Precisa de ajuda em alguma disputa? Converse com os nossos especialistas por chat.",
  "home.chat.wait.time": "Tempo médio de espera de 4 minutos",
  "home.email.wait.time": "Tempo médio de espera de 2 horas",
  "home.phone.wait.time": "Tempo médio de espera de 10 minutos",
  "home.overview": "Resumo de vendas",
  "home.report": "Relatório",
  "home.about": "sobre",
  "home.search.order": "Buscar por Número da venda ou CPF...",

  "home.faq.question.1": "Por que tenho Saldos Bloqueados?",
  "home.faq.answer.1":
    "Saldos bloqueados são referentes a valores que estão em disputa ou chargeback. Caso você tenha algum saldo bloqueado quer dizer que o montante corresponde a transações em que o usuário solicitou uma disputa sobre o valor.",
  "home.faq.question.2": "O que é Saldo A Receber?",
  "home.faq.answer.2":
    "É o faturamento bruto das vendas que ainda não foram liberados para saque. Esse valor já possui a taxa Preme deduzida de seu total.",
  "home.faq.question.3": "Pagamentos Recorrentes acontecem automaticamente?",
  "home.faq.answer.3":
    "Sim! Nossa tecnologia permite que você defina os pagamentos recorrentes e o cliente será cobrado automaticamente.",
  "home.faq.question.4":
    "Posso configurar Pagamentos Recorrentes como desejar?",
  "home.faq.answer.4":
    "Sim! Você pode optar por cobrar diariamente, semanalmente, mensalmente e/ou anualmente seus clientes.",
  "home.faq.question.5": "Como posso receber o Saldo Disponível?",
  "home.faq.answer.5":
    "O valor será transferido para a conta bancária previamente cadastrada, e você pode escolher o valor que deseja transferir tendo o montante do Saldo Disponível como limite.",
  "home.faq.question.6":
    "O Cliente disse que já pagou, porque ainda não está confirmado?",
  "home.faq.answer.6":
    "Para pagamentos via boleto o prazo de confirmação de pagamento pode ser de até 3 dias  úteis. Para pagamentos utilizando cartão em suas diversas modalidades a confirmação deve ser instantâneo. Caso tenha dúvidas por favor entre em contato com nossa Central de Ajuda.",

  "home.withdrawall.info":
    "Para pedidos de saques realizados após 11:00, ou em finais de semanas, ou em feriados, a transferência será realizada no próximo dia útil.",
  "home.withdrawall.confirm": "Confirmar pedido de saque",
  "home.withdrawall.confirm.foreigner": "Confirmar pedido de saque de",
  "home.withdrawall.change.bank.details": "Adicionar conta bancária",
  "home.withdrawall.history": "Visualizar histórico de saques",
  "home.withdrawall.search.bank": "Pesquise o banco",
  "home.withdrawall.account": "Conta",
  "home.withdrawall.branch": "Agência",
  "home.withdrawall.type.account": "Tipo da conta",
  "home.withdrawall.holder.name": "Titular",
  "home.withdrawall.bank.holder.name": "Titular",
  "home.withdrawall.confirm.new.bank.details": "Adicionar nova conta bancária",
  "home.withdrawall.cancel": "Cancelar",
  "home.withdrawall.withdrawall.history": "Histórico de saques",
  "home.withdrawall.withdrawall.history.date": "Data",
  "home.withdrawall.withdrawall.history.amount": "Valor",
  "home.withdrawall.withdrawall.history.user": "Usuário",
  "home.withdrawall.withdrawall.history.status": "Status",
  "home.withdrawall.status.message.default": " ",
  "home.withdrawall.status.message.success":
    "Operação realizada com sucesso. O depósito será efetuado em até 24h",
  "home.withdrawall.status.message.failed":
    "Ocorreu um erro ao requisitar o saque",
  "home.withdrawall.select.bank.account.placeholder":
    "Selecione uma conta bancária",
  "home.withdrawall.select.bank.account.placeholder.register":
    "Selecione um banco",
  "home.withdrawall.error.available.amount.zero":
    "Estabelecimento sem saldo suficiente. Para realizar saques, o estabelecimento precisa ter saldo disponível.",
  "home.withdrawall.error.amount.equal.zero":
    "Por favor insira um valor maior que zero para realizar o saque.",

  "withdrawall.merchant.number": "Número de negócio do cliente",
  "withdrawall.confirm.transfer": "Confirmar transferência",

  "financial.title": "Finanças",
  "financial.gross.volume": "Faturamento bruto",
  "financial.net.volume": "Faturamento Líquido",
  "financial.average.ticket.price": "Ticket médio",
  "financial.orders.quantity": "Número de vendas",
  "financial.dispute.count": "Disputas",
  "financial.amount.available.settlement": "Saldo disponível",
  "financial.amount.to.receive": "Saldo a receber",
  "financial.amount.dispute": "Saldo em disputa",
  "financial.withdrawal.country": "País",
  "financial.withdrawal.national": "Conta Nacional",
  "financial.withdrawal.international": "Conta Global",

  "brazil.fees": "Brasil",
  "global.fees": "Global",

  "financial.page.withdrawal.title": "Transferências e Retiradas",
  "financial.page.withdrawal.subtitle":
    "Você pode sacar para sua conta bancária desejada ou transferir para outro comerciante que tenha uma conta no Primefy com o saldo disponível.",
  "financial.page.transactions.title": "Transações",
  "financial.page.transactions.subtitle":
    "Aqui você pode ver todos os saques detalhados e pagamentos futuros.",
  "financial.page.transactions.upcoming.subtitle":
    "Aqui você pode ver todos os pagamentos futuros detalhados.",
  "financial.page.transactions.view.all": "Ver todos",
  "financial.page.transactions.upcoming.amount": "Valor a receber: ",

  "button.withdrawal": "Confirmar retirada",
  "button.refund": "Enviar estorno",
  "button.sidebar.home": "Resumo",
  "button.sidebar.transactions": "Vendas",
  "button.sidebar.sale-commissions": "Comissões",
  "button.sidebar.customer": "Clientes",
  "button.sidebar.signout": "Sair",
  "button.sidebar.developers": "Integrações",
  "button.sidebar.reports": "Relatórios",
  "button.sidebar.financials": "Finanças",
  "button.sidebar.settings": "Ajustes",
  "button.sidebar.plans": "Planos",
  "button.sidebar.switch.environment.prop": "Exibir dados de teste",
  "button.sidebar.switch.environment.test": "Exibindo dados de teste",
  "button.sidebar.switch.mode": "Modo",
  "withdrawal.amount.info": "Vamos transferir para sua conta bancária",
  "withdrawal.amount.inf.why": "Por quê?",
  "withdrawal.amount.inf.why.answer":
    "Devido à regulamentação brasileira sobre AML (Anti-Lavagem de Dinheiro), temos que transferir o valor total de um pedido específico e identificar o comprador.",

  "refund.amoun.label": "Valor em ",

  "withdrawal.transfer.type": "Tipo de transferência",
  "withdrawal.your.accout": "Sacar para a sua conta bancária",
  "withdrawal.merchant.accout":
    "Transferir para a conta de outro estabelecimento",

  "financial.withdrawal.title": "Sacar",
  "financial.withdrawal.available": "Disponível para saque",
  "financial.withdrawal.branch.code": "Agência",
  "financial.withdrawal.account.number": "Conta",
  "financial.withdrawal.amount": "Valor",
  "financial.withdrawal.description": "Descrição",
  "financial.withdrawal.bank": "Banco",
  "financial.type.account.checking": "Corrente",
  "financial.type.account.savings": "Poupança",
  "financial.type.account.select.account": "Conta bancária",

  "admin.financial.total.payment": "Volume total de pagamento",
  "admin.financial.average.order": "Pedido médio",
  "admin.financial.gross.fees": "Taxas brutas",
  "admin.financial.total.clients": "Total de clientes",
  "admin.financial.orders.quantity": "Quantidade de pedidos",
  "admin.financial.complains": "Complains",
  "admin.financial.gross.fees.per.client": "Taxas brutas por cliente",
  "admin.financial.new.clients": "Novos clientes",
  "admin.financial.availabble": "Disponível para saque",
  "admin.financial.on.hold": "On hold",
  "admin.financial.refunded": "Estornado",
  "admin.order.list.store.name": "Estabelecimento",
  "admin.order.list.fee": "Taxa",

  "home.performance": "Performance",
  "home.performance.hint":
    "Esse é o crescimento que a sua empresa cresceu se compararmos o mesmo período antes da Preme",
  "payment.": "",
  "payment.new": "Novo",
  "payment.confirmed": "Aprovado",
  "payment.succeeded": "Aprovado",
  "payment.partially.confirmed": "Parcialmente Confirmado",
  "payment.pending": "Pendente",
  "payment.awaiting": "Aguardando",
  "payment.refused": "Recusado",
  "payment.canceled": "Cancelado",
  "payment.PendingChallenge": "Pendente",
  "payment.partially.canceled": "Parcialmente Cancelado",
  "payment.blocked": "Bloqueado",
  "payment.failed": "Recusado",
  "payment.disputed": "Em Disputa",
  "payment.expiration": "Expirado",
  "payment.expired": "Expirado",
  "payment.charged.back": "Disputa perdida",
  "payment.pre.authorized": "Pré autorizado",
  "payment.credit.card": "Cartão de Crédito",
  "payment.debit.card": "Cartão de Débito",
  "payment.boleto.bancario": "Boleto Bancário",

  "request.payment": "Solicitar pagamento",

  "request.payment.manual.payment.title": "Cobrar instantaneamente",
  "request.payment.payment.link.title": "Criar um link de pagamento",
  "request.payment.payment.type": "Tipo de pagamento",
  "request.payment.plan": "Plano",
  "request.payment.plan.single": "Uma vez",
  "request.payment.plan.recurring": "Recorrente",
  "request.payment.preview.title": "Pré-visualização",
  "request.payment.preview.tip.text":
    "Você pode atualizar o nome do estabalecimento e o logotipo em",
  "request.payment.preview.tip.text.link": "Ajustes",

  "request.payment.header.return": "Retornar",

  "request.payment.credit.card.data": "Dados do cartão",

  "header.search.country": "Procurar...",
  "header.change.country": "Alterar país",
  "header.from.it": "A partir de",

  "header.or.until": "ou em até",
  "header.installments.of": "parcelas de",
  "header.with.fees": "com juros",
  "header.without.fees": "sem juros",
  "header.in": "Em",
  "header.credit.card.once": "Cartão de crédito à vista.",
  "header.payment.data": "Dados do pagamento",

  "request.payment.optional.label": "Opcional",

  "request.payment.customer.name": "Nome do cliente",
  "request.payment.customer.email": "Email do cliente",
  "request.payment.currency": "Moeda",
  "request.payment.currency.value": "BRL (R$)",
  "request.payment.amount": "Valor",
  "request.payment.boleto.days.before.generate.boleto":
    "Enviar Boleto para o cliente quantos dias antes do vencimento?",
  "request.payment.max.subcriptions.charge.label": "Número máximo de cobranças",
  "request.payment.title": "Título",
  "request.payment.card": "Cartão",
  "request.payment.order.number": "Número da Venda",
  "request.payment.due.date": "Prazo de vencimento",
  "request.payment.processing": "Processando pagamento",
  "request.payment.confirmed": "Pagamento confirmado",
  "request.payment.description": "Descrição",
  "request.payment.duedate": "Data de vencimento do primeiro Boleto",
  "request.payment.expiration.link": "Data de expiração do link de pagamento",
  "request.payment.expiration.link.placeholder": "dd/mm/aaaa, hh:mm",
  "request.payment.limit.boleto.error.message.1":
    "O valor máximo do Boleto Bancário pode ser de",
  "request.payment.limit.boleto.error.message.2":
    ", converse com nossa equipe para verificar a alteração desse limite.",
  "request.payment.min.amount.boleto.error.message":
    "O valor mínimo para uma parcela de boleto é de R$ 3,00",
  "request.payment.not.found.order.number": "Número de ordem não encontrado.",
  "request.payment.search.order.number":
    "Caso existente, informe o número da venda",
  "request.payment.invalid.boleto.duedate.error.message":
    "Data de vencimento informada é inválida.",
  "request.payment.personal.data.optional":
    "Informar dados pessoas e endereço completo (opcional)",
  "request.payment.customer.currency": "Valor",
  "request.payment.final.fx.amount": "Valor final a pagar",

  "request.payment.plan.daily": "Diário",
  "request.payment.plan.weekly": "Semanal",
  "request.payment.plan.monthly": "Mensal",
  "request.payment.plan.quarterly": "Trimestral",
  "request.payment.plan.halfYearly": "Semestral",
  "request.payment.plan.yearly": "Anual",

  "request.payment.plan.daily.simple": "Dia",
  "request.payment.plan.weekly.simple": "Semana",
  "request.payment.plan.monthly.simple": "Mês",
  "request.payment.plan.quarterly.simple": "Trimestre",
  "request.payment.plan.halfYearly.simple": "Semestre",
  "request.payment.plan.yearly.simple": "Ano",

  "request.payment.payment.duedate": "Defina a data de vencimento do pagamento",
  "request.payment.payment.duedate.tip":
    "Defina uma data de vencimento para informar o seu cliente sobre até quando você espera receber o pagamento.",

  "credit.card.number": "Número do cartão",
  "credit.card.expiration": "MM/AA",
  "credit.card.cvv": "CVV",

  "request.payment.button.label": "Solicitar pagamento",
  "request.payment.tip.title": "Dica",
  "request.payment.tip.text":
    "Você pode criar um link de pagamento ou cobrar diretamente de um cliente se já tiver seus detalhes de pagamento.",
  "request.payment.modal.button.payment.link": "Criar um link de pagamento",
  "request.payment.modal.button.manually": "Cobrar instantaneamente",
  "request.payment.modal.button.list.links": "Ver todos os links de pagamento",
  "request.payment.generate.payment.link.label": "Criar link de pagamento",
  "request.payment.generate.boleto.label": "Gerar boleto de",
  "request.payment.generate.first.boleto.only.label": "Gerar primeiro boleto",
  "request.payment.generate.first.boleto.label": "Gerar primeiro boleto de",
  "request.payment.generate.all.boleto.label": "Gerar boletos",
  "request.payment.credit.card.btn": "Confirmar pagamento",
  "request.payment.credit.card.of.btn": "Confirmar pagamento de",
  "request.payment.credit.card.in.btn": "Confirmar pagamento em",
  "request.payment.charge.manually.label": "Cobrar manualmente",
  "request.payment.payment.method.label": "Método de pagamento",
  "request.payment.payment.method.cc.label": "Cartão de Crédito",
  "request.payment.payment.method.boleto.label": "Boleto Bancário",
  "request.payment.payment.method.recurring.label": "Recorrência",
  "request.payment.recurring": "Frequência",
  "request.payment.payment.types": "Metodo de pagamento",
  "request.payment.payment.types.0": "Cartão de crédito",
  "request.payment.payment.types.1": "Boleto Bancário",
  "request.payment.payment.types.01": "Cartão de crédito ou Boleto Bancário",
  "request.payment.recurring.description": "Descrição",
  "request.payment.recurring.charge.days.before":
    "Boleto é enviado para o cliente com quantos dias antes do vencimento?",
  "request.payment.recurring.max.charge": "Limite máximo de cobranças",
  "request.payment.recurring.tax": "Impostos",
  "request.payment.charge.later": "Cobrar mais tarde",
  "request.payment.link": "Link de pagamento",
  "request.payment.recurring.cpf": "CPF",
  "request.payment.card.details": "Detalhes do cartão",
  "request.payment.card.name": "Nome do titular",
  "request.payment.card.error": "O que houve",
  "request.payment.boleto.name": "Nome",
  "request.payment.boleto.lastname": "Sobrenome",
  "request.payment.boleto.email": "Email",
  "request.payment.boleto.birth": "Data de Nascimento",
  "request.payment.boleto.phone": "Número de telefone",
  "request.payment.boleto.postal.code": "CEP",
  "request.payment.boleto.address": "Endereço",
  "request.payment.boleto.address.number": "Número",
  "request.payment.boleto.address.complement": "Complemento",
  "request.payment.boleto.neighborhood": "Bairro",
  "request.payment.boleto.city": "Cidade",
  "request.payment.boleto.state": "Estado",
  "request.payment.boleto.bar.code": "Código de barras",
  "request.payment.boleto.expiration": "Vencimento",
  "request.payment.boleto.cpf.cnpj": "CPF/CNPJ",
  "request.payment.boleto.business.name": "Nome da empresa",
  "request.payment.boleto.discount": "Desconto",
  "request.payment.boleto.discount.none": "Nenhum",
  "request.payment.boleto.discount.percentual": "Percentual (%)",
  "request.payment.boleto.discount.percentual.daily": "Percentual (%) diário",
  "request.payment.boleto.discount.percentual.monthly": "Percentual (%) mensal",
  "request.payment.boleto.discount.amount": "Valor (R$)",
  "request.payment.boleto.tax.fixed.amount": "Valor",
  "request.payment.boleto.discount.limit": "Data limite do desconto",
  "request.payment.boleto.fine": "Multa",
  "request.payment.boleto.fees.rp": "Juros",
  "request.payment.boleto.installment": "Boleto parcelado",
  "request.payment.generate.all.boleto":
    "Gerar todos os boletos instantaneamente",
  "request.payment.boleto.installment.2": "Parcelas no boleto",
  "request.payment.boleto.installments.label":
    "Selecione em até quantas parcelas cobrar",
  "request.payment.with.fees": "(com juros)",
  "request.payment.without.fees": "(sem juros)",
  "request.payment.of": "de",
  "request.payment.credit.card.installments": "Parcelas no cartão",
  "request.payment.brasilia.time": "Horário de Brasília",
  "request.payment.fee.transfer": "Repassar taxas",
  "request.payment.fee.transfer.explanation":
    "As taxas serão repassadas para o seu consumidor. Assim, você receberá o valor total.",
  "request.payment.pre.fill": "Pré-informar dados do cliente",
  "request.payment.pre.fill.explanation":
    "Informe dados do comprador para aumentar a taxa de conversão da sua venda",
  "request.payment.address.data": "Coletar endereço do cliente",
  "request.payment.address.data.explanation":
    "Para pagamentos com Cartão de Crédito, também será necessário informar os dados completos do comprador",
  "request.payment.inform.tax": "Informar imposto",
  "request.payment.inform.tax.explanation":
    "Informe o valor que será retido para pagamentos de impostos e tributos para uma melhor visualização do valor",
  "request.payment.inform.cycle": "Definir número máximo de cobranças",
  "request.payment.inform.cycle.tip":
    "Informe uma quantidade máxima de cobranças a serem realizadas por usuário neste plano.",
  "request.payment.tax": "Imposto",
  "request.payment.select.label": "Selecione",
  "request.payment.generate.payment.link.btn": "Gerar link de pagamento",
  "request.payment.select.plan": "Selecione um plano",

  "request.payment.banner.label":
    "Adicione imagens para serem exibidas no checkout",
  "request.payment.banner": "Banners",
  "request.payment.top.banner": "Banner supeior",
  "request.payment.top.banner.dimensions":
    "Dimensões recomendadas: 500px (largura) por 200px (altura)",
  "request.payment.right.banner": "Banner inferior",
  "request.payment.right.banner.dimensions":
    "Dimensões recomendadas: 390px (largura) por 456px (altura)",

  "payment.link.details.success.created.title":
    "Link de pagamento criado com sucesso!",
  "payment.link.details.success.created.note":
    "Nós não enviamos o link de pagamento com o seu cliente, para que você possa compartilhar da forma desejada por você, usando o link ou QR Code, podendo ser por Email, WhatsApp ou redes sociais.",

  "payment.link.fixed.installment": "Parcela fixa",
  "payment.link.pay.later": "Pagamento com cartão Pré-autorizado",
  "payment.link.pay.later.tip":
    "Ao invés de cobrar o seu cliente instantaneamente, o valor será pré-autorizado para que você faça a cobrança em até 28 dias.",
  "order.details.pay.later": "O valor está pré-autorizado",
  "payment.link.pay.later.btn": "Cobrar agora",

  "product.details.success.created.title": "Produto criado com sucesso!",
  "product.details.success.created.subtitle":
    "Crie um link de Checkout para receber pagamentos sobre este produto.",
  "product.details.create.payment.link": "Criar link de pagamento",
  "product.details.shippable": "Produto para envio",
  "product.details.package": "Detalhes do pacote",
  "product.details.unit.label": "Nome da unidade",
  "product.details.payment.link": "Link de pagamento",

  "create.product.title": "Criar produto",
  "cutomize.product.title": "Customizar produto",
  "create.a.product": "Criar produto",
  "view.all.product": "Produtos",
  "create.product.media.label": "Imagens",
  "create.product.price.label": "Preço por unidade",
  "deactivate.product.label": "Desativar produto",
  "delete.product.label": "Excluir produto",

  "create.product.shippable.product": "Produto para envio",
  "create.product.weight": "Peso",
  "create.product.weight.placeholder": "Peso em kilogramas",
  "create.product.width": "Largura",
  "create.product.width.placeholder": "Largura em centimetros",
  "create.product.height": "Altura",
  "create.product.height.placeholder": "Altura em centimetros",
  "create.product.length": "Comprimento",
  "create.product.length.placeholder": "Comprimento em centimetros",
  "create.product.centimeters": "centimetros",
  "create.product.kilograms": "kilogramas",

  "create.product.name.unit.label": "Nome da unidade",
  "create.product.name.unit.label.custom": "Customizado",
  "create.product.name.unit.label.custom.i.e": "ex: Livros, Sapatos, Camisas",

  "create.product.sales.label": "Página de venda",
  "create.product.page.label": "Página do produto",
  "create.product.sales.description": "Sua página de venda",
  "create.product.sales.placeholder": "https://exemplo.com/produto",

  "request.payment.enota": "Gerar NFe automaticamente com eNotas",
  "request.payment.enota.when.generate": "Quando gerar a NFe",
  "request.payment.enota.select": "Selecione um período",
  "request.payment.enota.select.on.sell": "No ato da venda",
  "request.payment.enota.select.after.warranty": "Após a garantia",
  "request.payment.enota.select.not.generate": "Não emitir",
  "request.payment.enota.select.warranty.days": "Número de dias da garantia",
  "request.payment.enota.select.send": "Enviar NFe para o consumidor",
  "request.payment.enota.select.send.select": "Selecione",
  "request.payment.enota.description": "Descrição da NFe",

  "settings.memberkit.description":
    "A parceria que você procurava para vender produtos educacionais com a Primefy. Faça o upload de seus cursos com Memberkit by Primefy.",
  "settings.memberkit.modal.title": "Memberkit",
  "settings.memberkit.modal.key.label": "Informe sua Chave API para conectar",
  "settings.memberkit.modal.where.key.label.1": "Clique aqui",
  "settings.memberkit.modal.where.key.label.2":
    " para saber onde encontrar a sua Chave API da Memberkit",
  "settings.memberkit.modal.api.key.label": "Chave API",
  "settings.memberkit.modal.api.secret.key.label":
    "Insira aqui sua chave secreta",
  "settings.memberkit.modal.cancel.label": "Cancelar",
  "settings.memberkit.modal.add.label": "Adicionar conta",
  "settings.memberkit.modal.connecting.label": "Conectando a Memberkit...",
  "settings.memberkit.modal.connecting.label.2":
    "Cadastrando Cursos da Memberkit como Produtos na Primefy...",
  "settings.memberkit.modal.success.connection.label":
    "Conexão realizada com sucesso!",
  "settings.memberkit.modal.success.connection.desc":
    "Para finalizar, precisamos que você defina o preço de cada curso.",
  "settings.memberkit.modal.do.you.sell.title": "Você vende por assinatura?",
  "settings.memberkit.modal.do.you.sell.desc":
    "Você poderá criar seus planos de assinatura depois, dando acesso a um ou mais cursos para seus alunos, definindo um valor customizado para suas assinaturas.",
  "settings.memberkit.modal.finish.integration": "Finalizar integração",
  "settings.memberkit.modal.course.integration": "Curso",
  "settings.memberkit.modal.price.integration": "Preço",

  "request.payment.redirect.payment":
    "Página de confirmação de redirecionamento",
  "request.payment.redirect.payment.instead":
    "Em vez disso, redirecione o usuário para o seu site.",
  "request.payment.redirect.payment.page": "Página de confirmação",
  "request.payment.redirect.payment.page.placeholder":
    "https://exemplo.com/confirmacao",

  "request.payment.create.plan": "Criar um novo plano",
  "request.payment.create.plan.trial": "Informe um número em dias",
  "request.payment.create.plan.trial.placeholder":
    "Quantos dias antes do primeiro pagamento",
  "request.payment.create.plan.customize.title": "Customizar",
  "request.payment.create.plan.label.button": "Criar plano",
  "request.payment.create.plan.limit.payment.methods":
    "Limitar métodos de pagamentos",

  "request.payment.charge.laterl.btn.label": "Cobrar mais tarde",
  "request.payment.charge.laterl.if.selected":
    "Para cobrar, acesse os detalhes da venda. Caso não ocorra nenhuma ação em 7 dias, o sistema cobrará automaticamente.",
  "request.payment.charge.laterl.btn.explaination":
    "Será cobrado em 7 dias corridos",
  "request.payment.success.link": "Link gerado com sucesso",
  "request.payment.error.link": "Erro ao gerar link de pagamento",
  "request.payment.success.charged": "Cobrança efetuada com sucesso",
  "request.payment.error.charged": "Erro ao processar a cobrança.",
  "request.payment.errors": "Erros",
  "request.payment.success.boleto.generated": "Boleto gerado com sucesso",
  "request.payment.error.boleto.generated": "Erro ao gerar boleto.",
  "request.payment.copy.barcode": "Copiar código de barras",
  "request.payment.view.boleto": "Visualizar Boleto Bancário",
  "request.payment.view.all.boleto": "Visualizar todos os Boletos Bancários",
  "request.payment.customize.boleto.btn": "Customizar Boleto Bancário",
  "request.payment.customize.boleto.explanation":
    "Determine multa, juros e descontos para pagamento antecipado, assim como customizar a geração dos boletos e a data de vencimento",
  "request.payment.customize.recurring.boleto.explanation":
    "Determine a geração dos boletos e a data de vencimento",
  "request.payment.request.type": "Tipo de cobrança",
  "request.payment.request.type.single": "Uma vez",
  "request.payment.request.type.recurring": "Recorrente",

  "request.payment.pix.copy.qrcode": "Copiar código",
  "request.payment.pix.download.qrcode": "Baixar QR Code",
  "request.payment.pix.generate.pix": "Gerar QR Code",

  "view.as.btn.label": "Visualize por Estabelecimento",
  "viewing.as.btn.label": "Visualizando como ",
  "return.to.view.as.business": "Retornar a visualizar como Negócio",
  "view.as.btn.establishments": "Estabelecimentos",
  "view.as.processing": "Processando",
  "view.as.search.establishment": "Pesquisar",
  "view.as.tip.text":
    "Você pode criar estabelecimentos para organizar melhor suas vendas, pode ser o mesmo CNPJ de uma empresa já criada ou diferentes.",
  "view.as.create.store": "Criar estabelecimento",

  "plan.select.tip.text":
    "Você pode criar um novo plano e definir o período de faturamento, formas de pagamento e até mesmo o período de teste.",
  "bank.account.select.tip.text":
    "Você pode adicionar quantas contas bancárias desejar para gerenciar melhor sua contabilidade.",

  "request.payment.recurring.plan": "Plano",
  "request.payment.recurring.name.plan": "Nome do plano",
  "request.payment.recurring.name.plan.placeholder":
    "Tênis, óculos de sol, streaming, etc.",
  "request.payment.recurring.trial": "Período de teste",
  "request.payment.recurring.trial.tip":
    "Você pode oferecer um período em dias para o seu cliente testar o seu produto antes de você cobrá-lo pela primeira vez.",
  "request.payment.create.plan.success.message": "Plano criado com sucesso.",
  "request.payment.create.plan.error.message":
    "Houve um erro ao criar plano, tente novamente.",

  "limit.installments": "Limitar parcelas por método de pagamento",

  "transactions.title": "Vendas",
  "transactions.order.details": "Detalhes da venda",
  "transactions.session": "Sessão",
  "transactions.customer.details": "Detalhes do comprador",
  "transactions.payment.details": "Detalhes de pagamento",
  "transactions.order.events": "Eventos",
  "transactions.cost": "Taxa",
  "transactions.refund.control": "Estornos",
  "transactions.refund.control.reference": "Motivo",
  "transactions.refund.control.reference.placeholder":
    "Somente para uso interno",
  "transactions.refund.control.percentage": "Porcentagem",
  "transactions.refund.error.message": "Não foi possível efetuar o estorno",
  "transactions.refund.success.message": "Estorno enviado com sucesso",
  "transactions.refund.select.card": "Escolha o cartão para estornar",
  "transactions.refund.select.card.placeholder": "Cartão",
  "transactions.type.recurring": "Recorrente",
  "transactions.type.single": "Uma vez",

  "transactions.search": "Buscar por",
  "transactions.search.number": "Número da venda",
  "transactions.search.identificationNumber": "Número de CPF/CNPJ",
  "transactions.search.name": "Nome ou Sobrenome",
  "transactions.search.email": "Endereço de email",
  "transactions.search.id": "Id do cliente",

  "sale.commissions.title": "Comissões",
  "sale.commissions.cost": "Comissão",
  "sale.commissions.seller.name": "Vendedor",
  "sale.commissions.brl.commission.amount": "Valor da comissão",

  "login.welcome": "Bem vindo",
  "login.button.login": "Acessar conta",
  "login.button.login.continue": "Continue",
  "login.invalid.username.or.password": "Usuário ou senha incorretos",
  "login.password": "Senha",
  "error.unknown.error": "Desculpe, um erro inesperado ocorreu",
  "error.failed":
    "Verifique os dados inseridos e tente novamente, ou entre em contato com o seu banco.",
  "login.access.dashboard": "Acessar painel",
  "login.recover.pass.dashboard": "Redefinir senha",
  "login.get.link.dashboard": "Receber link",
  "login.change.pass.dashboard": "Alterar senha",
  "login.button.recover.pass": "Esqueceu sua senha?",
  "login.text.recover.pass":
    "Enviaremos um email com um link para que você possa redefinir a sua senha",
  "login.textcreate.account.text": "Não possui uma conta?",
  "login.text.existent.account.text": "Já possui uma conta?",
  "login.text.existent.access.account": "Acesse agora",
  "login.textcreate.account.link": "Crie sua conta em 10 minutos",

  "redefine.password.success.link.sent":
    "Link enviado com sucesso para o email informado.",
  "redefine.password.user.not.found": "Usuário (email) não encontrado.",
  "redefine.password.success.redirect":
    "Senha alterada com sucesso. Redirecionando para o login...",
  "redefine.password.failed":
    "Desculpe, não foi possível alterar sua senha. Tente novamente.",
  "redefine.password.new.pass.label": "Nova Senha",

  "user.profile": "Editar perfil",
  "user.signout": "Sair",
  "user.profile.title": "Dados pessoais",
  "user.profile.email": "Email ",
  "user.profile.name": "Nome ",
  "user.profile.password": "Senha ",
  "user.profile.new.password": "Senha nova ",
  "user.profile.old.password": "Senha antiga ",

  "settings.business.information.title": "Informações do Negócio",
  "settings.business.information.text":
    "Você forneceu todas as informações necessárias do seu negócio para usar a Primefy. Podemos entrar em contato com você para coletar informações adicionais.",
  "settings.business.information.legal.business.name": "Razão Social",
  "settings.business.information.trading.name": "Nome fantasia",
  "settings.business.information.statement.descriptor": "Código no extrato",
  "settings.business.information.category": "Categoria",
  "settings.business.information.website": "Website",
  "settings.business.information.phone": "Telefone de suporte",
  "settings.business.information.address": "Endereço da empresa",
  "settings.business.information.logo": "Logo",

  "settings.business.api.key.enotas": "Chave de API",
  "settings.business.business.number": "Enviando",
  "settings.balance.recipient": "Recebedor",
  "settings.select.store.placeholder": "Selecione um estabelecimento",

  "settings.users.text":
    "Gerencie o acesso dos usuários, definindo o nível de acesso e quais estabelecimentos os usuários podem ver.",
  "settings.stores.text":
    "Você pode criar vários estabelecimentos com o mesmo CNPJ para ter uma melhor gestão contábil do seu negócio, ou até mesmo com CNPJs diferentes caso você seja um marketplace ou uma empresa de entrega, por exemplo.",
  "settings.fees.text":
    "Seu plano atual é o padrão. Se você deseja se inscrever para um plano de preços diferente, ",
  "settings.fees.text.link": "entre em contato com nossa equipe",

  "settings.team.list.header.user": "Usuário",
  "settings.team.list.header.email": "Endereço de email",
  "settings.team.list.edit": "Editar",

  "settings.store.list.status": "Status",
  "settings.store.list.name": "Nome do estabelecimento",
  "settings.store.list.id": "Número de identificação",
  "settings.store.list.created": "Data de criação",

  "settings.store.fee.boleto": "Boleto Bancário",
  "settings.store.fee.credit": "Cartão de Crédito",
  "settings.store.fee.pix": "PIX",
  "settings.store.fee.installments": "Parcela",
  "settings.store.fee.network": "Bandeira",
  "settings.store.fee.fee": "Taxa",

  "settings.store.create.user.label": "Criar um novo usuário",

  "settings.integrations.title": "Integrações",
  "settings.integrations.subtitle":
    "Integre o Primefy com aplicativos e automatize suas vendas sem atrito.",
  "settings.integrations.e.notas":
    "A eNotas emite suas notas fiscais no Brasil automaticamente para que você evite multas tributárias elevadas e possa se concentrar em aumentar suas vendas, produzir melhor conteúdo, atender melhor seus clientes, cuidar do que mais importa: prosperar e viver mais!",
  "settings.integrations.connect": "Conectar",
  "settings.integrations.connected": "Conectado",
  "settings.integrations.events": "eventos",
  
  "settings.new.user.error":
    "Nome inválido. O nome precisa ter de 1 a 45 caracteres.",
  "settings.new.user.email.error":
    "Email inválido. Um email precisa ter no máximo 80 caracteres e seguir o padrão com '.' e '@'.",
  "settings.new.user.store.error": "Por favor selecione uma store.",

  "notification.item.snooze": "Silenciar",
  "notification.item.time": "horas atrás",
  "notification.item.view.all": "Visualizar todos",

  "user.profile.page.title": "Perfil",
  "user.profile.page.subtitle":
    "Você forneceu todas as informações necessárias do seu negócio para usar a Primefy. Podemos entrar em contato com você para coletar informações adicionais.",
  "user.profile.page.notification.title": "Notificações",
  "user.profile.page.notification.subtitle":
    "Gerencie se você gostaria de receber notificações sobre seu negócio em execução.",
  "user.profile.team.title": "Equipe",
  "user.profile.team.name": "Nome",
  "user.profile.team.email": "Email",
  "user.profile.team.password": "Senha",
  "user.profile.team.access": "Acesso",
  "user.profile.team.access.owner": "Sócio",
  "user.profile.team.access.administrator": "Administrador",
  "user.profile.team.access.agent": "Agente",
  "user.profile.team.access.view.only": "Somente visualização",
  "user.profile.team.access.developer": "Desenvolvedor",
  "user.profile.team.access.business": "Negócio",
  "user.profile.team.access.store": "Estabelecimento",
  "user.profile.team.entity.type": "Tipo de cobrança",
  "user.profile.team.store": "Store",
  "user.profile.team.select.default": "Selecione",
  "user.profile.team.add.user.button.label": "Adicionar usuário",
  "user.profile.team.cancel.button.label": "Cancelar",
  "user.profile.team.delete.user.button.label": "Excluir usuário",
  "user.profile.team.save.changes.button.label": "Salvar alterações",
  "user.profile.team.create.account.button.label": "Criar conta",
  "user.profile.team.create.user.error.password":
    "A senha precisa ter mais de 8 caracteres.",
  "user.profile.team.create.user.error.administrator":
    "Um usuário administrador deve possuir um email @premepay.",
  "user.profile.team.create.user.error.already.exists":
    "Usuário já cadastrado.",
  "user.profile.team.create.user.error.update.try.again":
    "Erro ao tentar atualizar informações do usuário. Tente novamente.",
  "user.profile.team.create.user.error.create.try.again":
    "Erro ao tentar criar novo usuário. Tente novamente.",

  "user.profile.notifications.success.payments": "Pagamentos confirmados",
  "user.profile.notifications.payouts": "Payouts",
  "user.profile.notifications.disputes": "Disputas",
  "user.profile.notifications.refunds": "Estornos",
  "user.profile.notifications.failed.payments": "Pagamentos falhados",

  "user.profile.ui.create.store.button.label": "Adicionar novo estabelecimento",

  "user.profile.ui.save": "Salvar",
  "user.profile.ui.save.changes": "Salvar alterações",

  "user.profile.right.title": "Estabelecimentos",
  "user.profile.right.company.name": "Razão social",
  "user.profile.right.trading.name": "Nome fantasia",
  "user.profile.right.cnpj": "CNPJ",
  "user.profile.right.phone": "Telefone",
  "user.profile.right.zipcode": "Cep",
  "user.profile.right.address": "Endereço",
  "user.profile.right.reference": "Complemento",
  "user.profile.right.district": "Bairro",
  "user.profile.right.city": "Cidade",
  "user.profile.right.state": "Estado",
  "user.profile.right.logo": "Logo",
  "user.profile.right.logo.suggest":
    "Sugerimos uma logo SVG, com fundo transparente para ser aplicado em páginas com fundo branco.",
  "user.profile.right.logo.placeholder":
    "Arraste e solte seu arquivo ou <span class='filepond--label-action'>Busque-o</span>",
  "user.profile.right.fees.title": "Taxas aplicadas",
  "user.profile.right.fees.boleto": "Boleto bancário",
  "user.profile.right.fees.boleto.after": "por Boleto compensado",
  "user.profile.right.fees.pix": "PIX",
  "user.profile.right.fees.pix.after": "por venda confirmada",
  "user.profile.right.fees.credit.1x": "Crédito à vista (Visa e Mastercard)",
  "user.profile.right.fees.credit.1x.after": "por venda confirmada",
  "user.profile.right.fees.credit.1x.others": "Crédito à vista (Elo e outros)",
  "user.profile.right.fees.credit.2.6.x": "Crédito 2x à 6x (Visa e Mastercard)",
  "user.profile.right.fees.credit.2.6.x.others":
    "Crédito 2x à 6x (Elo e outros)",
  "user.profile.right.fees.credit.2.6.x.after": "por venda confirmada",
  "user.profile.right.fees.credit.7.12.x":
    "Crédito 7x à 12x (Visa e Mastercard)",
  "user.profile.right.fees.credit.7.12.x.others":
    "Crédito 7x à 12x (Elo e outros)",
  "user.profile.right.fees.credit.7.12.x.after": "por venda confirmada",
  "user.profile.right.fees.anticipation": "Antecipação",
  "user.profile.right.fees.anticipation.after": "por parcela antecipada",

  "detail.order.number": "Número da venda",
  "detail.order.status": "Status",
  "detail.order.risk.fraud.alert": "Venda bloqueada por suspeita de fraude",
  "detail.order.risk.fraud.button.allow": "Autorizar venda",
  "detail.order.risk.fraud.score": "Risco de fraude",
  "detail.order.risk.fraud.score.label.low": "Baixo",
  "detail.order.risk.fraud.score.label.medium": "Médio",
  "detail.order.risk.fraud.score.label.high": "Alto",
  "detail.order.cancel.subscription.day": "Assinatura em andamento a cada dia",
  "detail.order.cancel.subscription.week":
    "Assinatura em andamento a cada semana",
  "detail.order.cancel.subscription.month":
    "Assinatura em andamento a cada mês",
  "detail.order.cancel.subscription.quarterly":
    "Assinatura em andamento a cada 3 meses",
  "detail.order.cancel.subscription.halfYearly":
    "Assinatura em andamento a cada 6 meses",
  "detail.order.cancel.subscription.yearly":
    "Assinatura em andamento a cada ano",

  // "detail.order.cancel.subscription.days": " dias",

  "detail.order.cancel.subscription.button": "Cancelar assinatura",
  "detail.order.cancel.subscription.canceled": "Assinatura cancelada",
  "detail.order.type": "Tipo de cobrança",
  "detail.order.type.recurring": "Recorrente",
  "detail.order.type.single": "Único",
  "detail.order.date.created": "Criado",
  "detail.order.date.received": "Recebido",
  "detail.order.description": "Descrição",
  "detail.order.name.first": "Nome",
  "detail.order.business.name": "Nome da empresa",
  "detail.order.name.surname": "Sobrenome",
  "detail.order.cpf": "CPF",
  "detail.order.cnpj": "CNPJ",
  "detail.order.dob": "Data de nascimento",
  "detail.order.register": "Data de registro",
  "detail.order.email": "Email",
  "detail.order.phone": "Telefone",
  "detail.order.zipcode": "CEP",
  "detail.order.zip.code": "CEP (sem pontos ou traços)",
  "detail.order.street": "Rua",
  "detail.order.house.number": "Número",
  "detail.order.house.reference": "Referência",
  "detail.order.district": "Bairro",
  "detail.order.city": "Cidade",
  "detail.order.state": "Estado",
  "detail.order.country": "País",
  "detail.order.events.refunded": "Estornado",
  "detail.order.events.credit.card": "Cartão de crédito",
  "detail.order.events.received": "Pagamento recebido",
  "detail.order.events.expired.pix": "PIX expirado",
  "detail.order.events.failed": "Recusado",
  "detail.order.events.created": "Criado",
  "detail.order.events.boleto.n": "Boleto",
  "detail.order.events.boleto.of.the": "de",
  "detail.order.events.button.view.boleto": "Visualizar Boleto",
  "detail.order.events.request.sent": "Requisição de pagamento enviada",
  "detail.order.events.canceled.boleto": "Boleto Cancelado",
  "detail.order.transaction.code": "Código da transação",
  "detail.order.payment.method": "Método do pagamento",
  "detail.order.installments.quantity": "Parcelamento",
  "detail.order.card.type": "Bandeira do cartão",
  "detail.order.card.holder": "Nome no cartão",
  "detail.order.card.number": "Número do cartão",
  "detail.order.expiry.date": "Data de expiração",
  "detail.order.received.amount": "Valor total da venda",
  "detail.order.tax": "Imposto pré-informado",
  "detail.order.preme.fee": "Taxa de operação",
  "detail.order.additional.fee": "Taxas adicionais",
  "detail.sale.commission.amount": "Sua comissão",
  "detail.sale.commission.seller": "Vendido por",
  "detail.order.net.amount": "Você recebe",
  "detail.order.others": "Outros",
  "detail.order.commision": "Comissão",
  "detail.order.boleto.barcode": "Código de barras ",
  "detail.order.boleto.due.date": "Data de vencimento",
  "detail.order.net.amount.local.currency": "Valor líquido em sua moeda",
  "detail.order.payment.link": "Link de pagamento",
  "detail.order.already.paid": "Valor pago até o momento",
  "detail.order.iof": "IOF",

  "detail.order.ip.address": "Endereço de IP",
  "detail.order.os": "Sistema operacional",
  "detail.order.browser": "Navegador",
  "detail.order.device": "Aparelho ",
  "detail.order.location": "Localização",

  "detail.order.verify.pix.status": "Verificar status do pagamento",

  "detail.order.receivables.title": "Recebíveis",
  "detail.order.paid": "Recebido",
  "detail.order.scheduled": "Agendado",
  "detail.order.pending": "Pendente",
  "detail.order.canceled": "Cancelado",
  "detail.order.refunded": "Estornado",
  "detail.order.anticipation.fee": "Antecipação de taxa",

  "ui.cta.processing": "Processando...",
  "ui.cta.loading": "Carregando...",
  "ui.cta.create.plan": "Criar novo plano",
  "ui.cta.next": "Continuar",

  "manager.links.title": "Links de pagamento",
  "manager.links.header.modal": "Gerenciar links de pagamento",
  "manager.links.header.table.status": "Status",
  "manager.links.header.table.title": "Título",
  "manager.links.header.table.name": "Nome",
  "manager.links.header.table.price": "Preço",
  "manager.links.header.table.quantity.sales": "Vendas",
  "manager.links.header.table.store": "Estabelecimento",
  "manager.links.header.table.url": "URL do link",
  "manager.links.header.table.created": "Criado às",
  "manager.links.details.url": "URL do link",
  "manager.links.details.amount": "Valor",
  "manager.links.details.title": "Título",
  "manager.links.details.payment.methods": "Métodos de pagamento",
  "manager.links.details.payment.installments": "Parcelas",
  "manager.links.details.description": "Descrição",
  "manager.links.details.order.number": "Número de venda associado",

  "manager.links.all.details.title": "Detalhes do link de pagamento",
  "manager.links.customized.details.title": "Detalhes customizados",
  "manager.links.details.sales.title": "Vendas",
  "manager.links.details.billing.period": "Frequência de cobrança",
  "manager.links.details.trial.period": "Período grátis",
  "manager.links.details.trial.period.days": "Dias",
  "manager.links.details.activate": "Ativar link de pagamento",
  "manager.links.details.desactivate": "Desativar link de pagamento",
  "manager.links.details.tip": "Dica",
  "manager.links.details.tip.text":
    "Você pode desativar e reativar um link quantas vezes desejar.",

  "manager.links.details.payment.methods.0": "Cartão de crédito",
  "manager.links.details.payment.methods.1": "Boleto",
  "manager.links.details.payment.methods.01": "Cartão de crédito e Boleto",
  "manager.links.details.payment.methods.03": "Cartão de crédito e Pix",
  "manager.links.details.payment.methods.3": "Pix",
  "manager.links.details.payment.methods.13": "Boleto e Pix",
  "manager.links.details.payment.methods.013": "Todos os disponíveis",
  "manager.links.details.customized.boleto": "Boleto Customizado",
  "manager.links.details.billing address": "Endereço de cobrança",
  "manager.links.details.billing address.required": "Requerido",
  "manager.links.details.billing address.no.required": "Não requerido",
  "manager.links.details.pre.fill.data": "Dados do cliente pre-informados",
  "manager.links.details.include.fee.transfer":
    "Cliente paga pelas taxas de pagamento",
  "manager.links.details.include.fee.transfer.yes": "Sim",
  "manager.links.details.include.fee.transfer.no": "Não",
  "manager.links.details.sales.view": "Visualizar",

  "ui.label.copy": "Copiar",

  "manager.links.details.copy.barcode": "Copiar link de pagamento",
  "manager.links.details.copy.barcode.copied": "Copiado!",
  "manager.links.details.create.new.order": "Solicitar novo pagamento",

  "manager.links.details.generate.qrcode": "Gerar QR Code",
  "manager.links.details.qrcode": "Baixar QR Code",
  "manager.links.details.download.qrcode": "Baixar QR Code",

  "manager.links.details.delete.link": "Excluir link",

  "payment.error.credit.expiration": "Validade do cartão inválida",

  "error.card.number": "Número do cartão inválido",
  "error.card.expiration":
    "Data de expiração inválida. O mês precisa ser de 01 a 12 e o ano de expiração não pode ser anterior ao ano vigentee. A data de expiração deve ser no formato MM/AA",
  "error.card.cvv": "O código de segurança precisa ter no mínimo 3 caracteres",

  "create.store.page.title": "Adicionar estabelecimento",
  "create.store.page.header.pending": "Aguardando aprovação",
  "create.store.page.header.created": "Estabelecimento criado",

  "create.store.page.tip.1":
    "Você pode utilizar um CNPJ já cadastrado para criar estabelecimentos com o intuito de melhor gerenciar o seu painel",
  "create.store.page.tip.2": "A conta bancária estar vinculada ao",
  "create.store.page.tip.2.1": "por segurança",

  "create.store.page.intern.text": "Dados do negócio",
  "create.store.page.intern.bank.data": "Dados bancários",
  "create.store.page.intern.socio.data": "Dados do sócio",
  "create.store.page.intern.docs.data": "Docs cadastrais",

  //visa
  "request.payment.api.visa.error.4":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.15":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.51":
    "Transação não autorizada. Cartão restrito por falta de limite, contate o seu banco emissor.",
  "request.payment.api.visa.error.39":
    "Transação não autorizada. Utilize a função débito.",
  "request.payment.api.visa.error.52":
    "Transação não autorizada. Utilize a função crédito.",
  "request.payment.api.visa.error.53":
    "Transação não autorizada. Utilize a função crédito.",
  "request.payment.api.visa.error.59":
    "Transação não autorizada. Compra com suspeita de fraude, contate o seu banco emissor.",
  "request.payment.api.visa.error.58":
    "Transação não autorizada. O cartão fornecido não tem permissão para realizar esse tipo de transação. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.93":
    "Transação não autorizada pelo seu banco por suspeita de fraude. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.6":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.visa.error.3":
    "Transação não autorizada. Comerciante inválido.",
  "request.payment.api.visa.error.19":
    "Transação não autorizada. Problema no adquirente.",
  "request.payment.api.visa.error.12":
    "Transação não autorizada. Erro no formato. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.82":
    "Transação não autorizada. Erro no cartão. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.75":
    "Transação não autorizada. Excedida a quantidade de tentativa de senha/compra.",
  "request.payment.api.visa.error.76":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.61":
    "Transação não autorizada. Valor excedido. Contate a central do seu cartão.",
  "request.payment.api.visa.error.64":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.N4":
    "Transação não autorizada. Valor excedido. Contate a central do seu cartão.",
  "request.payment.api.visa.error.74":
    "Transação não autorizada. Senha vencida. Contate a central do seu cartão.",
  "request.payment.api.visa.error.81":
    "Transação não autorizada. Senha vencida. Contate a central do seu cartão.",
  "request.payment.api.visa.error.78":
    "Transação não autorizada. Desbloqueie o cartão.",
  "request.payment.api.visa.error.91":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",
  "request.payment.api.visa.error.92":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.94":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.96":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",
  "request.payment.api.visa.error.N8":
    "Transação não autorizada. Valor diferente da pré autorização. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.N3":
    "Transação não autorizada. Saque não disponível. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.R0":
    "Transação não autorizada. Suspensão de pagamento recorente. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.R1":
    "Transação não autorizada. Suspensão de pagamento recorente. Contate o seu banco para tentar novamente.",
  "request.payment.api.visa.error.R3":
    "Transação não autorizada. Suspensão de pagamento recorente. Contate o seu banco para tentar novamente.",

  //master
  "request.payment.api.master.error.15":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.master.error.51":
    "Transação não autorizada. Cartão restrito por falta de limite, contate o seu banco emissor.",
  "request.payment.api.master.error.58":
    "Transação não autorizada. O cartão fornecido não tem permissão para realizar esse tipo de transação. Contate o seu banco para tentar novamente.",
  "request.payment.api.master.error.1":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.master.error.3":
    "Transação não autorizada. Comerciante inválido.",
  "request.payment.api.master.error.30":
    "Transação não autorizada. Problema no adquirente.",
  "request.payment.api.master.error.88":
    "Transação não autorizada. Erro no cartão. Contate o seu banco para tentar novamente.",
  "request.payment.api.master.error.12":
    "Transação não autorizada. Valor da parcela inválido.",
  "request.payment.api.master.error.75":
    "Transação não autorizada. Excedida a quantidade de tentativa de senha/compra. Contate a central do seu cartão.",
  "request.payment.api.master.error.61":
    "Transação não autorizada. Valor excedido. Contate a central do seu cartão.",
  "request.payment.api.master.error.91":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",
  "request.payment.api.master.error.96":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",

  //elo
  "request.payment.api.elo.error.51":
    "Transação não autorizada. Cartão restrito por falta de limite, contate o seu banco emissor.",
  "request.payment.api.elo.error.59":
    "Transação não autorizada. Compra com suspeita de fraude, contate o seu banco emissor.",
  "request.payment.api.elo.error.4":
    "Transação não concluída. Por favor tente novamente.",
  "request.payment.api.elo.error.58":
    "Transação não autorizada. Comerciante inválido.",
  "request.payment.api.elo.error.6":
    "Transação não autorizada. Consultar credenciador.",
  "request.payment.api.elo.error.19":
    "Transação não autorizada. Problema no adquirente.",
  "request.payment.api.elo.error.30":
    "Transação não autorizada. Erro no formato. Contate o seu banco para tentar novamente.",
  "request.payment.api.elo.error.82":
    "Transação não autorizada. Erro no cartão. Contate o seu banco para tentar novamente.",
  "request.payment.api.elo.error.64":
    "Transação não autorizada. Valor mínimo de transação inválido.",
  "request.payment.api.elo.error.23":
    "Transação não autorizada. Valor da parcela inválido.",
  "request.payment.api.elo.error.38":
    "Transação não autorizada. Excedida a quantidade de tentativa de senha/compra. Contate a central do seu cartão.",
  "request.payment.api.elo.error.75":
    "Transação não autorizada. Excedida a quantidade de tentativa de saque. Contate a central do seu cartão.",
  "request.payment.api.elo.error.61":
    "Transação não autorizada. Valor excedido. Contate a central do seu cartão.",
  "request.payment.api.elo.error.74":
    "Transação não autorizada. Senha vencida. Contate a central do seu cartão.",
  "request.payment.api.elo.error.P5":
    "Transação não autorizada. Senha inválida. Contate o seu banco para tentar novamente.",
  "request.payment.api.elo.error.76":
    "Transação não autorizada. Conta destino inválida ou inexistente. Contate o seu banco para tentar novamente.",
  "request.payment.api.elo.error.77":
    "Transação não autorizada. Conta de origem inválida ou inexistente. Contate o seu banco para tentar novamente.",
  "request.payment.api.elo.error.78":
    "Transação não autorizada. Desbloqueie o cartão.",
  "request.payment.api.elo.error.91":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",
  "request.payment.api.elo.error.96":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",
  "request.payment.api.elo.error.99":
    "Transação não autorizada. Valor diferente da pré autorização. Contate o seu banco para tentar novamente.",
  "request.payment.api.elo.error.AB":
    "Transação não autorizada. Utilize a função crédito.",
  "request.payment.api.elo.error.AC":
    "Transação não autorizada. Utilize a função débito.",
  "request.payment.api.elo.error.P6":
    "Transação não autorizada. Senha inválida. Utilize a nova senha.",

  //amex
  "request.payment.api.amex.error.116":
    "Transação não autorizada. Cartão restrito por falta de limite, contate o seu banco emissor.",
  "request.payment.api.amex.error.117":
    "Transação não autorizada. Senha inválida.",
  "request.payment.api.amex.error.200":
    "Transação não autorizada pelo seu banco por suspeita de fraude. Contate o seu banco para tentar novamente.",
  "request.payment.api.amex.error.122":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.amex.error.115":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.amex.error.101":
    "Transação não autorizada. Prazo de validade do cartão fornecido está vencido, por favor insira um cartão válido.",
  "request.payment.api.amex.error.109":
    "Transação não autorizada. Comerciante inválido.",
  "request.payment.api.amex.error.181":
    "Transação não autorizada. Erro no formato. Contate o seu banco para tentar novamente.",
  "request.payment.api.amex.error.180":
    "Transação não autorizada. Erro no cartão. Contate o seu banco para tentar novamente.",
  "request.payment.api.amex.error.110":
    "Transação não autorizada. Valor da transação inválido.",
  "request.payment.api.amex.error.106":
    "Transação não autorizada. Excedida a quantidade de tentativa de senha/compra. Contate a central do seu cartão.",
  "request.payment.api.amex.error.912":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",
  "request.payment.api.amex.error.911":
    "Transação não autorizada. Falha de comunicação. Tente mais tarde.",

  //all networkds
  "request.payment.api.error.B1":
    "Transação não autorizada. Surcharge não suportado.",
  "request.payment.api.error.B2":
    "Transação não autorizada. Surcharge não suportado pela rede de débito.",
  "request.payment.api.error.N0":
    "Transação não autorizada. Contate o seu banco para tentar novamente.",
  "request.payment.api.error.R2": "Transação não autorizada para Visa PIN.",

  "request.payment.api.error.14":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",

  "request.payment.api.error.63":
    "Transação não autorizada. Compra com suspeita de fraude ou com violação de segurança, contate o seu banco emissor.",

  "request.payment.api.error.100":
    "Transação não autorizada. Compra com suspeita de fraude, contate o seu banco emissor.",
  "request.payment.api.error.101":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.102":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão ou o seu banco.",
  "request.payment.api.error.103":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.104":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.105":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito ou suspeita de fraude, contate o seu banco emissor.",
  "request.payment.api.error.106":
    "Desculpe, houve um erro no processamento com o emissor do cartão, por favor tente novamente.",
  "request.payment.api.error.107":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.108":
    "Transação não autorizada. O valor da compra não é permitido para o tipo do cartão fornecido.",
  "request.payment.api.error.109":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.error.110":
    "Transação não autorizada. O cartão fornecido não tem permissão para realizar esse tipo de transação.",
  "request.payment.api.error.111":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito, contate o seu banco emissor.",
  "request.payment.api.error.112":
    "Transação não autorizada. Prazo de validade do cartão fornecido está vencido, por favor insira um cartão válido.",
  "request.payment.api.error.113":
    "Transação não autorizada. Compra com suspeita moderada de fraude, contate o seu banco emissor.",
  "request.payment.api.error.114":
    "Transação não autorizada. A compra foi bloqueada pelo emissor do cartão.",
  "request.payment.api.error.115":
    "Transação não autorizada. Você excedeu o limite de transações permitidas em um período de tempo.",
  "request.payment.api.error.116":
    "Transação não autorizada. O cartão fornecido não tem permissão para realizar esse tipo de transação. Contate o seu banco para tentar novamente.",
  "request.payment.api.error.117":
    "Desculpe, não encontramos registros dessa transação.",
  "request.payment.api.error.118":
    "Transação não autorizada. O cartão fornecido está bloqueado, por favor insira um cartão válido.",
  "request.payment.api.error.119":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.121":
    "Desculpe, houve um erro no processamento, por favor tente novamente.",
  "request.payment.api.error.122":
    "Transação não autorizada. Uma transação similar foi enviada recentemente.",
  "request.payment.api.error.123":
    "Transação não autorizada. O estabelecimento cancelou assinaturas recorrentes do emissor.",
  "request.payment.api.error.124":
    "Transação não autorizada. Por favor entre em contato com o emissor.",
  "request.payment.api.error.170":
    "O cartão fornecido não tem permissão para realizar transações de verificação.",
  "request.payment.api.error.175": "Transação de verificação negada.",

  "request.payment.api.error.1":
    "Transação não autorizada. Prazo de validade do cartão incorreto, por favor verifique e tente novamente.",
  "request.payment.api.error.2":
    "Transação não autorizada. Prazo de validade do cartão incorreto, por favor verifique e tente novamente.",
  "request.payment.api.error.3":
    "Transação não autorizada. Prazo de validade do cartão incorreto, por favor verifique e tente novamente.",

  "request.payment.api.error.5":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.6":
    "Transação não autorizada. O CEP é inválido, por favor insira um CEP válido.",

  "request.payment.api.error.8":
    "Transação não autorizada. O CEP é inválido, por favor insira um CEP válido.",
  "request.payment.api.error.9":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.error.10":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.error.11":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",

  "request.payment.api.error.16":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.17":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.18":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.19":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.20":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.21":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.22":
    "Transação não autorizada. Informe o seu endereço completo.",
  "request.payment.api.error.23":
    "Transação não autorizada. Informe o seu endereço completo.",
  "request.payment.api.error.24":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.25":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.26":
    "Transação não autorizada. Problemas no cartão fornecido, contate o emissor do cartão.",
  "request.payment.api.error.27":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.28":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.29":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.30":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.31":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.32":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.33":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.34":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.35":
    "Transação não autorizada. Prazo de validade do cartão incorreto, por favor verifique e tente novamente.",
  "request.payment.api.error.36":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.error.37":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.error.38":
    "Transação não autorizada. O cartão fornecido não existe ou possui dados incorretos, por favor utilize dados de um cartão existente e válido.",
  "request.payment.api.error.39":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.40":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",

  "request.payment.api.error.42":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",

  "request.payment.api.error.44":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.45":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.46":
    "Transação não autorizada. Quantidade de parcelas acima do permitido, por favor verifique e tente novamente.",
  "request.payment.api.error.47":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.49":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito ou suspeita de fraude, contate o seu banco emissor.",
  "request.payment.api.error.50":
    "Transação não autorizada. Quantidade de parcelas inválida, por favor verifique e tente novamente.",

  "request.payment.api.error.53":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",

  "request.payment.api.error.59":
    "Transação não autorizada. O nome do portador do cartão fornecido é inválido, por favor verifique e tente novamente.",
  "request.payment.api.error.60":
    "Transação não autorizada. Informe o seu endereço completo.",
  "request.payment.api.error.61":
    "Transação não autorizada. O nome do portador do cartão fornecido é inválido, por favor verifique e tente novamente.",

  "request.payment.api.error.64": "Transação não autorizada. Tente novamente.",

  "request.payment.api.error.66":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.67":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.68":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",

  "request.payment.api.error.70":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito, tente novamente ou contate o seu banco emissor.",
  "request.payment.api.error.71":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito, tente novamente ou contate o seu banco emissor.",
  "request.payment.api.error.72":
    "Transação não autorizada. Por favor entre em contato com o emissor.",
  "request.payment.api.error.73":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito, tente novamente ou contate o seu banco emissor.",
  "request.payment.api.error.74": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.75":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.76": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.78": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.79":
    "Transação não autorizada. Prazo de validade do cartão fornecido está vencido, por favor insira um cartão válido.",
  "request.payment.api.error.80":
    "Transação não autorizada. Cartão restrito por falta de limite de crédito, tente novamente ou contate o seu banco emissor.",
  "request.payment.api.error.82":
    "Transação não autorizada. O cartão fornecido não tem permissão para realizar esse tipo de transação. Cartões de Débito não são aceitos em compras online.",
  "request.payment.api.error.83":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.84":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.85":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",

  "request.payment.api.error.87":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.88":
    "Transação não autorizada. O estabemecimento comercial não pode processar sua compra neste momento.",
  "request.payment.api.error.89":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.97":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.98":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.132":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.133":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.150": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.151":
    "Transação não autorizada. Quantidade de parcelas inválida, por favor verifique e tente novamente.",
  "request.payment.api.error.153":
    "Transação não autorizada. O CPF/CNPJ não existe ou possui dados incorretos, por favor verifique e tente novamente.",
  "request.payment.api.error.154":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.155":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.156":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.157":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.158":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.159": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.160": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.161": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.167": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.169": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.171":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.173": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.176": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.899":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.1018":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.1019":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.1020":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.1021":
    "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.1023":
    "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.1030":
    "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.1034":
    "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.1036":
    "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.1038":
    "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.3025":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3026":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3027":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3028":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3029":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3030":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3031":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3032":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3033":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3034":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.3035":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.4":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.7":
    "Transação não autorizada. O CEP é inválido, por favor insira um CEP válido.",
  "request.payment.api.error.12":
    "Transação não autorizada. O CPF/CNPJ não existe ou possui dados incorretos, por favor verifique e tente novamente.",
  "request.payment.api.error.13":
    "Transação não autorizada. O CPF/CNPJ não existe ou possui dados incorretos, por favor verifique e tente novamente.",
  "request.payment.api.error.15":
    "Transação não autorizada. O código de segurança fornecido é inválido, por favor insira um código de segurança válido.",
  "request.payment.api.error.41":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.43":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.51":
    "Transação não autorizada. Cartão restrito por falta de limite, contate o seu banco emissor.",
  "request.payment.api.error.54":
    "Transação não autorizada. Quantidade de parcelas inválida, por favor verifique e tente novamente.",
  "request.payment.api.error.55":
    "Transação não autorizada. O nome do portador do cartão fornecido é inválido, por favor verifique e tente novamente.",
  "request.payment.api.error.56": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.57":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.58":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.62":
    "Transação não autorizada. O nome do portador do cartão fornecido é inválido, por favor verifique e tente novamente.",
  "request.payment.api.error.65": "Transação não autorizada. Tente novamente.",
  "request.payment.api.error.69":
    "Transação não autorizada. A transação pode ter sido negada por haver dados incorretos ou por ordem do seu banco, por favor, tente novamente.",
  "request.payment.api.error.86":
    "Transação não autorizada. Prazo de validade do cartão fornecido está vencido, por favor insira um cartão válido.",

  "request.payment.discount.error":
    "O valor do desconto deve ser menor ou igual ao valor total do boleto.",
  "request.payment.late.fee.error":
    "O valor da multa deve ser menor ou igual a 2% do valor total do boleto.",
  "request.payment.interest.error.monthly":
    "O valor total dos juros não pode ser maior que 1% do valor total do boleto.",
  "request.payment.interest.error.daily.percentage":
    "O valor total dos juros não pode ser maior que 0.033% (ao dia) do valor total do boleto.",

  "developer.page.integration.title": "Crie uma integração personalizada",
  "developer.page.integration.subtitle": "Faça seu primeiro pagamento de teste",
  "developer.page.integration.3.subtitle":
    "Parabéns, você está pronto para atuar em produção",
  "developer.page.integration.text":
    "Sua conta já está ativa e habilitada para executar pagamentos ao vivo. Para iniciar sua integração, oferecemos nosso ambiente de teste para você.",
  "developer.page.api.doc.button": "Documentação de API",
  "developer.page.list.api.keys": "Chaves de API",
  "developer.page.list.store.name": "Nome do estabelecimento",
  "developer.page.list.store.id": "ID do estabelecimento",
  "developer.page.list.username": "Usuário",
  "developer.page.list.password": "Chave secreta",
  "developer.page.list.stores": "Seus estabelecimentos",
  "developer.page.token.title": "Tokens",
  "developer.page.token.description": "Crie tokens de acesso para que integrações externas possam utilizar a conta da sua empresa na Primefy.",
  "developer.page.token.create.button": "Criar novo token",

  "default.error.state.message": "default",

  "filter.label.btn": "Filtros",
  "filter.label.btn.export": "Exportar",
  "filter.label.products": "Produtos",
  "filter.label.title": "Filtros",
  "filter.label.period": "Período",
  "filter.label.status": "Status",
  "filter.label.status.all": "Todos",
  "filter.label.status.Succeded": "Aprovado",
  "filter.label.status.Pending": "Pendente",
  "filter.label.status.Canceled": "Cancelado",
  "filter.label.status.Failed": "Recusado",
  "filter.label.status.Rejected": "Recusado",
  "filter.label.status.PendingChallenge": "Pendente",
  "filter.label.status.Disputed": "Disputa",
  "filter.label.status.ChargedBack": "Disputa Perdida",
  "filter.label.status.Blocked": "Bloqueado",
  "filter.label.status.DisputeSucceeded": "Disputa Ganha",
  "filter.label.payment.methods": "Método de pagamento",
  "filter.label.payment.methods.all": "Todos",
  "filter.label.payment.methods.Boleto": "Boleto Bancário",
  "filter.label.payment.methods.Credit": "Cartão de Crédito",
  "filter.label.payment.methods.Pix": "Pix",
  "filter.label.type": "Tipo de cobrança",
  "filter.label.type.all": "Todos",
  "filter.label.type.single": "Uma vez",
  "filter.label.type.recurring": "Recorrente",
  "filter.cleaner": "Limpar",
  "filter.apply": "Aplicar",

  "download.loading.text": "O download do seu arquivo Excel está em andamento",
  "download.loading.text.to.complete": "para completar",

  "reports.filter.period.7": "Últimos 7 dias",
  "reports.filter.period.30": "Últimos 30 dias",

  "reports.gross.sales.title": "Faturamento Bruto",
  "reports.sales.title": "Vendas",
  "reports.payment.methods.title": "Métodos de Pagamento",
  "reports.customers.title": "Novos Clientes",
  "reports.disputes.title": "Disputas",
  "reports.refunds.title": "Estornos",
  "reports.refunds.pix.error":
    "Para pagamentos do tipo Pix você só pode estornar o valor completo.",

  "tour.homepage.0.title": "Seu novo painel na Primefy",
  "tour.homepage.0":
    "Aumente as suas vendas e acompanhe o fluxo de caixa para o sucesso da sua empresa, com a nossa solução de automação financeira.",
  "tour.homepage.1":
    "Você deseja organizar o seu negócio por filiais, departamentos ou regiões? Você pode criar múltiplos estabelecimentos com o mesmo CNPJ, ou até mesmo com CNPJs diferentes.",
  "tour.homepage.2":
    "Aqui você pode ver uma visão geral das suas vendas por período.",
  "tour.homepage.3":
    "Suas 5 vendas mais recentes aparecem aqui, atualizadas em tempo real, e você nem precisa atualizar a página.",
  "tour.homepage.4":
    "Conforme você recebe as suas vendas recentes, automaticamente irá atualizar no mapa em tempo real.",
  "tour.homepage.5":
    "Você pode criar links de pagamentos ou até mesmo manualmente cobrar um cliente. Aqui você consegue fazer tudo, sem precisar de um time de tecnologia.",
  "tour.homepage.6":
    "Suas finanças são organizadas como Saldo Disponível e Saldo A Receber. O Saldo Disponível é o valor que já pode ser transferido para a sua conta bancária, enquanto o Saldo A Receber vai estar disponível em breve.",
  "tour.homepage.7":
    "Você pode ver o histórico de saques, fluxo de caixa futuro detalhado e realizar saques.",
  "tour.homepage.8":
    "Em Ajustes, você pode customizar todos os detalhes do seu negócio, estabelecimentos e usuários. Ainda em Ajustes, você pode visualizar as suas taxas e integrações na plataforma.",
  "tour.homepage.9":
    "Você pode testar o quanto desejar antes de realizar uma venda real, com cartões de crédito para teste e mais.",
  "tour.homepage.10":
    "Estamos disponíveis para ajudar você a qualquer hora, por email ou por chat.",
  "tour.start": "Continue com o tour",
  "tour.finished": "Finalizar o tour",
  "tour.next": "Próxima dica",
  "tour.finish": "Pular o tour",

  "payment.link.details.dynamic.customer.firstName": "Nome",
  "payment.link.details.dynamic.customer.surname": "Sobrenome",
  "payment.link.details.dynamic.customer.address": "Endereço",
  "payment.link.details.dynamic.customer.birthdate": "Aniversário",
  "payment.link.details.dynamic.customer.email": "Email",
  "payment.link.details.dynamic.customer.identificationNumber":
    "Número de identificação",
  "payment.link.details.dynamic.customer.phone": "Telefone",

  "payment.link.details.dynamic.customer.address.city": "Cidade",
  "payment.link.details.dynamic.customer.address.district": "Bairro",
  "payment.link.details.dynamic.customer.address.number": "Número",
  "payment.link.details.dynamic.customer.address.reference": "Referência",
  "payment.link.details.dynamic.customer.address.state": "Estado",
  "payment.link.details.dynamic.customer.address.street": "Rua",
  "payment.link.details.dynamic.customer.address.zipCode": "CEP",

  "payment.link.details.grace.period": "Dia",
  "payment.link.details.grace.period.2": "Dias",

  "filter.label.period.creation": "Data de criação",
  "filter.label.order.period.creation": "Data de criação da venda",
  "filter.label.period.payment": "Data do pagamento",
  
  "filter.label.store.id": "Informar ID da Loja",
  "filter.label.procuct.name": "Informar o nome do Produto",
  "filter.label.procuct.id": "Informar ID do Produto",
  
  "products.search.by.name": "nome do produto",

  "header.tax": "Imposto incluído",

  "manual.payment.succeded.title": "Pagamento realizado com sucesso",
  "manual.payment.succeded.text.1": "Um pagamento para",
  "manual.payment.succeded.text.2": "aparecerá no  extrato do seu cliente como",

  "tip.home.finances.available.amount":
    "Você pode realizar o saque deste valor a qualquer momento.",
  "tip.home.finances.incoming.amount":
    "Este é o valor que temos para disponibilizar no futuro, e que pode variar dependendo de disputas e estornos realizados.",
  "tip.settings.fees.boleto":
    "A taxa só será cobrada se o seu cliente pagar o Boleto.",
  "tip.settings.fees.pix":
    "A taxa só será cobrada se o seu cliente pagar o PIX.",
  "tip.settings.fees.credit.card":
    "A taxa só será cobrada se o pagamento for aprovado.",

  "request.payment.address.data.tip":
    "Caso você tenha que enviar um pacote para o seu cliente, ou necessita as informações para emissão de Nota Fiscal, por exemplo.",
  "limit.installments.tip":
    "Por padrão, os seus clientes podem parcelar em até 12 parcelas, você pode customizar isso.",
  "request.payment.customize.boleto.btn.tip":
    "Deseja definir multa em caso de pagamento atrasado, ou oferecer desconto caso o seu cliente pagar antes? Customize como preferir!",
  "request.payment.create.plan.limit.payment.methods.tip":
    "Você pode decidir não aceitar pagamentos por Boleto, por exemplo.",
  "request.payment.pre.fill.tip":
    "Você já sabe algumas informações do seu cliente? Preencha antecipadamente para aumentar a taxa de conversão!",
  "manager.links.details.include.fee.transfer.tip":
    "Deseja receber o valor definido, independente da forma de pagamento do seu cliente?",
  "request.payment.expiration.link.tip":
    "Está realizando um lançamento com data e hora limite de pagamento? Defina para evitar receber pagamentos atrasados.",
  "request.payment.redirect.payment.tip":
    "Caso o cliente realize o pagamento com sucesso, você pode automaticamente redirecioná-lo para uma página do seu site.",

  "error.request.payment.link.title":
    "Insira um título para que os seus clientes possam saber o que estão comprando.",
  "error.request.payment.link.description":
    "Por favor insira uma descrição para o link de pagamento.",
  "error.request.payment.link.amount":
    "É necessário definir o valor que você deseja cobrar no link de pagamento.",
  "error.request.payment.link.amount.less.3":
    "O valor do link precisa ser no mínimo equivalente a R$ 3,00.",
  "error.request.payment.link.payment.types":
    "Selecione pelo menos um método de pagamento.",
  "error.request.payment.link.payment.boleto.duedate.valid":
    "Escolha uma data válida.",
  "error.request.payment.link.description.onblur":
    "A descrição precisar ter no máximo 255 carácteres.",

    "error.request.payment.amount.more.than.10k":
    "O valor para criação de boleto precisa ser de no máximo ",
    "error.request.payment.amount.more.than.limit.2":
    "Por favor desmarque a opção de pagamento com boleto ou altere o valor do seu link para prosseguir.",
    
  "error.request.payment.product.description":
    "Por favor insira uma descrição para o produto.",
  "error.request.payment.product.amount":
    "É necessário definir o valor que você deseja cobrar pelo produto.",
  "request.payment.create.product.label": "Criar produto",

  "error.request.payment.create.plan.name": "Insira o nome do plano.",
  "error.request.payment.create.plan.amount": "Insira o valor do plano.",
  "error.request.payment.url.redirect": "Por favor insira uma URL válida.",

  "error.request.payment.amount": "Por favor insira valor para a venda.",

  "personal.data.erro.name":
    "Nome inválido. O nome precisa ter de 1 a 45 caracteres.",
  "personal.data.erro.surname":
    "Sobrenome inválido. O sobrenome precisa ter de 1 a 45 caracteres.",
  "personal.data.erro.identification.cpf":
    "CPF inválido. Número inserido não corresponde ao formato de um CPF válido.",
  "personal.data.erro.identification.cnpj":
    "CNPJ inválido. Número inserido não corresponde ao formato de um CNPJ válido.",
  "personal.data.erro.email":
    "Email inválido. Um email precisa ter no máximo 80 caracteres e seguir o padrão com '.' e '@'.",
  "personal.data.erro.business.name":
    "Nome da empresa inválido. O nome precisa ter de 1 a 80 caracteres.",
  "personal.data.erro.identification.number":
    "CPF ou CNPJ inválido. O número inserido não corresponde ao formato de um CPF ou de um CNPJ válido.",

  "payment.data.erro.birthdate":
    "Data de nascimento inválida. A data de nascimento não pode ser depois da data atual e precisa ser no formato dd/mm/aaaa",
  "payment.data.erro.phone":
    "Telefone inválido. Número inserido não corresponde ao formato de um número de telefone válido.",
  "payment.data.erro.zipcode":
    "CEP inválido. O CEP precisa ter 8 caracteres no formato 12345-678",
  "payment.data.erro.address":
    "Endereço inválido. O endereço precisa ter de 1 a 70 caracteres.",
  "payment.data.erro.number":
    "Número inválido. Insira o número do endereço. 0 (zero) para quando não houver.",
  "payment.data.erro.district":
    "Bairro inválido. O nome do bairro precisa ter de 1 a 70 caracteres.",
  "payment.data.erro.city":
    "Cidade inválida. O nome da Cidade precisa ter de 1 a 70 caracteres.",
  "payment.data.erro.state":
    "Estado inválido. O nome do Estado precisa ter de 1 a 70 caracteres.",

  "fees.from": "A partir de",

  "integrations.title": "Integrações",
  "integrations.description":
    "Integre o Primefy com aplicativos e automatize suas vendas sem atritos.",

  "integrations.cancel": "Cancelar",
  "integrations.add": "Cadastrar webhook",
  "integrations.add.first": "Adicionar primeiro webhook",
  "integrations.add.new": "Conectar",

  "integrations.list.status": "Status",
  "integrations.list.description": "Descrição",
  "integrations.list.events": "Eventos",

  "webhook.title": "Webhooks",
  "webhook.description":
    "Create webhooks to receive events and notifications from Primefy.",
  "webhook.connection.endpoint.label": "Endpoint",
  "webhook.connection.description.label": "Descrição",
  "webhook.connection.store.label": "Store",
  "webhook.connection.events.label": "Eventos",

  "webhook.connection.events.placeholder": "Selecione um evento",
  "webhook.connection.events.0": "Todos os eventos",
  "webhook.connection.events.1": "Pagamentos pendentes",
  "webhook.connection.events.2": "pagamentos aprovados",

  "webhook.connection.nuvemshop.store": "Estabelecimento",
  "webhook.connection.nuvemshop.installments":
    "Número máximo de parcelas permitidas",
  "webhook.connection.nuvemshop.installments.1": "Sem parcelas",
  "webhook.connection.nuvemshop.installments.2": "No máximo 2 parcelas",
  "webhook.connection.nuvemshop.installments.3": "No máximo 12 parcelas",
  "webhook.connection.nuvemshop.ccard": "Cartão de crédito",
  "webhook.connection.nuvemshop.boleto": "Boleto bancário",
  "webhook.connection.nuvemshop.pix": "Pix",
  "webhook.connection.nuvemshop.payment.methods.error": "É necessário que seja informado pelo menos um método de pagamento",
  
  "webhook.connection.pluga":
    "O Pluga é uma plataforma de integração que conecta as ferramentas que você utiliza em sua empresa sem precisar ter nenhum tipo de conhecimento técnico.",
  "webhook.connection.zapier":
    "Conecte seus aplicativos e automatize fluxos de trabalho. Automação fácil para pessoas ocupadas. O Zapier move as informações entre seus aplicativos da Web automaticamente, para que você possa se concentrar no trabalho mais importante.",
  "webhook.connection.ifttt":
    "Cada coisa funciona melhor em conjunto. Automatize de forma rápida e fácil seus aplicativos e dispositivos favoritos. Personalize e controle suas integrações com código de filtro, consultas e múltiplas ações.",
  "webhook.connection.nuvemshop":
    "A Nuvemshop é a principal plataforma de e-commerce da América Latina. Atualmente, ajuda mais de 85.000 comerciantes a lançar, desenvolver e promover seus próprios negócios online. Venda em todo o mundo na Nuvemshop com a solução de pagamentos Primefy.",

  "webhook.success.message": "Webhook cadastrado com sucesso",
  "webhook.error.message": "Erro ao cadastrar Webhook",

  "nuvemshop.success.message": "Número máximo de parcelas alterado com sucesso",
  "nuvemshop.error.message":
    "Erro ao alterar número de parcelas. Por favor tente novamente mais tarde.",

  "webhook.error.message.default":
    "Campo obrigatório, por favor insira um valor válido.",

  "webhook.event.created": "Transações criadas",
  "webhook.event.succeded": "Transações confirmadas",
  "webhook.event.canceled": "Transações canceladas",
  "webhook.event.failed": "Transações falhadas",
  "webhook.event.disputed": "Transações disputadas",
  "webhook.event.charged": "Transações com disputa perdida",
  "webhook.event.dispute.succeeded": "Transações com disputa ganha",

  "open.purchase.payment.link.label": "O cliente define quanto deseja pagar",
  "open.purchase.payment.link":
    "O cliente poderá definir qualquer valor a partir de R$1,00. ONGs e Produtos abertos utilizam bastante dessa funcionalidade para receber doações ou recompensas de usuários.",

  "apply.smart.installments.label": "Oferecer Parcelas Inteligentes",
  "apply.smart.installments.tip":
    "Caso o parcelamento do cartão seja negado devido ao limite disponível, as parcelas serão cobradas como pagamentos recorrentes para aumentar a taxa de conversão.",
  "apply.smart.installments.description":
    "Esse recurso não garante que os pagamentos futuros serão feitos com sucesso e você entende o risco de futuros pagamentos inadimplência.",

  "financials.sales.payin": "Recebíveis",
  "financials.sales.payout": "Saques",
  "financials.sales.bank.account": "Conta",
  "financials.sales.agency": "Agência",
  "financials.sales.upcoming": "Agendado",

  "pagination.page": "Página",

  "choose.a.product.label": "Produto",
  "choose.a.product": "Escolha um produto",
  "create.new.product": "Adicionar um novo produto",
  "product.quantity": "Quantidade",

  "order.buc.label": "Defina Order bump, Upsell e Cross sell",
  "order.bump.label": "Order bump",
  "order.up.sell.label": "Upsell",
  "order.cross.sell.label": "Cross sell",
  "order.add.bump.label": "Adicionar outro produto como order bump",
  "order.add.upsell.label": "Adicionar outro produto como upsell",
  "order.add.cross.sell.label": "Adicionar outro produto como cross sell",
  "order.product.1.label": "Produto 1",
  "order.product.2.label": "Produto 2",

  "order.details.product.title": "Produtos",
  "order.details.product.name": "Nome",
  "order.details.product.quantity": "Quantidade",
  "order.details.product.price": "Preço por unidade",

  "products.tip.text":
    "Ofereça produtos adicionais durante o checkout e aumente seu faturamento",
  "order.bump.tip.text":
    "Ofereça produtos extras para o seu cliente adicionar durante o checkout",
  "cross.sell.tip.text":
    "Informe produtos que outros clientes compra com frequência para sugerir no checkout",
  "up.sell.tip.text":
    "Após uma venda ser confirmada, ofereça um produto especial ou com preço promocional e seu cliente pode comprar com apenas 1 clique",

  "request.payment.smart.retrial.label": "Informar tentativas inteligentes",
  "request.payment.smart.retrial.tip":
    "As novas tentativas inteligentes são um recurso da Primefy que repete automaticamente os pagamentos devidos das assinaturas. Por padrão, tentamos novamente a cada 3 dias por no máximo 60 dias para pagamentos atrasados ​​ativos.",
  "request.payment.smart.retrial.days":
    "Dias entre as tentativas de pagamentos devidos",
  "request.payment.smart.retrial.limit":
    "Informe o número máximo de tentativas",
  "request.payment.smart.retrial.days.error":
    "O número precisa ser menor do que 30.",
  "request.payment.smart.retrial.limit.error":
    "O número precisa ser menor do que 30.",

  "customers.page.customer": "Cliente",
  "customers.page.email": "Endereço de email",
  "customers.page.total.spent": "Total gasto",
  "customers.page.sales": "Compras",
  "customers.page.last.payment": "Último pagamento",
  "customers.page.last.created": "Criado",
  "customers.page.create.customer": "Criar cliente",
  "customers.page.finish.create.customer": "Salvar",
  "select.customers.modal": "Selecione um cliente ou crie um novo",

  "customers.details.spent": "Gasto",
  "customers.details.created": "Criado",
  "customers.details.disputes": "Disputas",
  "customers.details.subscriptions.title": "Assinaturas",
  "customers.details.subscriptions.status": "Status",
  "customers.details.subscriptions.name": "Nome",
  "customers.details.subscriptions.frequency": "Frequência",
  "customers.details.subscriptions.payment": "Pagamento",

  "customers.page.saved.cards": "Cartões salvos",
  "customers.page.saved.cards.network": "Bandeira do cartão",
  "customers.page.saved.cards.default": "Padrão",
  "customers.page.saved.cards.make.default": "Definir como padrão",

  "customers.page.subscription.next.payment": "Próximo pagamento em ",
  "customers.page.subscription.next.payment.for": " de ",
  "customers.page.subscription.next.payment.scheduled": " agendado",

  "customers.page.subscription.previous.payment": "Pagamento anterior em ",
  "customers.page.subscription.previous.payment.for": " de ",
  "customers.page.subscription.previous.payment.failed": " falhou",

  "signup.form.full.name": "Seu nome completo",
  "signup.create.free.account": "Crie sua conta gratuita",
  "signup.start.to.sale": "Comece a vender em minutos",
  "signup.terms.1": "Ao continuar, você concorda com nossos",
  "signup.terms.2": "termos e condições",

  "finish.register.top.banner":
    "Para começar a vender, você precisa finalizar o seu cadastro",

  "finish.register.settings.title": "Informações do negócio",
  "finish.register.settings.subtitle":
    "Preencha os dados restantes para começar a vender com o Primefy.",
  "finish.register.settings.button": "Finalize a criação da conta",
  "finish.register.business.details.title": "Detalhes do Negócio",
  "finish.register.yours.details.title": "Suas informações",
  "finish.register.partner.details.title": "Informações do sócio",
  "finish.register.business.name": "Nome fantasia",
  "finish.register.search.category": "Pesquise a categoria",
  "finish.register.select.category": "Selecione uma categoria",
  "finish.register.your.address.title": "Seu endereço",
  "finish.register.business.address.title": "Endereço da empresa",
  "finish.register.bank.account.title": "Conta bancária",
  "finish.register.documents.title": "Documentos",
  "finish.register.documents.proof.activity": "Comprovante de atividade",
  "finish.register.documents.proof.address": "Comprovante de endereço",
  "finish.register.documents.proof.address.partner":
    "Comprovante de endereço do sócio",
  "finish.register.documents.proof.personal": "Documento de identificação",
  "finish.register.documents.proof.partner":
    "Documento de identificação do sócio",
  "finish.register.documents.additional.documents": "Documentos adicionais",
  "finish.register.logo.label": "Adicione uma imagem",
  "finish.register.documents.label": "Adicione as imagens",
  "finish.register": "Enviar",
  "finish.phone.error.message": "Insira um telefone válido.",
  "finish.register.legal.company.name": "Razão Social",
  "finish.register.incorporation.document.label": "Documento de incorporação",

  "create.account.form.password.rule.title":
    "Escolha uma senha forte e fácil de lembrar que contenha pelo menos:",
  "create.account.form.password.rule.1": "8 ou mais caracteres",
  "create.account.form.password.rule.2": "1 letra minúscula (a-z)",
  "create.account.form.password.rule.3": "1 letra maiúscula (A-Z)",
  "create.account.form.password.rule.4": "1 número (0-9)",
  "create.account.exist.id":
    "Identification number já cadastrado em nossa base. Por favor insira um novo número.",
  "create.account.exist.email":
    "Email já cadastrado em nossa base. Por favor insira um novo Email.",
  "create.account.exist.pass":
    "A senha precisa conter algumas características definidas. Por favor revise sua senha.",
  "create.account.email.yup":
    "Email com formato inválido. Por favor envie um email válido.",
  "create.account.short.full.name.yup":
    "Nome muito pequeno. Por favor insira seu nome completo.",
  "create.account.bank.name": "Banco",
  "create.account.name.limit":
    "Favor inserir um nome com no máximo 45 carácteres.",

  "create.account.popular.categories.title": "Populares",
  "create.account.popular.categories.education": "Educação",
  "create.account.popular.categories.entertainment": "Entretenimento",
  "create.account.popular.categories.technology": "Tecnologia",
  "create.account.popular.categories.clothing": "Vestuário",

  "products.details.product.source": "Origem do produto",
  "create.payment.link.courses.selected.title": "Curso",
  "create.payment.link.products.selected.title": "Produtos",
  "create.payment.link.courses.selected.classroom": "Turma",
  "create.payment.link.courses.selected.select.classroom":
    "Selecione uma turma",
  "memberkit.error.register":
    "Chave de API inválida. Por favor insira uma chave válida.",
  "memberkit.error.register.dob": "Você não pode selecionar uma data futura",

  "splits.checkbox.label": "Defina split",
  "splits.checkbox.tip.label":
    "A cada venda proveniente deste link de pagamento, parte do valor será dividido com outro lojista. O comerciante precisa ter uma conta no Primefy.",
  "splits.recipient.label": "Recipient",
  "splits.search.label": "Busque por CPF/CNPJ",
  "splits.commission.label": "Comissão",
  "splits.expiration.date.label": "Data de expiração",
  "splits.add.label": "Criar novo split",
  "splits.add.2.label": "Adicionar novo split",
  "splits.add.commission.fixed.label": "Fixo (R$)",
  "splits.rules.label": "Regra de split",
  "splits.rules.select.placeholder": "Selecione uma regra de split",

  "cancel.order.title": "Cancelar Venda",
  "cancel.order.text":
    "Cancele toda a venda, inclusive a emissão de boletos futuros.",
  "cancel.order.cta": "Cancele Venda",

  "cancel.boleto.modal.title": "Cancelar Boleto",
  "cancel.boleto.modal.desc.1":
    "Você está prestes a cancelar o Boleto Bancário da parcela ",
  "cancel.boleto.modal.desc.2": "com vencimento para ",
  "cancel.boleto.modal.desc.3":
    "Ao ser cancelado pelo banco, ele não poderá mais ser pago.",

  "cancel.order.modal.title": "Cancelar Venda",
  "cancel.order.modal.desc.1":
    "Você está prestes a cancelar todos os boletos pendentes desta venda. Boletos já pagos não serão reembolsados.",

  "cancel.modal.cancel.cta": "Não quero cancelar",
  "cancel.boleto.modal.yes.cta": "Cancelar Boleto",
  "cancel.order.modal.yes.cta": "Cancelar Venda",

  "subscriptions.page.subscriptions": "Planos",
  "subscriptions.page.subscription": "Plano",
  "subscriptions.page.price": "Preço",
  "subscriptions.page.description": "Descrição",
  "subscriptions.page.frequency": "Frequência",
  "subscriptions.page.name": "Nome",
  "subscriptions.page.grace.period": "Período de teste",
  "subscriptions.page.max.charges": "Máx. Cobranças",
  "subscriptions.page.max.charges.until.cancel": "Com cancelamento",
  "subscriptions.page.create.subscription": "Criar plano",
  "subscriptions.page.edit.subscription": "Editar plano",
  "subscriptions.page.finish.create.subscription": "Salvar",
  "subscriptions.page.create.subscription.payment.type":
    "Selecione pelo menos um método de pagamento pra assinatura.",
  "subscriptions.page.grace.period.error":
    "Informe uma data igual ou depois da data atual",
  "subscriptions.page.grace.period.error.invalid": "Informe uma data válida",
  "pix.expiration.date": "Link de cadastro Nuvemshop expira em ",
  "pix.expiration.minutes": " minutos ",
  "pix.expiration.minutes.and": " e ",
  "pix.expiration.seconds": " segundos",
  "pix.expiration.date.expired": "Link de cadastro Nuvemshop expirado.",
  "pix.expiration.date.expired.2":
    "Por favor desinstale e instale novamente o aplicativo da Primefy na sua conta nuvemshop para tentar novamente.",
};

export default pt;
