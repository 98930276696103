import React from 'react';

type ErrorProps = {
  errors: string[];
  wantedError: string;
  errorMessage: string;
}

const ErrorMessage = ({ errors, wantedError, errorMessage }: ErrorProps) => {
  return (
    <div className="error" >{errors.some(error => error === wantedError) ? errorMessage : ""}</div>
  )
}

export default ErrorMessage;