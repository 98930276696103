import React from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

//Hooks
import { useCreatePlan } from "./hooks";

//components
import { LayoutWrapper } from "components/Layout/Index";
import Buttons from "components/Forms/Buttons";
import Select from "components/Forms/Select";
import InputFloat from "components/Forms/InputFloatLabel";
import { Input } from "components/Forms/Input/Input";
import SelectProductsButtonModal from "components/Layout/Modals/SelectProductsButtonModal";
import TipButtonModal from "components/Layout/Modals/TipButtonModal";

//Services
import SessionService from "services/Session";

import * as styled from "./styles";

const CreateSubscription = () => {
  const history = useHistory();
  const intl = useIntl();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  const {
    processing,
    submitNewSubscription,
    handleSubmit,
    control,
    errors,
    selectedProducts,
    setSelectedProducts,
    selectedCourses,
    setSelectedCourses,
    productPreSelected,
    setProductPreSelected,
    handleNewPaymentLinkDataAmount,
    classRooms,
    setClassRooms,
    selectedUnlimitedAccess,
    setSelectedUnlimitedAccess,
    setUnlimitedAccessCourse,
    handleCourseClassRoom,
    handleProductQuantity,
    paymentTypeWatch,
    gracePeriodWatch,
    amount,
    setAmount,
    isPtbr,
    params,
  } = useCreatePlan();

  return (
    <LayoutWrapper>
      <styled.LeftRightContainer>
        <styled.Left className="border-right">
          <styled.BoxDetails className="border-bottom">
            <div className="centered-container">
              <styled.Header>
                <div className="column-header">
                  <FormattedMessage
                    id={`subscriptions.page.${
                      params && params?.storeId && params?.id
                        ? "edit"
                        : "create"
                    }.subscription`}
                  />
                </div>
              </styled.Header>

              <styled.FormContainer>
                <form onSubmit={handleSubmit(submitNewSubscription)}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={intl.formatMessage({
                          id: "request.payment.recurring.name.plan",
                        })}
                        {...field}
                        ref={null}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => <p className="error">{message}</p>}
                  />

                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={intl.formatMessage({
                          id: "integrations.list.description",
                        })}
                        {...field}
                        ref={null}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="description"
                    render={({ message }) => <p className="error">{message}</p>}
                  />

                  <div className="form-item">
                    <Controller
                      name="gracePeriod"
                      control={control}
                      render={({ field }) => (
                        <Input
                          label={intl.formatMessage({
                            id: "request.payment.recurring.trial",
                          })}
                          type="number"
                          {...field}
                          ref={null}
                        />

                        // <InputFloat
                        //   mode="normal-mask"
                        //   mask="99/99/9999"
                        //   maxLength={10}
                        //   placeholder={isPtbr ? "DD/MM/AAAA" : "DD/MM/YYYY"}
                        //   handler={() => {}}
                        //   {...field}
                        //   ref={null}
                        // />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="gracePeriod"
                      render={({ message }) => (
                        <p className="error">{message}</p>
                      )}
                    />
                  </div>

                  <div className="form-item">
                    <label>
                      {intl.formatMessage({
                        id: "request.payment.payment.type",
                      })}
                    </label>

                    <Controller
                      name="paymentType.card"
                      control={control}
                      render={({ field }) => (
                        <InputFloat
                          required={false}
                          mode="checkbox"
                          readOnly={true}
                          valueToCheck={paymentTypeWatch?.card}
                          placeholder={intl.formatMessage({
                            id: "request.payment.payment.method.cc.label",
                          })}
                          handler={() => {}}
                          {...field}
                          ref={null}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="paymentType.card"
                      render={({ message }) => (
                        <p className="error">{message}</p>
                      )}
                    />

                    <Controller
                      name="paymentType.pix"
                      control={control}
                      render={({ field }) => (
                        <InputFloat
                          required={false}
                          mode="checkbox"
                          valueToCheck={paymentTypeWatch?.pix}
                          readOnly={true}
                          placeholder="Pix"
                          handler={() => {}}
                          {...field}
                          ref={null}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="paymentType.pix"
                      render={({ message }) => (
                        <p className="error">{message}</p>
                      )}
                    />

                    <Controller
                      name="paymentType.boleto"
                      control={control}
                      render={({ field }) => (
                        <InputFloat
                          required={false}
                          valueToCheck={paymentTypeWatch?.boleto}
                          mode="checkbox"
                          readOnly={true}
                          placeholder={intl.formatMessage({
                            id: "request.payment.payment.method.boleto.label",
                          })}
                          handler={() => {}}
                          {...field}
                          ref={null}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="paymentType.boleto"
                      render={({ message }) => (
                        <p className="error">{message}</p>
                      )}
                    />

                    <ErrorMessage
                      errors={errors}
                      name="paymentType"
                      render={({ message }) => (
                        <p className="error">{message}</p>
                      )}
                    />
                  </div>

                  {paymentTypeWatch?.boleto ? (
                    <>
                      <Controller
                        name="chargeDaysBefore"
                        control={control}
                        render={({ field }) => (
                          <Input
                            label={intl.formatMessage({
                              id: "request.payment.boleto.days.before.generate.boleto",
                            })}
                            type="number"
                            {...field}
                            ref={null}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="chargeDaysBefore"
                        render={({ message }) => (
                          <p className="error">{message}</p>
                        )}
                      />
                    </>
                  ) : null}

                  <Controller
                    name="maxCharges"
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={intl.formatMessage({
                          id: "request.payment.inform.cycle",
                        })}
                        type="number"
                        {...field}
                        ref={null}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="maxCharges"
                    render={({ message }) => <p className="error">{message}</p>}
                  />

                  <div className="form-item">
                    <label>
                      {intl.formatMessage({
                        id: "request.payment.amount",
                      })}
                    </label>

                    <InputFloat
                      required={true}
                      mode="normal-fixed-symbol"
                      isCurrency={true}
                      fixedSymbol="R$"
                      name="amount"
                      value={amount}
                      placeholder=""
                      handler={setAmount}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="amount"
                      render={({ message }) => (
                        <p className="error">{message}</p>
                      )}
                    />
                  </div>

                  <div className="form-item">
                    <div className="form-item__optional">
                      <h5>
                        {intl.formatMessage({
                          id: "choose.a.product.label",
                        })}
                      </h5>
                      <p>
                        {intl.formatMessage({
                          id: "request.payment.optional.label",
                        })}
                      </p>

                      <div className="tip-container">
                        <TipButtonModal
                          text={intl.formatMessage({
                            id: "products.tip.text",
                          })}
                        />
                      </div>
                    </div>

                    <SelectProductsButtonModal
                      handler={setSelectedProducts}
                      handlerCourse={setSelectedCourses}
                      selectedProducts={selectedProducts}
                      selectedCourses={selectedCourses}
                      clear={() => {}}
                      eventsToEdit={[]}
                      handleNewPaymentLinkDataAmount={
                        handleNewPaymentLinkDataAmount
                      }
                      productPreSelected={productPreSelected}
                      setProductPreSelected={setProductPreSelected}
                      classRooms={classRooms}
                      setClassRooms={setClassRooms}
                      selectedUnlimitedAccess={selectedUnlimitedAccess}
                      setSelectedUnlimitedAccess={setSelectedUnlimitedAccess}
                      setUnlimitedAccessCourse={setUnlimitedAccessCourse}
                      triggerVisualHandler={true}
                    />
                  </div>

                  {selectedCourses.length > 0 && !selectedUnlimitedAccess ? (
                    <>
                      <span className="flex-header-form-item">
                        <h5>
                          {intl.formatMessage({
                            id: "create.payment.link.courses.selected.title",
                          })}
                        </h5>
                        <h5>
                          {intl.formatMessage({
                            id: "create.payment.link.courses.selected.classroom",
                          })}
                        </h5>
                      </span>
                      <div className="inputs-table-container">
                        {selectedCourses.map((course) => (
                          <div
                            className="form-item inputs-container form-item--without-margin"
                            key={course.productId}
                          >
                            <InputFloat
                              disableCurrencyInput={true}
                              required={false}
                              mode="normal"
                              name="productName"
                              value={course.productName}
                              placeholder={""}
                              handler={() => {}}
                              borderSide="right"
                              bottomSeparator={true}
                            />

                            <div className="m-40-width">
                              <Select
                                mode="normal-child"
                                disabled={selectedUnlimitedAccess}
                                name="classRoom"
                                placeholder=""
                                handler={(e: any) =>
                                  handleCourseClassRoom(course.productId, e)
                                }
                                bottomSeparator={true}
                              >
                                <option className="bold-option" value="">
                                  {intl.formatMessage({
                                    id: "create.payment.link.courses.selected.select.classroom",
                                  })}
                                </option>
                                {classRooms.map((classRoom) => (
                                  <option
                                    key={classRoom.id}
                                    value={`${classRoom.name}-${classRoom.id}`}
                                  >
                                    {classRoom.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}

                  {selectedProducts?.length > 0 ? (
                    <>
                      <span className="flex-header-form-item">
                        <h5>
                          {intl.formatMessage({
                            id: "create.payment.link.products.selected.title",
                          })}
                        </h5>
                        <h5>
                          {intl.formatMessage({ id: "product.quantity" })}
                        </h5>
                      </span>
                      <div className="inputs-table-container">
                        {selectedProducts.map((product) => (
                          <div
                            className="form-item inputs-container form-item--without-margin"
                            key={product?.productId}
                          >
                            <InputFloat
                              disableCurrencyInput={true}
                              required={false}
                              mode="normal"
                              name="productName"
                              value={product?.productName}
                              placeholder={""}
                              handler={() => {}}
                              borderSide="right"
                              bottomSeparator={true}
                            />

                            <div className="m-20-width">
                              <InputFloat
                                disableCurrencyInput={false}
                                required={true}
                                mode="normal"
                                name="quantity"
                                value={product?.quantity}
                                placeholder={""}
                                handler={(e: any) =>
                                  handleProductQuantity(product?.productId, e)
                                }
                                bottomSeparator={true}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}

                  <div className="form-item">
                    <label>
                      {intl.formatMessage({
                        id: "request.payment.recurring",
                      })}
                    </label>

                    <Controller
                      name="frequency"
                      control={control}
                      render={({ field }) => (
                        <Select
                          mode="normal-child"
                          required={true}
                          placeholder={""}
                          handler={() => {}}
                          {...field}
                          ref={null}
                        >
                          <option value={0}>
                            {intl.formatMessage({
                              id: "request.payment.plan.daily",
                            })}
                          </option>
                          <option value={1}>
                            {intl.formatMessage({
                              id: "request.payment.plan.weekly",
                            })}
                          </option>
                          <option value={2}>
                            {intl.formatMessage({
                              id: "request.payment.plan.monthly",
                            })}
                          </option>
                          <option value={3}>
                            {intl.formatMessage({
                              id: "request.payment.plan.quarterly",
                            })}
                          </option>
                          <option value={4}>
                            {intl.formatMessage({
                              id: "request.payment.plan.halfYearly",
                            })}
                          </option>
                          <option value={5}>
                            {intl.formatMessage({
                              id: "request.payment.plan.yearly",
                            })}
                          </option>
                        </Select>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="frequency"
                      render={({ message }) => (
                        <p className="error">{message}</p>
                      )}
                    />
                  </div>

                  <div className="button-bottom-container">
                    {!processing && (
                      <Buttons
                        mode="filled"
                        handler={() => {}}
                        type="submit"
                        label={intl.formatMessage({
                          id: "customers.page.finish.create.customer",
                        })}
                        customClass="full-width"
                      />
                    )}

                    {processing && (
                      <Buttons
                        mode="filled-processing"
                        handler={() => {}}
                        type="submit"
                        label=""
                        customClass="full-width"
                        disable={true}
                      />
                    )}
                  </div>
                </form>
              </styled.FormContainer>
            </div>
          </styled.BoxDetails>
        </styled.Left>
      </styled.LeftRightContainer>
    </LayoutWrapper>
  );
};

export default CreateSubscription;
