import React from 'react';
import { useHistory } from 'react-router-dom';

//Services
import SessionService from 'services/Session';

//Components
import { LayoutWrapper } from 'components/Layout/Index';
import TopBar from 'components/Layout/TopBar/Index';
import TopBarMobile from 'components/Layout/TopBarMobile/Index';
import SidebarMobile from 'components/SidebarMobile';

//Styles
import * as styled from './styles/request-payment-styles';

function RequestPayment() {
  const history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }
  
  return (
    <>
      <SidebarMobile />
      <LayoutWrapper>
        <TopBar />
        <TopBarMobile />

        <styled.Container>
        </styled.Container>
      </LayoutWrapper>
    </>
  )
}

export default RequestPayment;
