import styled, { css } from "styled-components";

type TModal = {
  showModal?: boolean;
};

interface HeaderStatusProps {
  bg?: string;
}

export const RelativeContainer = styled.div`
  position: relative;

  .notification-icon,
  .notification-icon--settings {
    cursor: pointer;
    margin: 6px 24px;

    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  .tip-icon {
    cursor: pointer;
  }

  .notification-icon--settings {
    @media screen and (max-width: 700px) {
      display: flex;
    }
  }

  .margin-right {
    margin-right: 24px;
  }

  .margin-left {
    margin-left: 24px;
  }
`;

export const TipRelativeContainer = styled(RelativeContainer)`
  max-height: 21px;
`;

export const ModalContainer = styled.div<TModal>`
  background: ${(props) => props.theme.backgroundColors.primary};
  border: 2px solid ${(props) => props.theme.backgroundColors.third};
  box-sizing: border-box;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  left: -45%;
  top: 36px;
  transform: scale(0.9);
  transition: all ease-in-out 0.1s;
  z-index: 2;

  .header-container {
    padding: 16px 24px;
  }

  .header-container--flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
  }

  .buttons-container,
  .buttons-container-stores {
    padding-top: 8px;
    padding-bottom: 8px;

    .button__item,
    .button__item--large {
      padding: 0px 24px;
      display: flex;
      align-items: center;
      transition: color after 1s;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        transition: ease 0.3s;
        background: ${(props) => props.theme.backgroundColors.fourth};
        text-decoration: none;
      }

      .span-id-number {
        font-size: 12px !important;

        @media screen and (max-width: 320px) {
          font-size: 11px !important;
        }
      }
    }

    .button__item--large {
      margin: 10px 0;

      label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        padding-top: 8px;
      }
    }
  }

  .buttons-container-stores {
    max-height: 300px;
    overflow-y: scroll;
  }

  @media screen and (max-width: 600px) {
    left: -88%;
  }

  @keyframes scaleModal {
    to {
      transform: scale(1);
    }
  }

  @keyframes reveserScameModal {
    95% {
      transform: scale(0.7);
    }

    100% {
      opacity: 0;
    }
  }
`;

export const RequestPaymentContainer = styled(ModalContainer)`
  width: 293px;
`;

export const UserProfileContainer = styled(ModalContainer)`
  width: auto;
  left: 0%;
`;

export const NationSelectContainer = styled(ModalContainer)`
  width: auto;
  left: 0%;
`;

export const FilterContainer = styled(ModalContainer)`
  width: 350px;
  left: -100%;

  .buttons-container--padding {
    max-height: auto;
    padding: 0px 24px;
  }

  label {
    label {
      margin-bottom: 12px;
    }
  }

  .intern-container {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    opacity: 0;
    animation: fadeIn 0.2s ease-in-out forwards;
    transition: all 0.2s ease;

    .calendar-container {
      margin-bottom: 12px;

      .react-calendar {
        padding: 4px;
        font-size: 12px;
      }

      .react-calendar__navigation__arrow {
        font-size: 24px;
      }

      .react-calendar__month-view__weekdays {
        color: ${(props) => props.theme.contentColors.secondary};

        div {
          text-align: center;
        }
      }

      .react-calendar__tile {
        color: ${(props) => props.theme.contentColors.primary};
      }

      .react-calendar__navigation {
        padding: 0 4px 0 4px;

        button {
          color: ${(props) => props.theme.contentColors.secondary};
        }
      }

      .react-calendar__tile--hover {
        background-color: #f9b6b7;
        color: #c43d45;
      }

      .react-calendar__tile--hover.react-calendar__decade-view__years__year {
        background-color: transparent;
        color: ${(props) => props.theme.contentColors.secondary};
      }

      .react-calendar__tile--hover.react-calendar__year-view__months__month {
        background-color: transparent;
        color: ${(props) => props.theme.contentColors.secondary};
      }

      .react-calendar__tile--hover.react-calendar__day-view__days__day {
        background-color: transparent;
        color: ${(props) => props.theme.contentColors.secondary};
      }

      .react-calendar__tile--hover.react-calendar__tile--hoverStart {
        background-color: ${(props) => props.theme.primaryColors.primary};
        color: #fff;
      }

      .react-calendar__tile--hover + .react-calendar__tile--hoverEnd {
        background-color: ${(props) => props.theme.primaryColors.primary};
        color: #fff;
      }

      .react-calendar__tile--now {
        border: 1px solid #fb505a;
        font-weight: 500;
        color: #c43d45;
        background: transparent;
      }

      .react-calendar__tile--active {
        background-color: ${(props) => props.theme.primaryColors.primary};
        color: #fff;
      }

      .react-calendar__tile--hoverStart,
      .react-calendar__tile--hoverEnd {
        background-color: ${(props) => props.theme.primaryColors.primary};
        color: #fff;
      }
    }

    @keyframes fadeIn {
      to {
        opacity: 1;
        transition: all 0.2s ease;
      }
    }
  }
`;

export const SelectBankAccountContainer = styled(ModalContainer)`
  width: 361px;
  left: 0%;
  top: -380px;

  .buttons-container {
    max-height: 150px;
    overflow-y: scroll;

    scrollbar-base-color: ${(props) => props.theme.backgroundColors.seventh};
    scrollbar-base-color: ${(props) => props.theme.backgroundColors.seventh};
    scrollbar-3dlight-color: ${(props) => props.theme.backgroundColors.seventh};
    scrollbar-highlight-color: ${(props) =>
      props.theme.backgroundColors.seventh};
    scrollbar-track-color: ${(props) => props.theme.backgroundColors.eighth};
    scrollbar-arrow-color: black;
    scrollbar-shadow-color: ${(props) => props.theme.backgroundColors.seventh};
    scrollbar-dark-shadow-color: ${(props) =>
      props.theme.backgroundColors.seventh};

    ::-webkit-scrollbar-track {
      background-color: ${(props) => props.theme.backgroundColors.seventh};
    }
    ::-webkit-scrollbar {
      width: 4px;
      background: ${(props) => props.theme.backgroundColors.seventh};
    }
    ::-webkit-scrollbar-thumb {
      background: #000;
    }

    .button__item {
      cursor: pointer;

      button {
        width: auto;
      }

      span {
        color: ${(props) => props.theme.contentColors.primary};
        font-size: 13px;
      }
    }
  }

  .add-bank-account-container {
    margin: 0 0 24px 24px;
  }

  @media screen and (max-width: 600px) {
    left: 0%;
    top: -380px;
  }

  @media screen and (max-width: 480px) {
    width: 374px;
  }

  @media screen and (max-width: 390px) {
    width: 336px;
  }

  @media screen and (max-width: 320px) {
    width: 280px;
  }
`;

export const NotificationContainer = styled(ModalContainer)`
  width: 293px;
  left: -160%;

  .view-all__element {
    padding: 0 0 16px 0;
    width: 100%;
    text-align: center;

    p {
      font-size: 14px;
      color: ${(props) => props.theme.primaryColors.primary};
      cursor: pointer;
    }
  }
`;

export const SelectStoreContainer = styled(ModalContainer)`
  width: 400px;
  left: 0%;
  top: -370px;
  min-height: 340px;
  max-height: 340px;
  overflow-y: scroll;

  .header-container--search {
    padding: 0px 24px;
  }

  .events-buttons-container--padding {
    padding: 16px;

    label {
      margin: 16px 0;
    }

    .checkbox-span {
      font-family: "Brandon Text Regular" !important;
    }
  }

  .create-product-container {
    padding: 16px;
  }

  @media screen and (max-width: 600px) {
    left: 0%;
  }

  @media screen and (max-width: 480px) {
    width: 374px;
  }

  @media screen and (max-width: 390px) {
    width: 336px;
  }

  @media screen and (max-width: 320px) {
    width: 280px;
  }
`;

export const SelectProductsContainer = styled(SelectStoreContainer)`
  // overflow-x: hidden;

  .header-container--search,
  .header-container--search--borderless {
    border-bottom: 1px solid ${(props) => props.theme.backgroundColors.eighth};

    .button__item--large {
      margin: 16px 0;

      label {
        margin-bottom: 0;
      }
    }
  }

  .header-container--search--borderless {
    border-bottom: 0px;
    padding: 0px 24px !important;
  }

  .space {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .span-id-number {
      padding-top: 8px;
      color: #5e5e5e;
    }
  }

  .scroll-items {
    max-height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const SelectSplitsContainer = styled(SelectStoreContainer)`
  top: 60px;

  .header-container--search {
    border-bottom: 0px;

    .button__item--large {
      margin: 0;

      label {
        margin-bottom: 0;
      }
    }
  }
`;

export const SearchSplitRecipientContainer = styled(SelectStoreContainer)`
  top: 60px;
  min-height: 180px;
  max-height: 180px;

  .header-container--search {
    border-bottom: 0px;

    .button__item--large {
      margin: 0;

      label {
        margin-bottom: 0;
      }
    }
  }
`;

export const TipContainer = styled(ModalContainer)`
  width: 200px;
  left: 120%;
  top: -70%;
  font-size: 14px !important;
`;

export const MemberkitContainer = styled.div`
  z-index: 999999999;
  position: fixed;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  left: 0px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-centered {
    width: 600px;
    font-size: 14px !important;
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.1);
    background: #fff;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px;
      border-bottom: 1px solid ${(props) => props.theme.backgroundColors.third};

      h5 {
        color: #202223;
        font-size: 20px;
      }

      img {
        cursor: pointer;
      }
    }

    .loading-connecting-container {
      height: 200px;
      display: flex;
      justify-content: center;

      .spinner-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .spinner {
          border: 6px solid ${(props) => props.theme.primaryColors.fifth};
          border-left-color: #0e82f8;
          height: 82px;
          width: 82px;
          border-radius: 50%;
          animation: spin 0.8s linear infinite;
          margin-right: 8px;

          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
        }

        p {
          margin-top: 16px;
          color: #202223;
          font-size: 16px;
        }
      }
    }

    .form {
      padding: 32px 20px;

      strong {
        color: #000;
        font-size: 16px;
      }

      .blue-info-container,
      .grey-info-container {
        background: #eff7ff;
        border-radius: 3px;
        padding: 16px;
        margin: 24px 0;
        display: flex;
        align-items: center;

        p,
        a {
          color: #1b1b1b;
          font-size: 14px;
        }

        a {
          text-decoration: underline;
          cursor: pointer;
        }

        img {
          margin-right: 8px;
        }
      }

      .grey-info-container {
        background: #f7f7f7;

        div {
          display: flex;
          flex-direction: column;

          img {
            margin-bottom: 100%;
          }

          strong {
            font-size: 14px;
            color: #1b1b1b;
          }
        }
      }

      .form-container {
        .form-item {
          label {
            color: #5e5e5e;
            font-size: 14px;
          }

          .courses {
            .course__item {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .course__right {
                min-width: 248px;
              }

              .course__left {
                display: flex;
                align-items: center;
                overflow: hidden;
              }
            }
          }
        }

        .select-and-input-container {
          display: flex;
          border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
          border-radius: 4px;
          align-items: center;
          height: 40px;

          .m-half-width {
            min-width: 50%;
            max-width: 50%;
          }

          .m-half-width--flex {
            display: flex;
          }

          div {
            &:last-child {
              height: 100%;
              border-left: 1px solid
                ${(props) => props.theme.backgroundColors.eighth};
            }
          }

          input {
            height: 24px;
            padding: 0 8px;
            width: 100%;
            background: transparent;
          }

          input[type="time"],
          input[type="date"] {
            height: 24px;
            padding: 0px;
            width: 100%;
            background: transparent;
          }
          /* &:not(:first-child) {
          margin-top: 16px;
        } */
        }
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px 20px;
      border-top: 1px solid ${(props) => props.theme.backgroundColors.third};

      button {
        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }
`;

export const Background = styled.div<HeaderStatusProps>`
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 3px;
  margin-right: 8px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const SettingsNotificationContainer = styled(NotificationContainer)`
  left: -250px;

  @media screen and (max-width: 600px) {
    left: -250px;
  }
`;

export const ViewAsContainer = styled(ModalContainer)`
  width: 361px;
  left: 0%;

  .view-as__title {
    font-size: 13px;
    margin-bottom: 16px;
    color: ${(props) => props.theme.contentColors.secondary};
    font-family: "Brandon Text Medium" !important;
  }

  .create-store-container {
    margin: 0 0 24px 24px;
  }

  .return-to-business-container {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 425px) {
    left: -1%;
  }

  @media screen and (max-width: 390px) {
    left: -18%;
  }
`;

export const SearchTypeContainer = styled(ModalContainer)`
  width: 250px;
  left: -550%;

  @media screen and (max-width: 390px) {
    left: -550%;
  }
`;

export const SelectPlansContainer = styled(ModalContainer)`
  width: 361px;
  left: -1.5%;
  top: 48px;

  .buttons-container {
    max-height: 150px;
    overflow-y: scroll;

    scrollbar-base-color: ${(props) => props.theme.backgroundColors.seventh};
    scrollbar-base-color: ${(props) => props.theme.backgroundColors.seventh};
    scrollbar-3dlight-color: ${(props) => props.theme.backgroundColors.seventh};
    scrollbar-highlight-color: ${(props) =>
      props.theme.backgroundColors.seventh};
    scrollbar-track-color: ${(props) => props.theme.backgroundColors.eighth};
    scrollbar-arrow-color: black;
    scrollbar-shadow-color: ${(props) => props.theme.backgroundColors.seventh};
    scrollbar-dark-shadow-color: ${(props) =>
      props.theme.backgroundColors.seventh};

    ::-webkit-scrollbar-track {
      background-color: ${(props) => props.theme.backgroundColors.seventh};
    }
    ::-webkit-scrollbar {
      width: 4px;
      background: ${(props) => props.theme.backgroundColors.seventh};
    }
    ::-webkit-scrollbar-thumb {
      background: #000;
    }

    .button__item {
      button {
        width: auto;
      }

      span {
        color: ${(props) => props.theme.contentColors.primary};
        font-size: 13px;
      }
    }
  }

  .create-store-container {
    margin: 24px 0 24px 24px;
  }
`;

export const PeriodContainer = styled(ModalContainer)`
  width: auto;
  left: -10%;
`;

export const Tip = styled.div`
  .tip-intern-container {
    padding: 16px 24px;

    div {
      display: flex;
      margin-bottom: 5px;

      img {
        margin-right: 4px;
      }

      p {
        font-size: 13px;
        color: ${(props) => props.theme.contentColors.secondary};
        font-family: "Brandon Text Medium" !important;
      }
    }

    p {
      font-size: 13px;
      color: ${(props) => props.theme.contentColors.primary};
    }
  }

  border-top: 1px solid ${(props) => props.theme.backgroundColors.third};
`;

export const NotificationItem = styled(Tip)`
  .tip-intern-container {
    div {
      align-items: center;

      span {
        font-size: 13.9px;
        color: ${(props) => props.theme.primaryColors.primary};
        margin-left: auto;
        cursor: pointer;
      }
    }

    .tip-intern__text {
      padding-bottom: 8px;
    }

    .tip-intern__time {
      font-size: 10px;
      color: ${(props) => props.theme.contentColors.primary};
    }

    border-top: 0px;
    border-bottom: 1px solid ${(props) => props.theme.backgroundColors.third};

    &:last-child {
      border-bottom: 0px;
    }
  }

  border-top: 0px;
`;

export const LabelViewAs = styled.div`
  cursor: pointer;
  height: 31px;
  margin-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;

  strong {
    font-family: "Brandon Text Medium" !important;
    font-size: 16px;
    color: ${(props) => props.theme.contentColors.secondary};
    text-align: center;
    margin-right: 12px;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
      max-width: 150px;
      text-overflow: clip;
      text-overflow: ellipsis;
      text-overflow: "…";
    }

    @media screen and (max-width: 414px) {
      white-space: nowrap;
      width: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 356px) {
      width: 80px;
    }

    @media screen and (max-width: 320px) {
      width: 60px;
    }
  }
`;

export const LabelNation = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  p {
    color: ${(props) => props.theme.contentColors.secondary};
    margin: 0 8px;
  }
`;

export const LabelSelectPlan = styled.div`
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
  height: 40px;
  border-radius: 3px;
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: ${(props) => props.theme.contentColors.primary};
  padding: 0 8px;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const ArrowDowndToggle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
`;
