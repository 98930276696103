import styled from 'styled-components';

interface IconProps {
  icon: string;
}

export const TextArea = styled.div`
  background: ${({ theme }) => theme.backgroundColors.primary};
  border-radius: 3px;
  border: 1px solid ${props => props.theme.backgroundColors.eighth};
  width: 100%;
  height: 80px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: ${props => props.theme.textColors.primary};
  padding: 0 8px;

  img {
    cursor: pointer;
    transition: all .3s;
  }

  textarea {
    width: 100%;
    resize: none;
    height: 78px;
    padding: 4px 0 4px 0;
  }
`;

export const Icon = styled.div<IconProps>`
  background-color: ${props => props.theme.textColors.primary};
  -webkit-mask: url(${props => props.icon}) no-repeat center;
  mask: url(${props => props.icon}) no-repeat center;
  mask-size: contain;
  width: 18px;
  height: 18px;
`;