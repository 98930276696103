import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import type { FieldValues } from "react-hook-form/dist/types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, number, boolean, date } from "yup";
import { useIntl } from "react-intl";
import { parse, isDate } from "date-fns";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

//Services
import SessionService from "services/Session";
import RequestPaymentServices from "services/RequestPayment";
import ProductsServices from "services/Product";

//Types
import {
  AccessUnlimited,
  ClassRoom,
  FinalProducts,
  Product,
  ProductState,
} from "../../../types";
import ProductDetails from "../../../../ProductDetails";

export const useCreatePlan = () => {
  dayjs.extend(customParseFormat);

  const history = useHistory();
  const intl = useIntl();
  const params: { storeId?: string; id?: string } = useParams();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  const [processing, setProcessing] = useState(false);

  const [amount, setAmount] = useState("0");
  const [lastFinalAmount, setLastFinalAmount] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<Product[]>([]);
  const [productPreSelected, setProductPreSelected] = useState<ProductState>();
  const [classRooms, setClassRooms] = useState<ClassRoom[]>([]);
  const [selectedUnlimitedAccess, setSelectedUnlimitedAccess] = useState(false);
  const [unlimitedAccessCourse, setUnlimitedAccessCourse] =
    useState<AccessUnlimited>({
      connector: "",
      id: 0,
      images: [""],
      name: "",
      unitPrice: 0,
    });

  const stringRequired = string().required(
    intl.formatMessage({ id: "webhook.error.message.default" })
  );

  const isPtbr = navigator.language === "pt-BR";

  const today = new Date();

  const createSubscriptionSchema = object({
    name: stringRequired,
    description: stringRequired,
    paymentType: object()
      .shape({
        card: boolean(),
        pix: boolean(),
        boleto: boolean(),
      })
      .test(
        "validate if there is at least one select",
        intl.formatMessage({
          id: "subscriptions.page.create.subscription.payment.type",
        }),
        (values) => {
          for (const [_, value] of Object.entries(values)) {
            if (value) return true;
          }

          return false;
        }
      ),
    chargeDaysBefore: number().when("paymentType", (paymentType) => {
      if (paymentType.boleto) {
        return number().required();
      }

      return number();
    }),
    maxCharges: number(),
    amount: number().required().min(1),
    frequency: number(),
    gracePeriod: number().required(),
    // gracePeriod: date()
    //   .transform((value: any, originalValue: any) => {
    //     if (originalValue.length === 10) {
    //       const splittedDate = originalValue.split("/");
    //       const manualFormatDate = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
    //       const parsedDate = isDate(manualFormatDate)
    //         ? manualFormatDate
    //         : parse(manualFormatDate, "yyyy-MM-dd", today);

    //       return parsedDate;
    //     }

    //     return today;
    //   })
    //   .typeError(
    //     intl.formatMessage({
    //       id: "subscriptions.page.grace.period.error.invalid",
    //     })
    //   )
    //   .min(
    //     today,
    //     intl.formatMessage({ id: "subscriptions.page.grace.period.error" })
    //   )
    //   .nullable(),
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createSubscriptionSchema),
  });

  const paymentTypeWatch = watch("paymentType");
  const gracePeriodWatch = watch("gracePeriod");

  useEffect(() => {
    const selectedProductsAmount = selectedProducts.reduce((acc, product) => {
      return acc + product.quantity * product.unitPrice;
    }, 0);

    const selectedCoursesAmount = selectedCourses.reduce((acc, course) => {
      return acc + course.quantity * course.unitPrice;
    }, 0);

    const finalAmount = selectedCoursesAmount + selectedProductsAmount;

    const currentAmount = parseFloat(amount);
    const newAmountOnly = currentAmount - lastFinalAmount;

    setLastFinalAmount(finalAmount);
    setAmount((newAmountOnly + finalAmount).toString());
  }, [selectedProducts, selectedCourses]);

  useEffect(() => {
    setValue("amount", parseFloat(amount));
  }, [amount]);

  useEffect(() => {
    if (params && params?.storeId && params?.id) {
      RequestPaymentServices.requestPlan(parseInt(params!.id)).then(
        (response) => {
          const frequencies: { [key: string]: string } = {
            Daily: "0",
            Weekly: "1",
            Monthly: "2",
            Quarterly: "3",
            HalfYearly: "4",
            Yearly: "5",
          };

          setValue("name", response.data.name);
          setValue("description", response.data.description);
          if (response.data.products.length === 0) {
            setAmount(response.data.amount);
          }
          setValue("maxCharges", response.data.maxCharges);
          setValue("frequency", frequencies[response.data.frequency]);
          setValue("gracePeriod", response.data.gracePeriod);
          setValue("chargeDaysBefore", response.data.chargeDaysBefore);

          const formPaymentTypes = {
            card: response.data.paymentTypes.includes(0),
            pix: response.data.paymentTypes.includes(3),
            boleto: response.data.paymentTypes.includes(1),
          };

          const currentPlanProducts = response.data.products.map(
            (product: any) => {
              return ProductsServices.getProductDetails(product.id);
            }
          );

          Promise.all(currentPlanProducts).then((values) => {
            const formattedProducts: Product[] = values.map(
              (productPromise: any, index: number) => {
                return {
                  unitPrice: productPromise.unitPrice,
                  productName: productPromise.name,
                  productId: productPromise.id,
                  quantity: response.data.products[index].quantity,
                  createdOn: productPromise.unitPricecreatedOn,
                  connector: productPromise.connector,
                  selectedRoom: "",
                  selectedRoomId: 0,
                };
              }
            );

            const reducedProductsWithCourses = formattedProducts.reduce(
              (acc, curr) => {
                if (curr.connector) {
                  const courses = [...acc.courses];
                  courses.push(curr);
                  return {
                    products: [...acc.products],
                    courses: courses,
                  };
                }

                const products = [...acc.products];
                products.push(curr);
                return { products: products, courses: [...acc.courses] };
              },
              { products: [] as Product[], courses: [] as Product[] }
            );

            setSelectedCourses(reducedProductsWithCourses.courses);
            setSelectedProducts(reducedProductsWithCourses.products);
          });

          setValue("paymentType", formPaymentTypes);
        }
      );
    }
  }, [params]);
  
  function submitNewSubscription(data: FieldValues) {
    const productsAndCourses = [...selectedProducts, ...selectedCourses];
    const finalProducts = productsAndCourses.reduce(
      (acc: FinalProducts[], curr) => {
        const newProduct = {
          productId: curr.productId,
          quantity: curr.quantity,
        };

        return [...acc, newProduct];
      },
      []
    );

    setProcessing(true);

    let postPaymentTypes: number[] = [];

    for (const [key, value] of Object.entries(data.paymentType)) {
      if (key === "card" && value) {
        postPaymentTypes.push(0);
      }

      if (key === "boleto" && value) {
        postPaymentTypes.push(1);
      }

      if (key === "pix" && value) {
        postPaymentTypes.push(3);
      }
    }

    // const originalValue = dayjs(data.gracePeriod).format("DD/MM/YYYY");
    // const splittedDate = originalValue.split("/");
    // const manualFormatDate = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
    // const parsedDate = parse(manualFormatDate, "yyyy-MM-dd", new Date());
    // const newGracePeriod = dayjs(parsedDate).diff(dayjs(), "day");

    if (params && params?.storeId && params?.id) {
      RequestPaymentServices.updatePlan(
        data.name,
        data.description,
        data.frequency,
        parseFloat(data.amount),
        parseInt(data.gracePeriod),
        undefined,
        postPaymentTypes,
        data.maxCharges,
        data.chargeDaysBefore,
        0,
        "fixed",
        true,
        finalProducts,
        parseInt(params!.id)
      )
        .then((response) => {
          setProcessing(false);
          history.push(`/plans`);
        })
        .catch((err) => {
          console.log(err);
          setProcessing(false);
        });
    } else {
      RequestPaymentServices.postPlans(
        data.name,
        data.description,
        data.frequency,
        parseFloat(data.amount),
        parseInt(data.gracePeriod),
        undefined,
        postPaymentTypes,
        data.maxCharges,
        data.daysBeforeGenerateBoleto,
        0,
        "fixed",
        true,
        finalProducts
      )
        .then((response) => {
          setProcessing(false);

          history.push(`/plans`);
        })
        .catch((err) => {
          console.log(err);
          setProcessing(false);
        });
    }
  }

  function handleNewPaymentLinkDataAmount(value: string) {}

  function handleCourseClassRoom(id: number, value: string) {
    let currentCourses = selectedCourses;

    const newCourses = currentCourses.map((product) => {
      if (product.productId === id) {
        const classNameValue = value.split("-")[0];
        const classIdValue = parseInt(value.split("-")[1]);

        return {
          ...product,
          selectedRoom: classNameValue,
          selectedRoomId: classIdValue,
        };
      }

      return { ...product };
    });

    setSelectedCourses(newCourses);
  }

  function handleProductQuantity(id: number, value: string) {
    let currentProducts = selectedProducts;

    const newProducts = currentProducts.map((product) => {
      if (product.productId === id) {
        return {
          ...product,
          quantity: value === "" ? parseInt("0") : parseInt(value),
        };
      }

      return { ...product };
    });

    setSelectedProducts(newProducts);
  }

  return {
    processing,
    submitNewSubscription,
    handleSubmit,
    control,
    errors,
    selectedProducts,
    setSelectedProducts,
    selectedCourses,
    setSelectedCourses,
    productPreSelected,
    setProductPreSelected,
    handleNewPaymentLinkDataAmount,
    classRooms,
    setClassRooms,
    selectedUnlimitedAccess,
    setSelectedUnlimitedAccess,
    unlimitedAccessCourse,
    setUnlimitedAccessCourse,
    handleCourseClassRoom,
    handleProductQuantity,
    paymentTypeWatch,
    gracePeriodWatch,
    amount,
    setAmount,
    isPtbr,
    params,
  };
};
