import styled from "styled-components";

interface ILiProps {
  cor: string;
}

export const fullContainerLinearBg = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  form {
    width: 100%;
    height: 100vh;
    padding: 16px 24px;
    position: relative;
    background: #fff;

    .form-topbar {
      margin-bottom: 36px;

      img {
        width: 120px;
      }
    }

    .form-container {
      margin-top: 47px;

      .form-item {
        margin-top: 24px;

        label {
          font-size: 14px;
          color: #000;
        }
      }

      .c-password-rules {
        color: #000;
        margin-top: 24px;

        p {
          color: #000;
        }

        ul {
          list-style: none;
          padding: 0px;
        }
      }
    }

    .form-footer {
      position: relative;
      margin-top: 24px;

      p,
      a {
        font-size: 12px;
        color: #929292;
        font-family: "Rubik", sans-serif !important;
        font-weight: 400;
      }

      a {
        text-decoration: underline;
      }

      .terms {
        display: flex;
        margin-top: 24px;
        align-items: center;

        p,
        a {
          font-family: "Rubik", sans-serif !important;
          font-weight: 400;
          font-size: 12px;
          color: #000;
        }

        a {
          margin-left: 4px;
          display: flex;
          align-items: center;
          font-weight: 500;
          text-decoration: none;

          img {
            margin-left: 4px;
          }
        }

        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }

    h1,
    h3 {
      font-size: 24px;
      margin-bottom: 8px;
      font-family: "Rubik", sans-serif !important;
      font-weight: 600;
      color: ${(props) => props.theme.contentColors.eighth};
    }

    h3 {
      font-size: 16px;
      font-weight: 400;
    }

    .button-float {
      position: absolute;
      bottom: 0;
      padding: 8px 16px;
      border-top: 1px solid #e2e2e2;
      width: 100vw;
      left: 0;
      font-size: 16px;

      @media screen and (min-width: 768px) {
        position: relative;
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        border: 0px;
        margin-top: 24px;

        button {
          min-width: 269px !important;
          max-width: 269px !important;
        }
      }
    }

    @media screen and (min-width: 768px) {
      min-width: 528px;
      max-width: 528px;
      height: auto;
      border-radius: 19px;
      padding: 48px 64px;
    }
  }

  .terms--desktop {
    display: none;

    @media screen and (min-width: 768px) {
      display: flex;
      margin-top: 30px;
      display: flex;
      align-items: center;

      p,
      a {
        font-family: "Rubik", sans-serif !important;
        font-weight: 400;
        font-size: 20px;
        color: #fff;
      }

      a {
        margin-left: 8px;
        cursor: pointer;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    background: linear-gradient(97.06deg, #fb505a 1.34%, #ff2230 99.13%);
  }
`;

export const Li = styled.li<ILiProps>`
  font-size: 12px;
  color: #929292;
  font-family: "Rubik", sans-serif !important;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: ${(props) => props.cor} !important;

  span {
    width: 8px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
  }
`;
