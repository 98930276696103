import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

//Components
import MyLoader from "components/ContentLoader";
import {
  ContentBox,
  LayoutWrapper,
  Pagination,
} from "components/Layout/Index";
import Search from "components/Layout/Search";
import DownloadLoading from "components/DownloadLoading";

import Input from "components/Forms/InputFloatLabel";
import Buttons from "components/Forms/Buttons";

//Services
import SessionService from "services/Session";

//Assets
import searchIcon from "assets/images/global/search-transactions.svg";

//Hooks
import { useCustomer } from "./hooks";

//Styles
import * as indexStyled from "../../styles/index-styles";

//Types
import { Customer } from "./types";

import { useSettings } from "../../store/settings";

function Customers() {
  let history = useHistory();
  let { state } = useLocation();
  const intl = useIntl();

  const session = JSON.parse(localStorage.getItem("session") || "{}");

  const { environment } = useSettings((state) => state.settings); 
  const validBusinessIds = [3739, 110]

  const viewOnlyAndAgent = 
  ( session.role === 'Store.Agent' || 
    session.role === 'Store.ViewOnly' || 
    session.role === 'Business.ViewOnly' || 
    session.role === 'Business.Agent') && validBusinessIds.includes(Number(session.businessId)); 

  if (viewOnlyAndAgent) {
    history.push("/transactions")
  }

  const {
    isSearch,
    searchedCustomer,
    isWaitingDownload,
    percentageDownload,
    showNotFound,
    customers,
    isCustomersLoaded,
    pageIndex,
    setPageIndex,
    startShowOrders,
    pageIndexProcessing,
    numberTotalOfCustomers,
    handleSearchedCustomer,
    getCustomers,
  } = useCustomer();

  if (!state) {
    state = "";
  }

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  return (
    <>
      <LayoutWrapper>
        {isSearch && (
          <Search
            placeholder={intl.formatMessage({ id: "home.search.order" })}
          />
        )}

        <DownloadLoading
          isLoading={isWaitingDownload}
          percentage={percentageDownload}
        />

        {startShowOrders && (
          <indexStyled.RowListWithMarginBottom>
            <ContentBox cssClass="m-top-25 list-transactions">
              <indexStyled.TableList>
                <indexStyled.ContextBoxHeader>
                  <div className="title">
                    <FormattedMessage id="button.sidebar.customer" />
                  </div>
                  <div className="selects">
                    <Input
                      mode="expand-search"
                      placeholder={`${intl.formatMessage({
                        id: `transactions.search`,
                      })} ${intl.formatMessage({
                        id: `transactions.search.${searchedCustomer.type}`,
                      })}`}
                      value={searchedCustomer.value}
                      handler={handleSearchedCustomer}
                      icon={searchIcon}
                      type="text"
                      searchOptions={["identificationNumber", "email", "id"]}
                      onKeyDown={(e) =>
                        e.keyCode === 13 ? getCustomers() : null
                      }
                      searchFunction={() => getCustomers()}
                    />

                    <div>
                      <Buttons
                        mode="filled"
                        label={intl.formatMessage({
                          id: "customers.page.create.customer",
                        })}
                        handler={() => {
                          history.push(`/customers/create`);
                        }}
                      />
                    </div>
                  </div>
                </indexStyled.ContextBoxHeader>
                <indexStyled.GridHeaderCustomers>
                  <p>
                    <FormattedMessage id="customers.page.customer" />
                  </p>
                  <p>
                    <FormattedMessage id="customers.page.email" />
                  </p>
                  <p>
                    <FormattedMessage id="customers.page.last.created" />
                  </p>
                </indexStyled.GridHeaderCustomers>

                <indexStyled.GridCustomersContainer>
                  <span className="transactions-list-table">
                    {showNotFound ? (
                      <h3>{intl.formatMessage({ id: "items.not.found" })}</h3>
                    ) : null}

                    {isCustomersLoaded && customers.length > 0
                      ? customers.map((customer: Customer, index) => {
                          return (
                            <div
                              className={`grid ${
                                index === customers.length - 1
                                  ? "last-item"
                                  : ""
                              }`}
                              onClick={() =>
                                history.push(
                                  `/customers/${customer.storeId}/${customer.id}`
                                )
                              }
                              key={customer.id}
                            >
                              <p>{`${customer.customer}`}</p>
                              <p>{customer.email}</p>
                              <p>
                                {`${customer.createdOn
                                  .split(",")[0]
                                  .substr(0, 5)}, ${
                                  customer.createdOn.split(",")[1]
                                }`}
                              </p>
                            </div>
                          );
                        })
                      : null}

                    {!isCustomersLoaded ? <MyLoader /> : null}
                  </span>
                </indexStyled.GridCustomersContainer>
              </indexStyled.TableList>
              <indexStyled.PaginationWrapper>
                <Pagination
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  disableNextButton={customers.length < 100 ? true : false}
                  pageIndexProcessing={pageIndexProcessing}
                  totalOrder={numberTotalOfCustomers}
                  maxNumberOrderPerRequest={100}
                />
              </indexStyled.PaginationWrapper>
            </ContentBox>
          </indexStyled.RowListWithMarginBottom>
        )}
      </LayoutWrapper>
    </>
  );
}

export default Customers;
