import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

//Components
import DetailItem from "components/Details/DetailItem";
import { SubscriptionItem } from "../SubscriptionItem";
import { CreditCardItem } from "../CreditCardItem";
import { ListRelatedOrders } from "components/ListRelatedOrders";
import PersonalCustomerDetails from "components/Details/PersonalCustomerDetails";

//Hooks
import { useDetails } from "./hook";

//Styles
import * as styled from "components/Details/details-styles";

export const CustomerDetails = () => {
  let { storeId, id } = useParams<any>();
  let intl = useIntl();

  const { customer, setCustomer } = useDetails({ id, storeId });

  return (
    <styled.LeftRightContainer>
      <styled.Left className="border-right">
        <styled.BoxDetails className="border-bottom">
          <styled.Header>
            {customer
              ? `${customer.customer.firstName} ${customer.customer.surname}`
              : null}
          </styled.Header>

          <styled.DetailsItems>
            <DetailItem
              label={intl.formatMessage({ id: "customers.details.spent" })}
              value={
                customer
                  ? intl
                      .formatNumber(customer.spent.total, {
                        style: "currency",
                        currency: "BRL",
                      })
                      .toString()
                  : "R$ 00,00"
              }
              loading={customer === undefined ? { width: 75 } : undefined}
            />

            <DetailItem
              label={intl.formatMessage({ id: "customers.details.created" })}
              value={customer ? customer.customer.createdOn : ""}
              loading={customer === undefined ? { width: 75 } : undefined}
            />

            <DetailItem
              label={intl.formatMessage({ id: "customers.details.disputes" })}
              value={customer ? customer.disputes.total : 0}
              loading={customer === undefined ? { width: 75 } : undefined}
            />
          </styled.DetailsItems>
        </styled.BoxDetails>

        {customer ? (
          <PersonalCustomerDetails
            customer={customer}
            setCustomer={setCustomer}
          />
        ) : null}
      </styled.Left>

      <styled.Right>
        {customer && customer.subscriptions.length > 0 ? (
          <styled.BoxDetails className="border-bottom">
            <div className="limitted-box">
              <styled.Header>
                <FormattedMessage id="customers.details.subscriptions.title" />
              </styled.Header>
              <styled.DetailsItems>
                {customer &&
                  customer.subscriptions.map(
                    (subscription: any, index: number) => {
                      return (
                        <SubscriptionItem
                          key={subscription.id}
                          subscription={subscription}
                          isTheLastItem={
                            index === customer.subscriptions.length - 1
                          }
                          loading={
                            customer === undefined ? { width: 100 } : undefined
                          }
                        />
                      );
                    }
                  )}
              </styled.DetailsItems>
            </div>
          </styled.BoxDetails>
        ) : null}

        <ListRelatedOrders
          orders={customer ? customer.orders : []}
          ordersLoaded={true}
        />

        {customer && customer.cards.length > 0 ? (
          <styled.BoxDetails>
            <div className="limitted-box">
              <styled.Header>
                <FormattedMessage id="customers.page.saved.cards" />
              </styled.Header>
              <styled.DetailsItems>
                {customer &&
                  customer.cards.map((card: any, index: number) => {
                    return (
                      <CreditCardItem
                        key={card.id}
                        card={card}
                        customerId={customer.customer.id}
                        isTheLastItem={index === customer.cards.length - 1}
                        loading={
                          customer === undefined ? { width: 100 } : undefined
                        }
                      />
                    );
                  })}
              </styled.DetailsItems>
            </div>
          </styled.BoxDetails>
        ) : null}
      </styled.Right>
    </styled.LeftRightContainer>
  );
};
