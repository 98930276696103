import React from "react";
import { useIntl } from "react-intl";

//Components
import { LayoutWrapper, ContentBox } from "components/Layout/Index";
import OrdersChart from "components/ReportChart/OrdersChart";
import GrossSalesChart from "components/ReportChart/GrossSalesComponent";
import DisputesCountChart from "components/ReportChart/DisputesCountComponent";
import NewCustomersChart from "components/ReportChart/NewCustomersComponent";
import PaymentMethodsChart from "components/ReportChart/PaymentMethodsComponent";
import RefundsChart from "components/ReportChart/RefundsComponent";

//Styles
import * as styled from "./styles/reports-styles";

function Home() {
  const intl = useIntl();

  return (
    <>
      <LayoutWrapper>
        <styled.SpaceBetween>
          <ContentBox cssClass="border-right border-bottom">
            <GrossSalesChart
              id="gross-sales"
              title={intl.formatMessage({ id: "reports.gross.sales.title" })}
              data={{}}
            />
          </ContentBox>

          <ContentBox cssClass="border-bottom">
            <OrdersChart
              id="Orders"
              title={intl.formatMessage({ id: "reports.sales.title" })}
              data={{}}
            />
          </ContentBox>
        </styled.SpaceBetween>

        <styled.SpaceBetween>
          <ContentBox cssClass="border-right border-bottom">
            <PaymentMethodsChart
              id="Payment-methods"
              title={intl.formatMessage({
                id: "reports.payment.methods.title",
              })}
              data={{}}
            />
          </ContentBox>

          <ContentBox cssClass="border-bottom">
            <NewCustomersChart
              id="New-customers"
              title={intl.formatMessage({ id: "reports.customers.title" })}
              data={{}}
            />
          </ContentBox>
        </styled.SpaceBetween>

        <styled.SpaceBetween>
          <ContentBox cssClass="border-right border-bottom">
            <DisputesCountChart
              id="Disputes-count"
              title={intl.formatMessage({ id: "reports.disputes.title" })}
              data={{}}
            />
          </ContentBox>

          <ContentBox cssClass="border-bottom">
            <RefundsChart
              id="Estornos"
              title={intl.formatMessage({ id: "reports.refunds.title" })}
              data={{}}
            />
          </ContentBox>
        </styled.SpaceBetween>
      </LayoutWrapper>
    </>
  );
}

export default Home;
