import React, { createContext, useState } from 'react';

export const SessionContext = createContext<any>({});

type SessionType = {
  token: string;
  role: string;
  storeId: number;
}

export type ContextAllTypes = {
  session: SessionType;
  setSession: (session: SessionType) => void;
}

export const SessionProvider = ({ children }: any) => {
  const [session, setSession] = useState<SessionType>({
    token: "",
    role: "",
    storeId: 0
  });

  return (
    <SessionContext.Provider value={{
      session,
      setSession
    }}>
      {children}
    </SessionContext.Provider>
  )
}