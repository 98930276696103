const axios = require("axios");

class SplitsServices {
  async getSplitRules() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/split_rules`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async addSplitRule(splitRule: any) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/split_rules`,
      {
        ...splitRule,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );

    return response;
  }

  async getExistentSplitRecipients(identificationNumber: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${identificationNumber}`,
      {
        headers: {
          // Authorization: `Bearer ${session.token}`,
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI0IiwidW5pcXVlX25hbWUiOiJjaGVja291dCIsInJvbGUiOiJQcmVtZS5DaGVja291dCIsIm5iZiI6MTYwMjY5OTk0NywiZXhwIjoxNzYwNDY2MzQ3LCJpYXQiOjE2MDI2OTk5NDd9.rkk_o-UqRApKtA-RHrSg6xDLqgN4pRZE3ZE5fz1VYSk",
        },
      }
    );
  }
}

export default new SplitsServices();
