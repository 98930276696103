import styled from 'styled-components';

export const ProcessingButtonContainer = styled.button`
  background: linear-gradient(#FF7276, #FF7276) padding-box,
  linear-gradient(to bottom, #FF8887, #FF6565) border-box;
  color: ${props => props.theme.secondaryTextColors.primary};
  border-radius: 3px;
  padding: 8px 16px;
  width: 150px;
  border: 1px solid transparent;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;

  .spinner {
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-left-color: #fff;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    animation: spin .8s linear infinite;
    margin-right: 8px;

    @keyframes spin {
      to {
        transform: rotate(360deg); 
      }
    }
  }
`;
