import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

//Hooks
import useClickOutside from '../../../hooks/useClickOutside';

//Components
import Buttons from '../../Forms/Buttons';
import Input from '../../Forms/InputFloatLabel';

//Assets
import arrowIcon from 'assets/images/topbar/select-arrow.svg';

//Styles
import * as styled from './modals-styles';

type TFilter = {
  [key: string]: boolean;
}

type TModal = {
  handler: any;
  clear: () => void;
  eventsToEdit?: any;
}

const WHEventsButtonModal = ({ handler, clear, eventsToEdit }: TModal) => {
  const [isMounted, setIsMounted] = useState(false);
  const [showSelectAndButtonModal, setShowSelectAndButtonModal] = useState(false);
  const [periodType, setPeriodType] = useState('creation');
  const [isSelectedAStore, setIsSelectedAStore] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState('');
  const [searchedEvent, setSearchedEvent] = useState('');

  const [events, setEvents] = useState(['created', 'succeded', 'canceled', 'failed', 'disputed', 'charged', 'dispute.succeeded']);
  const [backUpEvents, setBackUpEvents] = useState(['created', 'succeded', 'canceled', 'failed', 'disputed', 'charged', 'dispute.succeeded']);
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [selectedEventsIndex, setSelectedEventsIndex] = useState<string[]>([]);

  const selectWHEventsModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const [filterChecked, setFilterChecked] = useState<TFilter>({
    date: false,
    status: false,
    paymentMethod: false,
    type: false
  });

  useEffect(() => {
    if(searchedEvent !== '') {
      let filteredStores = backUpEvents.filter((event: string) => event.toLowerCase().includes(searchedEvent.toLowerCase()));

      setEvents(filteredStores);
    }

    if(searchedEvent === '') {
      setEvents(backUpEvents);
    }

  }, [searchedEvent]);
  
  useEffect(() => {
    if(eventsToEdit.length > 0) {
      let selectedEventsArray: string[] = [];
      let selectedEventsEnum: string[] = [];

      eventsToEdit.map((label: any) => {
        selectedEventsEnum.push(label.type)
        selectedEventsArray.push(events[parseInt(label.type)]);
      });
      
      setSelectedLabel(selectedEventsArray.join(', '));
      handler(selectedEventsEnum);
    }

  }, []);

  function handleShowModal() {
    if(selectWHEventsModalRef.current) {
      setIsMounted(false);
      setShowSelectAndButtonModal(true);
    } else {
      setIsMounted(true);
      setShowSelectAndButtonModal(true);
    }
   
  }

  function handleFilter(property: string) {
    const currentLabels: string[] = [...selectedEvents];
    let selectedEventsArray: string[] = [];
    let selectedEventsEnum: string[] = [];

    if(currentLabels.includes(property)) {
      const indexToRemove = currentLabels.findIndex(event => event === property);
      const removingIndex = currentLabels.splice(indexToRemove, 1);

      setSelectedEvents(currentLabels);
    }else {
      currentLabels.push(property)
      setSelectedEvents(currentLabels);
    }

    currentLabels.map((label) => {
      selectedEventsEnum.push(label)
      selectedEventsArray.push(events[parseInt(label)]);
    });
    
    setSelectedLabel(selectedEventsArray.join(', '));
    handler(selectedEventsEnum);
    setSelectedEventsIndex(selectedEventsEnum);
  }

  useClickOutside(selectWHEventsModalRef, isMounted, setIsMounted);

  return (
    <styled.RelativeContainer>
      <styled.LabelSelectPlan onClick={handleShowModal}>
        <p>{selectedLabel !== '' ? selectedLabel : intl.formatMessage({ id: "webhook.connection.events.placeholder" })}</p>
        
        <img src={arrowIcon} alt="arrow" />
      </styled.LabelSelectPlan>
      
      {showSelectAndButtonModal && <styled.SelectStoreContainer 
        style={isMounted ? { animation: 'scaleModal .2s ease forwards' } : { animation: 'reveserScameModal .1s ease-out forwards' }} 
        ref={selectWHEventsModalRef}
        onAnimationEnd={() => { if (!isMounted) setShowSelectAndButtonModal(false) }}
      >
        <div className="header-container--flex">
          <p>{intl.formatMessage({ id: "filter.label.title" })}</p>
        </div>

        <div className="header-container--search">
          <div>
            <Input required={false} mode="search" name="wantedStore" value={searchedEvent} placeholder={intl.formatMessage({ id: "header.search.country" })} handler={setSearchedEvent} />
          </div>
        </div>

        <div className="events-buttons-container--padding">
          {events.map((event, index) => (
            <Input required={false} mode="checkbox" valueToCheck={selectedEventsIndex.includes(index.toString())} name="events" value={index} placeholder={intl.formatMessage({ id: `webhook.event.${event}` })} handler={(e: any) => handleFilter(e.target.value)} />
          ))}
        </div>
      </styled.SelectStoreContainer>}
    </styled.RelativeContainer>
  )
} 

export default WHEventsButtonModal;
