import moment from "moment";

interface ICustomer {
  firstName: string;
  lastName: string;
  businessName: string;
  surname: string;
  identificationNumber: string;
  email: string;
  phone: string;
  birthdate: string;
  address: {
    street: string;
    number: string;
    zipcode: string;
    reference: string;
    district: string;
    city: string;
    state: number;
    country: number;
  };
}

type TManualOrder = {
  planId?: number;
  frequency: number;
  paymentTypes: number[];
  grace: number;
  productCategory: string;
  orderNumber: string;
  amount: string;
  description: string;
  expiration: string;
  includeFeeTransfer: boolean;
  installments: number;
  duedate: string;
  whenSend: string;
  sendToCustomer: string;
  warrantyDays: string;
  generateAllBoletos: boolean;
  maxCharges?: number;
};

type PaymentLinkProps = {
  customer: ICustomer;
  progressStep: number;
  handleErrors: any;
  card: boolean;
  boleto: boolean;
  pix: boolean;
  isOptional: boolean;
  manualOrder?: TManualOrder;
  newPlan?: any;
  lateFee?: any;
  discount?: any;
  interest?: any;
  limitBoleto?: number;
};

export function customerValidations({
  customer,
  progressStep,
  handleErrors,
  card,
  boleto,
  pix,
  isOptional = false,
  manualOrder,
  newPlan,
  lateFee,
  discount,
  interest,
  limitBoleto
}: PaymentLinkProps) {
  let errors = [];
  let isCnpj = false;

  if (!isOptional && progressStep === 2) {
    
    if(boleto && manualOrder?.amount && limitBoleto && parseFloat(manualOrder?.amount) > limitBoleto) {
      errors.push("amountMoreThanLimit");
    }
    
    if (customer.identificationNumber.length > 14) {
      isCnpj = true;
    } else if (customer.identificationNumber.length === 0) {
      isCnpj = false;
    }

    if (isCnpj) {
      const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;
      if (!cnpjRegex.test(customer.identificationNumber)) errors.push("cnpj");

      if (
        customer.businessName.length > 80 ||
        customer.businessName.length === 0
      )
        errors.push("businessName");
    } else {
      if (customer.firstName.length > 45 || customer.firstName.length === 0)
        errors.push("customerName");

      if (customer.lastName.length > 45 || customer.lastName.length === 0)
        errors.push("customerLastname");

      if (customer.birthdate) {
        const birthdate = moment(customer.birthdate, "DD/MM/YYYY", true);
        if (!birthdate.isValid() || birthdate.isAfter(moment()))
          errors.push("birthdate");
      }

      if (customer.identificationNumber.indexOf("/") > 0) {
        let cpfClean = customer.identificationNumber
          .replace(".", "")
          .replace(".", "")
          .replace("/", "");

        let newCpf = cpfClean.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3-$4"
        );

        const cpfRegex = /\d{3}.\d{3}.\d{3}-\d{2}/;
        if (!cpfRegex.test(newCpf)) errors.push("cpf");
      } else {
        const cpfRegex = /\d{3}.\d{3}.\d{3}-\d{2}/;

        if (customer.identificationNumber.length === 11) {
          let formattedIdentificationNumber =
            customer.identificationNumber.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/,
              "$1.$2.$3-$4"
            );

          if (!cpfRegex.test(formattedIdentificationNumber)) errors.push("cpf");
        } else {
          if (!cpfRegex.test(customer.identificationNumber)) errors.push("cpf");
        }
      }
    }

    // if(customer.address.zipcode && customer.address.zipcode.length < 9) errors.push("customerZipcode");

    const zipcodeRegex = /\d{5}-\d{3}/;
    if (customer.address.zipcode.length === 8) {
      let formattedZipcode = customer.address.zipcode.replace(
        /(\d{5})(\d{3})/,
        "$1-$2"
      );

      if (!zipcodeRegex.test(formattedZipcode)) errors.push("customerZipcode");
    } else {
      if (!zipcodeRegex.test(customer.address.zipcode))
        errors.push("customerZipcode");
    }

    if (
      customer.address.street.length > 70 ||
      customer.address.street.length == 0
    )
      errors.push("street");

    if (
      customer.address.number.length > 10 ||
      customer.address.number.length == 0
    )
      errors.push("number");

    if (
      customer.address.district.length > 70 ||
      customer.address.district.length == 0
    )
      errors.push("district");

    if (customer.address.city.length > 70 || customer.address.city.length == 0)
      errors.push("city");

    if (customer.address.state < 0) errors.push("state");

    if (customer.phone) {
      if (customer.phone.length < 14) errors.push("customerPhone");
    }

    const emailExpression = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/;
    if (!emailExpression.test(customer.email)) errors.push("customerEmail");

    if (boleto && manualOrder && newPlan.name === "") {
      if (isNaN(parseFloat(manualOrder.amount))) errors.push("orderAmount");
    }
  } else {
    if (customer.identificationNumber.length > 14) {
      isCnpj = true;
    } else if (customer.identificationNumber.length === 0) {
      isCnpj = false;
    }

    if (isCnpj) {
      const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;
      if (!cnpjRegex.test(customer.identificationNumber)) errors.push("cnpj");

      if (
        customer.firstName &&
        customer.firstName !== "" &&
        customer.firstName.length > 80
      )
        errors.push("businessName");
    } else {
      if (
        customer.firstName &&
        customer.firstName !== "" &&
        customer.firstName.length > 45
      )
        errors.push("customerName");

      if (
        customer.lastName &&
        customer.lastName !== "" &&
        customer.lastName.length > 45
      )
        errors.push("customerLastname");

      const birthdate = moment(customer.birthdate, "DD/MM/YYYY", true);
      if (
        customer.birthdate &&
        customer.birthdate !== "" &&
        (!birthdate.isValid() || birthdate.isAfter(moment()))
      )
        errors.push("birthdate");

      if (
        customer.identificationNumber &&
        customer.identificationNumber !== "" &&
        customer.identificationNumber.indexOf("/") > 0
      ) {
        let cpfClean = customer.identificationNumber
          .replace(".", "")
          .replace(".", "")
          .replace("/", "");

        let newCpf = cpfClean.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3-$4"
        );

        const cpfRegex = /\d{3}.\d{3}.\d{3}-\d{2}/;

        if (!cpfRegex.test(newCpf)) errors.push("cpf");
      } else {
        const cpfRegex = /\d{3}.\d{3}.\d{3}-\d{2}/;

        if (
          customer.identificationNumber &&
          customer.identificationNumber !== "" &&
          customer.identificationNumber.length === 11
        ) {
          let formattedIdentificationNumber =
            customer.identificationNumber.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/,
              "$1.$2.$3-$4"
            );

          if (!cpfRegex.test(formattedIdentificationNumber)) errors.push("cpf");
        } else {
          if (
            customer.identificationNumber &&
            customer.identificationNumber !== "" &&
            !cpfRegex.test(customer.identificationNumber)
          )
            errors.push("cpf");
        }
      }
    }

    const zipcodeRegex = /\d{5}-\d{3}/;
    if (
      customer.address.zipcode &&
      customer.address.zipcode !== "" &&
      customer.address.zipcode.length === 8
    ) {
      let formattedZipcode = customer.address.zipcode.replace(
        /(\d{5})(\d{3})/,
        "$1-$2"
      );

      if (!zipcodeRegex.test(formattedZipcode)) errors.push("customerZipcode");
    } else {
      if (
        customer.address.zipcode &&
        customer.address.zipcode !== "" &&
        !zipcodeRegex.test(customer.address.zipcode)
      )
        errors.push("customerZipcode");
    }

    if (
      customer.address.street &&
      customer.address.street !== "" &&
      customer.address.street.length > 70
    )
      errors.push("street");

    if (
      customer.address.number &&
      customer.address.number !== "" &&
      customer.address.number.length > 10
    )
      errors.push("number");

    if (
      customer.address.district &&
      customer.address.district !== "" &&
      customer.address.district.length > 70
    )
      errors.push("district");

    if (
      customer.address.city &&
      customer.address.city !== "" &&
      customer.address.city.length > 70
    )
      errors.push("city");

    if (customer.address.city && customer.address.state < 0)
      errors.push("state");

    if (customer.phone) {
      if (customer.phone !== "" && customer.phone.length < 14)
        errors.push("customerPhone");
    }

    const emailExpression = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/;
    if (
      customer.email &&
      customer.email !== "" &&
      !emailExpression.test(customer.email)
    )
      errors.push("customerEmail");
  }

  if (discount) {
    if (discount.mode === "Fixed") {
      if (
        parseFloat(discount.amount) > parseFloat(manualOrder!.amount) ||
        parseFloat(discount.amount) < 0
      ) {
        errors.push("discountAmount");
      }
    } else {
      if (
        parseFloat(discount.amount) > 100 ||
        parseFloat(discount.amount) < 0
      ) {
        errors.push("discountAmount");
      }
    }
  }

  if (lateFee) {
    if (lateFee.mode === "Fixed") {
      let twoPercentLimit = parseFloat(manualOrder!.amount) * 0.02;

      if (
        parseFloat(lateFee.amount) < 1 ||
        parseFloat(lateFee.amount) < 0 ||
        parseFloat(lateFee.amount) > twoPercentLimit
      ) {
        errors.push("lateFeeAmount");
      }
    } else {
      if (parseFloat(lateFee.amount) > 2) {
        errors.push("lateFeeAmount");
      }
    }
  }

  if (interest) {
    if (interest.mode === "Daily_Amount") {
      let percentLimit = parseFloat(manualOrder!.amount) * 0.00033;

      if (
        parseFloat(interest.amount) < 1 ||
        parseFloat(interest.amount) < 0 ||
        parseFloat(interest.amount) > percentLimit
      ) {
        errors.push("interestAmountDailyA");
      }
    } else if (interest.mode === "Daily_Percentage") {
      if (parseFloat(interest.amount) > 0.033) {
        errors.push("interestAmountDailyP");
      }
    } else if (interest.mode === "Monthly_Percentage") {
      if (parseFloat(interest.amount) > 1) {
        errors.push("interestAmountMontlyP");
      }
    }
  }

  handleErrors(errors);

  return errors.length === 0;
}
