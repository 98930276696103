const bexsAllowCurrency = [
  {id: 0, currency: 'BRL', symbol: 'R$'},
  {id: 1, currency: 'USD', symbol: 'US$'},
  {id: 2, currency: 'EUR', symbol: '€'},
  {id: 3, currency: 'GBP', symbol: '£'},
  {id: 4, currency: 'CAD', symbol: 'C$'},
  {id: 5, currency: 'AUD', symbol: 'AU$'},
  {id: 6, currency: 'SEK', symbol: 'KR$'},
  {id: 7, currency: 'NOK', symbol: 'KR$'},
  {id: 8, currency: 'DKK', symbol: 'KR$'},
  {id: 9, currency: 'JPY', symbol: '¥'},
]

export default bexsAllowCurrency;