import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

//Components
import { Button } from '../Index';

//Assets
import plusCircle from "../../../assets/images/plus-circle.svg";

//Styles
import * as styled from './topbar-mobile-styles';

export const TopBarMobile = (props: any) => {
  const history = useHistory();
  const intl = useIntl();

  const [storeId, setStoreId] = useState(() => {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return session.storeId;
  });

  function handleToRequestPayment() {
    history.push('/request-payment');
  }

  return (
    <styled.TopbarMobile>
      {/* <Logo /> */}

      <styled.Left>
        {/* <ViewAs /> */}
        <Button label="Solicitar pagamento" icon={plusCircle} handler={handleToRequestPayment} css="margin-right" />
        {/* <Search placeholder="Search for an order or an cpf..." /> */}
      </styled.Left>
    </styled.TopbarMobile>
  )
}

export default TopBarMobile;
