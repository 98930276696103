import styled from "styled-components";

interface FormProps {
  background: string;
}

interface DisabledText {
  disabled?: boolean;
}

interface DisabledSwitchCursor {
  disabled?: boolean;
}

export const Sidebar = styled.div`
  width: 220px;
  background: ${(props) => props.theme.backgroundColors.fourth} 0% 0% no-repeat
    padding-box;
  border-right: 1px solid ${(props) => props.theme.backgroundColors.secondary};
  position: fixed;
  left: 0;
  top: 48px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.double-top-margin {
    top: 96px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 48px 8px 40px 8px;
  box-sizing: border-box;

  .menu-nav_item {
    padding: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 20px;
    text-align: center;
    text-decoration: none;
    color: ${(props) => props.theme.contentColors.primary};
    transition: color after 1s;
    margin-bottom: 24px;

    span {
      display: flex;
    }

    img {
      margin-right: 14px;
    }

    .label {
      width: auto;
      font-family: "Brandon Text Medium" !important;
    }

    &:hover {
      transition: ease 0.3s;
      opacity: 0.8;
    }

    &.active {
      color: ${(props) => props.theme.contentColors.fourth};
      position: relative;
      font-weight: 500;
    }
  }

  @keyframes growVertical {
    from {
      height: 0;
      top: 50%;
    }
    to {
      top: 0;
      height: 100%;
    }
  }
`;

export const IconItem = styled.div<FormProps>`
  background-color: ${(props) => props.theme.textColors.primary};
  -webkit-mask: url(${(props) => props.background}) no-repeat;
  mask: url(${(props) => props.background}) no-repeat;
  height: 20px;
  width: 20px;
  margin-right: 16px;
`;

export const SwitchButton = styled.div<DisabledSwitchCursor>`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 120px;
  left: 20px;

  .react-switch-bg {
    cursor: ${(props) =>
      props.disabled ? "not-allowed" : "pointer"} !important;
  }

  p {
    color: ${(props) => props.theme.contentColors.primary};
  }
`;

export const TextSwitchButton = styled.p<DisabledText>`
  margin-left: 8px;
  color: ${(props) => props.theme.textColors.primary};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
